<div id="alertTable">
    <table>
        <thead>
            <tr>
                <th>
                </th>
                <th class="tableHeaderCell" id="alertNameHeader">
                    Alert Name
                </th>
                <th class="tableCell tableHeaderCell" id="tableHeaderSeverity">
                    <app-table-select-filter [data]="filterColumns['severity']"
                        [name]="'Severity'" (handleAppliedFilter)="handleAppliedFilter($event)"
                        (applySorting)="sortTable($event)">
                    </app-table-select-filter>
                </th>
                <th class=" tableCell tableHeaderCell">
                    <app-table-select-filter [data]="filterColumns['status']"
                        [name]="'Status'" (handleAppliedFilter)="handleAppliedFilter($event)"
                        (applySorting)="sortTable($event)">
                    </app-table-select-filter>
                </th>
                <th class=" tableCell tableHeaderCell">
                    <app-table-select-filter [data]="filterColumns['alertType']"
                            [name]="'Alert Type'" (handleAppliedFilter)="handleAppliedFilter($event)"
                            (applySorting)="sortTable($event)">
                    </app-table-select-filter>
                </th>
                <th class="tableCell tableHeaderCell">
                    <span>Created on</span>
                    <app-table-sort-icon [data]="sortFilter['createdOn']"
                        (applySorting)="sortTable($event)" (handleAppliedFilter)="handleAppliedFilter($event)"
                        class="sortingIcon"></app-table-sort-icon>
                </th>
                <th class="tableCell tableHeaderCell">
                    <span>Active For</span>
                    <app-table-sort-icon [data]="sortFilter['activeFor']"
                        (applySorting)="sortTable($event)" (handleAppliedFilter)="handleAppliedFilter($event)"
                        class="sortingIcon">
                    </app-table-sort-icon>
                </th>
                <th class="lastColumn tableHeaderCell"> </th>
            </tr>
        </thead>
        <tbody>
            <!-- if it is loading show loader -->
            <ng-container *ngIf="loading">
                <tr class="loadingGif">
                    <td colspan="8" class="loading">
                        <div class="loader"></div>
                    </td>
                </tr>
            </ng-container>
            <!-- if it is not loading and no data found show no data found -->
            <ng-container *ngIf="!loading && paginatedData?.length == 0">
                <tr id="noResult">
                    <td colspan="8" class="noResult">
                            No alerts avaliable
                    </td>
                </tr>
            </ng-container>
            <!-- otherwise show alerts -->
            <ng-container *ngIf="!loading && paginatedData?.length != 0">
                <tr *ngFor="let alert of paginatedData" style="cursor: pointer;">
                    <td id="firstEmptyColumn">
                    </td>
                    <td id="secondColumn" matTooltip="{{alert.alertName}}" matTooltipPosition="above" matTooltipClass="above" (click)="goToManageAlert(alert)">
                        <div class="notificationType alertTd">
                            <span class="notificationName">{{alert.alertName|titlecase}}</span>
                        </div>
                    </td>
                    <td class="tableCell">
                        <div class="d-flex justify-content-center align-items-center" *ngIf="alert.deviceTypeOrFacility == 'DubaPlantScada' || !alert.severity " style="height:20px; width:50px">
                            -
                        </div>
                        <div *ngIf="alert.severity" class="d-flex justify-content-center align-items-center severity"
                            [ngClass] = "getBorderColor(alert.severity)">
                            <div class="statusColor" [ngClass]="getDotColor(alert.severity)"></div>
                            <div [ngClass]="getTextColor(alert.severity)">{{alert.severity}}</div>
                        </div>
                    </td>
                    <td class="tableCell" (click)="goToManageAlert(alert)">
                        <span class="status" [ngClass]="getStatusClass(alert.status)">{{alert.status}}</span></td>
                    <td class="tableCell" (click)="goToManageAlert(alert)">
                        <span class="mutedValue">{{getAlertType(alert.alertType)}}</span>
                    </td>
                    <td class="tableCell" (click)="goToManageAlert(alert)">
                        <span class="mutedValue">{{alert.createdOn | date:"MMM dd, yyyy"}}</span>
                    </td>
                    <td class="tableCell" (click)="goToManageAlert(alert)">
                        <span class="mutedValue">{{alert.activeFor}}</span> <!--{{calculateDaysActive(alert.eventStart, alert.eventEnd)}}-->
                    </td>
                    <td class="lastColumn"></td>
                </tr>
            </ng-container>
        </tbody>
    </table> 
</div>
<app-pagination
#tablePagination [currentPage]="currentPage" 
[data]="allAlerts" [itemPerPage]="itemPerPage" (paginationDone)="handlePaginationDone($event)"
[COLUMN_NAMES]="columnNames" [appliedFilters]="appliedFilters" [pageSizeOption]="pageSizeOption">
</app-pagination>
