<div class="dashboardMeterBlk">
    <div class="consumptionBlk">
        <div class="iconBlk">
            <span class="material-symbols-outlined">water_drop</span>
            <a routerLink="/watermeters/dashboard"><span class="alertbtnBg"><img class="shareIcon" src="../../../../assets/images/share-icon-gray.svg"> </span></a>
        </div>
        <div class="meterCount">
            <ul>
                <li>Water Meters</li>
                <li>{{getWaterMeterReading('Water Meter')?.totalDevices}}</li>
            </ul>
            <h3 class="meterValume">{{getWaterMeterReading('Water Meter')?.liveDevices}} online <span>/ {{getWaterMeterReading('Water Meter')?.offlineDevices}} offline</span></h3>
            <div class="onlineOffline">
                <div class="onlineDevice"  [ngStyle]="getStyles(getWaterMeterReading('Water Meter'))" ></div>
            </div>
        </div>
    </div>
    <div class="consumptionBlk">
        <div class="iconBlk">
            <span class="material-symbols-outlined">electric_bolt</span>
            <a routerLink="/energymeters/dashboard"><span class="alertbtnBg"><img class="shareIcon" src="../../../../assets/images/share-icon-gray.svg"> </span></a>
        </div>
        <div class="meterCount">
            <ul>
                <li>Energy Meters</li>
                <li>{{getWaterMeterReading('Energy Meter')?.totalDevices}}</li>
            </ul>
            <h3 class="meterValume">{{getWaterMeterReading('Energy Meter')?.liveDevices}} online <span>/ {{getWaterMeterReading('Energy Meter')?.offlineDevices}} offline</span></h3>
            <div class="onlineOffline">
                <div class="onlineDevice" [ngStyle]="getStyles(getWaterMeterReading('Energy Meter'))"></div>
            </div>
        </div>
    </div>
    <div class="consumptionBlk">
        <div class="iconBlk">
            <span class="material-symbols-outlined">mode_fan</span>
            <a routerLink="/watermeters-customer/flow-meters"><span class="alertbtnBg"><img class="shareIcon" src="../../../../assets/images/share-icon-gray.svg"> </span></a>
        </div>
        <div class="meterCount">
            <ul>
                <li>Flow Meters</li>
                <li>{{getWaterMeterReading('Flow Meter')?.totalDevices}}</li>
            </ul>
            <h3 class="meterValume">{{getWaterMeterReading('Flow Meter')?.liveDevices}} online <span>/ {{getWaterMeterReading('Flow Meter')?.offlineDevices}} offline</span></h3>
            <div class="onlineOffline">
                <div class="onlineDevice" [ngStyle]="getStyles(getWaterMeterReading('Flow Meter'))"></div>
            </div>
        </div>
    </div>
    <div class="consumptionBlk">
        <div class="iconBlk">
            <span class="material-symbols-outlined">humidity_mid</span>
        </div>
        <div class="meterCount">
            <ul>
                <li>Water Facilities</li>
                <li>{{getWaterMeterReading('all')?.totalDevices}}</li>
            </ul>
            <h3 class="meterValume">{{getWaterMeterReading('all')?.liveDevices}} online <span>/ {{getWaterMeterReading('all')?.offlineDevices}} offline</span></h3>
            <div class="onlineOffline">
                <div class="onlineDevice" [ngStyle]="getStyles(getWaterMeterReading('all'))"></div>
            </div>
        </div>
    </div>
</div>