import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { loadModules } from "esri-loader";
import { ArcgisMapService } from 'src/app/services/arcgis-map/arcgis-map.service';
@Component({
  selector: 'app-arcgis-device-location',
  templateUrl: './arcgis-device-location.component.html',
  styleUrls: ['./arcgis-device-location.component.css']
})
export class ArcgisDeviceLocationComponent implements OnInit {
  @Input() mapInfo;
  // The <div> where we will place the map
  @ViewChild("mapViewNode", { static: true }) private mapViewEl: ElementRef;
  view;
  map;
  graphicsLayer;
  constructor(private arcgisMapService: ArcgisMapService) { }

  ngOnInit(): void {
  }

  async ngOnChanges() {
    if(this.mapInfo) {
      await this.arcgisMapService.initializeMap(this.mapViewEl, this.mapInfo);
      this.map = this.arcgisMapService.getMapInstance();
      this.view = this.arcgisMapService.getViewInstance();
      await this.createMap();
    }
  }

  async createMap() {
    const [ Graphic, GraphicsLayer, PictureMarkerSymbol] = await loadModules([
      "esri/Graphic",
      "esri/layers/GraphicsLayer",
      "esri/symbols/PictureMarkerSymbol"
    ]);

    this.graphicsLayer = new GraphicsLayer();
    this.map.add(this.graphicsLayer);

    const point = {
      type: "point",
      longitude: this.mapInfo.longitude,
      latitude: this.mapInfo.latitude,
    }
    const locationSymbol = new PictureMarkerSymbol({
      url: "../../../assets/images/gps_pin_with_shadow.svg", // Replace with the URL to your location icon image
      width : 32,
      height: 38,
      yoffset : 10
    });
    // add point to graphic layer
    const graphic = new Graphic({
      geometry : {
        type: point.type,
        longitude: point.longitude,
        latitude: point.latitude
      },
      symbol: locationSymbol,
    })
    this.graphicsLayer.add(graphic);
  }
}