import * as d3 from "d3";
import { chartCustomScrollhandler, convertDate, convertValues, createSubGroups, formatAxisConfig, formatData, getFilterPositionNative, hideTooltip, setChartConfig, updateAxisConfig, updateBrushHandle } from "./chart-helper";
import { v4 as uuidv4 } from 'uuid';
import * as moment from "moment";
import { DATE_TIME_FORMAT_IGNORE_MILLISECONDS } from "../constant";
import { COLOR, tooltipCircleRadius, tooltipCircleStrokeOpacity, tooltipCircleStrokeWidth, filterScrollTextPadding, handleWidth, lineOpacity, tooltipLineColor, tooltipLineDasharray, tooltipLineWidth, lineWidth, VPmargin, viewportWidth, viewportHeight, handleWidthVP, tickSize } from "./chart-config";

export const lineGraphForNativeSplitYAxis = (element, data, config) => {

  const mainElement = d3.select(element);
  let width = mainElement.node()["clientWidth"];
  let height = mainElement.node()["clientHeight"];
  const brush = d3.brushX();
  let zoom;
  let handle, context, ploatFiltersvg;
  const guid = uuidv4()
  let subgroups, color;
  let vwheight = (parseFloat(VPmargin.ploatfilterheight) / 100) * viewportWidth;
  let vhmarginleft =  (parseFloat(VPmargin.yAxisMarginleft) / 100) * viewportHeight;
  let vwmarginTop = (parseFloat(VPmargin.yAxisMarginTop) / 100) * viewportWidth;
  let vwhandleWidth = (parseFloat(handleWidthVP) / 100) * viewportWidth;
  let vwmarginBottom = (parseFloat(VPmargin.xAxisMarginBottom) / 100) * viewportWidth;
  let ploatFilterHeight = config.ploatFilterHeight || vwheight;
  const dateList = [];
  const dataPoints = [];
  if (typeof (config.attributes) === "string") {
    subgroups = config.attributes;
  }
  else {
    subgroups = createSubGroups(config.attributes);
  }
  config.chartConfig = setChartConfig(config.chartConfig);
  width = width - vhmarginleft - config.chartConfig.margin.right;
  height = height - vwmarginTop - vwmarginBottom;

  if (config.showScrollbar) {
    height = height - ploatFilterHeight;
  }

  const bisectDate = d3.bisector(function (d: any) { return d; }).left;

  d3.selectAll(`${element} svg`).remove();
  d3.selectAll(`${element} .chart-tooltip`).remove();

  data = data.filter(item => {
    const row = subgroups.find(x => x.name === item.name)
    if (row.display || row.display === undefined) {
      return item;
    }
  })

  /* Format Data */
  data?.forEach(function (d) {
    d.nativeList.forEach(function (d) {
      d.dateTime = convertDate(d.dateTime);
      d.value = parseFloat(d.value);
      dateList.push(d.dateTime);
      dataPoints.push(d.value);
    });
  });
  dateList.sort(function (a, b) { return a - b });

  const _x = d3.scaleTime()
    .domain(d3.extent(dateList))
    .range([0, width]);

  color = d3.scaleOrdinal()
    .domain(subgroups.map(x => x.name || x.description))
    .range(COLOR);

  /* Add tooltip */
  const tooltip = mainElement
    .append("div")
    .classed('chart-tooltip', true);

  /* Add SVG */
  const svg = mainElement
    .append("svg")
    .attr('class', 'chart-plot')
    .attr("width", (width + vhmarginleft + config.chartConfig.margin.right) + "px")
    .attr("height", (height + vwmarginTop + vwmarginBottom) + "px");

  /* Add Axis into SVG */
  const xScale = d3.scaleTime()
    .domain(d3.extent(dateList))
    .range([0, width]);

  const xAxis = d3.axisBottom(xScale);

  svg.append("defs").append("clipPath")
    .attr("id", `clip-${guid}`)
    .append("rect")
    .attr("x", 0)
    .attr("width", width)
    .attr("height", height);

  // svg.append("g")
  //   .attr("class", "axis-bottom x-axis")
  //   .attr("transform", `translate(${vhmarginleft}, ${height - vwmarginTop})`)
  //   .attr("clip-path", `url(#clip-${guid})`)
  //   .call(xAxis);

  const yAxis = [];
  const yScale = [];
  const line = [];
  const yAxisGrid = [];

  // let ticksSize = Math.floor(((height / data.length) - (vwmarginTop * data.length)) / 20);
  // ticksSize = ticksSize <= 0 ? 2 : ticksSize
  let ticksSize = 5;

  const attributes = config.attributes.filter(res => {
    if (res.display || res?.display === undefined) {
      return res
    }
  })

  attributes.forEach((element, index) => {
    let _height = (height / data.length);
    const container = svg.append('g')
      .attr("transform", (d) => {
        return `translate(${vhmarginleft}, ${vwmarginTop + ((index * _height))}  )`
      });

    /* Scale */
    const maxValue = d3.max(data[index].nativeList.map(x => parseFloat(x.value))) || 10;
    const minValue = d3.min(data[index].nativeList.map(x => parseFloat(x.value))) || 0;

    yScale[index] = d3.scaleLinear()
      .domain([+minValue, +maxValue])
      .range([_height - vwmarginBottom, vwmarginTop])
      .nice();

    yAxisGrid[index] = d3.axisLeft(yScale[index]).ticks(ticksSize).tickSize(-width).tickFormat((d) => { return '' });

    line[index] = d3.line()
      .x((d: any) => {
        return xScale(d.dateTime)
      })
      .y((d: any) => yScale[index](d.value || 0));

    container.append('g')
      .attr('class', `y-axis-grid y-axis-grid-${index}`)
      .call(yAxisGrid[index]);

    container.append("path")
      .datum(data[index].nativeList)
      .attr('class', 'line')
      .style('stroke-width', lineWidth)
      .style('stroke', (d: any, i: any) => color(element.name || element.description))
      .style('opacity', lineOpacity)
      .style('fill', 'none')
      .attr("clip-path", `url(#clip-${guid})`)
      .attr('d', (d: any) => line[index](data[index].nativeList))

    /* Add Axis into SVG */
    yAxis[index] = d3.axisLeft(yScale[index])

    container.append("g")
      .attr("class", `axis-left y-axis y-axis-${index}`)
      .call(yAxis[index].tickFormat((d: any) => {
        return convertValues(d);
      }).ticks(ticksSize))
      .append('text')
      .attr("y", 15)
      .attr("transform", "rotate(-90)")
      .attr("fill", "#000")

    formatAxisConfig(svg, config.chartConfig, config.splitYAxis);

    container
      .append("circle")
      .attr('class', (d: any, i: any) => `circle-tooltip circle-tooltip-${index}`)
      .attr("r", tooltipCircleRadius)
      .style('fill', (d: any, i: any) => color(element.name || element.description))
      .style('stroke', (d: any, i: any) => color(element.name || element.description))
      .style('opacity', 0)
      .style('stroke-width', tooltipCircleStrokeWidth)
      .style('stroke-opacity', tooltipCircleStrokeOpacity)

    if (index + 1 === attributes.length) {

      if (config.showScrollbar) {
        ploatFiltersvg = mainElement
          .append("svg")
          .attr("class", 'filter-plot')
          .attr("width", width + vhmarginleft + config.chartConfig.margin.right)
          .attr("height", ploatFilterHeight)
          .append('g')
          .attr("transform", `translate(${vhmarginleft},${0})`);

        brush.extent([[0, 0], [width, ploatFilterHeight]])
          .on("brush", (d) => {
            if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom

            hideTooltip(svg, tooltip)
            // get bounds of selection
            const s = d3.event.selection || _x.range();
            xScale.domain(s.map(_x.invert, _x));
            svg.select(".x-axis").call(xAxis);

            svg.selectAll(".line")
              .attr('d', (d: any, i: any) => {
                /* Scale */
                const maxValue = formatData([data[i]], xScale.domain());
                yScale[i].domain([d3.min(maxValue), d3.max(maxValue)]).nice();
                svg.select(`.y-axis-${i}`).call(yAxis[i]);
                updateAxisConfig(yAxisGrid[i], yScale[i], svg, `.y-axis-grid-${i}`);
                formatAxisConfig(svg, config.chartConfig);
                return line[i](data[i].nativeList);
              });

            svg.select(".zoom").call(zoom.transform, d3.zoomIdentity
              .scale(width / (s[1] - s[0]))
              .translate(-s[0], 0));

            updateBrushHandle(context);
            handle.attr("x", (d) => {
              const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
              return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
            })
          });
        const xAxisFilter = d3.axisTop(_x).tickSize(0).tickPadding(filterScrollTextPadding);
        const linesFilter = ploatFiltersvg.append('g')
        linesFilter.append("g")
          .attr("class", "x-axis-filter")
          .attr("transform", `translate(0, ${ploatFilterHeight})`)
          .call(xAxisFilter);

        context = ploatFiltersvg.append("g")
          .attr("class", "context")
          .attr('transform', 'translate(' + 0 + ', ' + 0 + ')');

        handle = chartCustomScrollhandler(context, brush, ploatFilterHeight, ploatFiltersvg);
      }

      zoom = d3.zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([[0, 0], [width, height]])
        .extent([[0, 0], [width, height]])
        .on("zoom", (d) => {
          if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush
          hideTooltip(svg, tooltip)
          mainElement.select(".zoom").style("cursor", 'move');

          const t = d3.event.transform;
          xScale.domain(t.rescaleX(_x).domain());
          svg.select(".x-axis").call(xAxis);
          svg.selectAll(".line")
            .attr('d', (d: any, i: any) => {
              /* Scale */
              const maxValue = formatData([data[i]], xScale.domain());
              yScale[i].domain([d3.min(maxValue), d3.max(maxValue)]).nice();
              svg.select(`.y-axis-${i}`).call(yAxis[i]);
              updateAxisConfig(yAxisGrid[i], yScale[i], svg, `.y-axis-grid-${i}`);
              formatAxisConfig(svg, config.chartConfig);
              return line[i](data[i].nativeList);
            });

          mainElement.select(".x_brush").call(brush.move, xScale.range().map(t.invertX, t));
          updateBrushHandle(context);
          handle.attr("x", (d) => {
            const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
            return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
          })
        });

      svg.selectAll('.circle-tooltip')
        .attr("transform", `translate(${vhmarginleft}, ${0})`)
        .data(data).enter()
        .append("circle")
        .attr("class", "circle-tooltip")
        .attr("r", tooltipCircleRadius)
        .style('fill', (d: any, i: any) => color(d.name || d.description))
        .style('opacity', 0)

      svg
        .append("line")
        .attr("transform", `translate(${vhmarginleft}, ${0})`)
        .attr("class", "line-tooltip")
        .style("stroke", tooltipLineColor)
        .style("stroke-width", tooltipLineWidth)
        .style("stroke-dasharray", tooltipLineDasharray)
        .style('opacity', 0);

      svg.append("rect")
        .attr("class", "zoom")
        .attr("width", width)
        .attr("height", height)
        .style("fill", 'none')
        .style("cursor", 'move')
        .style("pointer-events", 'all')
        .attr("transform", `translate(${vhmarginleft}, ${0})`)
        .call(zoom)
        .on("mouseout", () => {
          hideTooltip(svg, tooltip)
        })
        .on("mousemove touchmove", () => {
          let toolTipText = '';
          const x0: any = xScale.invert(d3.event.offsetX - vhmarginleft),
            i = bisectDate(dateList, x0, 1),
            d0 = dateList[i - 1],
            d1 = dateList[i];
          let dateTime = x0 - d0 > d1 - x0 ? d1 : d0;

          mainElement.select(".zoom").style("cursor", 'pointer');
          for (let _index = 0; _index < data.length; _index++) {
            const d0 = data[_index].nativeList.find(x => moment(x.dateTime).format(DATE_TIME_FORMAT_IGNORE_MILLISECONDS) === moment(dateTime).format(DATE_TIME_FORMAT_IGNORE_MILLISECONDS));
            svg.select(`.circle-tooltip-${_index}`)
              .attr("cx", (_d, _i) => {
                if (d0) {
                  return xScale(d0.dateTime) - vhmarginleft;
                }
              })
              .attr("cy", (_d: any, _i) => {
                if (d0) {
                  toolTipText += `<p><span class='legend-box' style='background-color:${color(_d.name || _d.description)}'></span> ${dateTime.toLocaleString('en-GB')}  ${_d.name || _d.description} : ${d0.value.toFixed(3)}</p>`;
                  return yScale[_index](d0.value);
                }
              })
              .style("opacity", (_d: any, _i) => {
                if (d0) {
                  return 1;
                }
                return 0;
              })
          }

          svg.selectAll('.line-tooltip')
            .attr("x1", xScale(dateTime))
            .attr("y1", 0)
            .attr("x2", xScale(dateTime))
            .attr("y2", height)
            .style('opacity', 1);

          if (config.alertRange && (dateTime >= convertDate(config.alertRange.startDate) && dateTime <= convertDate(config.alertRange.endDate))) {
            toolTipText += `<p>Alert Start Time : ${moment(config.alertRange.startDate).format("DD/MM/YYYY, HH:mm:ss")} </p>`;
            toolTipText += config.alertRange.isActive ? '' : `<p>Alert End Time : ${moment(config.alertRange.endDate).format("DD/MM/YYYY, HH:mm:ss")} </p>`;
          }

          tooltip
          .style("opacity", "0.89")
            .html(toolTipText)

          tooltip.node().style.right = 'auto';
          tooltip.node().style.top = 'auto';
          tooltip.node().style.left = 'auto';

          let width = svg.node().clientWidth;

          let tipWidth = tooltip.node().offsetWidth;
          let tipHeight = tooltip.node().offsetHeight;

          if (width - xScale(dateTime) < tipWidth) {
            tooltip.node().style.right = width - xScale(dateTime) - vhmarginleft + 10 + 'px';
            tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
            tooltip.node().style.left = 'auto';
            tooltip.classed('chart-tooltip-right', true);
            tooltip.classed('chart-tooltip-left', false);
          }
          else {
            tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
            tooltip.node().style.left = d3.event.layerX + 10 + 'px';
            tooltip.node().style.left = xScale(dateTime) + vhmarginleft + 10 + 'px';
            tooltip.node().style.right = 'auto';
            tooltip.classed('chart-tooltip-right', false);
            tooltip.classed('chart-tooltip-left', true);
          }
        });
      ploatFiltersvg.select('.x_brush').call(brush.move, _x.range());

    }
  });
  svg.append("g")
    .attr("class", "axis-bottom x-axis")
    .attr("transform", `translate(${vhmarginleft}, ${height - vwmarginTop})`)
    .attr("clip-path", `url(#clip-${guid})`)
    .call(xAxis);
    formatAxisConfig(svg, config.chartConfig);

}

