import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomDashboardLogsService {

  constructor(private apiService: ApiService, private http: HttpClient) { }

  public saveCustomDashboardLog(postData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post('/dashboard/dashboardLog', postData)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }
}
