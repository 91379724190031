<!--------------------- Modal download ------------------------------------>

<div class="downloadIcon">
    <div class="download-yl">
        <img src="assets/images/register-device.svg" class="registerDeviceIcon">
    </div>
    <button type="button" class="close" mat-dialog-close aria-label="Close">
        <img src="assets/images/cross.svg" class="closeModalIcon" width="25px">
    </button>
</div>
<div class="download-area">
    <h5 class="modal-title" id="addtagsTitle">Register Device</h5>
    <span>Choose single device registration or streamline the process by registering devices in bulk. Please select your
        preferred option below to proceed.</span>
</div>


<div class="modal-body pb-none">
    <div class="form-group downloadDataClass">

        <div class="devicelistingBtn" (click)="openDeviceDialog(null,true)">Register single device <span> > </span>
        </div>
        <div class="devicelistingBtn pb-none" (click)="openBulkRegistrationDialog()">Register devices in bulk <span> > </span>
        </div>

    </div>
</div>

<!----------------------end Modal download ----------------------------------->