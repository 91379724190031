import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-unlink-button',
  templateUrl: './unlink-button.component.html',
  styleUrls: [
    './unlink-button.component.css',
    '../../../assets/Reusable-CSS/matTooltip.scss'

  ]
})

export class UnlinkButtonComponent implements OnInit {
  @Input() loading : boolean;
  constructor() { }

  ngOnInit(): void {
    console.log('loading ->', this.loading);
  }

}
