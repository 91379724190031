import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.css']
})
export class NoResultsComponent implements OnInit {

  @Input()
  public title = "No Result Found";
  @Input()
  public subTitle = "Try adjusting your search or filter to find what you are looking for";
  @Input()
  public showIcon : boolean = true;
  @Input()
  public iconUrl : string = '../../../assets/images/no-search-result.png';
  constructor() { }

  ngOnInit(): void {
  }

}
