import { HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { getLoginUser } from 'src/app/reusable/user-util';
import { ApiService } from "src/app/services/api.service";

@Injectable({
  providedIn: "root"
})
export class LoginService {

  constructor(private apiService: ApiService) {
  }

  public isAuthenticated(filters: any, options?: any) {

    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.post("/user/login", filters, options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public validateOtp(payload: any, options: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.post("/user/validate-otp", payload, options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public logout() {
    return new Promise((resolve, reject) => {
      this.apiService.post(`/user/logout`, null).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }
}
