export const deviceStatuses = {
    LIVE: {status: 'live', color: 'green'},
    ON_LINE: {status: 'online', color: ''},
    OFF_LINE: {status: 'offline', color: 'red'},
    ACTIVE: {status: 'active', color: 'green'},
    IN_ACTIVE: {status: 'inactive', color: 'grey'},
    REGISTERED: {status: 'registered', color: ''}
};

export const colorCode = {
    RED: {name: 'red', code: '', class: ''},
    GREEN: {name: 'green', code: '', class: ''}
};

export const WATER_METER_TELEMETRYDATA_COLUMNS = ['Time', 'Temperature', 'Volume', 'Flow'];
export const AGGREGATE_DATA_HOUR = 'hourly';
export const AGGREGATE_DATA_DAY = 'daily';
export const AGGREGATE_DATA_WEEK = 'weekly';
export const NATIVE_DATA = 'native';
export const METER_TYPE_WATER = 'water';
export const METER_TYPE_ENERGY = 'energy';
export const METER_TYPE_COOLING = 'cooling';
export const USER_ROLES = ['admin', 'analyst'];
export const USER_ROLE_ADMIN = 'admin';
export const AGGREGATE_DATA_NATIVE = 'native';