import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OperationalRangeService {

  constructor(
    private apiService: ApiService
  ) { }

  public getDeviceList(deviceType, measurableLocation): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.get(`/operational-range/getDeviceList?deviceType=${deviceType}&measurableLocation=${measurableLocation}`)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }

  public saveDeviceRange(payload): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post('/operational-range/saveDeviceRange',payload)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }

  public deviceRange(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.get('/operational-range/deviceRange/'+id)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }
}
