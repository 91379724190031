<div class="main-Div">
    <header class="mainHeader">
        <section id="searchHeading">
            <div id="backArrowDiv">
                <img id="backArrow" (click)="back()">
            </div>
            <span id="searchWord">Search Result for "{{searchTerm | attributeRename | titlecase}}" <span
                    (click)="clearResults()" id="clear">Clear</span></span>
        </section>
        <section id="filterRow">
            <!-- Filter Button -->
            <div class="filter">
                <search-attribute-filter [showText]="true" [showIcon]="true"
                    (updateSearchResult)="updateSearchResult($event)"></search-attribute-filter>
            </div>
        </section>
    </header>
    <div id="searchAttribute">
        <search-attribute-results (updateAttributeList)="updateAttributeList($event)" [isLoader]="isLoader"
            [data]="results" [filteredRecordsCount]="filteredRecordsCount"></search-attribute-results>
    </div>
    <div class="nextPagination">
        <button [disabled]="attributeList.length ===  0 ? true : null" (click)="searchAttribute()" class="button-Primary-N"
            id="next">
            Next
        </button>
        <div class="pb0" *ngIf="results?.length > 0">
            <app-pagination [data]="results" [currentPage]="currentPage" [itemPerPage]="pageSize"
                [totalPageCount]="totalPageCount" [pageSizeOption]="pageSizeOption"
                [totalRecords]="filteredRecordsCount" [asyncPagination]="true" (pageChange)="handlePageChange($event)"
                (itemPerPageChange)="tablePageSizeChange($event)">
            </app-pagination>
        </div>
    </div>
</div>