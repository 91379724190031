export const requiredFieldsErrMsg = 'Mandatory field is empty. Please fill and try saving again.';
export const lowerBoundValueCheckErrMsg = 'Normal Lower Limit should be lesser than Normal Upper Limit.';
export const updatedDeviceRangeMsg = 'Device range updated successfully.';
export const noDataFoundTitle = 'No data available for the selected filter';
export const noDataFoundSubTitle = 'Please select a filter for which data is available';
export const noUserFoundTitle = 'No users found';
export const abnormalBoundValueCheckErrMsg = 'Abnormal Lower Limit should be lesser than Abnormal Upper Limit.'
export const noOperatorErrMsg = 'Please select an operator for the threshold condition';
export const wrongFormatErrMsg = 'Please follow the specified format for the values';
export const noSelectionErrMsg = 'Please select a condition';
export const lowerValueErrMsg = 'The lower bound value should not be greater than or equal to the upper bound value';
export const hourLimitErrMsg = 'The no. of hours should not be greater than 72 hours.';
export const noteErrMsg = 'Description should not exceed the character limit.';
export const emailRecipientErrMsg = 'At least one Receipient is required.';
export const notAValidNumberErrMsg = 'The entered value should be a valid number';
export const notAnIntegerErrMsg = 'The entered value should be an integer';



export const ROUTE_DUBA_PLANT = "dubadesalplant";
export const ROUTE_SHARMA_PUMPSTATION = "sharmaPumpStation";
export const ROUTE_ALBADA = "ALBADA";
export const ROUTE_HPR_STATION = "hprstation";
export const ROUTE_FLOW_METER = "flow-meter";
export const ROUTE_LOGISTICS_PARK = "logisticspark";
export const ROUTE_NEOMBAYAI_AIRPORT = "neombayairport";
export const ROUTE_EXPERIENCE_CENTER = "experiencecenter";

export const INDUSTRIAL_ROUTE = {
    [ROUTE_DUBA_PLANT]: {
        route: '/watermeters-industrial/dubadesalplant',
        isDisableConnectionHealthTab: false
    },
    [ROUTE_SHARMA_PUMPSTATION]: {
        route: '/watermeters-industrial/sharmaPumpStation',
        isDisableConnectionHealthTab: false
    },
    [ROUTE_ALBADA]: {
        route: '/watermeters-industrial/ALBADA',
        isDisableConnectionHealthTab: false
    },
    [ROUTE_HPR_STATION]: {
        route: '/watermeters-industrial/hprstation',
        isDisableConnectionHealthTab: false
    },
    [ROUTE_FLOW_METER]: {
        route: '/watermeters-customer/flow-meters',
        isDisableConnectionHealthTab: false
    },
    [ROUTE_LOGISTICS_PARK]: {
        route: '/watermeters-industrial/logisticspark',
        isDisableConnectionHealthTab: false
    },
    [ROUTE_NEOMBAYAI_AIRPORT]: {
        route: '/watermeters-industrial/neombayairport',
        isDisableConnectionHealthTab: false
    },
    [ROUTE_EXPERIENCE_CENTER]: {
        route: '/watermeters-industrial/experiencecenter',
        isDisableConnectionHealthTab: false
    }
}   
