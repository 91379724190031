import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZoneMapService {

  constructor() { }

  getDeviceFillColor(status) {
    let fillColor = '';
    switch(status) {
      case 'Live':
        fillColor = '#4dc254';  // Green Color
        break;
      case 'Offline':
        fillColor = '#AAAAAA'; // Gray color
        break;
      default : 
        fillColor = '#808080';
    }
    return fillColor;
  }

  decimalToDMS(decimal) {
    var degrees = Math.floor(decimal);
    var decimalMinutes = (decimal - degrees) * 60;
    var minutes = Math.floor(decimalMinutes);
    var seconds = Math.round((decimalMinutes - minutes) * 60);

    return {
      degrees: degrees,
      minutes: minutes,
      seconds: seconds
    };
  }

  getTemplate(point) {
    const meterType = point.deviceType === 'Water Meter' ? 'watermeters' : 'energymeters';
    const domain = `${window.location.origin}/${meterType}/devices/device-dashboard/${point.deviceID}`;
    const longitude = this.decimalToDMS(point['longitude']);
    const latitude = this.decimalToDMS(point['latitude']);
    return `
   <div>
      <div class="align-items-center" style="display:flex;margin-top:12px;">
        Device Serial:
        <div style="padding-left: 5px;color: #000;font-size: 16px;font-weight: bold;"><a href='${domain}'>${point.uplinkReferenceKey}</a></div>
        ${point.status === 'Live'
        ? '<div style="background-color:#4dc254;border-radius: 4px;padding: 2px 5px;color:#fff;font-size: 12px;margin-left: 25px;">Live</div>'
        : '<div style="background-color:#a8a8a8;border-radius: 4px;padding: 2px 5px;color:#fff;font-size: 12px;margin-left: 25px;">Offline</div>'
      }
      </div>
     
      <div class="align-items-center mclass" style="display:flex; margin: 10px 0 5px;height:20px;">
        <div> Location: <span style="font-weight: bold; color:#000;"> ${point.measurableLocation} </span> </div>
        <div style="font-size: 13px;padding-left: 7px">Latitude: ${latitude.degrees}° ${latitude.minutes}´${latitude.seconds}" <span style="padding-left:7px">Longitude: ${longitude.degrees}° ${longitude.minutes}´${longitude.seconds}"</span></div>
      </div>
    </div>`
  }

  getMultiPointTemplate(point, devices = []) {
    const deviceList = [];
    for (let i = 0; i < devices?.length; i++) {
      const device = devices[i];
      const meterType = device.deviceType === 'Water Meter' ? 'watermeters' : 'energymeters';
      const domain = `${window.location.origin}/${meterType}/devices/device-dashboard/${device.deviceID}`;
      const template = `
        <div class="align-items-center" style="display:flex;padding-top: 10px">
          <div class="align-items-center" style="display:flex;font-size: 14px">
          Device Serial:
          <div style="padding-left: 5px;color: #000;font-size: 14px;font-weight: bold;"><a href='${domain}'>${device.deviceID}</a></div>
          </div>
          <div style="margin-left: 10px"> Consumption : <span style="font-weight: bold; color:#000;font-size: 14px"> ${point.consumptionFor30Days || 0} ${point.deviceType == "Energy Meter" ? "kWh" : "m³"} (Last 30 days) </span> </div>
          ${point.status === 'Live'
            ? '<div style="background-color:#4dc254;border-radius: 4px;padding: 2px 5px;color:#fff;font-size: 12px;margin-left: 10px;">Live</div>'
            : '<div style="background-color:#a8a8a8;border-radius: 4px;padding: 2px 5px;color:#fff;font-size: 12px;margin-left: 10px;">Offline</div>'
          }
        </div>`;
      deviceList.push(template);
    }
    return `<div>${deviceList.join('')}</div>`;
  }
}
