<h2 mat-dialog-title class="title">Add Additional Tag</h2>

<mat-dialog-content class="mat-typography">
    <div class="row p-3">
        <div class="col-12 example-container mat-elevation-z8">
            <table mat-table #table [dataSource]="dataSource">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef>
                        Name
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.name}}
                    </td>
                </ng-container>

                <!-- currentValue Column -->
                <ng-container matColumnDef="Current Value">
                    <th mat-header-cell *matHeaderCellDef> Current Value </th>
                    <td mat-cell *matCellDef="let element"> {{element.currentValue }} </td>
                </ng-container>

                <!-- Trend Column -->
                <ng-container matColumnDef="Trend">
                    <th mat-header-cell *matHeaderCellDef> Trend </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-icon *ngIf="element.trend === 'up'" class="trending_up">trending_up</mat-icon>
                        <mat-icon *ngIf="element.trend === 'down'" color="warn">trending_down</mat-icon>
                        <mat-icon *ngIf="element.trend === 'flat'" class="trending_flat">trending_flat</mat-icon>
                    </td>
                </ng-container>

                <!-- Trend Column -->
                <ng-container matColumnDef="Data">
                    <th mat-header-cell *matHeaderCellDef> Data </th>
                    <td mat-cell *matCellDef="let element">
                        <app-spark-line [name]="element.name" [data]="element._data"></app-spark-line>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
                </tr>
            </table>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="addButton">
        <button (click)="addTags()">Create Chart</button>
    </div>
</mat-dialog-actions>