<div class="row toast-cust" [style.display]="state.value === 'inactive' ? 'none' : ''">
    <div class="text-right">
        <a *ngIf="!options.closeButton" class="btn btn-light btn-sm" (click)="action($event)">
            {{ undoString }}
        </a>
        <a *ngIf="options.closeButton" (click)="remove()" class="tickBg">
            <img *ngIf="options.toastClass === 'success'" src="assets/images/success-icon.svg" width="26px">
            <img *ngIf="options.toastClass === 'warning'" src="assets/images/warning-icon.svg" width="26px">
            <img *ngIf="options.toastClass === 'error'" src="assets/images/error-icon.svg" width="26px">
            <img *ngIf="options.toastClass === 'info'" src="assets/images/success-icon.svg" width="26px">
        </a>
    </div>
    <div class="">
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
            {{ title }}
        </div>
        <div *ngIf="message && options.enableHtml" role="alert" [class]="options.messageClass"
            [innerHTML]="message">
        </div>
        <div *ngIf="message && !options.enableHtml" role="alert" [class]="options.messageClass"
            [attr.aria-label]="message">
            {{ message }}
        </div>
    </div>
</div>