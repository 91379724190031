import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-access-forbidden',
  templateUrl: './access-forbidden.component.html',
  styleUrls: ['./access-forbidden.component.css','../../../assets/Reusable-CSS/main.scss']
})
export class AccessForbiddenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
