import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { DataService } from 'src/app/services/behaviour.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css', '../../../assets/Reusable-CSS/mat.scss', '../../../assets/Reusable-CSS/main.scss']
})
export class NavigationComponent implements OnInit {

  public meterType: string;
  public sectorSelected: string;
  public tabIndex = 0;
  public pathUrl: string;
  public sectorTabs: any;
  public breadcrumb: Array<any> = [
    {
      title: 'Home',
      route: '/home',
      isActive: false
    }
  ]
  constructor(
    private router: Router,
    private dataService: DataService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) { }
    });

    // this.meterType = this.router.url.includes('water') ? 'water' : 'energy';
    // this.pathUrl = this.meterType === 'water' ? 'watermeters' : 'energymeters';
    // this.meterType = 'water'; // enable dummy graph
    if (this.router.url.includes('water')) {
      this.meterType = 'water';
    } else if (this.router.url.includes('energy')) {
      this.meterType = 'energy';
    } else {
      this.meterType = 'cooling';
    }
    if (this.meterType === 'water') {
      this.pathUrl = 'watermeters';
    } else if (this.meterType === 'energy') {
      this.pathUrl = 'energymeters';
    } else {
      this.pathUrl = 'coolingmeters';
    }

    this.sectorTabs = {
      "dashboard": {
        'index': 0,
        'page': `${this.pathUrl}/dashboard`
      },
      "device listing": {
        'index': 1,
        'page': `${this.pathUrl}/devices`
      },
      "device dashboard": {
        'index': 1,
        'page': `${this.pathUrl}/devices/device-dashboard`
      },
      "leakage insights": {
        'index': 2,
        'page': `${this.pathUrl}/leakage`
      },
      // "device alerts": {
      //   'index': 2,
      //   'page': "watermeters/alerts"
      // }
    };
  }

  ngOnInit() {
    this.dataService.currentSector.subscribe((data: string) => {
      this.sectorSelected = data;
      this.tabIndex = this.sectorTabs[`${this.sectorSelected}`]?.index;
    })

    if (this.meterType === 'water') {
      this.breadcrumb.push({
        title: 'Water - Customer',
        route: '/home/watermeters/customer',
        isActive: false
      })

      if (this.sectorSelected === 'device dashboard') {
        this.breadcrumb.push({
          title: 'Water Meters -  Device Listing ',
          route: '/watermeters/devices',
          isActive: false
        })
        this.breadcrumb.push({
          title: `${this.sectorSelected}`,
          isActive: true
        })
      }
      else {
        this.breadcrumb.push({
          title: `Water Meters - ${this.sectorSelected}`,
          isActive: true
        })
      }

    }
    else if (this.meterType === 'energy') {
      this.breadcrumb.push({
        title: 'Energy - Customer',
        route: '/home/energymeters/customer',
        isActive: false
      })

      if (this.sectorSelected === 'device dashboard') {
        this.breadcrumb.push({
          title: 'Energy Meters -  Device Listing ',
          route: '/energymeters/devices',
          isActive: false
        })
        this.breadcrumb.push({
          title: `${this.sectorSelected}`,
          isActive: true
        })
      }
      else {
        this.breadcrumb.push({
          title: `Energy Meters - ${this.sectorSelected}`,
          isActive: true
        })
      }

    }
    else {
      this.breadcrumb.push({
        title: 'Water - Customer',
        route: '/home/watermeters/customer',
        isActive: false
      })

      if (this.sectorSelected === 'device dashboard') {
        this.breadcrumb.push({
          title: 'Cooling Meters -  Device Listing ',
          route: '/coolingmeters/devices',
          isActive: false
        })
        this.breadcrumb.push({
          title: `${this.sectorSelected}`,
          isActive: true
        })
      }
      else {
        this.breadcrumb.push({
          title: `Cooling Meters - ${this.sectorSelected}`,
          isActive: true
        })
      }

    }
  }

  navigation() {
    // this.meterType === 'water' ?
    //  this.router.navigate(['watermeters']) : this.router.navigate(['energymeters']);
    if (this.meterType === 'water') {
      this.router.navigate(['watermeters']);
    } else if (this.meterType === 'energy') {
      this.router.navigate(['energymeters']);
    } else {
      this.router.navigate(['coolingmeters']);
    }
  }

  onTabChanged(page: string) {
    let sector = page.toLowerCase();
    this.tabIndex = this.sectorTabs[`${sector}`]?.index;
    this.selectSector(sector);
  }

  selectSector(sector: string) {
    this.dataService.updateSector(sector);
    const currentPage = this.sectorTabs[`${sector}`]?.page;
    this.router.navigateByUrl(`/${currentPage}`);
  }

}
