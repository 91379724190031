<!-- <button [matMenuTriggerFor]="filterMenu" type="button" class="btn btn-filter">
    <small *ngIf="showIcon && (this.deviceTypeSelectedList.length || this.locationSelectedList.length)" class="token">
        {{this.deviceTypeSelectedList?.length || this.locationSelectedList?.length ?
        (this.deviceTypeSelectedList?.length && this.locationSelectedList?.length ? 2 : 1) : 1}}
    </small>
    <img *ngIf="showIcon && !(this.deviceTypeSelectedList.length || this.locationSelectedList.length)" class="filterImg"
        src="assets/images/filter-results-button.png" width="20px">
    <span *ngIf="showText">Filter</span>

</button> -->

<button id="filterDiv" class="button-Primary-N" [matMenuTriggerFor]="filterMenu">
    <!-- <small *ngIf="showIcon && (this.deviceTypeSelectedList.length || this.locationSelectedList.length)" class="token">
        {{this.deviceTypeSelectedList?.length || this.locationSelectedList?.length ?
        (this.deviceTypeSelectedList?.length && this.locationSelectedList?.length ? 2 : 1) : 1}}
    </small> -->
    <div id="filterButton"><img src="assets/images/filter-results-button.png" width="15px"> Filter
        <span class="badge" *ngIf="deviceTypeSelectedList.length > 0 || locationSelectedList.length > 0">
            {{getAppliedFiltersLength()}}
          </span>
    </div>
    
</button>

<mat-menu xPosition="before" #filterMenu="matMenu" class="filterSearchResult">
    <div class=" pt-3 headingDiv">
        <span class="filterHeading">
            Filter
        </span>
        <span class="clearHeading">
            <a (click)="clearFilter()">Clear all</a>
        </span>
    </div>
    <mat-expansion-panel (click)="$event.stopPropagation();">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Device Type ({{this.deviceTypes?.length}})
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div *ngIf="this.deviceTypes?.length">
            <mat-checkbox *ngFor="let device of deviceTypes" [checked]="deviceTypeSelectedList.includes(device)"
                (change)="addDeviceTypeFilter(device,$event.checked)" class="col-12 example-margin">
                {{device}}</mat-checkbox>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel (click)="$event.stopPropagation();">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Location ({{this.filteredLocationData?.length}})
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="searchBox" (click)="$event.stopPropagation();">
            <input [(ngModel)]="filterSearchTerm" (keyup)="onSearch()" class="pb-1 searchInput" type="text"
                placeholder="Search...">
            <img class="searchIcon" src="assets/images/search.svg" width="10px">

        </div>
        
        <div class="locationFilter" *ngIf="!locationLoader">
            <mat-checkbox *ngIf="filteredLocationData.length" (change)="toggleSelectAll($event.checked)" [(ngModel)]="allSelected" class="me-3">
                Select All
            </mat-checkbox>
            <mat-checkbox *ngFor="let location of filteredLocationData" [checked]="locationSelectedList.includes(location)"
                (change)="addLocationFilter(location,$event.checked)" class="col-12 example-margin">
                {{location}}</mat-checkbox>
        </div>
    </mat-expansion-panel>
</mat-menu>