import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-developers-page',
  templateUrl: './developers-page.component.html',
  styleUrls: ['./developers-page.component.css','../../../assets/Reusable-CSS/main.scss']
})
export class DevelopersPageComponent implements OnInit {
  public routeName = "about";
  public parentRouteName = "about";
  public breadcrumb: Array<any> = [
    {
      title: 'Developer Guide',
      route: '/home',
      isActive: false
    }
  ]
  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.breadcrumb.push(
      {
        title: 'About IoT Sense',
        isActive: true
      }
    )
  }
  public navigateTo(parentRouteName,elementId: string, event: any): void {
    this.parentRouteName = parentRouteName;
    this.routeName = elementId;
    // this.breadcrumb[1].title = event //event.target.textContent.trim();
    document.getElementById(elementId).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
