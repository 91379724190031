<div>
    <div class="popupHeader" style="height: 10%;">
        <span>Dismiss Alert</span>
        <img src="assets/images/cross.svg" mat-dialog-close class="crossImage"width="30px" height="100%">
    </div>
    <div style="margin-top: 15px; margin-bottom: 15px; height: 75%;">
        <div>
            <span class="popupHeading">Select Reason for dismissal</span>
            <mat-form-field appearance="outline" class="input-area">
                <mat-select aria-label="select device type" placeholder="Select Reason" [disableOptionCentering]="true"  [(ngModel)]="dismissalReason">
                    <mat-option *ngFor="let list of dimissalReasonsList" value="{{list}}">{{list}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="d-flex flex-column" *ngIf="dismissalReason === OTHER">
            <span class="popupHeading">Detailed description</span>
            <textarea cols="30" rows="5" placeholder="Add details..." [(ngModel)]="otherReason"></textarea>
        </div>
    </div>
    <div class="d-flex align-items-center" style="height: 15%;">
        <button class="button-Dismisal-N white-color" (click)="dismissAlert()" [disabled]="isDisabled()">Dismiss Alert</button>
        <button class="button-Tertiary-N" mat-dialog-close>Cancel</button>
    </div>
</div>