<img src="assets/images/Calender-new.svg" class="calender">
<div id="commondatepicker" class="{{uuidv4}}">
    <div *ngIf="!disabled" name="daterangeInput" daterangepicker [options]="options" class="daterangeInput"
        (selected)="selectedDate($event, daterange)">
        <span class="applied-date">{{dateDisplay}}</span> <img class="arrowIcon" width="25" src="assets/images/arrow-dropdown.svg" />
    </div>
</div>

<div class="daterangeInput disabled" *ngIf="disabled" id="commondatepicker" name="daterangeInput">
    <span class="applied-date">{{dateDisplay}}</span> <img class="arrowIcon" width="25" src="assets/images/arrow-dropdown.svg" />
</div>