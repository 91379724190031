import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HeaderDateChangedService {

  constructor() { }
  private dateChangedSource = new Subject<string>();


  // Observable string streams
  dateChanged$ = this.dateChangedSource.asObservable();


  // Service message commands
  announceDate(dateSelected) {
    this.dateChangedSource.next(dateSelected);
  }


}
