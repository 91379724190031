<nav>
  <div class="fixHeading">
    <div class="logoNdSearchBlk">
      <div class="logoHeader" routerLink="/home">
        <!-- <small id="titleLogo">IoT Sense</small>
        <img src="assets/images/Frame-23.svg"> -->
        <img src="assets/images/Enowa-Iot Sense.svg">
      </div>
      <search-attribute id="mainSearch"
      *ngIf="!(breakPoints.isMobilePortrait || breakPoints.isMobileLandscape  || isRestrictedUser)"></search-attribute>
    </div>
    <div class="notificationAndUser">
      <div id="N-notifiation">
        <button mat-icon-button [matMenuTriggerFor]="notificationMenu"
          #menuTrigger="matMenuTrigger" aria-label="Example icon-button with a menu"
          (menuOpened)="OnTargetMenuOpened($event)" (click)="markSeen()">
          <img src="assets/images/Notifications-new.svg" class="notificationIcon">
          <!-- <span class="material-symbols-outlined">notifications</span> -->
          <!-- <img src="assets/images/notification_header.svg"> -->
          <!-- <mat-icon [matBadge]="newNotifications" [matBadgeHidden]="newNotifications == 0" matBadgeColor="warn"
            matBadgeSize="small" class="material-icons-outlined"
            matBadgeOverlap="true">notifications</mat-icon> -->
        </button>
        <mat-menu #notificationMenu="matMenu" class="N-notificationDropdown">
          <header class="alertsHeader">
            <small> Notifications </small>
            <div>
              <button *ngIf="showMarkAllRead" class="notificationReadbtn markAllAsRead" type="button"
                (click)="markAllAsRead()">
                <img src="assets/images/mark-all.svg" width="20px" alt="">Mark all as read
              </button>
            </div>
          </header>
          <div *ngIf="loading" class="loaderNotification">
            <div class="notificationLoader">
              <div class="loader"></div>
            </div>
          </div>
          <div *ngIf="!loading" (click)="$event.stopPropagation();" class="notificationTabs">
            <mat-tab-group *ngIf="notifications?.length > 0; else noNotifications" #targetMenuTabGroup>
              <mat-tab>
                <ng-template mat-tab-label>
                  <div [matBadge]="alertsNotifications.thresholdAlerts.newAlertsCount <= 9 ? alertsNotifications.thresholdAlerts.newAlertsCount : '9+'" matBadgeColor="accent"
                    [matBadgeHidden]="alertsNotifications.thresholdAlerts.newAlertsCount === 0" matBadgeOverlap="false">
                    Threshold</div>
                </ng-template>
                <div class="notificationScroll">
                  <div *ngIf="alertsNotifications.thresholdAlerts.alerts.length == 0" class="newNotidicationData"
                    style="height:100%;">
                    No new notification available.
                  </div>
                  <ng-container *ngIf="alertsNotifications.thresholdAlerts.alerts.length > 0">
                    <div *ngFor="let notification of alertsNotifications.thresholdAlerts.alerts; let i=index "
                      class="notificationWrapper notificationArticalLatest notificationaActive bgWhite"
                      (click)="changeNotificationStatus(notification, 'clicked'); closeMenu();">
                      <!-- <div class="iconImage">
                        <span class="activeDot" *ngIf="showActiveDot(notification)"></span>
                        <img width="20px" src={{notification.logo}}>
                      </div> -->
                      <div class="contentRow">
                        <div class="notificationDiv" matTooltip="{{capitalize(notification.alertName)}}"
                          matTooltipPosition="above" matTooltipClass="above">
                          <h3 *ngIf="isItSmartOrDeviceAlert(notification)">
                            <div class="alertNotification">
                              <div class="alertName">{{notification.alertName}}</div>
                              in <b>{{notification.zone}}</b>
                            </div>
                          </h3>
                          <h3 *ngIf="isItThreshold(notification)" class="grayColor" style="color: #7e8082;">
                            <b>Alert</b> for rule <b>{{notification.ruleName}}</b>
                            has been generated</h3>
                        </div>

                        <div class="notificationTimer">
                          <span class="notificationDate">{{notification.date}}</span>
                          <span>{{notification.deviceType}} </span>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                </div>

              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <div [matBadge]="alertsNotifications.smartAlerts.newAlertsCount <= 9 ? alertsNotifications.smartAlerts.newAlertsCount: '9+' "
                    [matBadgeHidden]="alertsNotifications.smartAlerts.newAlertsCount === 0" matBadgeColor="accent"
                    matBadgeOverlap="false">Smart Alert</div>
                </ng-template>
                <div class="notificationScroll">
                  <div *ngIf="alertsNotifications.smartAlerts.alerts.length == 0" class="noData">
                    <div class="read-value">
                      <div class="notificationImg">
                        <img src="assets/images/notification-bell.svg">
                      </div>
                      <div class="d-flex flex-column ml-2">
                        <span class="noHeader">No Notifications</span>
                        <span class="noSubHeader">No new notifications to display</span>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="alertsNotifications.smartAlerts.alerts.length != 0">
                    <div *ngFor="let notification of alertsNotifications.smartAlerts.alerts; let i=index "
                      class="notificationWrapper notificationArticalLatest notificationaActive bgWhite"
                      (click)="changeNotificationStatus(notification, 'clicked'); closeMenu();">
                      <!-- <div class="iconImage">
                        <span class="activeDot" *ngIf="showActiveDot(notification)"></span>
                        <img width='12px' src={{notification.logo}}>
                      </div> -->
                      <div class="contentRow">
                        <div class="notificationDiv" matTooltip="{{capitalize(notification.alertName)}}"
                          matTooltipPosition="above" matTooltipClass="above">
                          <h3 *ngIf="isItSmartOrDeviceAlert(notification)">
                            <div class="alertNotification">
                              <div class="smartAlertName">{{notification.alertName}}</div>
                              in {{notification.zone}}
                            </div>
                          </h3>
                          <h3 *ngIf="isItThreshold(notification)"><b>Alert</b> generated for <b>rule {{notification.ruleName}}</b>
                            <!--has been generated--></h3>
                        </div>
                        <p class="pb-6" [ngClass]="getSeverityClasses(notification?.severity?.toLowerCase())">
                          {{notification.severity}}</p>
                        <div class="notificationTimer">
                          <span class="notificationDate">{{notification.date}}</span>
                          <div class="severityBtn">
                            <span>{{notification.deviceType}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>
                  <div [matBadge]="alertsNotifications.deviceAlerts.newAlertsCount <= 9 ? alertsNotifications.deviceAlerts.newAlertsCount: '9+' "
                    [matBadgeHidden]="alertsNotifications.deviceAlerts.newAlertsCount === 0" matBadgeColor="accent"
                    matBadgeOverlap="false">Device Alert</div>
                </ng-template>
                <div class="notificationScroll">
                  <div *ngIf="alertsNotifications.deviceAlerts.alerts.length == 0" class="noData">
                    <div class="read-value">
                      <div class="notificationImg">
                        <img src="assets/images/notification-bell.svg">
                      </div>
                      <div class="d-flex flex-column ml-2">
                        <span class="noHeader">No Notifications</span>
                        <span class="noSubHeader">No new notifications to display</span>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="alertsNotifications.deviceAlerts.alerts.length != 0">
                    <div *ngFor="let notification of alertsNotifications.deviceAlerts.alerts; let i=index "
                      class="notificationWrapper notificationArticalLatest notificationaActive bgWhite"
                      (click)="changeNotificationStatus(notification, 'clicked'); closeMenu();">
                      <!-- <div class="iconImage">
                        <span class="activeDot" *ngIf="showActiveDot(notification)"></span>
                        <img width='12px' src={{notification.logo}}>
                      </div> -->
                      <div class="contentRow">
                        <div class="notificationDiv" matTooltip="{{capitalize(notification.alertName)}}"
                          matTooltipPosition="above" matTooltipClass="above">
                          <h3 *ngIf="isItSmartOrDeviceAlert(notification)">
                            <div class="alertNotification">
                              <div class="alertName">{{notification.alertName}} </div>
                              for device <b class="smartAlertName blueColor">{{notification.deviceID}}</b>
                            </div>
                          </h3>
                          <h3 *ngIf="isItThreshold(notification)"><b>Alert</b> generated for <b>rule {{notification.ruleName}}</b>
                            <!-- has been generated--></h3>
                        </div>
                        <p class="pb-6" [ngClass]="getSeverityClasses(notification?.severity?.toLowerCase())">
                          {{notification.severity}}</p>
                        <div class="notificationTimer">
                          <span class="notificationDate">{{notification.date}}</span>
                          <div class="severityBtn">
                            <span>{{notification.deviceType}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </mat-tab>
            </mat-tab-group>
            <ng-template #noNotifications>
              <div class="newNotidicationData" style="height:100%;">
                <div class="read-value">
                  <div class="notificationImg">
                    <img src="assets/images/notification-bell.svg">
                  </div>
                  <div class="d-flex flex-column ml-2">
                    <span class="noHeader">No Notifications</span>
                    <span class="noSubHeader">No new notifications to display</span>
                  </div>
                </div>
              </div>
            </ng-template>
            <div class="showMarkAllReadCond">
              <span (click)="viewAll(); closeMenu()">View all notifictions</span>
              <!-- <button class="button-Primary-N view-button" type="button" (click)="viewAll(); closeMenu()" style="height:40px">View all notifictions</button> -->
            </div>
          </div>
        </mat-menu>
      </div>

      <div class="user">
        <div>
          <!-- <mat-icon>more_vert</mat-icon> -->
          <mat-icon [matMenuTriggerFor]="menu" class="material-icons-outlined">
            <img class="userIcon" src="assets/images/Profile-new.svg" />
            <!-- <span class="material-symbols-outlined">person</span> -->
          </mat-icon>
        </div>
        <mat-menu #menu="matMenu" class="userMenu" [ngClass]="{'' : menu }" panelClass="userMenuClass">
          <a mat-menu-item routerLink="/connectioncanvas" target="_blank">
            <img class="navMenuIcon h17" src="assets/images/hmi-builder-icon.svg">
            <span class="">HMI Builder</span>
          </a>
          <a mat-menu-item routerLink="/alert-management">
            <img class="navMenuIcon" src="assets/images/alert-management-icon.svg">
            <span class="">Alert Management</span>
          </a>
          <!-- <a mat-menu-item routerLink="/arcgis-map">
            <img class="navMenuIcon" src="assets/images/arcgis-map-icon.svg">
            <span class="">ArcGIS Map</span>
          </a> -->
          <a mat-menu-item routerLink="/admin">
            <img class="navMenuIcon" src="assets/images/device-management.svg">
            <span>Device Management</span>
          </a>
          <a mat-menu-item routerLink="/developers-page" target="_blank">
            <img class="navMenuIcon" src=".../../../assets/images/developer-guide-icon.svg">
            <span>Developer Guide</span>
          </a>
          <a (click)="logout()" mat-menu-item>
            <img class="navMenuIcon h15" src="assets/images/logout-icon.svg" />
            <span>Logout</span>
          </a>
        </mat-menu>
      </div>
    </div>
  </div>
</nav>