export const environment = {
  production: false,

  // Server URL
  serverUrl: "https://iotsensedev.oci.sense.neomos.online",
  socketBaseUrl: 'https://iotsensedev.oci.sense.neomos.online',
  port: 4001,
  title: "Neom Sense - Water",
  googleApiKey: "",
  keycloak: {
    url: 'https://iotsenseuat.oci.sense.neomos.online/auth/',
    realm: 'Neos_sense_realm',
    clientId: 'IoTSenseUAT',
  },
};