import { Component, Inject, OnInit } from '@angular/core';
import { DeviceProfileService } from '../device-profile/device-profile.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DevicesProfileSucessModalComponent } from '../devices-profile-sucess-modal/devices-profile-sucess-modal.component';
import { DevicesService } from '../devices/devices.service';
import {
  defaultTopicIdentifierUplink,
  defaultTopicIdentifierDownlink
} from '../devices-profile-modal/device-profile';
import { getLoginUser } from 'src/app/reusable/user-util';
import * as moment from 'moment';
import { ToastService } from 'src/app/services/toast/toast.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { dropdownOptions, validationPatterns, DATE_FORMATS, errorMessages } from '../helper';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { Device, DeviceProfile } from '../bulk-registration/interface';
import { DeviceData, DeviceResponse, ProfileResponse } from './device';

@Component({
  selector: 'app-device-modal',
  templateUrl: './device-modal.component.html',
  styleUrls: ['./device-modal.component.css',
    '../../../../assets/Reusable-CSS/form.scss',
    '../../../../assets/styles/form.css',
    '../../../../assets/Reusable-CSS/buttons.scss'
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})

export class DeviceModalComponent implements OnInit {

  public deviceGroup: FormGroup;
  public profiles: Array<DeviceProfile> = [];
  public device: Device;
  public loading: boolean = false;
  public userName: string;
  public disabledEdit: boolean = false;
  public deviceType: string = '';
  public gateway: Array<string> = [];
  public connectorProtocol: string;
  public currentDate = new Date();
  public utilityServiceTypeOptions = [];
  public dropdownOptions = dropdownOptions;
  public errorMessages = errorMessages;
  public registrationFailureMessage = errorMessages['error'];
  public submitted: boolean = false;
  public hidePassword: boolean = false;
  public validators = {
    'meterPassword': [],
    'gatewayName': [],
    'port': [],
    'ipAddress': [],
  };
  public enableEdit: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DeviceData,
    public deviceProfileService: DeviceProfileService,
    private dialog: MatDialog,
    private deviceServices: DevicesService,
    private toastr: ToastService,
    private dialogRef: MatDialogRef<DeviceModalComponent>) {
  }

  async ngOnInit() {
    this.deviceGroup = new FormGroup({
      useGateway: new FormControl(false),
      deviceProfiles: new FormArray([]),
      servicePointID: new FormControl('', Validators.pattern(validationPatterns.alphaNumeric)),
      parentMeterServicePointID: new FormControl('', Validators.pattern(validationPatterns.alphaNumeric)),
      serialNumber: new FormControl('', [Validators.required, Validators.pattern(validationPatterns.alphaNumeric)]),
      utilityServiceType: new FormControl(''),
      customerMeterType: new FormControl(''),
      customerType: new FormControl(''),
      site: new FormControl(''),
      zone: new FormControl(''),
      measurableLocation: new FormControl('', Validators.pattern(validationPatterns.alphaNumeric)),
      customerDescription: new FormControl('', Validators.pattern(validationPatterns.alphaNumeric)),
      buildingNumber: new FormControl('', Validators.pattern(validationPatterns.alphaNumeric)),
      latitude: new FormControl('', Validators.required),
      longitude: new FormControl('', Validators.required),
      commissioningDate: new FormControl(moment()),
      batch: new FormControl('', Validators.pattern(validationPatterns.alphaNumeric)),
      manufacturingDate: new FormControl(''),
      firmwareVersion: new FormControl('', Validators.pattern(validationPatterns.alphaNumeric)),
      isBillable: new FormControl(false),
      gatewayName: new FormControl(''),
      waterDiameter: new FormControl(''),
      ipAddress: new FormControl(''),
      port: new FormControl(''),
      meterPassword: new FormControl(''),
      energyBreakerCapacity: new FormControl(''),
    })
    this.userName = getLoginUser();
    if (!this.data?.profileID) this.addDeviceProfile();
    if (this.data?.profileID) {
      this.disabledEdit = true;
      const res: Device = await this.getRegistrationByDeviceId(this.data?.deviceId, this.data?.profileID);
      this.device = res;
      const { deviceType, commissioningDate, location, zone, site, gatewayName, measurableLocation } = res;
      this.deviceType = deviceType;
      this.setCustomValidation();
      this.hidePassword = true;
      this.setFormValue('commissioningDate', moment(commissioningDate));
      this.setFormValue('latitude', location?.latitude);
      this.setFormValue('longitude', location?.longitude);
      this.setFormValue('zone', zone);
      this.setFormValue('site', site);
      this.setFormValue('gatewayName', gatewayName);
      this.setFormValue('measurableLocation', measurableLocation);
      this.utilityServiceTypeOptions = dropdownOptions?.serviceType[deviceType];
      if (res?.metadata) {
        const { servicePointID = '', parentMeterServicePointID = '', energyBreakerCapacity = '',
          waterDiameter = '', isBillable = false, batch = '', manufacturingDate = '', firmwareVersion = '',
          utilityServiceType = '', customerType = '', customerMeterType = '', customerDescription = '', buildingNumber = '' } = res?.metadata;
        this.setFormValue('servicePointID', servicePointID);
        this.setFormValue('parentMeterServicePointID', parentMeterServicePointID);
        this.setFormValue('energyBreakerCapacity', energyBreakerCapacity);
        this.setFormValue('waterDiameter', waterDiameter);
        this.setFormValue('isBillable', isBillable);
        this.setFormValue('batch', batch);
        this.setFormValue('manufacturingDate', manufacturingDate ? moment(manufacturingDate) : '');
        this.setFormValue('firmwareVersion', firmwareVersion);
        this.setFormValue('utilityServiceType', utilityServiceType);
        this.setFormValue('customerType', customerType);
        this.setFormValue('customerMeterType', customerMeterType);
        this.setFormValue('buildingNumber', buildingNumber);
        this.setFormValue('customerDescription', customerDescription);
      }
      if (res?.connection) {
        const { host = '', port = '', password = '' } = res?.connection;
        this.setFormValue('ipAddress', host);
        this.setFormValue('port', port);
        this.setFormValue('meterPassword', password);
      }
      this.setFormValue('useGateway', false);
      this.setFormValue('serialNumber', res?.uplinkReferenceKey);
      if (this.deviceType === 'Gateway') this.deviceGroup.get('useGateway').setValue(true);
      const deviceProfiles = this.deviceGroup.get('deviceProfiles') as FormArray;
      deviceProfiles.push(this.createDeviceProfileForm(res.profileID));
      if (res?.linkedProfiles?.length) {
        for (let i = 0; i < res?.linkedProfiles?.length; i++) {
          deviceProfiles.push(this.createDeviceProfileForm(res?.linkedProfiles[i]));
        }
      }
      this.profileChange();
    }
    if (this.disabledEdit) this.deviceGroup.disable();
    this.getDeviceProfiles();
  }

  async getRegistrationByDeviceId(deviceId: string, profileID: string) {
    return await this.deviceServices.getRegistrationByDeviceId(deviceId, profileID)
  }

  getDeviceProfiles() {
    this.deviceServices.getAvailableProfiles().then(async (response: ProfileResponse) => {
      const profileIds = response?.profileIds;
      const profiles = [];
      for (const key in profileIds) {
        const value = profileIds[key];
        profiles.push({ name: key, id: value[0], protocol: value[1] })
      }
      this.profiles = profiles;
      // if default profile present then set default profile
      if (response?.defaultProfile?.profileID) {
        const defaultProfile = await this.getDeviceProfileById(response?.defaultProfile?.profileID);
        this.setProfileSetting(defaultProfile);
        await this.deviceServices.getGatewayName(response?.defaultProfile?.profileID).then((data: Array<string>) => {
          this.gateway = data;
        }).catch((error: Error) => {
          this.gateway = [];
        })
      }
    }).catch((error: Error) => {
      throw error;
    });
  }

  async profileChange(event = null) {
    const profileId = event?.value || this.deviceGroup.getRawValue().deviceProfiles[0]?.profile;
    if (profileId) {
      await this.deviceServices.getGatewayName(profileId).then((data: Array<string>) => {
        this.gateway = data;
      }).catch((error) => {
        this.gateway = [];
      })
      await this.getDeviceProfileById(profileId).then((data) => {
        if (data) {
          const profile = data;
          this.deviceType = profile['deviceType'] || '';
          this.setCustomValidation();
          this.connectorProtocol = profile['connectorProtocol'] || '';
          if (event?.value) {
            this.utilityServiceTypeOptions = dropdownOptions?.serviceType[this.deviceType];
            this.setFormValue('utilityServiceType', '');
          }
        }
      }).catch((error: Error) => {
        this.deviceType = '';
        this.toastr.error(error?.message || this.registrationFailureMessage);
      })
    } else {
      this.deviceType = '';
      this.connectorProtocol = '';
    }
  }

  async getDeviceProfileById(profileID: string) {
    return await this.deviceProfileService.getDeviceProfileById(profileID)
  }

  setProfileSetting(profile) {
    this.setFormValue('profileID', profile.profileID || '');
    this.setFormValue('manufacturerName', profile.deviceManufacturer || '');
    this.device.deviceModel = profile.deviceModel;
    // if it's useCommonDeviceTopic is false that means it has a unique identifier
    if (!this.device.id) {
      if (!profile.connectorProperties.useCommonDeviceTopic) {
        this.device.connection.connectionProperties.uplinkTopic = defaultTopicIdentifierUplink + profile.connectorProperties.uniqueTopicIdentifier + '/(deviceId)';
        this.device.connection.connectionProperties.downlinkTopic = defaultTopicIdentifierDownlink + profile.connectorProperties.uniqueTopicIdentifier + '/(deviceId)';
      }
      else {
        this.device.connection.connectionProperties.uplinkTopic = defaultTopicIdentifierUplink + profile.connectorProperties.uniqueTopicIdentifier || '';
        this.device.connection.connectionProperties.downlinkTopic = defaultTopicIdentifierDownlink + profile.connectorProperties.uniqueTopicIdentifier || '';
      }
    }
  }

  addDevice() {
    this.submitted = true;
    if (this.deviceGroup.invalid) return;

    const deviceGroupValue = this.deviceGroup.getRawValue();

    // manufacuturing date should be on or before commissioning date
    const { manufacturingDate = '', commissioningDate = '', serialNumber, measurableLocation = '', zone = '', site = '', latitude, longitude,
      manufacturerName = '', useGateway, servicePointID = '', ipAddress, meterPassword, port, buildingNumber = '', parentMeterServicePointID = '', batch = '', firmwareVersion = '', isBillable,
      utilityServiceType = '', customerMeterType = '', customerType = '', customerDescription = '', energyBreakerCapacity = '', gatewayName, waterDiameter = ''
    } = deviceGroupValue;
    const checkDateCondition = manufacturingDate ? moment(manufacturingDate).isAfter(commissioningDate) : false;
    if (checkDateCondition) {
      this.toastr.error('Manufacturing date cannot be after commissioning date');
      return;
    }

    let payload: Device = {
      serialNumber,
      measurableLocation,
      zone,
      site,
      location: {
        latitude,
        longitude,
      },
      manufacturerName,
      operation: this.enableEdit ? "update" : "create",
      operationBy: "",
      commissioningDate: new Date(commissioningDate?.format() || commissioningDate),
      useGateway,
      metadata: {
        servicePointID: servicePointID || null,
        parentMeterServicePointID: parentMeterServicePointID || null,
        batch: batch || null,
        firmwareVersion: firmwareVersion || null,
        isBillable,
        manufacturingDate: manufacturingDate ? new Date(manufacturingDate?.format() || manufacturingDate) : null,
        utilityServiceType: utilityServiceType || null,
        customerMeterType: customerMeterType || null,
        customerType: customerType || null,
        customerDescription: customerDescription || null,
        buildingNumber: buildingNumber || null,
      },
      connection: {
        port: port || 0,
      },
      protocol: this.connectorProtocol?.toLowerCase(),
    };
    if (this.enableEdit) payload['metadata']['deviceID'] = this.device?.deviceID;
    if (this.deviceType === 'Energy Meter') {
      payload['metadata'] = {
        ...payload['metadata'],
        energyBreakerCapacity: energyBreakerCapacity || null
      };
      payload['connection'] = {
        ...payload['connection'],
        host: ipAddress,
        password: meterPassword,
      };
    }
    if (this.deviceType === 'Water Meter' || this.deviceType === 'Cooling Meter') {
      payload = {
        ...payload,
        gatewayName,
      };
      if (this.deviceType === 'Water Meter') {
        payload['metadata'] = {
          ...payload['metadata'],
          waterDiameter: waterDiameter || null
        }
      }
    }
    this.loading = true;
    const bulkPayload = [];
    const deviceProfiles = deviceGroupValue?.deviceProfiles?.map(device => device?.profile)?.filter(Boolean);
    for (let i = 0; i < deviceProfiles?.length; i++) {
      const profileID = deviceProfiles[i];
      const linkedProfiles = deviceProfiles?.filter(profile => profile !== profileID);
      const temp = {
        ...payload,
        hidden: i === 0 ? false : true,
        profileID,
        linkedProfiles
      };
      bulkPayload.push(temp);
    }
    if (deviceProfiles?.length > 1) {
      try {
        this.deviceServices.bulkRegistrationOfDevices(bulkPayload).then((response: DeviceResponse) => {
          this.dialogRef.close(true);
          this.dialog.open(DevicesProfileSucessModalComponent, {
            width: '100%',
            maxWidth: '33vw',
            minWidth: '320px',
            data: {
              title: `Device ${serialNumber} ${this.enableEdit ? 'Updated' : 'Added'} Sucessfully`,
              connection: response?.connection,
              deviceId: response?.deviceId
            }
          })
          this.loading = false;
        }).catch((error: Error) => {
          this.loading = false;
          this.toastr.error(this.registrationFailureMessage);
        });
      } catch (error) {
        this.loading = false;
        this.toastr.error(this.registrationFailureMessage);
      }
    } else {
      payload['profileID'] = deviceProfiles[0];
      payload['hidden'] = false;
      try {
        this.deviceServices.registerDevice(payload)
          .then((response: DeviceResponse) => {
            this.dialogRef.close(true);
            this.toastr.success(`Device ${serialNumber} ${this.enableEdit ? 'updated' : 'added'} sucessfully`);
            this.dialog.open(DevicesProfileSucessModalComponent, {
              width: '100%',
              maxWidth: '33vw',
              minWidth: '320px',
              data: {
                title: `Device ${serialNumber} ${this.enableEdit ? 'Updated' : 'Added'} Sucessfully`,
                connection: response?.connection,
                deviceId: response?.deviceId
              }
            })
            this.loading = false;
          }).catch((error) => {
            this.loading = false;
            this.toastr.error(this.registrationFailureMessage);
          });
      } catch (error) {
        this.loading = false;
        this.toastr.error(this.registrationFailureMessage);
      }
    }
  }

  onGatewayChange(event) {
    this.checkProfile();
    this.profileChange();
    const useGateway = event?.value;
    const deviceProfiles = this.deviceGroup.get('deviceProfiles') as FormArray;
    const deviceProfilesLength = deviceProfiles?.controls?.length;
    if (!useGateway) {
      for (let i = deviceProfilesLength; i > 1; i--) {
        this.removedeviceProfile(i - 1);
      }
    }
  }

  editDevice() {
    this.enableEdit = true;
    this.disabledEdit = false;
    this.deviceGroup.enable();

    const fieldsToDisable = ['useGateway', 'ipAddress', 'port', 'meterPassword', 'gatewayName'];
    this.deviceGroup.get('deviceProfiles').disable();
    fieldsToDisable?.forEach(field => {
      this.disbleFormField(field);
    });
  }

  // device profile functionalities

  addDeviceProfile() {
    const deviceProfiles = this.deviceGroup.get('deviceProfiles') as FormArray;
    deviceProfiles.push(this.createDeviceProfileForm());
  }

  removedeviceProfile(index: number) {
    const deviceProfiles = this.deviceGroup.get('deviceProfiles') as FormArray;
    deviceProfiles.removeAt(index);
  }

  createDeviceProfileForm(profileId: string = '') {
    const deviceProfiles = this.deviceGroup.get('deviceProfiles') as FormArray;
    const deviceProfilesLength = deviceProfiles?.controls?.length;
    const formGroup = new FormGroup({
      profile: new FormControl(profileId)
    });
    if (deviceProfilesLength === 0) formGroup.get('profile').setValidators(Validators.required);
    return formGroup;
  }

  getProfiles(index: number) {
    let profiles = [];
    const { useGateway, deviceProfiles } = this.deviceGroup.getRawValue();
    if (useGateway) {
      profiles = this.profiles?.filter((profile: DeviceProfile) => profile?.protocol?.toLowerCase() !== 'dlms');
    } else {
      profiles = this.profiles;
    }

    const deviceProfileIds = deviceProfiles?.map(device => device?.profile);

    const currentProfileId = deviceProfileIds[index];
    if (index !== 0) profiles = profiles?.filter(profile => !deviceProfileIds.includes(profile.id) || currentProfileId === profile.id);
    return profiles;

  }

  checkProfile() {
    const { deviceProfiles, useGateway } = this.deviceGroup.getRawValue();
    const currentProfileId = deviceProfiles[0]['profile'];
    const profile = this.profiles.find((profile: DeviceProfile) => profile?.id === currentProfileId);
    if (profile?.protocol?.toLowerCase() === 'dlms' && useGateway) {
      this.removedeviceProfile(0);
      this.addDeviceProfile();
    }
  }

  addProfileCondition() {
    const { deviceProfiles, useGateway } = this.deviceGroup.getRawValue();
    const checkPreviousValue = deviceProfiles?.every(device => device?.profile);
    return useGateway && this.deviceGroup.get('deviceProfiles')['controls']?.length <= 5 && checkPreviousValue;
  }

  // form functions
  setFormValue(key: string, value) {
    this.deviceGroup.get(`${key}`).patchValue(value);
  }

  setCustomValidation() {
    const { useGateway } = this.deviceGroup.getRawValue();
    const keyValidators = {
      'gatewayName': [Validators.required],
      'ipAddress': [Validators.required, Validators.pattern(validationPatterns.ipAddress)],
      'port': [Validators.required, Validators.pattern(validationPatterns.numeric)],
      'meterPassword': [Validators.required],
    };

    let keysToAdd = [];
    let keysToRemove = [];
    if ((this.deviceType === 'Water Meter' || this.deviceType === 'Cooling Meter') && !useGateway) {
      keysToAdd = ['gatewayName'];
      keysToRemove = ['ipAddress', 'port', 'meterPassword'];
    } else if (this.deviceType === 'Energy Meter' && !useGateway) {
      keysToRemove = ['gatewayName'];
      keysToAdd = ['ipAddress', 'port', 'meterPassword'];
    } else if (useGateway) {
      keysToRemove = ['ipAddress', 'port', 'meterPassword', 'gatewayName'];
    }
    keysToAdd?.length && keysToAdd?.forEach(key => {
      this.setValidators(key, keyValidators[key]);
    });
    keysToRemove?.length && keysToRemove?.forEach(key => {
      this.clearValidators(key);
    });
  }

  disbleFormField(key: string) {
    this.deviceGroup.controls[key].disable();
  }

  // validators, validation & error handling functionalites

  setValidators(key: string, validators = []) {
    const control = this.deviceGroup.get(`${key}`);
    if (!this.validators[key]?.length && control) {
      control.setValidators(validators);
      control.updateValueAndValidity();
    }
    this.validators[key] = this.validators[key].length ? this.validators[key] : validators;
  }

  clearValidators(key: string) {
    const control = this.deviceGroup.get(`${key}`);
    if (this.validators[key]?.length && control) {
      control.clearValidators();
      control.updateValueAndValidity();
      this.validators[key] = [];
    }
  }

  checkValidation(key: string) {
    const control = this.deviceGroup.get(`${key}`);
    if (!control) return false;
    return control.invalid && (this.submitted || control.dirty || control.touched);
  }

  checkProfileValidation(item: FormControl) {
    const control = item.get('profile');
    if (!control) return false;
    return control.invalid && (this.submitted || control.touched || control.dirty);
  }

  checkSqlInjectionValidation(key: string) {
    const checkSqlInjection = this.deviceGroup?.controls[key]?.errors?.sqlInjection;
    const messageCondition = checkSqlInjection && (this.deviceGroup?.controls[key]?.errors?.pattern ? this.deviceGroup?.controls[key]?.errors?.message !== this.errorMessages['pattern'] && checkSqlInjection : checkSqlInjection)
    return messageCondition;
  }

  checkPatternValidation(key: string) {
    return this.deviceGroup?.controls[key]?.errors?.pattern;
  }

  checkRequiredValidation(key: string) {
    return this.deviceGroup?.controls[key]?.errors?.required;
  }

  getErrorMessage(key: string) {
    let errorMessage = '';
    for (const control in this.deviceGroup?.controls[key]?.errors) {
      switch (control) {
        case 'required':
          errorMessage = errorMessage + '\n' + this.errorMessages['required'];
          break;
        case 'pattern':
          errorMessage = errorMessage + '\n' + this.errorMessages['pattern'];
          break;
        case 'sqlInjection':
          if (this.checkSqlInjectionValidation(key)) errorMessage = errorMessage + '\n' + this.deviceGroup?.controls[key]?.errors?.message;
          break;
        default:
          break;
      }
    }
    return errorMessage;
  }

}