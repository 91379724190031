import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';

const SERVER_URL = environment.serverUrl;
const apiVersion1 = "/api/v1";

@Injectable({
  providedIn: "root"
})
export class ApiService {

  constructor(private http: HttpClient) {
  }

  get(urlAddress: string, options?: any) {
    if (options) {
      return this.http
        .get<any>(SERVER_URL + apiVersion1 + urlAddress, {
          headers: {
            entity: JSON.stringify(options)
          }
        })
        .pipe(map(data => data));
    }
    return this.http
      .get<any>(SERVER_URL + apiVersion1 + urlAddress)
      .pipe(map(data => data));
  }

  post(urlAddress: string, payload: any, options?: any) {
    if (options) {
      return this.http
        .post<any>(SERVER_URL + apiVersion1 + urlAddress,
          payload,
          {
          headers: {
            entity: JSON.stringify(options)
          }
        })
        .pipe(map(data => data));
    }
    return this.http
      .post<any>(SERVER_URL + apiVersion1 + urlAddress, payload, options)
      .pipe(map(data => data))
      .pipe(catchError(this.handleError))
  }

  put(urlAddress: string, payload: any, options?: any) {
    if (options) {
      return this.http
        .put<any>(SERVER_URL + apiVersion1 + urlAddress, payload, {
          headers: {
            entity: JSON.stringify(options)
          }
        })
        .pipe(map(data => data));
    }
    return this.http
      .put<any>(SERVER_URL + apiVersion1 + urlAddress, payload, options)
      .pipe(map(data => data));
  }

  delete(urlAddress: string, options?: any) {
    if (options) {
      return this.http
        .delete<any>(SERVER_URL + apiVersion1 + urlAddress,{
          headers: {
            entity: JSON.stringify(options)
          }
        })
        .pipe(map(data => data));
    }
    return this.http
      .delete<any>(SERVER_URL + apiVersion1 + urlAddress)
      .pipe(map(data => data));
  }

  streamPost(urlAddress: string, payload: any, options?: any) {
    options = options || {};
    return this.http
      .post<any>(SERVER_URL + apiVersion1 + urlAddress, payload, options)
      .pipe(map(data => {
        return data;
      }));
  }

  postTag(urlAddress: string, payload: any, options?: any) {
    options = options || {};
    return this.http
      .post<any>(urlAddress, payload, options)
      .pipe(map(data => data));
  }

  private handleError(error: any) { 
    return throwError(error || 'server Error');

  }
}
