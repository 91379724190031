<div class="pinnedDashboards">
    <div class="pinnedHeader">
        <div class="pinnedHeading">
            <div class="material-symbols-outlined">bookmark</div> Pinned Dashboards 
            <!-- <span>Last 4 activity</span> -->
        </div>
        <!-- <div class="seeAllBtn">See all</div> -->
    </div>
    <div class="pinnedContent" style="text-align: center;" *ngIf="!pinnedDashboard.length">Hey there! Looks like you haven't pinned any dashboards yet. </div>
    <div class="pinnedCard">
        <div class="pinnedCardContent" *ngFor="let dashboard of pinnedDashboard">
            <div class="pinnedContentBlk" [routerLink]="getRouterLink(dashboard)">
                <div class="pinnedImg">{{getFirstLetter(dashboard.name)}}</div>
                <div class="pinnedContent">
                   {{dashboard.name}}
                    <!-- <div class="dashboardName">Duba</div> -->
                </div>
                <!-- <div><span class="material-symbols-outlined moreAction">more_vert</span></div> -->
            </div>
        </div>
    </div>
</div>