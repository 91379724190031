import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as d3 from 'd3';
import { AdditionalParametersComponent } from 'src/app/reusable/additional-parameters/additional-parameters.component';
import { createSubGroups, downloadData, downloadDataForMinMax, getStartDateEndDate, processDataForBarChart, processDataForLineChart } from 'src/app/reusable/chart-util/chart-helper';
import { lineGraphForNativeStatusData } from 'src/app/reusable/chart-util/line-graph-native-status-chart';
import { SaveProjectComponent } from 'src/app/reusable/save-project/save-project.component';
import { ElasticSearchService } from 'src/app/reusable/services/elastic-search.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { WidgetService } from 'src/app/services/CustomDashboard/widget.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TableViewComponent } from 'src/app/reusable/table-view/table-view.component';
import * as _ from 'underscore';
import * as moment from 'moment';
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_DAY_END, DATE_TIME_FORMAT_DAY_START, TELEMETRY } from 'src/app/reusable/constant';
import { lineGraphForNative } from 'src/app/reusable/chart-util/line-graph-native';
import { areaGraphForNative } from 'src/app/reusable/chart-util/area-graph-native';
import { Subscription } from 'rxjs';
import { AGGREGATION_INTERVAL_OPTIONS, AGGREGATION_TYPE_OPTIONS, COLOR } from 'src/app/reusable/chart-util/chart-config';
import { renderChart } from 'src/app/reusable/chart-util/aggregation-line-bar-area';
import { TelemetryService } from 'src/app/reusable/services/telemetry.service';
import { RegistrationService } from 'src/app/reusable/services/registration.service';

@Component({
  selector: 'app-asset-results',
  templateUrl: './asset-results.component.html',
  styleUrls: [
    './asset-results.component.css',
    '../../../assets/Reusable-CSS/buttons.scss',
    '../../../assets/Reusable-CSS/popup.scss',
    '../../../assets/Reusable-CSS/matTooltip.scss',
    "../../../assets/Reusable-CSS/ng-select.scss",
    "../../../assets/Reusable-CSS/form.scss",
    "../../../assets/styles/form.css",
  ]
})
export class AssetResultsComponent implements OnInit {

  public searchTerm: any;
  public chartSelected: string;
  public aggregationInterval = 'daily';
  public aggregationType = 'average';
  public subgroups = [];
  public aggregationIntervalOptions = AGGREGATION_INTERVAL_OPTIONS;
  public aggregationTypeOptions = AGGREGATION_TYPE_OPTIONS;
  public startDate;
  public endDate;
  private margin = { top: 10, right: 30, bottom: 20, left: 50 };
  private width = 600 - this.margin.left - this.margin.right;
  private height = 400 - this.margin.top - this.margin.bottom;
  private ploatFilterHeight = 50;
  public colors = COLOR;
  public data: any = [];
  public responseData: any = [];
  public widget: any = {};
  public isLoader = false;
  public showTable = false;
  public showKpiCard = false;
  public noDataFound = false;
  public nativeDataDownloadLoader = false;
  public cardType = null;
  public splitYAxis = false;
  public subscription: Subscription;
  @ViewChild(TableViewComponent, { static: false }) table: TableViewComponent;
  public adaptive: any = {
    "isAdaptive": true,
    "config": "7 days"
  }
  // status chart
  constructor(private container: ElementRef, private sharedService: SharedServiceService,
    private registrationService: RegistrationService,
    private telemetryService: TelemetryService,
    private location: Location, private dialog: MatDialog, private elasticSearchservice: ElasticSearchService, private route: ActivatedRoute, public widgetService: WidgetService, public toastrService: ToastService,) {
    this.getChartData = _.debounce(this.getChartData, 1000);
  }

  datepicker(value: any) {
    const dateArr = value;
    const adaptiveValue = dateArr[2] === "Custom Range" ? false : true
    this.adaptive = {
      "isAdaptive": adaptiveValue,
      "config": dateArr[2]
    }
    if (this.adaptive && this.adaptive.config === "24 hours") {
      this.endDate = moment(new Date()).format(DATE_TIME_FORMAT);
      this.startDate = moment(new Date()).subtract(24, 'h').format(DATE_TIME_FORMAT);
    }
    else {
      this.startDate = moment(new Date(dateArr[0])).format(DATE_TIME_FORMAT_DAY_START);
      this.endDate = moment(new Date(dateArr[1])).format(DATE_TIME_FORMAT_DAY_END);
    }
    this.graphResult();
  }

  ngOnInit() {
    this.chartSelected = this.sharedService.attributeList.length > 0 ? 'linechart' : '';
    const container = d3.select(this.container.nativeElement).select(".chart-container").node();
    this.width = container["clientWidth"] - this.margin.left - this.margin.right;
    this.height = container["clientHeight"] - this.margin.top - this.margin.bottom - this.ploatFilterHeight;
    this.sharedService.searchTerm.subscribe((data: string) => {
      this.searchTerm = data;
    });
    this.registrationService.getTagDataForStatusValues().subscribe((data: any) => {
      this.sharedService.statusDateList = data;
    })
    if (this.sharedService.attributeList.every(x => x.hasOwnProperty('enumeritation'))) {
      this.aggregationInterval = 'native';
    }
    this.widget = this.route.snapshot.paramMap.get('id');
    if (this.widget) {
      this.renderWidget();
    }
    else {
      this.endDate = moment(new Date()).format(DATE_TIME_FORMAT_DAY_END)
      this.startDate = moment(new Date()).subtract(6, 'days').format(DATE_TIME_FORMAT_DAY_START)
      this.graphResult();
    }
  }

  renderWidget() {
    this.widgetService.getWidgetById(this.widget).then((data: any) => {
      this.widget = data;
      this.searchTerm = data.name;
      this.chartSelected = data.type;
      this.aggregationInterval = data.requestPayload.aggregationInterval;
      this.aggregationType = data.requestPayload.aggregationType;
      this.sharedService.attributeList = data.requestPayload.attributes;
      if (data.adaptive === undefined) {
        this.adaptive = {
          isAdaptive: true,
          config: '7 days'
        }
      }
      if (data.adaptive.config !== "Custom Range") {
        const date = getStartDateEndDate(data.adaptive.config);
        data.requestPayload["dateRange"] = [date.startDate, date.endDate];
      }
      else {
        this.adaptive = {
          isAdaptive: false,
          config: 'Custom Range'
        }
      }
      this.startDate = data.requestPayload.dateRange[0];
      this.endDate = data.requestPayload.dateRange[1];
      this.graphResult();
    })
  }

  selectChart(chart: string) {
    if (chart !== this.chartSelected) {
      this.chartSelected = chart;
      if (this.responseData?.length === 0) {
        this.graphResult();
      }
      else {
        d3.selectAll('svg.filter-plot').remove();
        this.renderChart(this.responseData, false);
      }
    }
  }

  clearSvgElement() {
    d3.selectAll('svg.chart-plot').remove();
    d3.selectAll('.chart-tooltip').remove();
    d3.selectAll('svg.filter-plot').remove();
  }

  async graphResult() {
    this.subgroups = createSubGroups(this.sharedService.attributeList);
    this.widget ? this.widget.subgroups = this.subgroups : {};
    if (this.chartSelected === 'statuschart') {
      await this.getStatusChartGraph();
    } else {
      this.getChartData();
    }
  }

  async getStatusChartGraph() {
    this.sharedService.attributeList = this.sharedService.attributeList.map((attribute) => {
      attribute['dataType'] = TELEMETRY;
      return attribute;
    });
    const payload = {
      attributes: this.sharedService.attributeList,
      aggregationInterval: 'native',
      aggregationType: this.aggregationType.toLowerCase(),
      dateRange: [this.startDate, this.endDate]
    };
    let statusChartData = [];
    await this.telemetryService.attributeResult(payload)
      .subscribe((data: any) => {
        this.isLoader = false;
        const list = [];
        data?.forEach(element => {
          const newObj = {
            name: element.name,
            id: element.id,
            _data: element.nativeList,
            data: element.nativeList
          };
          list.push(newObj);
        });
        statusChartData = list;
      })
    return statusChartData;
  }

  renderChart(_data, isUpdate) {
    this.showKpiCard = false;
    d3.select(this.container.nativeElement)
      .select(".chart-container div.main-chart")
      .style("width", (this.width) + this.margin.left + this.margin.right + "px")
      .style("height", (this.height + this.margin.top + this.margin.bottom + this.ploatFilterHeight) + "px")
    d3.selectAll(`.chart-container div.main-chart svg`).remove();
    d3.selectAll(`.chart-container div .chart-tooltip`).remove();
    if (this.aggregationInterval === "native" && this.chartSelected !== "tablechart" && this.chartSelected !== "areachart" && this.chartSelected !== 'kpicard') {
      this.lineGraphForNative(_data, isUpdate);
    }
    else if (this.aggregationInterval === "native" && this.chartSelected === "areachart") {
      this.areaGraphForNative(_data, isUpdate);
    }
    else if (this.chartSelected === "tablechart") {
      this.widget = {
        ...this.widget,
        widgetType: this.chartSelected,
        subgroups: this.subgroups,
        requestPayload: {
          attributes: this.sharedService.attributeList,
          aggregationInterval: this.aggregationInterval.toLowerCase(),
          aggregationType: this.aggregationType.toLowerCase(),
          dateRange: [this.startDate, this.endDate]
        }
      }
      this.showTable = true;
      this.table.ngOnInit();
    } else if (this.chartSelected === 'kpicard') {
      this.showKpiCard = true;
    } else {
      d3.select('.chart-container div.main-chart').attr('hidden', null);
      const config = {
        attributes: this.sharedService.attributeList,
        widgetType: this.chartSelected,
        showScrollbar: true,
        aggregationType: this.aggregationType,
        aggregationInterval: this.aggregationInterval,
        elasticSearchservice: this.telemetryService,
        enumeritation: [],
        splitYAxis: this.splitYAxis
      }
      if (!this.noDataFound) {
        renderChart(`.chart-container div.main-chart`, _data, config);
      }
    }
  }

  processDataForChart() {
    let data;
    if (this.chartSelected === "linechart") {
      data = processDataForLineChart(this.responseData, this.subgroups)
    }
    else {
      data = processDataForBarChart(this.responseData, this.subgroups)
    }
    return data;
  }

  async getChartData() {
    this.isLoader = true;
    this.showTable = false;
    this.sharedService.attributeList = this.sharedService.attributeList.map((attribute) => {
      attribute['dataType'] = TELEMETRY;
      return attribute;
    });
    const payload = {
      attributes: this.sharedService.attributeList,
      aggregationInterval: this.aggregationInterval.toLowerCase(),
      aggregationType: this.aggregationType.toLowerCase(),
      dateRange: [this.startDate, this.endDate]
    };
    this.responseData = [];
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.telemetryService.attributeResult(payload)
      .subscribe((data: any) => {
        this.isLoader = false;
        this.responseData = data;
        this.noDataFound = this.responseData?.every(item => {
          return (item.aggregation && _.isEmpty(item.aggregation)) || item?.nativeList?.length === 0
        })
        const _data = this.responseData?.filter((item => {
          return (!(item.aggregation && _.isEmpty(item?.aggregation))) || item?.nativeList?.length !== 0
        }));
        this.renderChart(_data, false);
      })
  }

  async lineGraphForNative(data, isUpdate) {
    this.isLoader = false;
    const config = {
      attributes: this.sharedService.attributeList,
      showScrollbar: true,
      splitYAxis: this.splitYAxis
    }

    d3.select('.chart-container div.main-chart').attr('hidden', null)
    d3.select(this.container.nativeElement)
      .select(".chart-container div.main-chart")
      .style("width", (this.width) + this.margin.left + this.margin.right + "px")
      .style("height", (this.height + this.margin.top + this.margin.bottom + this.ploatFilterHeight) + "px")
    if (this.sharedService.attributeList.every(x => x.hasOwnProperty('enumeritation'))) {
      if (!this.noDataFound) {
        lineGraphForNativeStatusData(`.chart-container div.main-chart`, data, config);
      }
    }
    else {
      if (!this.noDataFound) {
        lineGraphForNative(`.chart-container div.main-chart`, data, config);
      }
    }
  }

  async areaGraphForNative(data, isUpdate) {
    this.isLoader = false;
    const config = {
      attributes: this.sharedService.attributeList,
      showScrollbar: true,
      splitYAxis: this.splitYAxis
    }

    d3.select('.chart-container div.main-chart').attr('hidden', null)
    d3.select(this.container.nativeElement)
      .select(".chart-container div.main-chart")
      .style("width", (this.width) + this.margin.left + this.margin.right + "px")
      .style("height", (this.height + this.margin.top + this.margin.bottom + this.ploatFilterHeight) + "px")
    if (this.sharedService.attributeList.every(x => x.hasOwnProperty('enumeritation'))) {
      if (!this.noDataFound) {
        lineGraphForNativeStatusData(`.chart-container div.main-chart`, data, config);
      }
    }
    else {
      if (!this.noDataFound) {
        areaGraphForNative(`.chart-container div.main-chart`, data, config);
      }
    }
  }

  intervalChange(event) {
    this.aggregationInterval = event.value;
    if (this.widget) {
      this.widget.requestPayload.aggregationInterval = event.value;
    }
    this.graphResult();
  }

  typeChange(event) {
    this.graphResult();
  }

  openAdditionalParameter() {
    const dialogRef = this.dialog.open(AdditionalParametersComponent, {
      width: "50vw",
      height: "90vh",
      data: {
        headerTitle: "Add/Remove Additional Parameter",
        actionButton: "Update"
      }
    });

    dialogRef.afterClosed().subscribe((isClosed: boolean) => {
      if (isClosed) {
        this.graphResult();
      }
    })
  }

  back() {
    this.location.back()
  }

  pageChange(data) {
    this.widget = data;
  }

  saveProject() {
    if (this.widget?._id) {
      const widgetData =
      {
        _id: this.widget._id,
        name: this.searchTerm,
        type: this.chartSelected,
        dashboardId: this.widget.dashboardId,
        requestPayload: {
          attributes: this.sharedService.attributeList,
          aggregationInterval: this.aggregationInterval.toLowerCase(),
          aggregationType: this.aggregationType.toLowerCase(),
          dateRange: [this.startDate, this.endDate]
        },
        requestMethod: 'POST',
        requestUrl: `${environment.serverUrl}/api/v1/elastic-search/attributeResult`,
        xaxis: 'time',
        yaxis: 'value',
        x: this.widget.x,
        y: this.widget.y,
        h: this.widget.h,
        w: this.widget.w,
        adaptive: this.adaptive,
        pageSize: this.widget?.pageSize || 5,
        splitYAxis: this.splitYAxis
      }
      this.dialog.open(SaveProjectComponent, {
        width: "40vw",
        maxHeight: "500px",
        data: {
          widgetData: widgetData
        }
      });
    }
    else {
      this.dialog.open(SaveProjectComponent, {
        width: "40vw",
        maxHeight: "500px",
        data: {
          name: this.searchTerm,
          widgetType: this.chartSelected,
          requestPayload: {
            attributes: this.sharedService.attributeList,
            aggregationInterval: this.aggregationInterval.toLowerCase(),
            aggregationType: this.aggregationType.toLowerCase(),
            dateRange: [this.startDate, this.endDate]
          },
          requestMethod: 'POST',
          requestUrl: `${environment.serverUrl}/api/v1/elastic-search/attributeResult`,
          xaxis: 'time',
          yaxis: 'value',
          adaptive: this.adaptive,
          cardType: this.cardType,
          splitYAxis: this.splitYAxis,
          pageSize: this.widget?.pageSize || 5
        }
      });
    }
  }

  enableSplitYAxis() {
    this.splitYAxis = !this.splitYAxis;
    this.renderChart(this.responseData, false);
  }

  downloadData() {
    const config = {
      aggregationInterval: this.aggregationInterval,
      searchTerm: this.searchTerm,
      subgroups: this.subgroups,
      aggregationType: this.aggregationType,
      widgetType: this.chartSelected
    }
    if (this.aggregationInterval !== 'native' && (this.aggregationType === "min" || this.aggregationType === "max")) {
      const payload = {
        attributes: this.sharedService.attributeList,
        aggregationInterval: this.aggregationInterval.toLowerCase(),
        aggregationType: this.aggregationType.toLowerCase(),
        dateRange: [this.startDate, this.endDate]
      };
      this.toastrService.success("Download started");
      this.telemetryService.downloadResults(payload).subscribe((result :any) => {
        downloadDataForMinMax(result?.data, config);
        this.toastrService.success("Download finished");
      })
    }
    else {
      downloadData(this.responseData, config);
    }
  }

  getCardInfo(isMerged: boolean) {
    this.cardType = isMerged ? 'merged' : 'unmerged';
  }

  displayChart(data: any) {
    this.sharedService.attributeList = this.sharedService.attributeList?.map(attribute => {
      if (attribute.deviceId === data.deviceId) {
        attribute.display = !attribute.display;
      }
      return attribute;
    });
    this.subgroups = createSubGroups(this.sharedService.attributeList);
    this.renderChart(this.responseData, false);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}