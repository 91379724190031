import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ElasticSearchService } from 'src/app/reusable/services/elastic-search.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { searchAttributesRestricted } from '../dataSource';
import * as _ from 'underscore';
import { RegistrationService } from '../services/registration.service';

@Component({
  selector: 'search-attribute',
  templateUrl: './search-attribute.component.html',
  styleUrls: ['./search-attribute.component.css']
})
export class SearchAttributeComponent implements OnInit {

  public searchTerm: string;
  public searchSuggestions = [];
  public attributesRestricted = searchAttributesRestricted;

  @Input() redirectPage;
  @Output() displayResult = new EventEmitter<any>();

  constructor(private elasticSearchservice: ElasticSearchService, private registrationService: RegistrationService, private sharedService: SharedServiceService, private router: Router) {
    this.onSearch = _.debounce(this.onSearch, 500);
  }

  ngOnInit(): void {
  }

  public onSearch(value: any) {
    if (value) {
      this.searchTerm = value.target.value
      if (value.keyCode === 13) {
        this.searchResult(this.searchTerm);
      }
      else {
        this.elasticSearchservice.searchSuggestion({ query: this.searchTerm })
          .toPromise()
          .then((data: any) => {
            this.searchSuggestions = data?.filter((suggestion: any) => {
              if (this.attributesRestricted?.includes(suggestion?.keyword)) {
                return;
              }
              return suggestion;
            });
          })
      }

    } else {
      this.searchSuggestions = [];
    }
  }

  searchResult(param: string) {
    this.sharedService.updateSearchTerm(param);

    this.registrationService.getTagDataForStatusValues().subscribe((data: any) => {
      this.sharedService.statusDateList = data;
    })
    if (this.redirectPage) {
      this.displayResult.emit(param);
      this.searchTerm = null;
    }
    else {
      if (param) {
        this.searchTerm = null;
        this.router.navigateByUrl('/search-result');
      }
    }
  }
}
