import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-dismiss-alert',
  templateUrl: './dismiss-alert.component.html',
  styleUrls: ['./dismiss-alert.component.css', 
  '../../../assets/Reusable-CSS/form.scss', 
  '../../../assets/Reusable-CSS/buttons.scss',
  '../../../assets/Reusable-CSS/popup.scss',
  "../../../assets/styles/form.css",
  "../../../assets/Reusable-CSS/mat.scss"
  ]
})

export class DismissAlertComponent implements OnInit {
  public ALERT_NOT_SIGNIFICANT =   "Alert not significant";
  public ALERT_INCORRECTLY_MARED = "Alert incorrectly marked";
  public OTHER = 'Other';

  public dimissalReasonsList = [
    this.ALERT_NOT_SIGNIFICANT,
    this.ALERT_INCORRECTLY_MARED,
    this.OTHER
  ]

  public dismissalReason : string = "";
  public otherReason : string = "";
  constructor(@Inject(MAT_DIALOG_DATA)
   public data,
   private matDialogRef : MatDialogRef<DismissAlertComponent> 
  ) { }

  ngOnInit(): void {
  }

  dismissAlert() {
    this.data.dismissalReason.reason  = this.dismissalReason == 'others' ? this.otherReason : this.dismissalReason;
    this.matDialogRef.close(true);
  }

  isDisabled() {
    if(this.dismissalReason === this.OTHER && this.otherReason) {
      return false;
    }
    return (this.dismissalReason && this.dismissalReason !== this.OTHER) ? false : true
  }

}
