import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-data-not-found-template',
  templateUrl: './chart-data-not-found-template.component.html',
  styleUrls: ['./chart-data-not-found-template.component.css']
})
export class ChartDataNotFoundTemplateComponent implements OnInit {

  @Input()
  public title = "No data available for the selected duration";
  @Input()
  public subTitle = "Please select a duration for which data is available";
  @Input()
  public showIcon : boolean = true;
  @Input()
  public iconUrl : string = '../../../assets/images/no-search-result.png';
  constructor() { }

  ngOnInit(): void {
  }

}
