import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { ApiService } from "src/app/services/api.service";
import { routes } from '../device-onboarding.config';

@Injectable({
  providedIn: "root"
})
export class DeviceProfileService {
  private options = {
    headers: {
      "Content-Type": "application/json;ty=2",
      'Accept': 'Application/json'
    }
  }
  constructor(private apiService: ApiService) {
  }

  public saveDeviceProfile(payload: any) {
    const options = { headers: { "Content-Type": "application/json" } };

    return new Promise((resolve, reject) => {
      this.apiService.post("/device-onboarding/saveDeviceProfile", payload, options).subscribe((response) => {
        resolve(response);
      } ,(error: any) => {
        reject(error);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public updateDeviceProfile(payload: any) {
    const options = { headers: { "Content-Type": "application/json" } };
    return new Promise((resolve, reject) => {
      this.apiService.post("/device-onboarding/updateDeviceProfile", payload, options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }



  public makeDefaultProfile(payload: any) {
    const options = { headers: { "Content-Type": "application/json" } };
    return new Promise((resolve, reject) => {
      this.apiService.post("/device-onboarding/makeDefaultProfile/"+payload, null, options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }


  public getDeviceProfileById(id: string) {
    return new Promise((resolve, reject) => {
      this.apiService.get(routes.GET_PROFILE_BY_NAME+'/'+id, this.options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }
  
  public getAllDeviceProfile() {
    return new Promise((resolve, reject) => {
      this.apiService.get(routes.GET_ALL_DEVICE_PROFILE, this.options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public getAllManufacturer(createdBy: any, manufacturer: string) {
    return new Promise((resolve, reject) => {
      this.apiService.get(`/device-onboarding/getAllManufacturer?createdBy=${createdBy}&manufacturer=${manufacturer}`, null).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }


  public deleteDeviceProfile(id: string) {
    return new Promise((resolve, reject) => {
      this.apiService.delete(`/device-onboarding/deleteDeviceProfile/${id}`, null).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public saveDraftOPCProfile(payload: any, options?: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.post("/onboarding/profile/opcua", payload, options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public async generateCertificate(payload: any, options?: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.post(`/onboarding/generate/opcua/certificate?profileID=${payload}`, null, options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }
 
  public getProfileByName(name) {
    return new Promise((resolve, reject) => {
      this.apiService.get(`/onboarding/profile/opcua/${name}`).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public async uploadCertificate(payload: any, options?: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.post(`/onboarding/upload/opcua/certificate?profileID=${payload.profileID}`, payload.file, options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public async testConnection(payload: any, options?: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.get(`/onboarding/testConnection?profileID=${payload}`).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public async getNodes(payload: any, options?: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.get(`/onboarding/getNodes?profileID=${payload.profileID}&parentNodeId=${payload.parentNodeId?payload.parentNodeId:""}`).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public registerbulkProfiles(payload: any, options?: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.post("/onboarding/bulkRegistration/opcua", payload, options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  getRegistrationsByProfileName(payload: any, options?: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.get(`/onboarding/registration/${payload}`).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public async downloadCertificate(payload: any,options?: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.get(`/onboarding/download/certificate?ociPath=${payload}`,options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  saveNodes(profileID,payload: any){
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.put(`/onboarding/saveNodes?profileID=${profileID}`, payload).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public availableDeviceTypes(payload: any, options?: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.post("/onboarding/profile/protocol/availableDeviceTypes", payload, options).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public async isReachable(payload: any, options?: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.get(`/onboarding/isReachable?ip=${payload}`).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }

  public deployProfile(payload: any, options?: any) {
    return new Promise((resolve, reject) => {
      // todo: put url's in a config
      this.apiService.post(`/onboarding/profile/${payload}/start`, payload).subscribe((response) => {
        resolve(response);
      });
    }).catch((error: any) => {
      throw error;
    });
  }
}
