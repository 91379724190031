import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ElasticSearchService } from 'src/app/reusable/services/elastic-search.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { deviceTypeData, locationData, whiteListedAttributes } from '../dataSource';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-additional-parameters',
  templateUrl: './additional-parameters.component.html',
  styleUrls: [
    './additional-parameters.component.css',
    '../../../assets/Reusable-CSS/popup.scss',
    '../../../assets/Reusable-CSS/buttons.scss',
    '../../../assets/Reusable-CSS/mat.scss'
  ]

})
export class AdditionalParametersComponent implements OnInit {

  public attributeList = [];
  public results = [];
  public isLoader = false;
  public isNotFound = false;
  public searchTerm: string = '';
  public whiteListedAttributes = whiteListedAttributes;
  public deviceTypeSelectedList: string[] = [];
  public locationSelectedList: string[] = [];


  currentPage = 1;
  totalRecordsCount = 0;
  totalPageCount = 0;
  pageSize = 10;
  pageSizeOption = [10, 15, 20, 25];
  filteredRecordsCount;

  subscription: Array<Subscription> = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private elasticSearchservice: ElasticSearchService,
    private sharedService: SharedServiceService,
    private dialogRef: MatDialogRef<AdditionalParametersComponent>) { }

  ngOnInit() {
    this.attributeList = this.sharedService.attributeList;
    this.filteredRecordsCount = this.attributeList?.length; 
    const subscribe = this.sharedService.searchTerm.subscribe((data: string) => {
      this.searchTerm = data;
      this.results = [];
      this.attributeList.forEach(element => {
        element.isSeleted = true;
        this.results.push(element)
      });
    })
    this.subscription.push(subscribe);
  }

  searchResult(queryParam) {
    this.searchTerm = queryParam;
    this.isLoader = true;
    const payload = {
      query: queryParam,
      filter: {
        deviceType: this.deviceTypeSelectedList?.length ? this.deviceTypeSelectedList : null,
        location: this.locationSelectedList?.length ? this.locationSelectedList : null
      }
    };
    const subscribe = this.elasticSearchservice.attributeTextSearch(payload, this.pageSize, this.currentPage)
    .subscribe((data: any) => {
      this.isLoader = false;
      if (data) {
        this.results = data?.currentValues.filter((result: any) => {
         // if (this.whiteListedAttributes.includes(result?.path)) {
          return !this.attributeList.find((x) => x.id === result.id);
         // }
        });
        this.results = this.sharedService.attributeList.concat(this.results);
        this.totalPageCount = data.totalNumberOfPages;
        this.totalRecordsCount = data.totalNumberOfRecords;
        this.filteredRecordsCount = data.filteredRecords;
      } else {
        this.results = [];
        // this.toastr.error("No results found");
      }
    })
    this.subscription.push(subscribe);
  }

  addParameter() {
    if (this.attributeList?.length) {
      this.sharedService.attributeList = this.attributeList;
      this.sharedService.attributeList.forEach(item => {
        const tag = this.sharedService.statusDateList.find(x => x.deviceID === item.deviceID);
        if (tag) {
          item.enumeritation = tag.enumeration;
          item.path = 'content.value'
        }
      })
      this.dialogRef.close(true);
    }
  }

  updateAttributeList(event) {
    this.attributeList = event;

  }

  updateSearchResult(event) {
    this.deviceTypeSelectedList = event.deviceTypeSelectedList;
    this.locationSelectedList = event.locationSelectedList;
    this.handlePageChange(1);
  }

  handlePageChange(pageNo) {
    this.currentPage = pageNo;
    this.searchResult(this.searchTerm);
  }

  tablePageSizeChange(itemPerPage) {
    this.pageSize = itemPerPage;
    this.handlePageChange(1);
  }

  ngOnDestroy() {
    if (this.subscription.length) {
      this.subscription.forEach(item => item.unsubscribe());
    }
  }
  
}
