import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { SQL_INJECTION_REGEX, SQL_INJECTION_ERROR, STRING_LENGTH_EXCEDDED_ERROR, INVALID_INPUT, NOT_A_VALID_NUMBER, NOT_A_VALID_INTEGER, REQUIRED_FIELD_ERROR, VALUE_LIMIT_EXCEEDED, TYPE_NUMBER, TYPE_INTEGER, NUMERIC_STRING_REGEX } from '../reusable/constant';
import { SqlInjectionValidatorOptions, thresholdInputValidatorOptions } from '../reusable/common.interfaces';

@Injectable({
  providedIn: 'root'
})
export class CustomvalidationService {

  constructor() { }

  sqlInjectionValidator(options: SqlInjectionValidatorOptions): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control?.value) {
        return null;
      }
      const sqlKeywords = ['SELECT', 'INSERT', 'UPDATE', 'DELETE', 'DROP', 'UNION', 'OR', 'CREATE','TABLE', 'UNION', 'NULL', '--', 'JOIN', 'ON'];
      const specialCases = ['ORDER BY', 'GROUP BY'];
      // Transform the input to uppercase for case-insensitive comparison
      const inputValue = control?.value?.toString().toUpperCase() as string;

      // Check for special case keywords first
      for (const keyword of specialCases) {
        if (inputValue?.includes(keyword)) {
          return { sqlInjection: true, message: INVALID_INPUT };
        }
      }

      // Split the input into words and check against the other keywords
      const inputWords = inputValue?.split(/\s+/);
      for (const keyword of sqlKeywords) {
        if (inputWords?.includes(keyword)) {
          return { sqlInjection: true, message: INVALID_INPUT };
        }
      }

      // Additional checks for common SQL operators
      if (inputValue?.includes('\'') || inputValue?.includes(';')) {
        return { sqlInjection: true, message: SQL_INJECTION_ERROR};
      }

      // allow alphabets and numbers only
      if (!inputValue?.match(SQL_INJECTION_REGEX)) {
        return { sqlInjection: true, message: SQL_INJECTION_ERROR };
      }

      if(inputValue?.length > options?.maxLength) {
        return { sqlInjection: true, message: STRING_LENGTH_EXCEDDED_ERROR };
      }
      return null;
    };
  }

  thresholdInputValidator(options: thresholdInputValidatorOptions): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return { thresholdInput: true, message: REQUIRED_FIELD_ERROR };
    }

    const inputValue = control?.value as string;
    const numericValue = Number(inputValue);

    // General number validation
    const isNumber = !isNaN(numericValue) && NUMERIC_STRING_REGEX.test(inputValue);
    const isInteger = isNumber && Number.isInteger(numericValue);

    // Number type validation
    if (options.type.toUpperCase() === TYPE_NUMBER) {
      if (!isNumber) {
        return { thresholdInput: true, message: NOT_A_VALID_NUMBER };
      }
    }

    // Integer type validation
    if (options?.type?.toUpperCase() === TYPE_INTEGER) {
      if (!isInteger) {
        return { thresholdInput: true, message: NOT_A_VALID_INTEGER };
      }
    }
    
    if (options?.maxValue && numericValue > options?.maxValue) {
      return {
        thresholdInput: true,
        message: VALUE_LIMIT_EXCEEDED
      };
    }

    return null;
  };
}
}
