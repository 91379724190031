<button class="meterFilter">
    <div class="filterHeader">
        <span>{{title}} </span>
        <img src="assets/images/filter (1).svg" class="filterCommonIcon">
    </div>
    <div class="meterPopup">
        <div class="optionsFilter">
            <div class="dashboards" *ngFor="let type of filterData">
                <mat-checkbox [(ngModel)]="type.isSelect" (ngModelChange)="filterClick()">
                    {{type.key}}
                </mat-checkbox>
            </div>
        </div>
    </div>
</button>