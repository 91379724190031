import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-data-not-found',
  templateUrl: './data-not-found.component.html',
  styleUrls: ['./data-not-found.component.css']
})
export class DataNotFoundComponent implements OnInit {
  // default titles 
  @Input() public title = "No data available for the selected duration";
  @Input() public subTitle = "Please select a duration for which data is available";
  @Input() public showIcon : boolean = true;
  @Input() public iconUrl : string = '../../../assets/images/no-search-result.png';

  constructor() { }

  ngOnInit(): void {
  }

}
