<div style="position: relative;">
    <div class="search_box" [ngClass]="{ 'borderRadious': this.searchTerm ? 1 : 0 }">
        <img src="assets/images/searchNew.svg">
        <input class="searchInput" [(ngModel)]="searchTerm" (keyup)="onSearch($event)" type="text"
            placeholder="Search across Tags, Assets & Dashboard...">

        <button [ngStyle]="{ 'opacity': this.searchTerm ? 1 : 0 }" (click)="this.searchTerm=null"
            class="clearButton"><img src="assets/images/circle-close-gray-dark.svg" /></button>
        <!-- <button (click)="searchResult(this.searchTerm)" class="searchButton">Search</button> -->
    </div>
    <div *ngIf="this.searchTerm" class="suggestion" id="suggestion1">
        <ul *ngIf="this.searchSuggestions && this.searchSuggestions.length">
            <li class="searchSuggestion" *ngFor="let data of searchSuggestions"
                (click)="searchResult(data.displayName)">{{data.displayName | attributeRename | titlecase}}
            </li>
        </ul>
    </div>
</div>
