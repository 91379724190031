import { ACTIVE, CRITICAL, DEVICE, DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_WATER_METER, DUBA_PLANT_SCADA, HIGH, LOW, MEDIUM, RESOLVED, SMART, THRESHOLD } from "src/app/reusable/constant"

export const COLUMN_NAMES = {
    alertName : [],
    severity : [],
    status : [],
    alertType : [],
    deviceTypeOrFacility:[],
    createdOn:[],
    activeFor: [],
    deviceSerialNumber: [],
    alertID:[],
    acknowledgedBy:[]
}


export class FILTER_COLUMNS {
    deviceTypeOrFacility = []
    severity = [
        {
          selected: false,
          name: 'Low',
          severity: LOW,
          key : "severity"
        },
        {
          selected: false,
          name: 'Medium',
          severity: MEDIUM,
          key : "severity"
        },
        {
          selected: false,
          name: 'High',
          severity: HIGH,
          key : "severity"

        },
        {
          selected: false,
          name: 'Critical',
          severity: CRITICAL,
          key : "severity"
        }
    ]
    status = [
        {
          selected: false,
          name: 'Active',
          status: ACTIVE,
          key : "status"
        },
        {
          selected: false,
          name: 'Resolved',
          status: RESOLVED,
          key : "status"
        }
    ]
    alertType = [
        {
          selected: false,
          name: 'Device',
          alertType: DEVICE,
          key : "alertType"
        },
        {
          selected: false,
          name: 'Smart',
          alertType: SMART,
          key : "alertType"
        },
        {
          selected: false,
          name: 'Threshold',
          alertType: THRESHOLD,
          key : "alertType"
        }
    ]
}

export const SORT_FILTER  = {
    createdOn: {
        inc: true,
        dec: false,
        hover: false,
        key : "createdOn"
    },
    activeFor: {
        inc: true,
        dec: false,
        hover: false,
        key : "activeFor"
    },
    deviceSerialNumber: {
        inc: true,
        dec: false,
        hover: false,
        key : "deviceSerialNumber"
    },
    alertID: {
        inc: true,
        dec: false,
        hover: false,
        key : "alertID"
    },
    acknowledgedBy: {
        inc: true,
        dec: false,
        hover: false,
        key : "acknowledgedBy"
    }

}





