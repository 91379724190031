import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as d3 from 'd3';
import { createSubGroups, downloadData, downloadDataForMinMax, processDataForBarChart, processDataForLineChart } from 'src/app/reusable/chart-util/chart-helper';
import { lineGraphForNativeStatusData } from 'src/app/reusable/chart-util/line-graph-native-status-chart';
import { ElasticSearchService } from 'src/app/reusable/services/elastic-search.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/services/toast/toast.service';
import { WidgetService } from 'src/app/services/CustomDashboard/widget.service';
import { SaveProjectComponent } from 'src/app/reusable/save-project/save-project.component';
import { ActivatedRoute } from '@angular/router';
import { TableViewComponent } from 'src/app/reusable/table-view/table-view.component';
import * as _ from 'underscore';
import { OperationalRangeService } from 'src/app/services/operational-range.service';
import { DevicesService } from '../../admin/devices/devices.service';
import { ALBADA_DEVICE_TYPE, DATE_TIME_FORMAT, DATE_TIME_FORMAT_DAY_END, DATE_TIME_FORMAT_DAY_START, DESKTOP, DUBA_DEVICE_TYPE, MOBILE_LANDSCAPE, MOBILE_PORTRAIT, PUMP_STATION_DEVICE_TYPE, TABLET_LANDSCAPE, TABLET_PORTRAIT, waterIndustrials, HPR_STAION_DEVICE_TYPE, FLOW_METER_DEVICE_TYPE, LOGISTICS_PARK_DEVICE_TYPE, NEOM_BAY_AIRPORT_DEVICE_TYPE, EXPERIENCE_CENTER_DEVICE_TYPE, SHARMA_OPCUA_DEVICE_TYPE, TELEMETRY, ALERT_TYPE_THRESHOLD, ALERT_TYPE_STALE, ALERT_TYPE_STAGNANT  } from 'src/app/reusable/constant';
import * as moment from 'moment';
import { lineGraphForNative } from 'src/app/reusable/chart-util/line-graph-native';
import { areaGraphForNative } from 'src/app/reusable/chart-util/area-graph-native';
import { Subscription } from 'rxjs';
import { breakPoints } from 'src/app/reusable/constant';
import { BreakpointService } from 'src/app/services/breakpoint-service/breakpoint.service';
import { AdditionalParametersComponent } from 'src/app/reusable/additional-parameters/additional-parameters.component';
import { AGGREGATION_INTERVAL_OPTIONS, AGGREGATION_TYPE_OPTIONS, COLOR } from 'src/app/reusable/chart-util/chart-config';
import { renderChart } from 'src/app/reusable/chart-util/aggregation-line-bar-area';
import { waterCommercial } from 'src/app/reusable/constant';
import { IEntityHeader, lineOfBusiness } from 'src/app/reusable/services/entity.interface';
import { RegistrationService } from 'src/app/reusable/services/registration.service';
import { TelemetryService } from 'src/app/reusable/services/telemetry.service';
import { setDateRange } from "src/app/reusable/date-util";
import { AlertsManagementService } from 'src/app/reusable/services/alerts-management.service';
import { IAlertDataModel } from '../../alerts/alerts.interface';
@Component({
  selector: 'app-tag-results',
  templateUrl: './tag-results.component.html',
  styleUrls: ['./tag-results.component.css', 
  '../../../../assets/Reusable-CSS/form.scss', 
  '../../../../assets/Reusable-CSS/main.scss', 
  '../../../../assets/Reusable-CSS/buttons.scss',
  "../../../../assets/Reusable-CSS/ng-select.scss",
  "../../../../assets/styles/form.css",]
})
export class TagResultsComponent implements OnInit {

  public chartSelected: string = 'linechart';
  public aggregationInterval = 'native';
  public aggregationType = 'average';
  public aggregationIntervalOptions = AGGREGATION_INTERVAL_OPTIONS;
  public aggregationTypeOptions = AGGREGATION_TYPE_OPTIONS;
  public colors = COLOR;
  public oprationalRange = false;
  public opRange;
  public showAlertRange = false;
  public alertRange;
  public subgroups = [];
  public group;
  public responseData: any = [];
  public isLoader = false;
  public noDataFound = false;
  public adaptive: any = {
    "isAdaptive": false,
    "config": "Custom Range"
  }
  public showTable = false;
  public showKpiCard = false;
  public cardType = null;
  public options: any = {
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    showCustomRangeLabel: true,
  };
  public breadcrumb: Array<any> = [
    {
      title: 'Home',
      route: '/home',
      isActive: false
    }
  ];
  public title = '';
  public deviceType = '';
  public dashBoardType = '';
  public deviceDetails: any = [];
  public minDate;
  public maxDate;
  public widget;
  private subscription: Subscription;
  public splitYAxis = false;
  public statusChart = false;
  breakPoints = breakPoints;
  FLOW_METER = FLOW_METER_DEVICE_TYPE;
  // Responsive design related variables
  private breakpointSubscription: Subscription;

  public alertsDataForChart: IAlertDataModel[] = [];
  public alertClassification: any = [];
  public alertsThreshold: boolean = false;
  public alertsStale: boolean = false;
  public alertsStagnant: boolean = false;
  public alertsCreated: boolean = false;
  public alertsSubscribed: boolean = false;
  public selectedOptions: any = [];

  @ViewChild(TableViewComponent, { static: false }) table: TableViewComponent;

  constructor(private container: ElementRef, private sharedService: SharedServiceService,
    private deviceService: DevicesService,
    private dialog: MatDialog, private elasticSearchservice: ElasticSearchService,
    public widgetService: WidgetService, public toastrService: ToastService, private route: ActivatedRoute,
    public operationalRangeService: OperationalRangeService,
    private breakpointService: BreakpointService,
    private registrationService: RegistrationService,
    private telemetryService: TelemetryService,
    private alertsManagementService: AlertsManagementService
  ) {

  }

  ngOnInit() {
    this.loadChart();
    this.registrationService.getTagDataForStatusValues().subscribe((data: any) => {
      this.sharedService.statusDateList = data;
    })
    // subscribe for change in breakpoint(Mobile, Tablet, Desktop) and there orientation
    this.breakpointSubscription = this.breakpointService.breakpointChanges.subscribe(matches => {
      this.breakpointService.setScreenUsed(matches);
      if (this.responseData.length) this.createChart();
    });
  }

  public loadChart() {
    this.setHeader();
    if (this.route.snapshot.paramMap.get('deviceId') === "all") {
      this.deviceDetails = this.sharedService.attributeList.map(x => {
        return {
          deviceId: x.deviceID,
          deviceID: x.deviceID,
          deviceType: x.deviceType,
          path: "content.value",
          dataType: TELEMETRY,
          name: x.description,
          unit: x.unit,
          id: x.id,
          display : true
        }
      });
      this.getChartData();
      this.title = this.breadcrumb[2].title;
    }
    else {
      this.deviceDetails.push({
        deviceId: this.route.snapshot.paramMap.get('deviceId'),
        deviceType: this.deviceType,
        path: "content.value",
        dataType: TELEMETRY,
        display : true
      });
      this.getRangeData(this.deviceDetails[0].deviceId);
      this.fetchRegistrationMetadata();
      this.title = this.deviceDetails[0].description;
    }
  }

  public selectedDate(value: any) {
    const dateArr = value;
    const adaptiveValue = dateArr[2] === "Custom Range" ? false : true
    this.adaptive = {
      "isAdaptive": adaptiveValue,
      "config": dateArr[2]
    }
    const date = setDateRange(dateArr);
    this.minDate = date.startDate;
    this.maxDate = date.endDate;
    this.getChartData();
  }

  setHeader() {
    this.deviceType = this.route.snapshot.paramMap.get('deviceType');
    this.minDate = decodeURIComponent(this.route.snapshot.queryParams.startDate);
    this.maxDate = decodeURIComponent(this.route.snapshot.queryParams.endDate);
    if(this.deviceType === SHARMA_OPCUA_DEVICE_TYPE) {
      this.dashBoardType = decodeURIComponent(this.route.snapshot.queryParams.dashboardType);
    }
    this.adaptive = {
      "isAdaptive": true,
      "config": "24 hours"
    }
    if (this.deviceType === 'Flow Meter') {
      this.breadcrumb.push(
        {
          title: 'Water - Customer',
          isActive: false,
          route: '/home/watermeters/customer'
        })
    } else {
      this.breadcrumb.push(
        {
          title: 'Water - Industrial',
          isActive: false,
          route: '/home/watermeters/industrial'
        })
    }


    if (this.deviceType === DUBA_DEVICE_TYPE)
      this.breadcrumb.push({
        title: waterIndustrials["DubaPlantScada"].title,
        route: waterIndustrials["DubaPlantScada"].route,
        isActive: false
      })
    else if (this.deviceType === PUMP_STATION_DEVICE_TYPE)
      this.breadcrumb.push({
        title: waterIndustrials['Pump Station'].title,
        route: waterIndustrials["Pump Station"].route,
        isActive: false
      })
    else if (this.deviceType === ALBADA_DEVICE_TYPE)
      this.breadcrumb.push({
        title: waterIndustrials['Water Recycling Plant'].title,
        route: waterIndustrials["Water Recycling Plant"].route,
        isActive: false
      })
    else if (this.deviceType === HPR_STAION_DEVICE_TYPE)
      this.breadcrumb.push({
        title: waterIndustrials['Dosing Plant'].title,
        route: waterIndustrials["Dosing Plant"].route,
        isActive: false
      })
    else if (this.deviceType === FLOW_METER_DEVICE_TYPE)
      this.breadcrumb.push({
        title: waterCommercial['Flow Meter'].title,
        route: waterCommercial["Flow Meter"].route,
        isActive: false
      })
    else if (this.deviceType === SHARMA_OPCUA_DEVICE_TYPE)
    this.breadcrumb.push({
      title: waterIndustrials[this.dashBoardType].title,
      route: waterIndustrials[this.dashBoardType].route,
      isActive: false
    })


    this.breadcrumb.push({
      title: 'Attribute Details',
      isActive: true
    })

  }

  createChart() {
    this.subgroups = createSubGroups(this.deviceDetails);
    this.statusChart = false;
    this.widget = {
      ...this.widget,
      widgetType: this.chartSelected,
      attributeType: this.widget?.attributeType || this.deviceDetails.deviceType,
      subgroups: this.subgroups,
      requestPayload: {
        attributes: this.deviceDetails,
        aggregationInterval: this.aggregationInterval.toLowerCase(),
        aggregationType: this.aggregationType.toLowerCase(),
        dateRange: [this.minDate, this.maxDate]
      }
    }
    this.group = this.deviceDetails;
    this.showKpiCard = false;
    d3.selectAll('.chart-container .main-chart svg').remove();
    if (this.chartSelected === "tablechart") {
      this.showTable = true;
    } else if (this.chartSelected === 'kpicard') {
      this.showKpiCard = true;
    } else {
      const config = {
        attributes: this.deviceDetails,
        widgetType: this.chartSelected,
        showScrollbar: true,
        aggregationType: this.aggregationType,
        aggregationInterval: this.aggregationInterval,
        elasticSearchservice: this.telemetryService,
        operationalRange: null,
        alertRange: null,
        splitYAxis: this.splitYAxis
      }
      const width = d3.select(`.chart-container`)?.style('width');
      const height = d3.select(`.chart-container`)?.style('height');

      d3.select('.chart-container div.main-chart').attr('hidden', null)
      d3.select(".chart-container div.main-chart")?.style("width", width)?.style("height", height)
      d3.selectAll(`.chart-container div.main-chart svg`).remove();
      d3.selectAll(`.chart-container div .chart-tooltip`).remove();

      if (this.oprationalRange && (this.chartSelected === 'linechart' || this.chartSelected === 'areachart') && this.deviceDetails.length === 1) {
        config.operationalRange = this.opRange;
      }
      if (this.showAlertRange && this.aggregationInterval === "native" && this.chartSelected === 'linechart' && this.deviceDetails.length === 1) {
        config.alertRange = this.alertRange;
      }
      if (this.aggregationInterval === "native" && !this.noDataFound && this.deviceDetails.every(x => x.hasOwnProperty('enumeritation'))) {
        this.statusChart = true;
        lineGraphForNativeStatusData(`.chart-container .main-chart`, this.responseData, config);
      }
      else if (this.aggregationInterval === "native" && this.chartSelected === "linechart" && !this.noDataFound && !this.deviceDetails.every(x => x.hasOwnProperty('enumeritation'))) {
        lineGraphForNative(`.chart-container .main-chart`, this.responseData, config);
      }
      else if (this.aggregationInterval === "native" && this.chartSelected === "areachart" && !this.noDataFound && !this.deviceDetails.every(x => x.hasOwnProperty('enumeritation'))) {
        areaGraphForNative(`.chart-container .main-chart`, this.responseData, config);
      }
      if (!this.noDataFound && this.aggregationInterval !== "native") {
        renderChart(`.chart-container .main-chart`, this.responseData, config);
      }
    }
  }

  selectChart(event) {
    d3.selectAll('.chart-container .main-chart svg').remove();
    this.chartSelected = event;
    this.createChart();
  }

  enableSplitYAxis() {
    this.splitYAxis = !this.splitYAxis;
    this.createChart();
  }

  fetchRegistrationMetadata() {
    this.isLoader = true;
    const payload = {
      attributes: this.deviceDetails,
      aggregationInterval: this.aggregationInterval.toLowerCase(),
      aggregationType: this.aggregationType.toLowerCase(),
      dateRange: [
        this.minDate,
        this.maxDate
      ]
    };
    this.deviceService.fetchRegistrationMetadata(this.deviceDetails[0].deviceId, payload)
      .then((data: any) => {
        this.deviceDetails[0] = { ...data[0] };
        this.deviceDetails[0].deviceId = data[0].deviceID;
        this.deviceDetails[0].display = true
        this.deviceDetails[0].path = "content.value";
        this.deviceDetails[0].dataType = TELEMETRY;
        this.deviceDetails[0].name = this.deviceDetails[0].metadata.description;
        if (this.deviceDetails[0].metadata.enumeration) {
          this.deviceDetails[0].enumeritation = this.deviceDetails[0].metadata.enumeration;
        }
        d3.select(`.chart-container .main-chart`)?.style('display', 'block');
        this.isLoader = false;
        if (data[1]) {
          this.responseData = data[1];
          this.responseData[0].name = this.deviceDetails[0].metadata.description;
          this.responseData[0].id = this.deviceDetails[0].id;
          this.noDataFound = this.responseData.every(item => {
            return (item.aggregation && _.isEmpty(item.aggregation)) || item?.nativeList?.length === 0
          })
          this.createChart();
        }
      }).catch((error) => {
        this.noDataFound = true;
        this.isLoader = false;
      })
  }

  intervalChange(event) {
    this.aggregationInterval = event.value;
    this.getChartData();
  }

  pageChange(data) {
    //this.widget = data;
  }

  typeChange(event) {
    this.aggregationType = event.value;
    this.getChartData();
  }

  getChartData() {
    this.isLoader = true;
    this.showTable = false;
    const payload = {
      attributes: this.deviceDetails,
      aggregationInterval: this.aggregationInterval.toLowerCase(),
      aggregationType: this.aggregationType.toLowerCase(),
      dateRange: [
        this.minDate,
        this.maxDate
      ]
    };
    this.unsubscribe();
    this.subscription = this.telemetryService.attributeResult(payload)
      .subscribe((data: any) => {
        d3.select(`.chart-container .main-chart`)?.style('display', 'block');
        this.isLoader = false;
        if (data) {
          this.responseData = data;
          this.noDataFound = this.responseData.every(item => {
            return (item.aggregation && _.isEmpty(item.aggregation)) || item?.nativeList?.length === 0
          })
          if(this.aggregationInterval === 'native' && this.chartSelected === 'linechart' && this.deviceDetails.length === 1 && this.deviceType != FLOW_METER_DEVICE_TYPE) {
            this.getAlertsDataForChart();
          }
          
          this.createChart();
        }
      }, (error) => {
        this.isLoader = false;
        this.noDataFound = true;
      })
  }

  getRangeData(id) {
    const entity: IEntityHeader = {
      deviceType: this.deviceDetails[0].deviceType,
      sector: 'Water',
      lineOfBusiness: lineOfBusiness.industrial
    }
    this.registrationService.fetchRegistrationMetadata(id, entity).subscribe((result: any) => {
      this.opRange = {
        minValue: result.metadata?.minValue,
        maxValue: result.metadata?.maxValue
      }
    })
  }

  renderWidget() {
    this.getChartData();
  }

  saveProject() {
    this.dialog.open(SaveProjectComponent, {
      width: "30%",
      minWidth: '320px',
      data: {
        name: this.deviceDetails[0].name,
        widgetType: this.chartSelected,
        attributeType: this.deviceDetails.deviceType,
        isOperationalRange: this.oprationalRange,
        requestPayload: {
          attributes: this.deviceDetails,
          aggregationInterval: this.aggregationInterval.toLowerCase(),
          aggregationType: this.aggregationType.toLowerCase(),
          dateRange: [this.minDate, this.maxDate]
        },
        requestMethod: 'POST',
        requestUrl: `${environment.serverUrl}/api/v1/elastic-search/attributeResult`,
        xaxis: 'time',
        yaxis: 'value',
        adaptive: this.adaptive,
        pageSize: 5,
        splitYAxis: this.splitYAxis
      }
    });
  }

  processDataForChart() {
    let data;
    if (this.chartSelected === "linechart") {
      data = processDataForLineChart(this.responseData, this.subgroups)
    }
    else {
      data = processDataForBarChart(this.responseData, this.subgroups)
    }
    return data;
  }

  enableOprationalRange() {
    this.oprationalRange = !this.oprationalRange;
    this.selectedOptions = [];
    this.createChart();
  }

  downloadData() {
    const config = {
      aggregationInterval: this.aggregationInterval,
      searchTerm: this.deviceDetails[0].metadata?.description || this.deviceDetails[0].name,
      subgroups: this.subgroups,
      aggregationType: this.aggregationType,
      widgetType: this.chartSelected
    }
    if (this.aggregationInterval !== 'native' && (this.aggregationType === "min" || this.aggregationType === "max")) {
      const payload = {
        attributes: this.deviceDetails,
        aggregationInterval: this.aggregationInterval.toLowerCase(),
        aggregationType: this.aggregationType.toLowerCase(),
        dateRange: [
          this.minDate,
          this.maxDate
        ]
      }
      this.toastrService.success("Download started");
      this.telemetryService.downloadResults(payload).subscribe((result: any) => {
        downloadDataForMinMax(result?.data, config);
        this.toastrService.success("Download finished");
      }, (error) => {
        this.toastrService.error("Error while downloading");
      })
    }
    else {
      downloadData(this.responseData, config);
    }
  }

  getCardInfo(isMerged: boolean) {
    this.cardType = isMerged ? 'merged' : 'unmerged';
  }

  openAdditionalParameter() {
    this.sharedService.attributeList = this.deviceDetails;
    const dialogRef = this.dialog.open(AdditionalParametersComponent, {
      width: "50vw",
      height: "90vh",
      data: {
        headerTitle: "Add/Remove Additional Parameter",
        actionButton: "Update"
      }
    });

    dialogRef.afterClosed().subscribe((isClosed: boolean) => {
      if (isClosed) {
        // this.sharedService.attributeList
        this.sharedService.attributeList = this.sharedService.attributeList.map((attribute) => {
          attribute['dataType'] = TELEMETRY;
          return attribute;
        });
        this.deviceDetails = this.sharedService.attributeList;
        if (this.deviceDetails.length === 1) {
          this.getRangeData(this.deviceDetails[0].deviceId);
        }
        this.getChartData();
      }
    })
  }

  displayChart(data: any) {
    this.deviceDetails = this.deviceDetails?.map(attribute => {
      if (attribute.deviceID === data.deviceId) {
        attribute.display = !attribute.display;
      }
      return attribute;
    });
    this.createChart();
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
  
  getAlertsDataForChart() {
    const deviceID = this.deviceDetails.length ? (this.deviceDetails[0]?.deviceID || '') : '';
    const payload = {
      deviceID: deviceID,
      alertClassification: this.alertClassification,
      created: this.alertsCreated,
      subscribed: this.alertsSubscribed,
      dateRange: [
        this.minDate,
        this.maxDate
      ]
    }
    this.isLoader = true;
    this.alertsManagementService.getAlertsPlotIndustrial(payload).subscribe(
      (result: any) => {
      this.setAlertsData(result);
      this.isLoader = false;
      },
      (error) => {
        console.error('Error fetching alerts data:', error);
        this.isLoader = false;
      });
  }

  handleAlertClassificationChange(getData: boolean = true) {
    const selectedValues = [];
    if(this.alertsThreshold) {
      selectedValues.push(ALERT_TYPE_THRESHOLD);
    }
    if(this.alertsStale) {
      selectedValues.push(ALERT_TYPE_STALE);
    }
    if(this.alertsStagnant) {
      selectedValues.push(ALERT_TYPE_STAGNANT);
    }
    this.alertClassification = selectedValues;
    this.selectedOptions = [];
    if(getData) this.getAlertsDataForChart();
  }

  setAlertsData(result: any) {
    this.alertsDataForChart = result.data;
      if(this.alertsDataForChart && this.alertsDataForChart.length > 0) {
        this.alertRange = this.setDataForMultipleAlert(this.alertsDataForChart);
        this.showAlertRange = true;
      }
      else {
        this.showAlertRange = false;
      }
      this.createChart();
  }

  setDataForMultipleAlert(data) {
    const userEmail = localStorage.getItem('useremail');
    const alertData = data?.map(item => ({
        id: item.id,
        isActive: !item.eventEnd,
        startDate: item.eventStart,
        endDate: item.eventEnd || moment(),
        // alertDate: [{ alertDate: item.createdOn, color: '#70AD47' }],
        alertClassification: item.alertClassification,
        subscibedUser: item.rules[0].createdByUser !== userEmail,
        thresholdValues: []
      }));

    return alertData || [];
  }

}