<div class="d-flex filters">
    <div class="d-flex align-items-start filterTagsList">
        <div class="filterTags"*ngFor="let item of appliedFilters">
            <div *ngIf="item.selected">
                <span>{{item.name}}</span>
                <img src="assets/images/cross.svg" width="14px" class="cursorPointer" (click)="removeFilter(item)"/>
            </div>
        </div>
        <button class="clearAll" *ngIf="appliedFilters.length > 0" (click)="clearAllFilter()">Clear All</button>
    </div>
</div>