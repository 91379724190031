<div class="customMapFilter">
    <div class="leftBlk" id="leftOptions">
      <ng-container *ngIf="isOptionsAddedToMap">
        <div class="customFilter bgWhite" *ngIf="!selectedMeterOrFacility">
            <ul class="consumerIndustrialMenu">
                <li>
                    <button class="subNavigationClick">
                        Consumer <span class="material-symbols-outlined">keyboard_arrow_down</span>
                        <div class="consumerIndustrialSubMenu">
                            <a *ngFor="let item of consumerList" (click)="handleSelectionOfMeterOrFacility(item)">{{item}}</a>
                        </div>
                    </button>
                </li>
                <li>
                    <button class="subNavigationClick">
                        Industrial <span class="material-symbols-outlined">keyboard_arrow_down</span>
                        <div class="consumerIndustrialSubMenu">
                            <a *ngFor="let item of industrialList" (click)="handleSelectionOfMeterOrFacility(item)">{{item}}</a>
                        </div>
                    </button>
                </li>
            </ul>
        </div>
        <div class="afterSelectValue bgWhite" *ngIf="selectedMeterOrFacility">
            {{selectedMeterOrFacility}} <span>({{devicesOfSelectedMeterOrFacility.length}})</span> 
            <span class="material-symbols-outlined closeIcon" (click)="clearSelectedMeterOrFacility()">
                close
            </span>
        </div>
      </ng-container>
    </div>

    <div class="rightBlk" id="rightOption">
        <ul class="waterHeatmap">
            <li>
                <div class="contentBlk showCons" *ngIf="showHeatMapCheckbox">
                    <mat-checkbox [(ngModel)]="selectedHeatMap" (change)="handleHeatMapToggle($event)">Show Heatmap</mat-checkbox>
                </div>
            </li>
        </ul>
    </div>
    <div *ngIf="loading" class="spinner">
        <!-- <img id="loading-image" src="assets/images/loader.gif" alt="Loading..." height="70px" width="70px"/> -->
         <div class="loader"></div>
    </div>
    <app-landing-page-esri-map [selectedHeatMap]="selectedHeatMap" style="position: relative;" (mapInitialized)="esriMapInitialized($event)" (optionAddedToMap)="optionAddedToMap($event)"></app-landing-page-esri-map>
    
</div>
