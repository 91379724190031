import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomDashboardService } from 'src/app/services/CustomDashboard/custom-dashboard.service';
import { UserAuthService } from '../../sso/user-auth.service';
import { ElasticSearchService } from 'src/app/reusable/services/elastic-search.service';
import { restrictedUsers } from '../../../reusable/constant';
@Component({
  selector: 'app-sector-home',
  templateUrl: './sector-home.component.html',
  styleUrls: ['./sector-home.component.css']
})
export class SectorHomeComponent implements OnInit {

  public returnUrl: string;
  public customDashboard = [];
  public dashboard: any;
  public defaultDashboard : any;
  public isRestrictedUser: boolean;
  public restrictedUsers = restrictedUsers
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private elasticSearchService: ElasticSearchService,
    public customDashboardService: CustomDashboardService,
    public userAuthService: UserAuthService

  ) { }

  ngOnInit(): void {
    this.defaultDashboard = this.userAuthService.defaultDashboard;
    this.isRestrictedUser = this.restrictedUsers.some(x => x=== this.userAuthService.getLoggedUser());
  }

  redirectDashboard(dashboardId: string) {
    this.router.navigate([this.returnUrl + dashboardId]);
  }

  dashboardRender(dashboard, option) {
    this.dashboard = dashboard;
    sessionStorage.setItem('meterType', this.dashboard.name);
    this.elasticSearchService.meterOptionType.next(option.name);
    dashboard.name === 'water' ? this.router.navigateByUrl(`home/watermeters/${option.name.toLowerCase()}`) : this.router.navigateByUrl(`home/energymeters/${option.name.toLowerCase()}`);
    this.removeSiteFromSessionStorage();
  }

  removeSiteFromSessionStorage() {
    sessionStorage.removeItem('site');
  }

}
