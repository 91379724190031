export interface IEntityHeader {
    deviceType: string,
    lineOfBusiness: string,
    site?: string,
    manufacturerName?: string,
    sector: string,
    organisation?: string
}


export const lineOfBusiness = {
    customer : "Customer",
    industrial : "Industrial"
}

export const sector = {
    water : "Water",
    energy : "Energy"
}