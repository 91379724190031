<div id="myreportrange2" name="daterangeInput2" daterangepicker [options]="options" (selected)="selectedDate($event, daterange)"
     style="background: #f6f7fa ;
    color: #0371a2;
    cursor: pointer;
    padding: 5px 10px 5px 2px;
        display: inline;
    /*width: 100%;*/
    /*position: absolute;*/
    /*font-size: 16px;*/
    /*top: -4px;*/
    /*left: 149px;*/
    /*z-index: 98;*/
    /*width: 240px;*/
}">
    <span class="applied-date">{{dateDisplay}}</span> <i class="fa fa-caret-down"></i>
</div>

