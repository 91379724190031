import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { HomeService } from "./home.service";
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { SocketService } from 'src/app/services/socket.service';
import { renderChart } from "src/app/reusable/chart-util/aggregation-line-bar-area";
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_DAY_END, DATE_TIME_FORMAT_DAY_START, DEVICE_TYPE_GATEWAY, DEVICE_TYPE_WATER_METER_GATEWAY, meterType, severityColor } from "src/app/reusable/constant";
import * as d3 from "d3";
import * as _ from "underscore";
import { Router } from "@angular/router";
import { initializeDateRange, setDateRange } from "src/app/reusable/date-util";
import { IAlertDataModel } from "../../alerts/alerts.interface";
import { DataList, ForecastinGroups, IRegisteredDevice,Sites } from "../../device-management/interfaces";
import { DeviceManagementService } from "../../device-management/services/device-management.service";
import { breakPoints } from "src/app/reusable/constant";
import { BreakpointService } from "src/app/services/breakpoint-service/breakpoint.service";
import {
  NC1_BASE_CAMP,
  ISKRA_DUBAI_LAB,
  WATER_METER_STITES,
  ENERGY_METER_SITES,
  COOLING_METER_SITES
} from "src/app/reusable/siteConstants";
import { RegistrationService } from "src/app/reusable/services/registration.service";
import { TelemetryService } from "src/app/reusable/services/telemetry.service";
import { AlertsManagementService } from "src/app/reusable/services/alerts-management.service";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { lineGraphForNative } from "src/app/reusable/chart-util/line-graph-native";
import { forecastinglineGraphForNative } from "src/app/reusable/chart-util/forecasting-line-graph-native";
import { downloadData } from "src/app/reusable/chart-util/chart-helper";
// consumptionAggretType options
const averageConsumption = "Average Consumption";
const totalConsumption = "Total Consumption";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css", '../../../../assets/Reusable-CSS/main.scss', '../../../../assets/Reusable-CSS/form.scss']
})

export class HomeComponent implements OnInit {
  @Input() accessingFrom;
  @Output() mapGraphToggle = new EventEmitter<any>();

  public startDate = null;
  public endDate = null;
  public severityColor = severityColor;
  public statusBasedLeakageDataChartOption;
  isMap = false;

  // alerts related variable
  public allAlerts: IAlertDataModel[];
  public alertLoader: boolean = false;
  public deviceCountLoader: boolean = false;

  // Leakage Alerts start
  public leakageDataLoader = false;
  public leakageData;
  // Leakage Alerts end
  // Leakage Alert Count start 
  public leakageDataAggregate;
  public leakageDataAggregateNoDataFound = false;
  public leakageDataAggregateLoader = false;
  public leakageAlertAggregate = 'daily';
  // Leakage Alert Count end 
  // Water Leaked start 
  public leakageWaterLeaked;
  public leakageWaterLeakedNoDataFound = false;
  public leakageWaterLeakedLoader = false;
  public leakageWaterLeakedAggregate = 'daily';
  // Water Leaked end 
  // Average Consumption Per Household start
  public averageConsumptionPerHousehold;
  public averageConsumptionPerHouseholdNoDataFound = false;
  public averageConsumptionPerHouseholdLoader = false;
  public averageConsumptionPerHouseholdStatLoader = false;
  public averageConsumptionPerHouseholdAggregate = 'hourly';
  public consumptionAggretType = totalConsumption;
  // Average Consumption Per Household end
  // Average Consumption Per Device start
  public averageConsumptionPerDevice;
  public averageConsumptionPerDeviceNoDataFound = false;
  public foreCastingNoDataFound = false;
  public averageConsumptionPerDeviceLoader = true;
  public averageConsumptionPerDeviceAggregate = 'monthly';
  // Average Consumption Per Device end
  public totalAlert: number = 0;
  public activeAlerts: number = 0;
  public zonewiseDivision: any;
  public zoneList = [];
  activeDevices;
  public showMap = false;
  public zonewiseAlert = {};
  public meterType: string;
  public percentageValue;
  public percentageLoader = false;

  // deviceListing variable
  public deviceListing: IRegisteredDevice[];
  public filterDeviceListing: IRegisteredDevice[];
  public deviceSubgroups = [];
  public searchDevice = "";
  public mapData;
  public zones = {
    zone1: {
      coordinates: [
        [35.107288, 28.11497],
        [35.108135, 28.114993],
        [35.108153, 28.11438],
        [35.107309, 28.114352],
        [35.107288, 28.11497]
      ],
      labelLocation: [35.107634999999995, 28.114628000000003],
      fillColor: 'rgba(254, 202, 57, 0.1)',
      borderColor: '#e4bf32',
      labelText: 'Zone 1'
    }
    ,
    zone2: {
      coordinates: [
        [35.107312, 28.114205],
        [35.10816, 28.114231],
        [35.108187, 28.113613],
        [35.107335, 28.113590000000002],
        [35.107312, 28.114205],
      ],
      labelLocation: [35.107657, 28.113953000000002],
      fillColor: 'rgba(254, 202, 57, 0.1)',
      borderColor: '#e4bf32',
      labelText: 'Zone 2'
    }
    ,
    zone3: {
      coordinates: [
        [35.107338, 28.113453999999997],
        [35.107507, 28.113459999999996],
        [35.107535999999996, 28.112845],
        [35.107358000000005, 28.112835999999998],
        [35.107338, 28.113453999999997]
      ],
      labelLocation: [35.107347, 28.113101],
      fillColor: 'rgba(254, 202, 57, 0.1)',
      borderColor: '#e4bf32',
      labelText: 'Zone 3'
    },
    zone4: {
      coordinates: [
        [35.107364000000004, 28.112628000000004],
        [35.10822, 28.112655],
        [35.108249, 28.111862],
        [35.107397999999996, 28.111842],
        [35.107364000000004, 28.112628000000004]
      ],
      labelLocation: [35.10779, 28.1124],
      fillColor: 'rgba(254, 202, 57, 0.1)',
      borderColor: '#e4bf32',
      labelText: 'Zone 4'
    },
    zone5: {
      coordinates: [
        [35.1074, 28.111694],
        [35.108253000000005, 28.111720000000002],
        [35.108270000000005, 28.110931],
        [35.107438, 28.110908000000002],
        [35.1074, 28.111694]
      ],
      labelLocation: [35.10779, 28.111441],
      fillColor: 'rgba(254, 202, 57, 0.1)',
      borderColor: '#e4bf32',
      labelText: 'Zone 5'
    }
  }

  breakPoints = breakPoints;

  sites : Sites = {};
  selectedSites = [];
  selectedZones = [];
  consumptionGraphSubscription: Subscription;
  selectedDevice = [];
  filter: any = {};
  forecastinGroups: ForecastinGroups[];
  dataList:any[]=[];

  constructor(
    public sharedService: SharedServiceService,
    public homeService: HomeService,
    private registrationService: RegistrationService,
    private alertsManagementService: AlertsManagementService,
    private io: SocketService,
    private router: Router,
    private breakpointService: BreakpointService,
    private telemetryService: TelemetryService
  ) {
    this.averageConsumptionPerHouseholdStatLoader = true;
    this.averageConsumptionPerHouseholdLoader = true;
    this.averageConsumptionPerHouseholdNoDataFound = true;
  }

  ngOnInit() {
    const date = initializeDateRange();
    this.startDate = date.startDate;
    this.endDate = date.endDate;
    this.getMeterTypeAndSites();
    // this.getDeviceListing([]);

    // this.getAlertsForAll(this.meterType);
    // this.io.listen('newAlert').subscribe((data: any) => this.getAlertsForAll(this.meterType));
    // this.getConsumptionPerDevice(this.averageConsumptionPerHouseholdAggregate, true);
    // this.resetSearchBar();
    // this.percentageData();

    // subscribe for change in breakpoint(Mobile, Tablet, Desktop) and there orientation
    this.consumptionGraphSubscription = this.breakpointService.breakpointChanges.subscribe(matches => {
      this.consumptionTypeChange(this.consumptionAggretType);
      // this.forecastingByDeviceId();
      if (this.selectedDevice) {
        this.getConsumptionByDeviceId(this.averageConsumptionPerDeviceAggregate)
      }
    });
  }

  getMapData() {
    const selectedZones = [];
    const zones = [];
    if (this.selectedSites?.includes(NC1_BASE_CAMP)) {
      const nc1Zones = this.filter[NC1_BASE_CAMP];
      selectedZones.push(...nc1Zones);
      for (const zone of selectedZones) {
        const zoneName = zone?.toLowerCase()?.replace(/ /g, '');
        const zoneInfo = this.zones[zoneName];
        if (zoneInfo) zones.push(zoneInfo);
      }
    }

    this.mapData = this.selectedSites?.includes(NC1_BASE_CAMP) ? { show: "zones", data: zones } : ((this.selectedSites.includes('Dummy Meter') || this.selectedSites.includes('Dummy Site')) ? { show: "zones", data: [] } : { show: ISKRA_DUBAI_LAB, data: this.deviceListing?.length ? this.deviceListing[0]?.location : '' });
  }

  getMeterTypeAndSites() {
    if (this.router.url.includes('water')) {
      this.meterType = 'water';
    } else if (this.router.url.includes('energy')) {
      this.meterType = 'energy';
    } else if (this.router.url.includes('home')) {
      this.meterType = this.showContent
    } else {
      this.meterType = 'cooling';
    }

    this.getSites();
    // const storedMeterType = sessionStorage.getItem('meterType');
    // const storedSite = JSON.parse(sessionStorage.getItem('site'));

    // if (this.meterType === storedMeterType) {
    //   this.selectedSite = storedSite ? storedSite['name'] : this.sites[0]['name'];
    //   if (!storedSite) sessionStorage.setItem('site', JSON.stringify({ name: this.selectedSite }));
    // } else {
    //   this.selectedSite = this.sites[0]['name'];
    //   sessionStorage.setItem('meterType', this.meterType);
    //   sessionStorage.setItem('site', JSON.stringify({ name: this.selectedSite }));
    // }
  }

  getSites() {
    this.deviceCountLoader = true;
    this.alertLoader = true;
    this.percentageLoader = true;
    this.registrationService.getSiteZonesByDeviceType(meterType[this.meterType])
      .subscribe(res => {
        const sites : Sites = {};
        for (const key in res) {
          if (key) sites[key] = res[key];
        }
        this.sites = sites;
      })
  }



  getUserName(value: string) {
    return value.split('@')[0];
  }

  getAlertsForAll(sector: string) {
    this.alertLoader = true;
    this.zonewiseAlert = {};

    const payload = {
      deviceTypeOrFacility: [meterType[sector]],
      dates: [this.startDate, this.endDate],
      filter: this.filter
    }
    this.alertsManagementService.getAllAlerts(payload).subscribe((response: any) => {
      this.allAlerts = response.data;
      this.getAlertsCounts();
      this.alertLoader = false;
    });
  }

  getAlertsCounts() {
    this.totalAlert = this.allAlerts?.length;
    this.activeAlerts = 0;
    this.allAlerts.forEach(alert => {
      if (alert.status.toLowerCase() === 'active') {
        const zone = alert.location.zone;
        this.activeAlerts++;
        this.zonewiseAlert[zone] = this.zonewiseAlert[zone] + 1 || 1;
      }
    })
  }

  public _filterDeviceList(value: string) {
    const filterValue = value.toLocaleLowerCase();
    const data = this.deviceListing?.filter((item: any) => {
      return item.uplinkReferenceKey.toLocaleLowerCase().includes(filterValue.trim())
    });
    this.filterDeviceListing = data;
  }

  deviceSelectionChange(data) {
    const deviceSubgroups = [];
    this.selectedDevice?.forEach(device => {
      deviceSubgroups.push({
        displayLegend: device,
        deviceId: device,
        display: true
      })
    });
    this.deviceSubgroups = deviceSubgroups;
    this.getConsumptionByDeviceId(this.averageConsumptionPerDeviceAggregate);
    this.resetSearchBar();
    this._filterDeviceList("");
  }

  resetSearchBar() {
    this.searchDevice = "";
    this._filterDeviceList("")
  }

  async getDeviceListing() {
    const dates = [moment().subtract(29, 'days').format(DATE_TIME_FORMAT_DAY_START), moment().format(DATE_TIME_FORMAT_DAY_END)];
    this.deviceCountLoader = true;
    if(this.filter && Object.keys(this.filter).length > 0  ){
      const payload = { dates, filter: this.filter };
      await this.registrationService.getDevices(meterType[this.meterType], payload)
        .toPromise().then(resp => {
          // only show the device which having uplinkReferenceKey not null
          // uplinkReferenceKey null it means it's a gateway, which not need to show on UI
          this.deviceListing = resp?.filter(device => device.deviceType.toLowerCase() != DEVICE_TYPE_GATEWAY.toLowerCase() && device.deviceType.toLowerCase() != DEVICE_TYPE_WATER_METER_GATEWAY.toLowerCase());
          this.filterDeviceListing = this.deviceListing;
          // this.selectedDeviceID = this.deviceListing && this.deviceListing?.length ? [this.deviceListing[0].deviceID] : []/;
          this.selectedDevice = this.deviceListing && this.deviceListing?.length ? [this.deviceListing[0].uplinkReferenceKey] : [];
          if (this.deviceListing[1]) this.selectedDevice.push(this.deviceListing[1]?.uplinkReferenceKey)
          this.deviceSubgroups = this.selectedDevice?.map(device => {
            return {
              displayLegend: device,
              deviceId: device,
              display: true
            };
          });
          this.getMapData();
          this.getConsumptionByDeviceId();
          this.getZoneData();
          this.showMap = true;
          this.activeDevices = this.deviceListing?.filter(x => x.status === 'Live')?.length;
          this.deviceCountLoader = false;
        });
    }else{
      this.deviceListing = [];
      this.deviceCountLoader = false;
    }
  }

  getZoneData() {
    const sites = _.groupBy(this.deviceListing, 'site');
    const zoneList = [];
    Object.keys(sites)?.forEach((site) => {
      const zone = _.groupBy(sites[site], 'zone');
      Object.keys(zone)?.map((item) => {
        if (item !== 'null') {
          zoneList.push({
            'name': item,
            'device': zone[item],
            'site': site || '',
            'allLive': zone[item]?.every(x => x.status === 'Live'),
            'offline': zone[item]?.filter(x => x.status !== 'Live'),
          })
        }
      });
    });
    zoneList?.sort(function (a, b) {
      return a.name - b.name;
    });
    if (this.selectedZones) {
      const zones = zoneList?.filter(zone => this.selectedZones?.includes(zone.name));
      this.zoneList = zones;
    } else {
      this.zoneList = zoneList;
    }
  }

  getNotificationTimeFormatted(dateString: Date) {
    return this.sharedService.returnDaysAgoFromTodayDate(dateString, true);
  }

  consumptionTypeChange(consumptionAggretType: string) {
    this.consumptionAggretType = consumptionAggretType;
    let dataList;

    if (this.consumptionAggretType === averageConsumption) {
      dataList = this.averageConsumptionPerHousehold?.avgConsumptionPerDeviceAggregated?.map(x => {
        return {
          dateTime: x.timestamp,
          value: x.value
        }
      })
    }
    else {
      dataList = this.averageConsumptionPerHousehold?.totalConsumptionAggregated?.map(x => {
        return {
          dateTime: x.timestamp,
          value: x.value
        }
      })
    }
    if (dataList?.length > 0) {
      this.averageConsumptionPerHouseholdNoDataFound = false;
      const name = this.consumptionAggretType;
      const dataSet = [{
        name,
        nativeList: dataList
      }]
      const attributes = [{
        name,
        deviceType: meterType[this.meterType]
      }]
      this.createChart(dataSet, attributes, '.average-consumption', this.averageConsumptionPerHouseholdAggregate);
    }
    else {
      d3.selectAll(`.average-consumption svg`).remove();
      this.averageConsumptionPerHouseholdNoDataFound = true;
    }
  }

  getConsumptionPerDevice(averageConsumptionPerHouseholdAggregate = 'hourly', averageConsumptionPerHouseholdStatLoader = false) {
    this.averageConsumptionPerHouseholdLoader = true;
    if (averageConsumptionPerHouseholdStatLoader) this.averageConsumptionPerHouseholdStatLoader = true;
    this.consumptionAggretType = this.consumptionAggretType || averageConsumption;
    this.averageConsumptionPerHouseholdAggregate = averageConsumptionPerHouseholdAggregate;
    if (this.accessingFrom == 'dashboard') {
      this.startDate = moment().startOf('month').format(DATE_TIME_FORMAT_DAY_START);
      this.endDate = moment().format(DATE_TIME_FORMAT_DAY_END);
    }
    const payload = {
      dates: [this.startDate, this.endDate],
      filter: this.filter
    };
    this.telemetryService.getConsumptionPerDevice(payload, this.averageConsumptionPerHouseholdAggregate, meterType[this.meterType]).subscribe((resp: any) => {
      this.averageConsumptionPerHouseholdLoader = false;
      this.averageConsumptionPerHouseholdStatLoader = false;
      this.averageConsumptionPerHousehold = resp;
      let dataList;
      if (this.consumptionAggretType === averageConsumption) {
        dataList = this.averageConsumptionPerHousehold?.avgConsumptionPerDeviceAggregated?.map(x => {
          return {
            dateTime: x.timestamp,
            value: x.value
          }
        })
      }
      else {
        dataList = this.averageConsumptionPerHousehold?.totalConsumptionAggregated?.map(x => {
          return {
            dateTime: x.timestamp,
            value: x.value
          }
        })
      }

      if (dataList?.length > 0) {
        this.averageConsumptionPerHouseholdNoDataFound = false;
        const name = this.consumptionAggretType;
        const dataSet = [{
          name,
          nativeList: dataList
        }]
        const attributes = [{
          name,
          deviceType: meterType[this.meterType]
        }]
        this.createChart(dataSet, attributes, '.average-consumption', this.averageConsumptionPerHouseholdAggregate);
      }
      else {
        d3.selectAll(`.average-consumption svg`).remove();
        this.averageConsumptionPerHouseholdNoDataFound = true;
      }
    });
  }

  // forecastingByDeviceId() {
  //   this.telemetryService.forecastingByDeviceId(meterType[this.meterType]).subscribe(async (resp: any) => {
  //     this.averageConsumptionPerHouseholdLoader = false;
  //     if (resp) {
  //       const nameList = [];
  //       for (const [key, value] of Object.entries(resp)) {
  //         nameList.push(key);
  //         this.dataList.push({
  //           name: key,
  //           nativeList: value
  //         });
  //       }
  //       this.dataList?.forEach(data => {
  //         data.nativeList = data.nativeList.map(x => ({
  //           dateTime: x.timestamp,
  //           value: x.value
  //         }));
  //       });
  //       if (this.dataList?.length > 0) {
  //         this.foreCastingNoDataFound = false;
  //         this.averageConsumptionPerHouseholdNoDataFound = false;
  //         const attributes = nameList.map(name => ({
  //           name: name,
  //           deviceType: meterType[this.meterType],
  //         }));
  //         this.forecastinGroups = nameList.map((device, index) => ({
  //           displayLegend: device,
  //           deviceId: device,
  //           display: true,
  //           displayName: device,
  //           name: device,
  //         }));
  //         this.getForecastingByDeviceId(this.dataList, attributes);
  //       } else {
  //         d3.selectAll(`.average-consumption-forecasting svg`).remove();
  //         this.foreCastingNoDataFound = true;
  //       }
  //     } else {
  //       this.foreCastingNoDataFound = true;
  //     }
  //   }, (error) => {
  //     this.foreCastingNoDataFound = true;
  //   });
  // }

  // getForecastingByDeviceId(dataList, attributes) {
  //   this.createChartNative(dataList, attributes, '.average-consumption-forecasting', 'hourly');
  // }
  
  percentageData() {
    this.percentageLoader = true;
    const payload = {
      dates: [this.startDate, this.endDate],
      filter: this.filter
    };
    this.telemetryService.percentageData(payload, meterType[this.meterType]).subscribe((resp: any) => {
      this.percentageLoader = false;
      this.percentageValue = resp
    });
  }

  getConsumptionByDeviceId(averageConsumptionPerDeviceAggregate = 'hourly') {
    this.averageConsumptionPerDeviceLoader = true;
    this.averageConsumptionPerDeviceAggregate = averageConsumptionPerDeviceAggregate;
    const deviceIDs = this.deviceSubgroups?.filter(subgroup => subgroup?.display)?.map(device => device?.deviceId);
    const payload = { dates: [this.startDate, this.endDate], deviceIDs };
    const attributes = this.deviceSubgroups?.map((device) => {
      return {
        name: device?.deviceId,
        deviceType: meterType[this.meterType],
        display: device?.display
      }
    });
    this.telemetryService.getConsumptionPlotByDeviceIds(payload, this.averageConsumptionPerDeviceAggregate, meterType[this.meterType])
      .subscribe((response: any) => {
        if (Object.keys(response)?.length) {
          this.averageConsumptionPerDeviceLoader = false;
          const dataList = [];
          for (const deviceId in response) {
            const aggregatedData = response[deviceId];
            const nativeList = aggregatedData?.map(x => {
              return {
                dateTime: x.timestamp,
                value: x.value
              }
            })
            dataList.push({
              name: deviceId,
              nativeList
            });
          }
          const checkForNoData = dataList?.every(data => !data.nativeList?.length);
          if (!checkForNoData) {
            const dataSet = dataList?.sort((a, b) => b?.nativeList?.length - a?.nativeList?.length);
            this.averageConsumptionPerDeviceNoDataFound = false;
            this.createChart(dataSet, attributes, '.monthly-consumption', this.averageConsumptionPerDeviceAggregate);
          } else {
            this.averageConsumptionPerDeviceLoader = false;
            d3.selectAll(`.monthly-consumption svg`).remove();
            this.averageConsumptionPerDeviceNoDataFound = true;
          }
        } else {
          this.averageConsumptionPerDeviceLoader = false;
          d3.selectAll(`.monthly-consumption svg`).remove();
          this.averageConsumptionPerDeviceNoDataFound = true;
        }
      },
        (error) => {
          this.averageConsumptionPerDeviceLoader = false;
          this.averageConsumptionPerDeviceNoDataFound = true;
        }
      )
  }

  createChart(dataSet, attributes, element, aggregationInterval = 'daily', chartType = 'areachart') {
    const config = {
      attributes,
      showScrollbar: true,
    }
    const dataSetForBarChart = dataSet?.map((set) => {
      const resultObj = {};
      set?.nativeList?.forEach((nativeData, index) => {
        const { dateTime, value } = nativeData
        const dateRange = [dateTime];
        resultObj[`Tag ${index}`] = {
          dateRange,
          value
        }
      })
      set['aggregation'] = resultObj;
      return set;
    });
    config["removeExisting"] = true;
    config["widgetType"] = chartType;
    config["aggregationInterval"] = aggregationInterval;
    d3.select(element).attr('hidden', null);
    if (chartType === 'line') {
      renderChart(element, dataSet, config);
    } else {
      renderChart(element, dataSetForBarChart, config);
    }
  }

  // createChartNative(dataSet, attributes, element, aggregationInterval?: string) {
  //   const config = {
  //     attributes,
  //     showScrollbar: true,
  //   }
  //   const dataSetForBarChart = dataSet?.map((set) => {
  //     const resultObj = {};
  //     set?.nativeList?.forEach((nativeData, index) => {
  //       const { dateTime, value } = nativeData
  //       const dateRange = [dateTime];
  //       resultObj[`Tag ${index}`] = {
  //         dateRange,
  //         value
  //       }
  //     })
  //     set['aggregation'] = resultObj;
  //     return set;
  //   });
  //   config["removeExisting"] = true;
  //   config["widgetType"] = 'linechart';
  //   d3.select(element).attr('hidden', null);
  //   if(aggregationInterval){
  //     config["aggregationInterval"] = aggregationInterval;
  //     const Forecasted = dataSetForBarChart?.find(x => x.name !== "Recorded Consumption").nativeList.map(x => x.dateTime)
  //     const Recorded = dataSetForBarChart?.find(x => x.name === "Recorded Consumption").nativeList.map(x => x.dateTime)
  //     const alertDates = Forecasted?.filter(function (obj) { return Recorded.indexOf(obj) == -1; });
  //     alertDates.sort(function (a, b) { return a - b });
  //     config["alertRange"] = {
  //       startDate: moment( alertDates[0]).subtract(1,'day'),
  //       endDate: alertDates[alertDates.length - 1]
  //     }
  //     forecastinglineGraphForNative(element, dataSetForBarChart, config);
  //   }else{
  //     config["aggregationInterval"] = 'native';
  //     lineGraphForNative(element, dataSet, config);
  //   }
  // }

  datepicker(dateArr: any) {
    const date = setDateRange(dateArr);
    this.startDate = date.startDate;
    this.endDate = date.endDate;
    this.getConsumptionPerDevice(this.averageConsumptionPerHouseholdAggregate, true);
    this.getConsumptionByDeviceId();
    this.percentageData();
    this.getAlertsForAll(this.meterType);
  }

  siteChanges(selectedSites: any) {
    this.filter = selectedSites;
    const sites = [];
    const zones = [];
    for (const site in selectedSites) {
      sites.push(site);
      zones.push(...selectedSites[site]);
    }
    this.selectedSites = sites;
    this.selectedZones = zones;
    // selectedSites.forEach((option) => {
    //   const site = option.split(".")[0]
    //   const zone = option.split(".")[1]
    //   if(!this.filter[site]) {
    //     this.filter[site] = [];
    //   }
    //   const sites = this.filter[site];
    //   sites.push(zone);
    //   this.filter[site] = sites;
    // })
    // this.selectedZone = zone;
    // this.selectedSite = site;
    this.getDeviceListing();
    this.getConsumptionPerDevice(this.averageConsumptionPerHouseholdAggregate, true);
    this.percentageData();
    this.getAlertsForAll(this.meterType);
  }

  ngOnDestroy() {
    this.consumptionGraphSubscription.unsubscribe();
  }

  showContent = 'energy';
  handleToggle(selectedToggle) {
    this.showContent = selectedToggle;
    this.getMeterTypeAndSites()
    this.getConsumptionPerDevice('daily', true);
    // this.selectedToggle.emit(selectedToggle)
  }

  changeMap() {
    this.isMap = !this.isMap
    this.mapGraphToggle.emit(this.isMap)
  }

  displayChart(data: any) {
    this.deviceSubgroups = this.deviceSubgroups?.map(attribute => {
      if (attribute.deviceId === data.deviceId) {
        attribute.display = !attribute.display;
      }
      return attribute;
    });
    this.getConsumptionByDeviceId(this.averageConsumptionPerDeviceAggregate);
  }

  // forecastingChart(data: any) {
  //   this.forecastinGroups = this.forecastinGroups?.map(attribute => {
  //     if (attribute.deviceId === data.deviceId) {
  //       attribute.display = !attribute.display;
  //     }
  //     return attribute;
  //   });
  //   this.forecastingByDeviceIdfilter();
  // }

  downloadData() {
    const config = {
      aggregationInterval: 'hourly',
      searchTerm: 'Water Meter Forecast',
      subgroups: this.forecastinGroups,
      aggregationType: 'average',
      widgetType: 'linechart',
      oneDeviceData: true,
    }
    downloadData(this.dataList, config, 'forecast');
  }
  // forecastingByDeviceIdfilter() {
  //   const arr = [];
  //   const forecastingFilter = this.forecastinGroups?.filter(group => group.display);

  //   forecastingFilter?.forEach(filter => {
  //     const matchingData = this.dataList.find(data => data.name === filter.displayLegend);
  //     if (matchingData) {
  //       arr.push(matchingData);
  //     }
  //   });
  //   if (arr.length > 0) {
  //     const attributes = this.forecastinGroups.map(name => ({
  //       name: name.deviceId,
  //       deviceType: meterType[this.meterType],
  //       display: name?.display,
  //       displayName : name?.display
  //     }));
  //     this.averageConsumptionPerHouseholdNoDataFound = false;
  //     this.getForecastingByDeviceId(arr, attributes);
  //   } else {
  //     d3.selectAll(`.average-consumption-forecasting svg`).remove();
  //   }
  // }
  getStyles(activeAlerts, totalAlert){
    return  {'width': `${activeAlerts*100/totalAlert}%`}
  }
}
