<div class="d-flex flex-column align-items-center justify-content-center" style="height: 40vh;">
    <div *ngIf="showIcon" class="row d-flex justify-content-center imageIcon">
        <img src="{{iconUrl}}" alt="">
    </div>
    <div class="row d-flex justify-content-center titleIcon">
        <p class="font-weight-bold">{{title}}</p>
    </div>
    <div class="row d-flex justify-content-center subtitleIcon">
        <span>{{subTitle}}</span>
    </div>
</div>