<mat-tab-group class="searchTab" mat-align-tabs="start">
    <mat-tab>
        <div *ngIf="!isLoader">
            <div *ngIf="this.data?.length; else noResults" class="result">
                <div *ngFor="let result of data" class="form-check">
                    <div [ngClass]="result.isSeleted ? 'selected' : 'nonSelected'">
                        <mat-checkbox [checked]="result.isSeleted" (change)="selectAttribute($event.checked,result)">
                            <p class="form-check-label resultHeading" for="flexCheckDefault">
                                {{result?.displayName || result?.name}}</p>
                        </mat-checkbox>
                        <p *ngIf="result?.manufacturerName && result?.site" class="pl-4 ml-1 resultData metaData">{{getTagName(result)}} | Mfg
                            Name - {{result.manufacturerName}} | <img src="assets/images/place.png" class="placeIcon mb-1"> {{result.site}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isLoader" class="loader">
            <div class="spinner">
                <div class="loader"></div>
            </div>
        </div>

        <ng-template mat-tab-label>
            <label>
                Attributes ({{this.filteredRecordsCount}})
            </label>
        </ng-template>
    </mat-tab>
    <mat-tab disabled>
        <ng-template mat-tab-label>
            <label matTooltip="Coming Soon" matTooltipPosition="above" matTooltipClass="above">
                Assets
            </label>
        </ng-template>
    </mat-tab>
    <mat-tab disabled>
        <ng-template mat-tab-label>
            <label matTooltip="Coming Soon" matTooltipPosition="above" matTooltipClass="above">
                Dashboard
            </label>
        </ng-template>
    </mat-tab>
</mat-tab-group>
<ng-template #noResults style="height: 100%;">
    <div *ngIf="isNotFound">
        <app-no-results></app-no-results>
    </div>
    <div *ngIf="!isNotFound" style="width: 100%; height: 35vh; display: flex; align-items: center; justify-content: center;">
        <app-data-not-found [title]="noDataFoundTitle" [subTitle]="noDataFoundSubTitle"></app-data-not-found>
    </div>
</ng-template>