import { Component, OnInit } from '@angular/core';
import { CustomDashboardService } from 'src/app/services/CustomDashboard/custom-dashboard.service';
import { getLoginUser } from './../../../reusable/user-util';
import { DataService } from 'src/app/reusable/services/data.service';

@Component({
  selector: 'app-pinned-dashboards',
  templateUrl: './pinned-dashboards.component.html',
  styleUrls: ['./pinned-dashboards.component.css']
})
export class PinnedDashboardsComponent implements OnInit {
  pinnedDashboard = []
  constructor(
    public customDashboardService: CustomDashboardService,
    private dataService : DataService
  ) { 
    this.dataService.getPinnedDashboard$.subscribe(data =>this.getPinnedDashboard() )
  }

  ngOnInit(): void {
    this.getPinnedDashboard()
  }

  getPinnedDashboard() {
    this.customDashboardService.getPinnedDashboard(getLoginUser()).then((data: any) => {
      this.pinnedDashboard = data.dashboards;
    })
  }

  getFirstLetter(name){
    return name?.charAt(0).toUpperCase()
  }

  getRouterLink(dashboard) {
    if(dashboard.drawingData){
      return "/connectioncanvas/" + dashboard._id;
    }else{
      return "/custom-dashboard/" + dashboard._id;
    }
  }
}
