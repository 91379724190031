import { Component, ElementRef, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';
import { ElasticSearchService } from 'src/app/reusable/services/elastic-search.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { TelemetryService } from '../services/telemetry.service';
import { TELEMETRY } from '../constant';

@Component({
  selector: 'app-stacked-chart',
  templateUrl: './stacked-chart.component.html',
  styleUrls: ['./stacked-chart.component.css']
})
export class StackedChartComponent implements OnInit {

  data = [];

  private w: number = 600;
  private h: number = 400;
  private margin = { top: 10, right: 50, bottom: 60, left: 40 };
  private width = this.w - this.margin.left - this.margin.right;
  private height = this.h - this.margin.top - this.margin.bottom;

  private x: any;
  private y: any;
  private svg: any;
  private g: any;
  private stack: any;
  private chart: any;
  private layersBarArea: any;
  private layersBar: any;
  private xAxis: any;
  private yAxis: any;
  private legend: any;
  private legendItems: any;
  private tooltip: any;
  private stackedSeries: any;
  public aggregationInterval = 'daily';
  public aggregationType = 'average';
  public attributeList = [];
  public intervalList = [];
  public aggregationIntervalOptions = ['daily', 'weekly', 'monthly', 'yearly'];
  public aggregationTypeOptions = ['sum', 'average', 'max'];
  public startDate = new Date();
  public endDate = new Date();

  private colors = ['#eabf3e', '#dae2e9', '#7BD500', '#7EAB64', '#1536DD', '#EEF10F', '#0FF13C', '#084B15', '#2E084B', '#1DDEE7'];
  xTitle: any;
  yTitle: any;

  constructor(private container: ElementRef, private sharedService: SharedServiceService, private elasticSearchservice: ElasticSearchService, private telemetryService: TelemetryService) {
  }

  ngOnInit() {
  }

  graphResult() {
    this.data = [];
    this.attributeList = [];
    this.intervalList = [];
    d3.select("svg").remove();


    this.sharedService.attributeList = this.sharedService.attributeList.map((attribute) => {
      attribute['dataType'] = TELEMETRY;
      return attribute;
    });
    
    const payLoad = {
      attributes: this.sharedService.attributeList,
      aggregationInterval: this.aggregationInterval,
      aggregationType: this.aggregationType,
      dateRange: [this.startDate, this.endDate]
    };

    this.sharedService.attributeList?.forEach((attribute => {
      this.attributeList.push(attribute?.name)
    }))

    this.telemetryService.attributeResult(payLoad)
      .subscribe((response: any) => {
        const responseData = response;
        const aggregationList = [];
        responseData?.forEach((attribute => {
          aggregationList.push(attribute);
        }))
        const aggregation = responseData[0]?.aggregation;
        for (const interval in aggregation) {
          this.intervalList.push(interval);
        }

        for (const interval of this.intervalList) {
          let attributeObj = {};
          attributeObj['date'] = interval;
          this.attributeList.forEach((attribute => {
            const aggregateValue = aggregationList?.find(x => x.name === attribute)?.aggregation[`${interval}`]?.value;
            attributeObj[`${attribute}`] = Number(aggregateValue) || 24;
          }))
          this.data.push(attributeObj);
        }

        this.stack = d3.stack()
          .keys(this.attributeList)

        this.initScales();
        this.initSvg();
        this.createStack(this.data);
        this.drawAxis();
      })
  }

  private initScales() {
    this.x = d3.scaleBand()
      .rangeRound([0, this.width])
      .padding(0.05);

    this.y = d3.scaleLinear()
      .range([this.height, 0])
  }

  private initSvg() {

    this.svg = d3.select(this.container.nativeElement)
      .select('.chart-container')
      .append('svg')
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr('class', 'chart')
      .attr('width', this.w)
      .attr('height', this.h)
      .attr("viewBox", "0 0 600 400");

    this.chart = this.svg.append('g')
      .classed('chart-contents', true)
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    this.layersBarArea = this.chart.append('g')
      .classed('layers', true);
  }

  private drawAxis() {
    this.xAxis = this.chart.append('g')
      .classed('x axis', true)
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(this.x));

    this.chart.append("text")
      .attr("y", this.height + 40)
      .attr("x", (this.width / 2))
      .classed('axis-title', true)
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .text(this.xTitle);

    this.yAxis = this.chart.append('g')
      .classed('y axis', true)
      .call(d3.axisLeft(this.y)
        .ticks(7));

    this.chart.append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - 60)
      .attr("x", 0 - (this.height / 2))
      .style("text-anchor", "middle")
      .style('stroke', 'none')
      .classed('axis-title', true)
      .text(this.yTitle);
  }

  private createStack(stackData: any) {
    this.stackedSeries = this.stack(stackData);
    this.drawChart(this.stackedSeries)
  }

  private drawChart(data: any) {
    this.layersBar = this.layersBarArea.selectAll('.layer')
      .data(data)
      .enter()
      .append('g')
      .classed('layer', true)
      .style('fill', (d: any, i: any) => {
        return this.colors[i]
      });

    this.x.domain(this.data.map((d: any) => {
      return d.date
    }));

    this.y.domain([0, +d3.max(this.stackedSeries, function (d: any) {
      return d3.max(d, (d: any) => {
        return d[1]
      })
    })]);

    this.layersBar.selectAll('rect')
      .data((d: any) => {
        return d;
      })
      .enter()
      .append('rect')
      .attr('y', (d: any) => {
        return this.y(d[1])
      })
      .attr('x', (d: any, i: any) => {
        return this.x(d.data.date)
      })
      .attr('width', this.x.bandwidth())
      .attr('height', (d: any, i: any) => {
        return this.y(d[0]) - this.y(d[1]);
      })
  }

}
