import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-kpi-card',
  templateUrl: './kpi-card.component.html',
  styleUrls: ['./kpi-card.component.css','../../../assets/Reusable-CSS/table.scss']
})
export class KpiCardComponent implements OnInit {

  nativeFormat = 'DD-MMM-y, H:mm:ss';
  otherFormat = 'DD-MMM-y, H:mm';

  isMerged: boolean;

  @Input() title: any;
  @Input() groups: any;
  @Input() cardType: string = 'unmerged';
  @Input() responseData: any;
  @Input() hideButton: boolean = false;
  @Input() widgetId: string = 'false';

  @Output() getCardType: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateWidgetHeight: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
    this.getCardInfo();
  }

  ngOnChanges() {
    this.getCardInfo();
  }

  getCardInfo() {
    this.isMerged = this.cardType === 'merged';
    this.groups = this.groups?.map((group: any) => {
      const result = this.responseData?.find((response: any) => response?.id === group?.id);
      const isNative = !!result?.nativeList;
      group.name = group?.name || group?.description;
      const aggregation = result?.aggregation || result?.nativeList || [];
      const keys = Object.keys(aggregation);
      const finalValueIndex = keys[keys?.length - 1];
      const finalValue = aggregation[finalValueIndex];
      group.value = Number(finalValue?.value)  ? Number(finalValue?.value).toFixed(2) :  finalValue?.value
      const dateValue = (isNative ? finalValue?.dateTime : finalValue?.dateRange[0]) || '';
      const dateFormat = isNative ? this.nativeFormat : this.otherFormat;
      group.latestDate = dateValue ? moment(dateValue).format(dateFormat) : '';
      if (group?.unit === "cubic meter") {
        group.unit = 'm<sup>3</sup>';
      }
      else if (group?.unit === "volt") {
        group.unit = 'V';
      }
      else if (group?.unit === "cubic meter per hour") {
        group.unit = 'm<sup>3</sup>/h';
      }
      else if (group?.unit === "degree" || group?.unit === "degree celsius") {
        group.unit = '℃';
      }
      else if (group?.unit === "micro siemens per centimeter") {
        group.unit = 'uS/cm';
      }
      else if (group?.unit === "null") {
        group.unit = '-';
      }
      return group;
    })
  }

  changeMerge() {
    this.isMerged = !this.isMerged;
    this.getCardType.emit(this.isMerged);
  }

  ngAfterViewInit() {
    if (this.widgetId) {
      const kpi: HTMLElement = document.getElementById(`widget-main-${this.widgetId}`)?.getElementsByTagName('app-kpi-card')[0] as HTMLElement;
      this.updateWidgetHeight.emit({ height: kpi?.offsetHeight + 50, width: kpi?.offsetWidth + 100, _id: this.widgetId });
    }
  }

  displayName(name: string){
    const displayName = name.split('.');
    return displayName[displayName.length-1];
  }
}
