import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { routes } from '../arcgis-map.config';
import { DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_WATER_METER, DEVICE_TYPE_COOLING_METER} from 'src/app/reusable/constant';

@Injectable({
  providedIn: 'root'
})
export class ArcgisMapApiService {
  public options = {
    'headers': {
      'Content-Type': 'application/json;ty=2'
    }
  }
  constructor( private apiService : ApiService) { }
  
  public getDevices(meterType) {
    // const deviceType = meterType.toLowerCase().includes('water') ? DEVICE_TYPE_WATER_METER : DEVICE_TYPE_ENERGY_METER;
    const deviceType = meterType.toLowerCase().includes('water') ? DEVICE_TYPE_WATER_METER : ( meterType.toLowerCase().includes('energy') ? DEVICE_TYPE_ENERGY_METER : DEVICE_TYPE_COOLING_METER);


    return new Promise((resolve, reject)  => {
        this.apiService.get(`${routes.GET_DEVICES}?deviceType=${deviceType}`,this.options)
          .subscribe((response) => {
            resolve(response);
          },
            (error:any) => {
              reject(error);
            }  
        )
    })
  }
}
