import * as jwtDecode from 'jwt-decode';

export const isTokenValid = (token: string): boolean => {
    const parsedToken = decodeToken(token);
    const expiresIn = parsedToken.exp;
    const currentEpochTime = Math.round(new Date().getTime() / 1000);

    if (currentEpochTime < expiresIn) return true;

    return false;
}

export const decodeToken = (token: string): any => {
    return jwtDecode.default(token);
}