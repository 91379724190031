import { Pipe, PipeTransform } from '@angular/core';
import { renameAttributes } from '../../dataSource';

@Pipe({
  name: 'attributeRename'
})

export class AttributeRenamePipe implements PipeTransform {

  transform(value: string) {
    const attributeName = value?.replace(/\s+/g, "").toLowerCase();
    const result = renameAttributes?.find((attribute:any) => attribute?.name.toLowerCase() === attributeName);
    if(result){
      return result?.alt;
    }
    return value;
  }

}
