<section class="parent-data-section">
    <div>
        <div class="subHeading">Default Dashboards</div>
        <div class="mutedSubHeading">Select any card to proceed</div>
    </div>
    <div id="cardList">
        <ng-container *ngFor="let dashboard of defaultDashboard" >
            <div  *ngIf="!(dashboard.name === 'energy' && isRestrictedUser)" [ngClass]="{ 'w-100': !(dashboard.name === 'energy' && isRestrictedUser) }" class="dashboardCard">
                <div class="cardBody">
                    <div class="cardLogo">
                        <img src={{dashboard.icon}}>
                    </div>
                    <div class="cardName">
                        <p>{{dashboard.name}}</p>
                    </div>
                </div>
                <div class="cardOptions">
                    <ng-container *ngFor="let option of dashboard.option">
                        <button [disabled]="option.isDisable" *ngIf="!(option.name==='Customer' && isRestrictedUser)" (click)="dashboardRender(dashboard,option)" 
                        >{{option.name}}
                    </button>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</section>