<div class="col-12">
    <div class="close" (click)="close(data.dashboardId)">
        <img src="assets/images/cross.svg " alt="" width="20px" mat-dialog-close>
    </div>
    <div class="col-12 mb-3 centerAlign">
        <img src="assets/images/tick_icon_svg (1).svg" alt="" width="70px">
    </div>
    <div class="col-12 saveHeading centerAlign">
        <span>{{data.title}}</span>
    </div>
    <div class="col-12 saveSubHeading centerAlign mb-3">
        <span>{{data.subTitle}}</span>
    </div>
    <div *ngIf="data.showNavigation" (click)="openDashboard(data.dashboardId)" class="col-12 buttonDashboard centerAlign mb-3">
        <button>Go to DashBoard</button>
    </div>
    <div *ngIf="data.showNavigation" (click)="close(data.dashboardId)" class="col-12 buttonReturn centerAlign">
        <button>No, Thanks</button>
    </div>
</div>