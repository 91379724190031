import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { COLOR,FORCASTCOLOR } from '../chart-util/chart-config';
@Component({
  selector: 'app-chart-legend',
  templateUrl: './chart-legend.component.html',
  styleUrls: ['./chart-legend.component.css']
})
export class ChartLegendComponent implements OnInit {

  @Input() public subgroups = [];
  @Input() public showName = false;
  @Input() public noClick = false;
  @Output() legendOutput = new EventEmitter<any>();
  public colors = COLOR;

  constructor() { }

  ngOnInit(): void {
    if(this.noClick){
      this.colors = FORCASTCOLOR
    }
  }

  displayChart(attribute: any) {
    this.legendOutput.emit(attribute);
  }

}
