import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { routes } from '../threshold-alert.config';
import { DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_WATER_METER } from 'src/app/reusable/constant';
import { resolve } from 'dns';

const options = {
  headers: {
    "Content-Type": "application/json;ty=2",
    'Accept': 'Application/json'
  }
}

@Injectable({
  providedIn: 'root'
})
export class ThresholdAlertService {
  constructor(private apiServices: ApiService) { }

  getNotifications() {
    return new Promise((resolve, reject) => {
      this.apiServices.post(routes.GET_ALERTS_NOTIFICATIONS, options.headers).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  markNotificationStatus(alertIds: string[], status: string) {
    return new Promise((resolve, reject) => {
      this.apiServices.post(routes.MARK_NOTIFICATION_STATUS, {alertIds, status}, options.headers).subscribe((response) => {
        resolve(response);
      }, (error: any) => {
        reject(error);
      });
    });
  }

  getAlertByRuleId(payload, ruleID: string) {
    return new Promise((resolve, reject) => {
      this.apiServices.post(routes.GET_ALERT_BY_RULE_ID + '?ruleID=' + ruleID, payload, options.headers)
        .subscribe((response) => {
          resolve(response);
        }),
        (error: any) => {
          reject(error);
        }
    })
  }
  getAlertsByDeviceId(deviceID) {
      return new Promise((resolve, reject) => {
        this.apiServices.get(`/alerts-management/get-alerts-by-deviceID?deviceID=${deviceID}`)
        .subscribe((response) => {
          resolve(response);
        }),
        (error: any) => {
          reject(error);
        }
      })
  }
}



