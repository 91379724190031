<header id="heading">Custom Dashboards</header>
<div class="switches-container">
    <div id='switch1' class="switch" (click)="filterCustomDashboards(0, $event)">
        <!-- <img src="../../../../assets/images/table.png" width="14px"> -->
         <span> Custom Dashboards</span>
    </div>
    <div id='switch2' class="switch" (click)="filterCustomDashboards(1, $event)">
    <!-- <img src="../../../../assets/images/map.svg" width="15px">  -->
    <span class="mapToggle" >HMI Projects</span>
    </div>
</div>
<section id="cardList" *ngIf="!isLoader">
    <div (click)="redirectDashboard (dashboard)" *ngFor="let dashboard of filteredCustomDashboard" class="sharedDashboardCard">
        <div class="cardImage">
            <img src="assets/images/defaultDashboard.svg">
        </div>
        <div class="cardDetails">
            <div class="sharedCardName" title="{{dashboard.name}}">{{dashboard.name}}</div>
            <div class="d-flex align-items-center">
                <div class="editTime" matTooltip="Last Edited" matTooltipPosition="above" matTooltipClass="above">
                    <img src="assets/images/time_icon.svg">
                    <span>{{dashboard?.dashboardLog?.creationDate | date: 'dd MMM | hh:mm'}}</span>
                </div>
                <div class="owner" matTooltip="{{dashboard.createdBy.split('@')[0].split('.')[0] | titlecase}}" matTooltipPosition="above" matTooltipClass="above">
                    <img src="assets/images/name_icon.svg">
                    <span>{{dashboard.createdBy.split('@')[0].split(".")[0]}}</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="noFound" class="customDashboardRight">
        <div class="d-flex flex-column align-items-center justify-content-center" style="height: 40vh;">
            <div class="row d-flex justify-content-center imageIcon">
                <img src="assets/images/defaultDashboard.svg">
            </div>
            <div class="row d-flex justify-content-center titleIcon">
                <span>{{noFoundText}}</span>
            </div>
            <div class="row d-flex justify-content-center subtitleIcon">
                <span>{{bottomText}}</span>
            </div>
        </div>
        <!-- <app-no-results [iconUrl]="'../../../assets/images/defaultDashboard.svg'" id="iconNoDashboard" [title]="''" [subTitle]="'Execute search to get started.'"></app-no-results> -->
    </div>
</section>
<div *ngIf="isLoader" id="loadingScreen">
    <div class="loader"></div>
</div>
