import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-table-sort-icon',
  templateUrl: './table-sort-icon.component.html',
  styleUrls: ['./table-sort-icon.component.css']
})
export class TableSortIconComponent implements OnInit {
  @Input() data
  @Output() applySorting = new EventEmitter<any>();
  sortingInfo :any;

  constructor() { }

  ngOnInit(): void {
  }

  sort() {
    // 'createdOn',sortFilter..inc ? 'inc':'dec'
    // if it inc is true that means it is showing inc arrow
    // on click emit for sort column to inc and marked inc state to false so that down arrow shows next time
    this.sortingInfo = {
      sort : this.data['inc'] ? 'inc' : 'dec',
      column : this.data['key'] 
    }
    if(this.data.inc) {
      this.data['inc'] = false;
      this.data['dec'] = true;
    }else{
      this.data['inc'] = true;
      this.data['dec'] = false;
    }

    this.applySorting.emit(this.sortingInfo);
  }

}
