import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input
} from "@angular/core";
import { loadModules } from "esri-loader";
import { ArcgisMapService } from 'src/app/services/arcgis-map/arcgis-map.service';
import { MapInfo } from "src/app/services/arcgis-map/interfaces";
import { ZoneMapService } from "./zone-map.service";


@Component({
  selector: 'app-zone-map',
  templateUrl: './zone-map.component.html',
  styleUrls: ['./zone-map.component.css', '../../../../../assets/Reusable-CSS/main.scss']
})
export class ZoneMapComponent implements OnInit {
  @ViewChild('mapViewNode', { static: true }) private mapViewEl: ElementRef;
  @Input() mapData:any = [];
  @Input() devices = [];

  zoneCenter = [35.110818, 28.11297];
  zoom = 16;
  view;
  map;
  graphicsLayer;
  devicesGraphicArr;
  zoneLabelingInfo = {
    zonePolygon:[], 
    zoneLabelGraphic:[]
  }

  constructor(
    private arcgisMapService:ArcgisMapService,
    private zoneMapService: ZoneMapService
  ) { }

  async ngOnChanges() {
    if(this.mapData && this.devices) {
      const mapInfo:MapInfo = {
        zoom: 16,
        center: [35.110818, 28.11297]
      }
      await this.arcgisMapService.initializeMap(this.mapViewEl, mapInfo);
      this.map = this.arcgisMapService.getMapInstance();
      this.view = this.arcgisMapService.getViewInstance();
      this.view.watch('zoom', (newZoom) => {
        if (newZoom > 17) {
          this.removeAllLayer();
          // this.addZonePolygonAndLabel();
          for(let i = 0; i < this.zoneLabelingInfo['zonePolygon'].length; i++) {
            const rectange = this.zoneLabelingInfo['zonePolygon'][i];
            this.graphicsLayer.addMany([rectange]);
          }
          
          this.graphicsLayer.addMany(this.devicesGraphicArr);

          for(let i = 0; i < this.zoneLabelingInfo['zonePolygon'].length; i++) {
            const label = this.zoneLabelingInfo['zoneLabelGraphic'][i];
            this.graphicsLayer.addMany([label]);
          }
        } else {
          this.removeAllLayer();  
          this.addZonePolygonAndLabel();  
        }
      });
      await this.createMap();
    }
  }

  async ngOnInit():Promise<any> {
  }

  async createMap() {
    const [ Graphic, GraphicsLayer, PictureMarkerSymbol, Point, TextSymbol] = await loadModules([
        "esri/Graphic",
        "esri/layers/GraphicsLayer",
        "esri/symbols/PictureMarkerSymbol",
        "esri/geometry/Point",
        "esri/symbols/TextSymbol"
    ]);
    this.graphicsLayer = new GraphicsLayer();
    this.map.add(this.graphicsLayer);

    await this.addDevices();

    // Made the polygon using zones locations
    if(this.mapData.show === 'zones') {
      const zones = this.mapData.data
      for (const key in zones) {
        const rectange = new Graphic({
          geometry: {
            type: 'polygon',
            rings: zones[key]['coordinates']
          },
          symbol: {
            type: 'simple-fill',
            color: zones[key]['fillColor'], // Set the fill color (red with 50% transparency)
            outline: {
              color: zones[key]['borderColor'], // Set the outline color (red)
              width: 1 // Set the outline width
            }
          }
        });

        // Add label and Text
        const locationSymbol = new PictureMarkerSymbol({
          url: '../../../../assets/images/gps_pin_with_shadow.svg', // Replace with the URL to your location icon image
          width: 32, // Replace with desired width of the icon
          height: 38, // Replace with desired height of the icon,
          xoffset: 4

        });
        var labelLocation = new Point({
          x: zones[key]['labelLocation'][0],
          y: zones[key]['labelLocation'][1]
          // spatialReference: spatialReference // Replace with desired label/icon location coordinates and spatial reference
        });
  
        // Label Text
        const labelTextSymbol = new TextSymbol({
          text: zones[key]['labelText'], // Replace with your desired label text
          color: [0, 0, 0], // Replace with desired text color
          haloColor: [255, 255, 255], // Replace with desired halo color
          haloSize: "1px", // Replace with desired halo size
          font: {
            size: 12, // Replace with desired font size
            weight: "normal" // Replace with desired font weight
          },
          xoffset: 4

        });
        
        const textLabelGraphics = new Graphic({
          geometry: labelLocation,
          symbol: labelTextSymbol
        });

        this.graphicsLayer.addMany([rectange, textLabelGraphics]);
        this.zoneLabelingInfo['zonePolygon'].push(rectange);
        this.zoneLabelingInfo['zoneLabelGraphic'].push(textLabelGraphics);
      }
    }
    else {
      const location = this.mapData.data;
      this.view.center = [location.longitude, location.latitude]
      const point = {
        type: "point",
        longitude: location.longitude,
        latitude: location.latitude,
      }
      const locationSymbol = new PictureMarkerSymbol({
        url: "../../../assets/images/gps_pin_with_shadow.svg", // Replace with the URL to your location icon image
        width : 32,
        height: 38,
        yoffset : 10
      });
      // add point to graphic layer
      const graphic = new Graphic({
        geometry : {
          type: point.type,
          longitude: point.longitude,
          latitude: point.latitude
        },
        symbol: locationSymbol,
      })

      this.graphicsLayer.add(graphic); 
    }
  }
  async addDevices() {
    this.removeAllLayer();
    const graphicsArr = [];
    const groupedDevices = this.devices.reduce((acc, device) => {
      const key = `${device.location?.latitude},${device.location?.longitude}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(device);
      return acc;
    }, {});
    for (let point in groupedDevices) {
      const value = groupedDevices[point];
      const device = value[0];
      await this.addPoint(device, graphicsArr, value);
    }
    // this.graphicsLayer.addMany(graphicsArr);
    this.devicesGraphicArr = graphicsArr;

  }

    async addPoint(device, graphicsArr, points = []) {
      if (!(device.location?.longitude && device.location?.latitude)) {
        return;
      }
      const [Graphic] = await loadModules([
        "esri/Graphic",
      ]);
  
      const point = {
        type: "point",
        longitude: device.location.longitude,
        latitude: device.location.latitude,
        deviceID: device.deviceID,
        uplinkReferenceKey: device.uplinkReferenceKey,
        deviceManufacturer: device.deviceManufacturer,
        status: device.status,
        measurableLocation: device.measurableLocation,
        deviceType: device.deviceType,
        percentageData: device.percentageData.toFixed(2),
        consumption: device.consumption,
        consumptionFor30Days: device.consumptionFor30Days
      };
  
      const graphic = new Graphic({
        geometry: {
          type: point.type,
          latitude: point.latitude,
          longitude: point.longitude
        },
        symbol: {
          type: 'simple-marker',
          style: `${point.deviceType === 'Water Meter Gateway' ? 'triangle' : 'circle'}`,
          color: `${this.zoneMapService.getDeviceFillColor(point.status)}`,
          size: '10px',
        },
        attributes: {
          deviceID: point.deviceID
        },
        popupTemplate: {
          content: point.deviceType === 'Cooling Meter' && points?.length > 1 ? this.zoneMapService.getMultiPointTemplate(point, points) : this.zoneMapService.getTemplate(point)
        }
      });
      graphicsArr.push(graphic);
    }
    
    async removeAllLayer() {
      this.graphicsLayer.removeAll();
    }

    addZonePolygonAndLabel() {
      for(let i = 0; i < this.zoneLabelingInfo['zonePolygon'].length; i++) {
        const rectange = this.zoneLabelingInfo['zonePolygon'][i];
        const label = this.zoneLabelingInfo['zoneLabelGraphic'][i];
        this.graphicsLayer.addMany([rectange, label]);
      }
    }
  }
