export class OnboardingDevice {
    profileID: string = ""
    serialNumber: string = ""
    measurableLocation: string = ""
    zone: string = ""
    site: string = ""
    location: {
        latitude: string
        longitude: string
    } = { latitude: "", longitude: "" }
    operation: string = ''
    operationBy: string = ''
    commissioningDate: Date
    manufacturerName: string
    useGateway: boolean = false
    metadata: {
        servicePointID: string
        parentMeterServicePointID: string
        batch: string
        manufacturingDate: Date | string
        firmwareVersion: string
        isBillable?: boolean
        utilityServiceType: string
        customerMeterType: string
        customerType: string
        buildingNumber: string
        customerDescription: string
        waterDiameter?: string
        energyBreakerCapacity?: number | string
    } = {
            servicePointID: '',
            parentMeterServicePointID: '',
            batch: '',
            manufacturingDate: '',
            firmwareVersion: '',
            utilityServiceType: '',
            customerMeterType: '',
            customerType: '',
            buildingNumber: '',
            customerDescription: '',
            energyBreakerCapacity: '',
            waterDiameter: ''
        }
    connection: {
        port: number | string
        host?: string
        password?: string
    }
    protocol: string
    hidden: boolean
    gatewayName?: string
}

export class Device {
    id?: string
    deviceID?: string
    deviceType?: string
    profileID?: string = ""
    serialNumber?: string = ""
    measurableLocation?: string = ""
    connectorProtocol?: string = ""
    zone?: string = ""
    site?: string = ""
    location?: {
        latitude: string
        longitude: string
    } = { latitude: "", longitude: "" }
    commissioningDate?: Date
    manufacturerName?: string
    useGateway?: boolean = false
    metadata?: {
        servicePointID: string
        parentMeterServicePointID: string
        batch: string
        manufacturingDate: Date | string
        firmwareVersion: string
        isBillable?: boolean
        utilityServiceType: string
        customerMeterType: string
        customerType: string
        buildingNumber: string
        customerDescription: string
        waterDiameter?: string
        energyBreakerCapacity?: number | string
    } = {
            servicePointID: '',
            parentMeterServicePointID: '',
            batch: '',
            manufacturingDate: '',
            firmwareVersion: '',
            utilityServiceType: '',
            customerMeterType: '',
            customerType: '',
            buildingNumber: '',
            customerDescription: '',
            energyBreakerCapacity: '',
            waterDiameter: ''
        }
    operation?: string = ''
    operationBy?: string = ''
    connection?: {
        port: number | string
        host?: string
        password?: string
        connectionProperties?: {
            uplinkTopic?: string
            downlinkTopic?: string
        }
    }
    protocol?: string
    gatewayName?: string
    deviceModel?: string
    uplinkReferenceKey?: string
    linkedProfiles?: Array<string>
}

export class BulkRegistrationData {
    profileID: string = ""
    servicePointID: string
    parentMeterServicePointID: string
    serialNumber: string = ""
    utilityServiceType: string
    customerMeterType: string
    customerType: string
    site: string = ""
    zone: string = ""
    measurableLocation: string = ""
    customerDescription: string
    buildingNumber: string
    location: {
        latitude: string
        longitude: string
    } = { latitude: "", longitude: "" }
    commissioningDate: Date
    useGateway: boolean = false
    batch: string
    manufacturingDate: Date | string
    firmwareVersion: string
    isBillable: boolean
    gatewayName?: string
    waterDiameter?: string
    energyBreakerCapacity?: string
    port?: number | string
    ipAddress?: string
    meterPassword?: string
    manufacturerName?: string
    operation?: string
    operationBy?: string
    constructor(data) {
        const { profile, csvRecord, deviceType } = data;
        this.profileID = profile;
        this.servicePointID = csvRecord[0]?.trim();
        this.parentMeterServicePointID = csvRecord[1]?.trim();
        this.serialNumber = csvRecord[2]?.trim();
        this.utilityServiceType = csvRecord[3]?.trim();
        this.customerMeterType = csvRecord[4]?.trim();
        this.customerType = csvRecord[5]?.trim();
        this.site = csvRecord[6]?.trim();
        this.zone = csvRecord[7]?.trim();
        this.measurableLocation = csvRecord[8]?.trim();
        this.customerDescription = csvRecord[9]?.trim();
        this.buildingNumber = csvRecord[10]?.trim();
        this.location['latitude'] = csvRecord[11]?.trim();
        this.location['longitude'] = csvRecord[12]?.trim();
        this.commissioningDate = csvRecord[13];
        this.useGateway = csvRecord[14]?.trim().toLowerCase() === 'true' ? true : false;
        this.batch = csvRecord[15]?.trim();
        this.manufacturingDate = csvRecord[16]?.trim();
        this.firmwareVersion = csvRecord[17]?.trim();
        this.isBillable = csvRecord[18]?.trim().toLowerCase() === 'no' ? true : false;

        if (deviceType === 'Water Meter' || 'Cooling Meter') {
            const gatewayName = csvRecord[19]?.trim();
            this.gatewayName = gatewayName === 'null' ? null : gatewayName;
            if (deviceType === 'Water Meter') this.waterDiameter = csvRecord[20]?.trim();
        }
        if (deviceType === 'Energy Meter') {
            this.ipAddress = csvRecord[19]?.trim();
            this.port = csvRecord[20]?.trim();
            this.meterPassword = csvRecord[21]?.trim();
            this.energyBreakerCapacity = csvRecord[22]?.trim();
        }
    }
}

export class DeviceProfile {
    name: string = ''
    id: string = ''
    protocol: string = ''
}