<div id="backgroundDiv">
  <div id="pageLeftContent">
    <div id="neomLogo">
      <img src="assets/images/logo-neom.da169c7.png" id="neomLogo">
    </div>
    <div id="greetingText">
      <span id="greetingHeading">Welcome to the IOT SENSE</span>
      <p id="greetingQuote">
        A next-generation standards-based horizontal IoT platform that enables
        you to connect, monitor and control various devices, assets and other iot platforms. Leverage the power of
        interoperability, harmonized data sets and advanced analytics.
      </p>
    </div>
  </div>
  <div id="pageRightContent">
    <div class="loginForm">
      <h2>
        <span> IOT SENSE</span>
      </h2>
      <button routerLink="/sso" type="submit" class="ssoLoginButton mb-1">
        <img src="assets/images/logo-neom.da169c7.png" class="loginIcon">Log in with NEOM account
      </button>

      <div *ngIf="unauthorized" class="mt-2 text-left mb-2">
        <span class="text-danger">You do not have login access to the platform. Please request the platform
          administrator to grant you the login access. </span>
      </div>

      <div class="ssoSeprator">
        <span class="sepratorHeading">or</span>
      </div>
      <div *ngIf="userNotFound || invalidCredentials" id="validation">
        <span class="text-danger">Invalid Username / Credentials</span>
      </div>
      <div *ngIf="!isOtpValid" id="validation">
        <span class="text-danger">Incorrect OTP</span>
      </div>
      <form (submit)="!loginDisabled ? onSubmit() : ''" method="post" target="hiddenFrame">
        <div class="credentialInput required">
          <input [disabled]="showOtpBox" type="text" id="username" placeholder="Email" [(ngModel)]="username" name="username">
        </div>
        <div class="credentialInput required">
          <input [disabled]="showOtpBox" type="password" id="pass" placeholder="Password" [(ngModel)]="password" name="password">
        </div>
        <div *ngIf="showOtpBox" class="credentialInput required otp">
          <input type="otp" id="otp" placeholder="Check your email for OTP" [(ngModel)]="emailOtp" name="otp">
        </div>
        <div class="customCheckbox">
          <div class="checkboxText">
            <div class="">
              <mat-checkbox [(ngModel)]="rememberMe" name="rememberme" checked></mat-checkbox>
              <label for="rememberMe">Remember me</label>
            </div>
            <div class="supportText">
              <p class="supportContent">Need Help? <a href="mailTo:ankur.tiwari@neom.com" class="contactSup"> Contact
                  Support</a>
              </p>
            </div>
          </div>
        </div>
        <div class="loginField">
          <div *ngIf="loginDisabled">
            <p class="text-danger">You have reached maximum login attempts. Please try to login after sometime.</p>
            <button type="" class="TimerBtn">
              Wait for <span class="textGrey">00:{{timeLeft.toString().length > 1 ? timeLeft : '0' + timeLeft}}</span>
              minutes
            </button>
          </div>
          <div *ngIf="!loginDisabled">
            <button [disabled]="loginDisabled" type="submit" class="button-Primary-N loginButton">Login</button>
          </div>
        </div>
        <p class="loginText">
          You need to have an account to be able to login. If you do not have one, please request your administrator.
        </p>
      </form>
    </div>
  </div>
</div>