import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LoginService } from "./login.service";
import { Helper } from 'src/app/reusable/helper-util';
import { UserManagementService } from '../access-management/services/user-management.service';
import { ToastService } from "src/app/services/toast/toast.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css", "../../../assets/Reusable-CSS/buttons.scss"]
})
export class LoginComponent implements OnInit {
  @Input() showLoginCard = false;

  // public showLogin Card = false;
  public username: string;
  public password: string;
  public rememberMe = true;
  public invalidCredentials = false;
  public returnUrl: string;
  public showOtpBox = false;
  public emailOtp: string;
  public isOtpValid = true;
  public userNotFound = false;
  public unauthorized = false;
  public invalidLoginAttempt: number = 0;
  public loginDisabled = false;
  public timeLeft: number = 60;

  constructor(public loginService: LoginService,
    public route: ActivatedRoute,
    public router: Router,
    public helper: Helper,
    private userManagementService: UserManagementService,
    private toastr: ToastService
  ) {
    this.userManagementService.validateToken().subscribe(async (data: any) => {
      if (Object.keys(data).indexOf('preferred_username') !== -1 || Object.keys(data).indexOf('email') !== -1) {
        this.router.navigate(['/home']);
      }
    })
  }

  ngOnInit() {

    // if(localStorage.getItem("showcard")==='true'){
    //   this.showLoginCard=true;
    // }
    if (localStorage.getItem('invalidLoginAttempt')) this.invalidLoginAttempt = Number(localStorage.getItem('invalidLoginAttempt'));
    this.unauthorized = this.route.snapshot.queryParams["unauthorized"] ? true : false;
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/home";
    if (this.invalidLoginAttempt >= 5) {
      this.disableLoginAttempt();
    }
  }

  // public setCookie(payload){
  //   if (this.rememberMe) {
  //     const day = 1000 * 60 * 60 * 24; // One day in milliseconds
  //     document.cookie = "token=" + JSON.stringify(payload) + `;expires=${new Date(new Date().getTime() + day).toUTCString()}`;
  //   } else {
  //     document.cookie = "token=" + JSON.stringify(payload);
  //   }
  // }

  storeToken(token: string) {
    localStorage.setItem("token", token);
    localStorage.setItem('sso-user', 'false');
    return;
  }

  onSubmit() {
    const password = this.password;
    const encryptedPassword = this.helper.encryptPassword(password);
    let payload: any = {
      email: this.username,
      password: encryptedPassword
    };

    if (!this.checkOtp(this.emailOtp) && !this.showOtpBox) {
      this.login(payload);
      return;
    }

    payload = {
      email: this.username,
      password: encryptedPassword,
      otp: this.emailOtp
    };

    if (this.checkOtp(this.emailOtp)) {
      this.validateOtp(payload);
    }
    return;
  }

  login(payload: any) {
    this.loginService.isAuthenticated(payload, { headers: { "Content-Type": "application/json" }, responseType: "boolean" })
      .then((response: any) => {
        if (response.isError) {
          this.showOtpBox = false;
          if (response?.message.toLowerCase() === 'user not found') {
            this.userNotFound = true;
            return;
          }
          this.invalidCredentials = true;
          this.invalidLoginAttempt++;
          localStorage.setItem('invalidLoginAttempt', JSON.stringify(this.invalidLoginAttempt));
          if (this.invalidLoginAttempt >= 5) this.disableLoginAttempt();
        } else {
          this.userNotFound = false;
          this.invalidCredentials = false;
          this.showOtpBox = true;
        }

      }).catch((error: any) => {
        this.invalidCredentials = true;
        throw error;
      });
  }

  validateOtp(payload) {

    this.loginService.validateOtp(payload, { headers: { "Content-Type": "application/json" } })
      .then((response: any) => {
        if (response.isError) {
          this.isOtpValid = false;
        } else if (!response.isError && response.auth) {
          this.isOtpValid = true;
          this.storeToken(response.token);

          if (response?.userRole)
            localStorage.setItem('userRole', response.userRole);
          //this.redirectHome();
          // this.showLoginCard=true
          this.router.navigate(['/home']);
        }
        return;
      }).catch((error: any) => {
        this.invalidCredentials = true;
        throw error;
      });
    localStorage.setItem('useremail', this.username);
  }

  checkOtp(otp: string): boolean {
    if (!otp || otp.length < 6) {
      return false;
    }

    return true;
  }

  disableLoginAttempt() {
    this.loginDisabled = true;
    this.startTimer();
  }

  enableLoginAttempt() {
    this.loginDisabled = false;
    this.invalidLoginAttempt = 0;
    this.timeLeft = 60;
    localStorage.removeItem('timeLeft');
    localStorage.removeItem('invalidLoginAttempt');
  }

  startTimer() {
    if (localStorage.getItem('timeLeft') && JSON.parse(localStorage.getItem('timeLeft')) > 0) this.timeLeft = JSON.parse(localStorage.getItem('timeLeft'));
    const interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        localStorage.setItem('timeLeft', JSON.stringify(this.timeLeft));
      } else {
        clearInterval(interval);
        this.enableLoginAttempt();
      }
    }, 1000);
  }
}
