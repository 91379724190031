import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AutosizeModule } from 'ngx-autosize';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedServiceService } from './services/shared-service.service';
import { ApiService } from './services/api.service';
import { HeaderDateChangedService } from './reusable/header/header-date-changed.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './reusable/header/header.component';
import { EllipsisPipe } from './reusable/elipsis.pipe';
import { SidebarComponent } from './reusable/sidebar/sidebar.component';
import { LoginComponent } from './modules/login/login.component';
import { PageNotFoundComponent } from './modules/common/page-not-found/page-not-found.component';
import { DataService } from './services/behaviour.service';
import { Interceptor } from './modules/common/interceptor';
import { SharedModuleModule } from './modules/shared-module/shared-module.module';
import { SharedDataService } from './modules/shared-module/shared-meter-type.service';
import { SocketService } from './services/socket.service';
import { SearchResultComponent } from './modules/search-result/search-result.component';
import { NoResultsComponent } from './reusable/no-results/no-results.component';
import { AssetResultsComponent } from './modules/asset-results/asset-results.component';
import { AdditionalParametersComponent } from './reusable/additional-parameters/additional-parameters.component';
import { StackedChartComponent } from './reusable/stacked-chart/stacked-chart.component';
import { SaveProjectComponent } from './reusable/save-project/save-project.component';
import { SearchAttributeComponent } from './reusable/search-attribute/search-attribute.component';
import { SearchAttributeResultsComponent } from './reusable/search-attribute-results/search-attribute-results.component';
import { SearchAttributeFilterComponent } from './reusable/search-attribute-filter/search-attribute-filter.component';
import { ConfirmationDialog } from './reusable/confirmation-dialog/confirmation-dialog.component';
import { AddAdditionalTagsComponent } from './reusable/add-additional-tags/add-additional-tags.component';
import { DatePipe } from '@angular/common';
import { Daterangepicker } from 'ng2-daterangepicker';
import { HoverClassDirective } from './reusable/hover-class.directive';
import { CustomDashboardListComponent } from './reusable/custom-dashboard-list/custom-dashboard-list.component';
import { BackButtonComponent } from './reusable/back-button/back-button.component';
import { UnlinkButtonComponent } from './reusable/unlink-button/unlink-button.component';
import { Error404Component } from './reusable/error404/error404.component';
import { DismissAlertComponent } from './reusable/dismiss-alert/dismiss-alert.component';
import { DevelopersPageComponent } from './modules/developers-page/developers-page.component';
import { PerLoginHeaderComponent } from './reusable/per-login-header/per-login-header.component';
import { ToastComponent } from './reusable/toast/toast.component';
import { NotificationPopupComponent } from './reusable/notification-popup/notification-popup.component';
import { SelectSectorComponent } from './modules/select-sector/select-sector.component';
import { NgSelectConfig, ɵs } from '@ng-select/ng-select';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppInitializerService } from './modules/sso/app-Initializer.service';
import { AccessForbiddenComponent } from './reusable/access-forbidden/access-forbidden.component';
import { SectorKpicardsComponent } from './modules/select-sector/sector-kpicards/sector-kpicards.component';
import { SectorHomeComponent } from './modules/select-sector/sector-home/sector-home.component';
import { RegisterDeviceModalComponent } from './reusable/register-device-modal/register-device-modal.component';
import { CommonCPAAComponent } from './modules/select-sector/common-cpaa/common-cpaa.component';
import { MeterStatusComponent } from './modules/select-sector/meter-status/meter-status.component';
import { PinnedDashboardsComponent } from './modules/select-sector/pinned-dashboards/pinned-dashboards.component';
import { SearchInputValidationDirective } from './directive/search-input-validation.directive';

export function initializeApp(appInitializerService: AppInitializerService) {
  return (): Promise<any> => {
    return appInitializerService.initializeApp();
  };
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    EllipsisPipe,
    SidebarComponent,
    PageNotFoundComponent,
    SearchResultComponent,
    NoResultsComponent,
    AssetResultsComponent,
    AdditionalParametersComponent,
    StackedChartComponent,
    SaveProjectComponent,
    SearchAttributeComponent,
    SearchAttributeResultsComponent,
    SearchAttributeFilterComponent,
    ConfirmationDialog,
    AddAdditionalTagsComponent,
    HoverClassDirective,
    CustomDashboardListComponent,
    BackButtonComponent,
    UnlinkButtonComponent,
    Error404Component,
    DismissAlertComponent,
    DevelopersPageComponent,
    PerLoginHeaderComponent,
    ToastComponent,
    NotificationPopupComponent,
    SelectSectorComponent,
    AccessForbiddenComponent,
    SectorKpicardsComponent,
    SectorHomeComponent,
    RegisterDeviceModalComponent,
    CommonCPAAComponent,
    MeterStatusComponent,
    PinnedDashboardsComponent,
    SearchInputValidationDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    Daterangepicker,
    BrowserAnimationsModule,
    NgbModule,
    AutosizeModule,
    ToastrModule.forRoot(),
    SharedModuleModule,
    KeycloakAngularModule
  ],
  providers: [
    SharedServiceService,
    ApiService,
    HeaderDateChangedService,
    DataService,
    SharedDataService,
    SocketService,
    NgSelectConfig, ɵs,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializerService],
      multi: true, // Set to true if you have multiple APP_INITIALIZER functions.
    },
    KeycloakService
  ],
  entryComponents: [],
  exports: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
