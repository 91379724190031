<form [formGroup]="deviceGroup" (ngSubmit)="addDevice()" novalidate>
    <mat-dialog-title>
        <div class="deviceformHeading">
            <h4>{{data?.deviceId ? 'Device Information' : 'Register New Device'}}</h4>
            <img src="assets/images/cross.svg" class="crossImg" mat-dialog-close>
        </div>
    </mat-dialog-title>
    <mat-dialog-content class="mat-typography modalHeight">
        <div class="formSpacing">
            <span class="label-text">Is this device a gateway?</span>
            <div class="identifierInput">
                <mat-radio-group formControlName="useGateway" [disabled]="disabledEdit || enableEdit" aria-label="Select an option"
                    class=" input-area" (change)="onGatewayChange($event)">
                    <mat-radio-button [value]="true">Yes</mat-radio-button> &nbsp;
                    <mat-radio-button [value]="false">No</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="profileRow" formArrayName="deviceProfiles">
            <div class="formSpacing" *ngFor="let item of deviceGroup.get('deviceProfiles')['controls']; let i = index;"
                [formGroupName]="i">
                <span class="label-text">Device Profile {{i+1}} </span> <span *ngIf="i===0" class="asterick">*</span>
                <div class="selector formField positionRelative">
                    <mat-form-field appearance="outline" floatLabel="never"
                        [ngClass]="{ 'invalid': i===0 && checkProfileValidation(item) }">
                        <mat-select [disableOptionCentering]="true" formControlName="profile"
                            (selectionChange)="i===0 ? profileChange($event) : ''" name="profile"
                            [disabled]="disabledEdit || enableEdit" placeholder="Select Profile">
                            <mat-option value="{{profile.id}}"
                                *ngFor="let profile of getProfiles(i)">{{profile?.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span *ngIf="i===0 && checkProfileValidation(item)"
                        [ngStyle]="{'color': i===0 && checkProfileValidation(item) ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoSelectBox"
                        matTooltip="{{i===0 && checkProfileValidation(item) && item?.controls?.profile?.errors?.required ? errorMessages['required'] : ''}}"
                        matTooltipPosition="below" matTooltipClass="below">info</span>
                    <!-- <mat-error *ngIf="i===0 && checkProfileValidation(item)">
                        <mat-error class="errorText"
                            *ngIf="item?.controls?.profile?.errors?.required">{{item?.controls?.profile?.errors?.required ? errorMessages['required'] : ''}}</mat-error>
                    </mat-error> -->
                </div>
                <div *ngIf="i!==0 && deviceGroup.get('deviceProfiles')['controls']?.length > 1 && !disabledEdit"
                    (click)="removedeviceProfile(i)" class="removedText">Remove Profile</div>
            </div>
            <div *ngIf="!disabledEdit && addProfileCondition()" (click)="addDeviceProfile()" class="addText">+ Add
                Profile</div>
        </div>

        <div class="centerAlign">
            <div class="formSpacing formWidth">
                <span class="label-text">Service Point ID</span>
                <div class="formField input-spacing positionRelative">
                    <input appInputTrim appSqlInjectionValidation [disabled]="disabledEdit"
                        [ngClass]="{'is-invalid': checkValidation('servicePointID')}"
                        placeholder="Enter Service Point ID" formControlName="servicePointID" type="text"
                        class="input-text" name="servicePointID">
                    <span *ngIf="checkValidation('servicePointID')"
                        [ngStyle]="{'color': checkValidation('servicePointID') ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoInput"
                        [matTooltip]="getErrorMessage('servicePointID')" matTooltipPosition="below">info</span>
                    <!-- {{(checkValidation('servicePointID') && checkPatternValidation('servicePointID') ? errorMessages['pattern'] : '') + '\n' + (checkValidation('servicePointID') && checkSqlInjectionValidation('servicePointID') ? deviceGroup?.controls?.servicePointID?.errors?.message : '')}} -->
                    <!-- <mat-error *ngIf="checkValidation('servicePointID')">
                        <mat-error class="errorText"
                            *ngIf="checkPatternValidation('servicePointID')">{{errorMessages['pattern']}}</mat-error>
                        <mat-error class="errorText"
                            *ngIf="checkSqlInjectionValidation('servicePointID')">{{deviceGroup?.controls?.servicePointID?.errors?.message}}</mat-error>
                    </mat-error> -->
                </div>
            </div>
            <div class="formSpacing formWidth">
                <span class="label-text">Parent Meter Service Point ID </span>
                <div class="formField positionRelative">
                    <input appInputTrim appSqlInjectionValidation [disabled]="disabledEdit"
                        [ngClass]="{'is-invalid': checkValidation('parentMeterServicePointID')}"
                        placeholder="Enter Parent Meter Service Point ID" formControlName="parentMeterServicePointID"
                        type="text" class="input-text" name="parentMeterServicePointID">
                    <span *ngIf="checkValidation('parentMeterServicePointID')"
                        [ngStyle]="{'color': checkValidation('parentMeterServicePointID') ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoInput"
                        [matTooltip]="getErrorMessage('parentMeterServicePointID')"
                        matTooltipPosition="below">info</span>
                    <!-- <mat-error *ngIf="checkValidation('parentMeterServicePointID')">
                        <mat-error class="errorText"
                            *ngIf="checkPatternValidation('parentMeterServicePointID')">{{errorMessages['pattern']}}</mat-error>
                        <mat-error class="errorText"
                            *ngIf="checkSqlInjectionValidation('parentMeterServicePointID')">{{deviceGroup?.controls?.parentMeterServicePointID?.errors?.message}}</mat-error>
                    </mat-error> -->
                </div>
            </div>
        </div>
        <div class="centerAlign">
            <div class="formSpacing formWidth">
                <span class="label-text">Serial Number <span class="asterick">*</span> </span>
                <div class="formField input-spacing positionRelative">
                    <input appInputTrim appSqlInjectionValidation [disabled]="disabledEdit" class="input-text"
                        placeholder="Enter serial number" formControlName="serialNumber" type="text" name="serialNumber"
                        [ngClass]="{'is-invalid': checkValidation('serialNumber')}">
                    <span *ngIf="checkValidation('serialNumber')"
                        [ngStyle]="{'color': checkValidation('serialNumber') ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoInput"
                        [matTooltip]="getErrorMessage('serialNumber')" matTooltipPosition="below">info</span>
                    <!-- <mat-error *ngIf="checkValidation('serialNumber')">
                        <mat-error class="errorText"
                            *ngIf="checkRequiredValidation('serialNumber')">{{errorMessages['required']}}</mat-error>
                        <mat-error class="errorText"
                            *ngIf="checkPatternValidation('serialNumber')">{{errorMessages['pattern']}}</mat-error>
                        <mat-error class="errorText"
                            *ngIf="checkSqlInjectionValidation('serialNumber')">{{deviceGroup?.controls?.serialNumber?.errors?.message}}</mat-error>
                    </mat-error> -->
                </div>
            </div>
            <div class="formSpacing formWidth">
                <span class="label-text">Utility Service Type</span>
                <div class="selector formField">
                    <mat-form-field appearance="outline" floatLabel="never">
                        <mat-select [disableOptionCentering]="true" name="utilityServiceType" [disabled]="disabledEdit"
                            formControlName="utilityServiceType" placeholder="Select Utility Service Type">
                            <mat-option *ngFor="let option of utilityServiceTypeOptions"
                                [value]="option">{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="centerAlign">
            <div class="formSpacing formWidth">
                <span class="label-text">Customer Meter Type </span>
                <div class="selector formField input-spacing">
                    <mat-form-field appearance="outline" floatLabel="never">
                        <mat-select [disableOptionCentering]="true" name="customerMeterType" [disabled]="disabledEdit"
                            formControlName="customerMeterType" placeholder="Select Customer Meter Type">
                            <mat-option *ngFor="let option of dropdownOptions['customerMeterType']"
                                [value]="option">{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="formSpacing formWidth">
                <span class="label-text">Customer Type </span>
                <div class="selector formField">
                    <mat-form-field appearance="outline" floatLabel="never">
                        <mat-select [disableOptionCentering]="true" name="customerType" [disabled]="disabledEdit"
                            formControlName="customerType" placeholder="Select Customer Type">
                            <mat-option *ngFor="let option of dropdownOptions['customerType']"
                                [value]="option">{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="centerAlign">
            <div class="formSpacing formWidth">
                <span class="label-text">Region</span>
                <div class="selector formField input-spacing">
                    <mat-form-field appearance="outline" floatLabel="never">
                        <mat-select [disableOptionCentering]="true" name="site" [disabled]="disabledEdit"
                            formControlName="site" placeholder="Select Region">
                            <mat-option *ngFor="let option of dropdownOptions['site']"
                                [value]="option">{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="formSpacing formWidth">
                <span class="label-text">Location Group</span>
                <div class="selector formField">
                    <mat-form-field appearance="outline" floatLabel="never">
                        <mat-select [disableOptionCentering]="true" name="zone" [disabled]="disabledEdit"
                            formControlName="zone" placeholder="Select Location Group">
                            <mat-option *ngFor="let option of dropdownOptions['cluster']"
                                [value]="option">{{option}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="centerAlign">
            <div class="formSpacing formWidth">
                <span class="label-text">Measurable Location </span>
                <div class="formField input-spacing positionRelative">
                    <input appInputTrim appSqlInjectionValidation [disabled]="disabledEdit"
                        formControlName="measurableLocation" type="text" class="input-text"
                        placeholder="Enter Measurable Location" name="measurableLocation"
                        [ngClass]="{'is-invalid': checkValidation('measurableLocation')}">
                    <span *ngIf="checkValidation('measurableLocation')"
                        [ngStyle]="{'color': checkValidation('measurableLocation') ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoInput"
                        [matTooltip]="getErrorMessage('measurableLocation')" matTooltipPosition="below">info</span>
                    <!-- <mat-error *ngIf="checkValidation('measurableLocation')">
                        <mat-error class="errorText"
                            *ngIf="checkPatternValidation('measurableLocation')">{{errorMessages['pattern']}}</mat-error>
                        <mat-error class="errorText"
                            *ngIf="checkSqlInjectionValidation('measurableLocation')">{{deviceGroup?.controls?.measurableLocation?.errors?.message}}</mat-error>
                    </mat-error> -->
                </div>
            </div>
            <div class="formSpacing formWidth">
                <span class="label-text">Customer Description </span>
                <div class="formField positionRelative">
                    <input appInputTrim appSqlInjectionValidation placeholder="Enter customer description"
                        [disabled]="disabledEdit" formControlName="customerDescription" type="text" class="input-text"
                        name="customerDescription" [ngClass]="{'is-invalid': checkValidation('customerDescription')}">
                    <span *ngIf="checkValidation('customerDescription')"
                        [ngStyle]="{'color': checkValidation('customerDescription') ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoInput"
                        [matTooltip]="getErrorMessage('customerDescription')" matTooltipPosition="below">info</span>
                    <!-- <mat-error *ngIf="checkValidation('customerDescription')">
                        <mat-error class="errorText"
                            *ngIf="checkPatternValidation('customerDescription')">{{errorMessages['pattern']}}</mat-error>
                        <mat-error class="errorText"
                            *ngIf="checkSqlInjectionValidation('customerDescription')">{{deviceGroup?.controls?.customerDescription?.errors?.message}}</mat-error>
                    </mat-error> -->
                </div>
            </div>
        </div>
        <div class="centerAlign">
            <div class="formSpacing formWidth">
                <span class="label-text">Building Number </span>
                <div class="formField input-spacing positionRelative">
                    <input appInputTrim appSqlInjectionValidation placeholder="Enter building number"
                        [disabled]="disabledEdit" formControlName="buildingNumber" type="text" class="input-text"
                        name="buildingNumber" [ngClass]="{'is-invalid': checkValidation('buildingNumber')}">
                    <span *ngIf="checkValidation('buildingNumber')"
                        [ngStyle]="{'color': checkValidation('buildingNumber') ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoInput"
                        [matTooltip]="getErrorMessage('buildingNumber')" matTooltipPosition="below">info</span>
                    <!-- <mat-error *ngIf="checkValidation('buildingNumber')">
                        <mat-error class="errorText"
                            *ngIf="checkPatternValidation('buildingNumber')">{{errorMessages['pattern']}}</mat-error>
                        <mat-error class="errorText"
                            *ngIf="checkSqlInjectionValidation('buildingNumber')">{{deviceGroup?.controls?.buildingNumber?.errors?.message}}</mat-error>
                    </mat-error> -->
                </div>
            </div>
            <div class="formSpacing formWidth">
                <span class="label-text">Latitude <span class="asterick">*</span> </span>
                <div class="formField positionRelative">
                    <input appInputTrim placeholder="Enter Latitude" [disabled]="disabledEdit"
                        formControlName="latitude" type="number" step="0.001" class="input-text" name="latitude"
                        [ngClass]="{'is-invalid': checkValidation('latitude')}">
                    <span *ngIf="checkValidation('latitude')"
                        [ngStyle]="{'color': checkValidation('latitude') ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoInput"
                        [matTooltip]="getErrorMessage('latitude')" matTooltipPosition="below">info</span>
                    <!-- <mat-error *ngIf="checkValidation('latitude')">
                        <mat-error class="errorText"
                            *ngIf="checkRequiredValidation('latitude')">{{errorMessages['required']}}</mat-error>
                        <mat-error class="errorText"
                            *ngIf="checkSqlInjectionValidation('latitude')">{{deviceGroup?.controls?.latitude?.errors?.message}}</mat-error>
                    </mat-error> -->
                </div>
            </div>
        </div>
        <div class="centerAlign">
            <div class="formSpacing formWidth">
                <span class="label-text">Longitude <span class="asterick">*</span> </span>
                <div class="formField input-spacing positionRelative">
                    <input appInputTrim [disabled]="disabledEdit" class="input-text" formControlName="longitude"
                        type="number" step="0.001" placeholder="Enter Longitude" name="longitude"
                        [ngClass]="{ 'is-invalid': checkValidation('longitude') }">
                    <span *ngIf="checkValidation('longitude')"
                        [ngStyle]="{'color': checkValidation('longitude') ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoInput"
                        [matTooltip]="getErrorMessage('longitude')" matTooltipPosition="below">info</span>
                    <!-- <mat-error *ngIf="checkValidation('longitude')">
                        <mat-error class="errorText"
                            *ngIf="checkRequiredValidation('longitude')">{{errorMessages['required']}}</mat-error>
                        <mat-error class="errorText"
                            *ngIf="checkSqlInjectionValidation('longitude')">{{deviceGroup?.controls?.longitude?.errors?.message}}</mat-error>
                    </mat-error> -->
                </div>
            </div>
            <div class="formSpacing formWidth">
                <span class="label-text">Commissioning Date </span><span class="asterick">*</span>
                <div class="formField inputBorder">
                    <mat-form-field appearance="outline">
                        <input formControlName="commissioningDate" matInput [matDatepicker]="picker" readonly>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker [disabled]="disabledEdit" xPosition="end" #picker></mat-datepicker>
                    </mat-form-field>
                </div>

            </div>
        </div>
        <div class="centerAlign">
            <div class="formSpacing formWidth">
                <span class="label-text">Batch </span>
                <div class="formField input-spacing positionRelative">
                    <input appInputTrim appSqlInjectionValidation placeholder="Enter batch" [disabled]="disabledEdit"
                        [ngClass]="{'is-invalid': checkValidation('batch')}" formControlName="batch" type="text"
                        class="input-text" name="batch">
                    <span *ngIf="checkValidation('batch')"
                        [ngStyle]="{'color': checkValidation('batch') ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoInput"
                        [matTooltip]="getErrorMessage('batch')" matTooltipPosition="below">info</span>
                    <!-- <mat-error *ngIf="checkValidation('batch')">
                        <mat-error class="errorText"
                            *ngIf="checkPatternValidation('batch')">{{errorMessages['pattern']}}</mat-error>
                        <mat-error class="errorText"
                            *ngIf="checkSqlInjectionValidation('batch')">{{deviceGroup?.controls?.batch?.errors?.message}}</mat-error>
                    </mat-error> -->
                </div>
            </div>
            <div class="formSpacing formWidth">
                <span class="label-text">Manufacturing Date </span>
                <div class="formField inputBorder">
                    <mat-form-field appearance="outline">
                        <input formControlName="manufacturingDate" matInput [matDatepicker]="picker2" readonly
                            [max]="currentDate" placeholder="DD/MM/YYYY">
                        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker [disabled]="disabledEdit" xPosition="end" #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="centerAlign">
            <div class="formSpacing formWidth">
                <span class="label-text">Firmware Version </span>
                <div class="formField input-spacing positionRelative">
                    <input appInputTrim appSqlInjectionValidation placeholder="Enter Firmware Version"
                        [ngClass]="{'is-invalid': checkValidation('firmwareVersion')}" [disabled]="disabledEdit"
                        formControlName="firmwareVersion" type="text" class="input-text" name="firmwareVersion">
                    <span *ngIf="checkValidation('firmwareVersion')"
                        [ngStyle]="{'color': checkValidation('firmwareVersion') ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoInput"
                        [matTooltip]="getErrorMessage('firmwareVersion')" matTooltipPosition="below">info</span>
                    <!-- <mat-error *ngIf="checkValidation('firmwareVersion')">
                        <mat-error class="errorText"
                            *ngIf="checkPatternValidation('firmwareVersion')">{{errorMessages['pattern']}}</mat-error>
                        <mat-error class="errorText"
                            *ngIf="checkSqlInjectionValidation('firmwareVersion')">{{deviceGroup?.controls?.firmwareVersion?.errors?.message}}</mat-error>
                    </mat-error> -->
                </div>
            </div>
            <div class="formSpacing formWidth">
                <span class="label-text">Is Billable </span>
                <div class="selector formField">
                    <mat-form-field appearance="outline" floatLabel="never">
                        <mat-select [disableOptionCentering]="true" name="isBillable" [disabled]="disabledEdit"
                            formControlName="isBillable">
                            <mat-option *ngFor="let option of dropdownOptions['isBillable']"
                                [value]="option.value">{{option.label}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf="!deviceGroup.value.useGateway && (deviceType === 'Water Meter' || deviceType === 'Cooling Meter')">
            <div class="formSpacing">
                <span class="label-text">Gateway Name <span class="asterick">*</span> </span>
                <div class="selector formField positionRelative">
                    <mat-form-field appearance="outline" floatLabel=never
                        [ngClass]="{ 'invalid': checkValidation('gatewayName')}">
                        <mat-select [disableOptionCentering]="true" name="gatewayName" [disabled]="disabledEdit || enableEdit"
                            placeholder="Select gateway" formControlName="gatewayName">
                            <mat-option value="{{data}}"
                                *ngFor="let data of gateway; let i = index;">{{data}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span *ngIf="checkValidation('gatewayName')"
                        [ngStyle]="{'color': checkValidation('gatewayName') ? '#dc3545' : ''}"
                        class="material-symbols-outlined infoIconDevice infoSelectBox"
                        [matTooltip]="getErrorMessage('gatewayName')" matTooltipPosition="below">info</span>
                    <!-- <mat-error *ngIf="checkValidation('gatewayName')">
                        <mat-error class="errorText"
                            *ngIf="checkRequiredValidation('gatewayName')">{{errorMessages['required']}}</mat-error>
                    </mat-error> -->
                </div>
            </div>
            <div *ngIf="deviceType === 'Water Meter'" class="formSpacing">
                <span class="label-text">Meter Diameter</span>
                <div class="selector formField">
                    <mat-form-field appearance="outline" floatLabel="never">
                        <mat-select [disableOptionCentering]="true" name="waterDiameter" [disabled]="disabledEdit"
                            placeholder="Select diameter" formControlName="waterDiameter">
                            <mat-option value="{{data}}" *ngFor="let data of dropdownOptions['waterDiameter']">DN
                                {{data}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf="deviceType === 'Energy Meter'">
            <div class="centerAlign">
                <div class="formSpacing formWidth">
                    <span class="label-text">IP Address <span class="asterick">*</span> </span>
                    <div class="formField input-spacing positionRelative">
                        <input appInputTrim appSqlInjectionValidation [disabled]="disabledEdit"
                            formControlName="ipAddress" type="text"
                            [ngClass]="{ 'is-invalid': checkValidation('ipAddress') }" class="input-text"
                            name="ipAddress" placeholder="Enter IP Address">
                        <span *ngIf="checkValidation('ipAddress')"
                            [ngStyle]="{'color': checkValidation('ipAddress') ? '#dc3545' : ''}"
                            class="material-symbols-outlined infoIconDevice infoInput"
                            [matTooltip]="getErrorMessage('ipAddress')" matTooltipPosition="below">info</span>
                        <!-- <mat-error *ngIf="checkValidation('ipAddress')">
                            <mat-error class="errorText"
                                *ngIf="checkRequiredValidation('ipAddress')">{{errorMessages['required']}}</mat-error>
                            <mat-error class="errorText"
                                *ngIf="checkPatternValidation('ipAddress')">{{errorMessages['pattern']}}</mat-error>
                            <mat-error class="errorText"
                                *ngIf="checkSqlInjectionValidation('ipAddress')">{{deviceGroup?.controls?.ipAddress?.errors?.message}}</mat-error>
                        </mat-error> -->
                    </div>
                </div>
                <div class="formSpacing formWidth">
                    <span class="label-text">Port <span class="asterick">*</span></span>
                    <div class="formField positionRelative">
                        <input appInputTrim appSqlInjectionValidation placeholder="Enter port" [disabled]="disabledEdit"
                            formControlName="port" type="text" [ngClass]="{ 'is-invalid': checkValidation('port') }"
                            class="input-text" name="port" min="0" max="9999">
                        <span *ngIf="checkValidation('port')"
                            [ngStyle]="{'color': checkValidation('port') ? '#dc3545' : ''}"
                            class="material-symbols-outlined infoIconDevice infoInput"
                            [matTooltip]="getErrorMessage('port')" matTooltipPosition="below">info</span>
                        <!-- <mat-error *ngIf="checkValidation('port')">
                            <mat-error class="errorText"
                                *ngIf="checkRequiredValidation('port')">{{errorMessages['required']}}</mat-error>
                            <mat-error class="errorText"
                                *ngIf="checkPatternValidation('port')">{{errorMessages['pattern']}}</mat-error>
                            <mat-error class="errorText"
                                *ngIf="checkSqlInjectionValidation('port')">{{deviceGroup?.controls?.port?.errors?.message}}</mat-error>
                        </mat-error> -->
                    </div>
                </div>
            </div>
            <div class="centerAlign">
                <div class="formSpacing formWidth">
                    <span class="label-text">Meter Password <span class="asterick">*</span></span>
                    <div class="formField icon-container input-spacing positionRelative">
                        <input appInputTrim appSqlInjectionValidation placeholder="Enter meter password"
                            [disabled]="disabledEdit" [ngClass]="{ 'is-invalid': checkValidation('meterPassword') }"
                            formControlName="meterPassword" type="{{hidePassword ? 'password': 'text'}}"
                            class="input-text" name="meterPassword">
                        <span *ngIf="checkValidation('meterPassword')"
                            [ngStyle]="{'color': checkValidation('meterPassword') ? '#dc3545' : ''}"
                            class="material-symbols-outlined infoIconDevice infoInput"
                            [matTooltip]="getErrorMessage('meterPassword')" matTooltipPosition="below">info</span>
                        <img src="{{hidePassword? 'assets/Neom-Images/hide.svg' : 'assets/Neom-Images/show.svg'}}"
                            class="hideIcon icon" [ngStyle]="{'cursor' : disabledEdit ? 'not-allowed' : ''}"
                            (click)="!disabledEdit ? hidePassword=!hidePassword : ''">
                        <!-- <mat-error *ngIf="checkValidation('meterPassword')">
                            <mat-error *ngIf="checkRequiredValidation('meterPassword')"
                                class="errorText">{{errorMessages['required']}}</mat-error>
                            <mat-error class="errorText"
                                *ngIf="checkSqlInjectionValidation('meterPassword')">{{deviceGroup?.controls?.meterPassword?.errors?.message}}</mat-error>
                        </mat-error> -->
                    </div>
                </div>
                <div class="formSpacing formWidth">
                    <span class="label-text">Energy Breaker Capacity</span>
                    <div class="selector formField">
                        <mat-form-field appearance="outline" floatLabel="never">
                            <mat-select [disableOptionCentering]="true" name="energyBreakerCapacity"
                                [disabled]="disabledEdit" formControlName="energyBreakerCapacity">
                                <mat-option *ngFor="let option of dropdownOptions['energyBreakerCapacity']"
                                    [value]="option">{{option}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <div *ngIf="!disabledEdit" class="registerBtn">
        <button [disabled]="loading" type="submit" class="button-Primary-N buttonYellow"
            [ngStyle]="{'cursor': deviceGroup?.valid ? 'pointer' : 'not-allowed'}">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner mr-2': loading}"></i>{{enableEdit ? 'Update' : 'Register'}}</button>
        <button class="button-Tertiary-N cancelBtn" mat-dialog-close>Cancel</button>
    </div>
    <div *ngIf="disabledEdit" class="registerBtn">
        <button *ngIf="!enableEdit" (click)="editDevice()" class="button-Primary-N buttonYellow">Edit</button>
    </div>
</form>