import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { COLOR } from '../chart-util/chart-config';
import { convertDate } from '../chart-util/chart-helper';

@Component({
  selector: 'app-spark-line',
  templateUrl: './spark-line.component.html',
  styleUrls: ['./spark-line.component.css']
})
export class SparkLineComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;

  @Input() data: Array<number>;
  @Input() name: string;
  @Input() width: number;
  updateFlag = false;
  chartOptions: Highcharts.Options = {
    chart: {
      backgroundColor: 'transparent',
      borderWidth: 0,
      type: "line",
      margin: [2, 0, 2, 0],
      height: 50,
      style: {
        overflow: "visible"
      }
    },
    title: {
      text: ""
    },
    credits: {
      enabled: false
    },
    xAxis: {
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0,
      visible: false

    },
    yAxis: {
      gridLineWidth: 0,
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
    },
    legend: {
      enabled: false
    },
    tooltip: {
      hideDelay: 0,
      outside: true,
      shared: true,
      //headerFormat: '<span style="font-size: 10px">' + `{series.name}` + ', Q{point.x}:</span><br/>',
      formatter() {
        const series = this.points[0].series;
        return `
          <b>${convertDate(this.x).toLocaleString('en-GB')}</b>
          <br>
          <b>${this.y.toFixed(2)}</b>
          `
      },
      //pointFormat: 
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },

        marker: {
          radius: 1,
          states: {
            hover: {
              radius: 2
            }
          }
        },
      },
      column: {
        negativeColor: "#910000",
        borderColor: "silver"
      }
    },
    series: [
      {
        name: '',
        type: 'area',
        data: []
      }
    ]
  };

  constructor(
    public date: DatePipe,
    private container: ElementRef,
  ) { }

  ngOnInit(): void {

  }


  ngOnChanges(change: SimpleChanges) {
    this.chartOptions.series = [{
      name: change.name ? change.name.currentValue : null,
      type: 'line',
      data: change.data.currentValue.map(x => parseFloat(x.value)),
      color: COLOR[0]
    }];
    // console.log("width", this.width)
    this.chartOptions.chart.width = this.width;
    this.chartOptions.xAxis = {
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0,
      visible: false,
      categories: change.data.currentValue.map(x => this.date.transform(x.dateTime, 'dd-MMM-yyyy HH:mm:ss'))
    };
    this.updateFlag = true;
  }
}
