<mat-dialog-title>
    <div class="col-12">
        <div class="col-12 close">
            <img src="assets/images/cross.svg " alt="" width="20px" mat-dialog-close />
        </div>
        <div class="col-8 mb-3 tickIconbg">
            <img src="assets/images/tick_icon_svg (1).svg" alt="" width="70px">
        </div>
        <div class="col-12 saveHeading">
            <span>{{data?.title}}</span>
        </div>
        <div class="border-separator"></div>
        <div *ngIf="data?.connection?.authenticationType== 'Token'">
            <span>Authentication Code</span>
            <div class ="d-flex align-items-center mt-2">
                <input type="text" class="input-text mr-2" id="accessToken" [(ngModel)]="data.connection.token"
                    [disabled]="'true'" />
                <button (click)="copyAuthentication('Token',data?.connection)"  mat-icon-button color="primary">
                    <img src="assets/images/copy.svg" width="15px">
                </button>
            </div>
        </div>
        <div *ngIf="data?.connection?.authenticationType === 'UserPass'">
            <span>Username and Password</span>
            <div class="d-flex align-items-center mt-2">
                <input type="text" class="input-text mr-2" id="userName" [(ngModel)]="data.connection.username"
                    [disabled]="'true'" />
                <input type="text" class="input-text mr-2" id="password" [(ngModel)]="data.connection.password"
                    [disabled]="'true'" />
                <button (click)="copyAuthentication('UserPass',data?.connection)"  mat-icon-button color="primary">
                    <img src="assets/images/copy.svg" width="15px">
                </button>
            </div>
        </div>
        <div *ngIf="data?.connection?.authenticationType === 'Certificate'">
            <div class="d-flex align-items-center mt-2">
                <span class="mr-2">Download Certificate</span>
                <button (click)="copyAuthentication('Certificate',data?.connection)" mat-icon-button color="primary">
                    <img src="assets/images/download-blue.svg" width="15px">
                </button>
            </div>
        </div>
    </div>
</mat-dialog-title>
