// Attributes allowed for search results
export const whiteListedAttributes = [
    'content.VOLUME',
    'content.FLOW_TEMPERATURE',
    'content.VOLUME_FLOW',
    'content.Vln_an',
    'content.Vln_bn',
    'content.Vln_cn',
    'content.kwInst',
    'content.kwhDel',
    'content.Current_A_THD',
    'content.Current_B_THD',
    'content.Current_C_THD',
    'content.Voltage_A_THD',
    'content.Voltage_B_THD',
    'content.Voltage_C_THD',
    'content.AvgPowerFactor',
    'content.value',
    'content.rawValue',
]

export const waterWhiteListedAttributes = [
    'content.VOLUME',
    'content.FLOW_TEMPERATURE',
    'content.VOLUME_FLOW',
    'content.RETURNVOLUME',
    'content.STATUS',
    'content.REMAINBATTERYLIFETIME',
    'content.ambientTemperature',
    'content.forwardVolume',
];

export const coolingMeterBlackListedAttributes = [
    'content.RSSI',
    'content.SNR',
    'content.telegramCounter',
    'content.moduleId',
    'content.filename',
];

// Attributes restricted for search suggestion
export const searchAttributesRestricted = ['Status', 'Filename', 'TelegramCounter', 'RemainingBatteryLifetime', 'Timestamp', 'ReturnVolume', 'Rssi', 'Snr', 'Flag', 'ModuleId', 'Disconnect'];

export const deviceTypeData = [
    {
        name: "Energy Meter",
        value: "Energy Meter"
    },
    {
        name: "Water Meter",
        value: "Water Meter"
    },
    {
        name: "Desalination Plant",
        value: "DubaPlantScada"
    },
    {
        name: "Water Recycling Plant",
        value: "Water Recycling Plant"
    },
    {
        name: "Pump Station",
        value: "Pump Station"
    }
];

export const locationData = [
    'C297', 'C217', 'C382', 'C377', 'C323', 'C335', 'C325',
    'C369', 'C351', 'C287', 'C245', 'C288', 'C322', 'C344',
    'C372', 'C308', 'C228', 'C259', 'C265', 'C216', 'C244',
    'C362', 'C210', 'C284', 'C290', 'C292', 'C208', 'C242',
    'C363', 'C306', 'C261', 'C224', 'C399', 'C392', 'C327',
    'C276', 'C263', 'C348', 'C310', 'C342', 'C389', 'C300',
    'C347', 'C282', 'C212', 'C357', 'C213', 'C365', 'C257',
    'C404', 'C256', 'C232', 'C273', 'C339', 'C340', 'C248',
    'C209', 'C312', 'C219', 'C251', 'C352', 'C260', 'C354',
    'C331', 'C330', 'C317', 'C311', 'C361', 'C264', 'C289',
    'C405', 'C211', 'C401', 'C321', 'C400', 'C358', 'C390',
    'C318', 'C360', 'C247', 'C345', 'C326', 'C274', 'C370',
    'C367', 'C381', 'C250', 'C397', 'C359', 'C371', 'C233',
    'C243', 'C280', 'C343', 'C329', 'C316', 'C315', 'C278',
    'C388', 'C396', 'C235', 'C220', 'C379', 'C254', 'C222',
    'C240', 'C385', 'C356', 'C279', 'C303', 'C271', 'C268',
    'C293', 'C226', 'C307', 'C376', 'C366', 'C314', 'C266',
    'C283', 'C334', 'C267', 'C275', 'C304', 'C296', 'C320',
    'C305', 'C378', 'C375', 'C355', 'C231', 'C294', 'C239',
    'C225', 'C341', 'C255', 'C346', 'C309', 'C384', 'C395',
    'C272', 'C364', 'C234', 'C214', 'C221', 'C246', 'C402',
    'C338', 'C337', 'C319', 'C353', 'C373', 'C406', 'C299',
    'C328', 'C301', 'C262', 'C218', 'C236', 'C253', 'C336',
    'C285', 'C237', 'C230', 'C252', 'C298', 'C394', 'C398',
    'C241', 'C223', 'C258', 'C368', 'C229', 'C380', 'C387',
    'C302', 'C407', 'C349', 'C393', 'C238', 'C281', 'C277',
    'C386', 'C350', 'C249', 'C391', 'C215', 'C324', 'C227',
    'C374', 'C270', 'C403', 'C313', 'C295', 'C333', 'C383',
    'C286', 'C332', 'C269', 'C291', "Mailbox Building", "Pharmacy",
    "Laundry Meter #3", "Laundry Meter #2", "Laundry Meter #1",
    "Laundry Meter #4", "Al Bada", "Duba", "Sharma"
];

export const renameAttributes = [{
    name: 'meterreading',
    alt: "meter units"
}, {
    name: 'instantaneouspower',
    alt: 'demand'
}, {
    name: 'averagepowerfactor',
    alt: 'power factor'
}, {
    name: 'volumeflow',
    alt: 'flow rate'
}, {
    name: 'flowtemperature',
    alt: 'temperature'
}];