import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as moment from 'moment';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {

  @Output()
  authorClick: EventEmitter<any> = new EventEmitter<any>(); //creating an output event

  constructor(private toastr: ToastService) { }

  public daterange: any = {};
  @Input()
  public minDate: any = moment("2018-09-01");
  @Input()
  public maxDate: any = moment("2019-02-07");

  public dateDisplay: any;
  public todaysDate: any = moment();

  public options: any = {
    startDate: this.minDate,
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    showCustomRangeLabel: true,
    minDate: this.minDate,
    maxDate: this.todaysDate
  };
  //private JSON: any;

  public selectedDate(value: any, datepicker?: any) {
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.displayDate(value.start, value.end);

    this.storeDateInLS(datepicker.start, datepicker.end)
    this.authorClick.emit("emitted");
  }

  public storeDateInLS(startDate: any, endDate: any) {
    if (endDate.diff(endDate, 'days') > 90) {
      this.toastr.warning('Please select less than 90 days');
      return;
    }
    let datearr = [];
    datearr.push(startDate.format('YYYY-MM-DD'));
    datearr.push(endDate.format('YYYY-MM-DD'));
    localStorage.setItem("assetResultDates", JSON.stringify(datearr));
    return;
  }

  public checkIfDateIsInLS() {
    if (!localStorage.getItem("assetResultDates")) {
      return false;
    }
    else {
      return true;
    }
  }

  ngOnInit() {
    // if (this.checkIfDateIsInLS()) {
    //   let date = this.getDateFromLS();
    //   //  This is taken from localstorage
    //   this.dateDisplay = moment(date[0]).format('Do MMM YY') + ' - ' + moment(date[1]).format('Do MMM YY');
    //   console.log("1 ", date[0],"  ", date[1])
    // }
    // else {
    //   this.dateDisplay = this.minDate.format('Do MMM YY') + ' - ' + this.maxDate.format('Do MMM YY');
    // }
    // This is too taken from localstorage, above code is redundant and performing the same task
    
    const selectedDate = JSON.parse(localStorage.getItem('assetResultDates'));

    let startDate;
    let endDate;
    if (selectedDate) {
      startDate = moment(selectedDate[0]);
      endDate = moment(selectedDate[1]);
      // 1 & 2 is same
      // console.log("2 ", startDate,"  ", endDate);
      this.displayDate(startDate, endDate);
    } else {
      startDate = moment(this.todaysDate).subtract(7, 'days'), moment();
      endDate = moment();
      this.displayDate(startDate, endDate);
      this.storeDateInLS(startDate, endDate);
      console.log(startDate)
    }
    let yearDate = new Date(new Date(new Date().getFullYear(), 0, 2))
    // @ts-ignore
    let days = Math.ceil((new Date() - yearDate) / 86400000);
    // @ts-ignore
    let monthdays = Math.ceil((new Date() - new Date(new Date().getFullYear(), new Date().getMonth(), 2)) / 86400000)
    // changing the option object to control datepicker to select future dates
    this.options = {
      ranges: {
        '24 Hours': [moment(this.todaysDate).subtract(1, 'days'), moment()],
        '3 Days': [moment(this.todaysDate).subtract(3, 'days'), moment()],
        '7 Days': [moment(this.todaysDate).subtract(7, 'days'), moment()],
        '30 Days': [moment(this.todaysDate).subtract(30, 'days'), moment()],
        'YTD':[moment(this.todaysDate).subtract(days,'days'), moment()],
        'MTD':[moment(this.todaysDate).subtract(monthdays,'days'), moment()],
      },
      startDate: moment(startDate),
      endDate: moment(endDate),
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: true,
      showCustomRangeLabel: true,
      minDate: this.minDate,
      maxDate: this.todaysDate
    };
  }


  public displayDate(startDate, endDate) {
    let d1;
    d1 = moment(endDate.format());
    d1 = d1.toDate();
    let d2;
    d2 = moment(this.todaysDate.format());
    d2 = d2.toDate();
    let d3;
    d3 = moment(startDate.format());
    d3 = d3.toDate();
    let setDate = new Date(new Date().getFullYear(), 0, 1);
    const daysDifference = endDate.diff(startDate, 'days'); 

    if (d1.getTime() === d2.getTime() && daysDifference !== 0) {
      if (d3.getTime() === setDate.getTime()) {
        this.dateDisplay = `Year to Date`;
        return;
      }
      if (d3.getTime() === new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime()) {
        this.dateDisplay = `Month to Date`;
        return;
      }
      this.dateDisplay = `Last ${daysDifference} ${daysDifference > 1 ? 'Days' : 'Day'}`;
      return;
    }
    this.dateDisplay = startDate.format('Do MMM YY') + ' - ' + endDate.format('Do MMM YY');
    return;
  }

  public getDateFromLS() {
    if (localStorage.getItem('assetResultDates') !== null) {
      const start = JSON.parse(localStorage.getItem('assetResultDates'))[0];
      const end = JSON.parse(localStorage.getItem('assetResultDates'))[1];
      return [start, end];
    } else {
      // TODO: A little repetitive else block can be improved
      this.options.startDate = this.minDate;
      this.options.endDate = this.maxDate;
      this.dateDisplay = this.minDate.format('Do MMM YY') + ' - ' + this.maxDate.format('Do MMM YY');
      this.storeDateInLS(this.minDate, this.maxDate);
      return [this.minDate.format(), this.maxDate.format()];
    }
  }
}