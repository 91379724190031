<div class="widgets-item-legend">
    <div class="d-flex flex-wrap">
            <div (click)=" !noClick ? displayChart(attribute) :''" role="button" class="d-flex m-1 attribute" *ngFor="let attribute of subgroups; let i = index;">
                <div class="attributeColor"  [ngStyle]="{'background-color':attribute.display ? colors[i] : '#fff','border':'solid 2px','border-color': attribute.display ? colors[i] : '#929ca8'}"></div>
                <p [ngStyle]="{'color':attribute.display ? '#000' : '#929ca8'}" class="attributeNumber" *ngIf="!showName">{{attribute.displayLegend}}
                    <span [innerHTML]="attribute.unit"></span>
                </p>
                <p [ngStyle]="{'color':attribute.display ? '#000' : '#929ca8'}" class="attributeNumber" *ngIf="showName">{{attribute.name}}
                    <span [innerHTML]="attribute.unit"></span>
                </p>
            </div>
    </div>
</div>