import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { routes } from '../device-onboarding.config';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { IEntityHeader, lineOfBusiness } from 'src/app/reusable/services/entity.interface';

const SERVER_URL = environment.serverUrl;
const apiVersion1 = "/api/v1";

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  private options = {
    headers: {
      "Content-Type": "application/json;ty=2",
      'Accept': 'Application/json'
    }
  }
  constructor(private apiServices: ApiService, private http: HttpClient) { }

  getAllDevice(payload: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.post(routes.GET_ALL_DEVICES, payload, this.options.headers)
        .subscribe((response) => {
          resolve(response);
        }),
        (error: any) => {
          reject(error);
        }
    })
  }

  getAvailableColumnValues(columnName: string) {
    return new Promise((resolve, reject) => {
      this.apiServices.get(`${routes.GET_AVAILABLE_COLUMN_VALUES}?columnName=${columnName}`)
        .subscribe((response) => {
          resolve(response);
        }),
        (error: any) => {
          reject(error);
        }
    })
  }

  getAvailableProfiles() {
    return new Promise((resolve, reject) => {
      this.apiServices.get(routes.GET_AVAILABLE_PROFILE, this.options.headers)
        .subscribe((response) => {
          resolve(response);
        }),
        (error: any) => {
          reject(error);
        }
    })
  }

  copyDownloadCertificate(deviceId) {
    return SERVER_URL + apiVersion1 + routes.DOWNLOAD_CERTIFICATE_BY_DEVICE_ID + `?deviceId=${deviceId}`
  }

  downloadCertificateByDeviceId(deviceId) {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return new Promise((resolve, reject) => {
      this.apiServices.get(routes.DOWNLOAD_CERTIFICATE_BY_DEVICE_ID + `?deviceId=${deviceId}`, httpOptions)
        .subscribe((response) => {
          resolve(response);
        }),
        (error: any) => {
          reject(error);
        }
    })
  }

  public registerDevice(payload: any) {
    return new Promise((resolve, reject) => {
      try {
        this.apiServices.post(routes.REGISTER_DEVICE, payload, this.options).subscribe((response) => {
          resolve(response);
        },(error: any) => {      
          reject(error);
        });
      } catch (error) {        
        reject(error);
      }
    })
  }

  public getGatewayName(profileID: string) {
    return new Promise((resolve, reject) => {
      this.apiServices.post(routes.GATEWAY_BY_PROFILE, { profileID }, this.options).toPromise().then((response) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
    }) 
    })
  }

  public getRegistrationByDeviceId(deviceId, profileID) {
    return new Promise((resolve, reject) => {
      this.apiServices.get(routes.GET_REGISTRATION_BY_DEVICEID + `?deviceId=${deviceId}&profileID=${profileID}`, this.options).toPromise().then((response) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    })
  }


  public deleteDevice(deviceId) {
    return new Promise((resolve, reject) => {
      this.apiServices.delete(routes.DELETE_DEVICE_REGISTRATION_BY_DEVICE_ID + `?deviceId=${deviceId}`, this.options).toPromise().then((response) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    })
  }

  public removeDevice(deviceId) {
    return new Promise((resolve, reject) => {
      this.apiServices.get(routes.REMOVE_DEVICE_REGISTRATION_BY_DEVICE_ID + `?deviceId=${deviceId}`, this.options).toPromise().then((response) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    })
  }

  public updateDevice(payload: any) {
    return new Promise((resolve, reject) => {
      this.apiServices.put(routes.UPDATE_DEVICE, payload, this.options).toPromise().then((response) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  public bulkRegistrationOfDevices(payload) {
    return new Promise((resolve, reject) => {
      this.apiServices.post(routes.BULK_REGISTRATIOB_OF_DEVICE, payload, this.options).toPromise().then((response) => {
        resolve(response);
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  public fetchRegistrationMetadata(id, postData) {
    const entity: IEntityHeader = {
      deviceType: postData.attributes[0].deviceType,
      sector: 'Water',
      lineOfBusiness: lineOfBusiness.industrial
    }
    return new Promise((resolve, reject) => {
      const device = this.http.get<any>(`${SERVER_URL}${apiVersion1}/registration/registration-metadata?deviceID=${id}`, {
        headers: {
          entity: JSON.stringify(entity)
        }
      });
      const data = this.http.post<any>(`${SERVER_URL}${apiVersion1}/telemetry/get-aggregate-asset-results`, postData,
        {
          headers: {
            entity: JSON.stringify(entity)
          }
        }
      );
      forkJoin([device, data])
        .subscribe(res => {
          resolve(res);
        });
    }).catch((error: any) => {
      throw error;
    });

  }
}
