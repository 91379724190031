import * as d3 from "d3";
import { chartCustomScrollhandler, convertDate, convertValues, createSubGroups, formatAxisConfig, formatData, formatDataForDevice, getFilterPositionNative, hideTooltip, setChartConfig, updateAxisConfig, updateBrushHandle } from "./chart-helper";
import { v4 as uuidv4 } from 'uuid';
import * as moment from "moment";
import { DATE_TIME_FORMAT_IGNORE_MILLISECONDS, EN_GB_LOCAL_STRING } from "../constant";
import { COLOR, tooltipCircleRadius, tooltipCircleStrokeOpacity, tooltipCircleStrokeWidth, filterScrollTextPadding, handleWidth, lineOpacity, tooltipLineColor, tooltipLineWidth, tooltipLineDasharray, lineWidth, VPmargin, viewportWidth, viewportHeight, handleWidthVP, tickSize } from "./chart-config";

export const areaGraphForNative = (element, data, config) => {

  const mainElement = d3.select(element);
  let width = mainElement.node()["clientWidth"];
  let height = mainElement.node()["clientHeight"];
  let vwheight = (parseFloat(VPmargin.ploatfilterheight) / 100) * viewportWidth;
  let vhmarginleft =  (parseFloat(VPmargin.yAxisMarginleft) / 100) * viewportHeight;
  let vwmarginTop = (parseFloat(VPmargin.yAxisMarginTop) / 100) * viewportWidth;
  let vwhandleWidth = (parseFloat(handleWidthVP) / 100) * viewportWidth;
  let vwmarginBottom = (parseFloat(VPmargin.xAxisMarginBottom) / 100) * viewportWidth;
  let ploatFilterHeight = config.ploatFilterHeight || vwheight
  const guid = uuidv4();
  const brush = d3.brushX();
  let zoom;
  let handle, context, ploatFiltersvg;
  let subgroups, color;

  if (typeof (config.attributes) === "string") {
    subgroups = config.attributes;
  }
  else {
    subgroups = createSubGroups(config.attributes);
  }
  config.chartConfig = setChartConfig(config.chartConfig);
  width = width - vhmarginleft - config.chartConfig.margin.right;
  height = height - vwmarginTop - vwmarginBottom;
  if (config.showScrollbar) {
    height = height - ploatFilterHeight;
  }

  let dateList = [];
  const dataPoints = [];
  const bisectDate = d3.bisector(function (d: any) { return d; }).left;

  d3.selectAll(`${element} svg`).remove();
  d3.selectAll(`${element} .chart-tooltip`).remove();

  data = data.filter(item => {
    const row = subgroups.find(x => x.name === item.name)
    if (row.display || row.display === undefined) {
      return item;
    }
  })
  
  /* Format Data */
  data.forEach(function (d) {
    d.nativeList.forEach(function (d) {
      d.dateTime = convertDate(d.dateTime);
      d.value = +d.value;
      dateList.push(d.dateTime);
      dataPoints.push(d.value);
    });
  });

  dateList.sort(function (a, b) { return a - b });
  /* Scale */
  const xScale = d3.scaleTime()
    .domain(d3.extent(dateList))
    .range([0, width]);

  const maxValue = d3.max(dataPoints) || 10;
  const minValue = d3.min(dataPoints) || 0;

  const yScale = d3.scaleLinear()
    .domain([+minValue, +maxValue])
    .nice()
    .range([height, 0]);

  const yAxisGrid = d3.axisLeft(yScale).ticks(tickSize)
    .tickSize(-width)
    .tickFormat((d) => { return '' });

  const _x = d3.scaleTime()
    .domain(d3.extent(dateList))
    .range([0, width]);

  const _y = d3.scaleLinear()
    .range([ploatFilterHeight, 0])
    .domain([+minValue, +maxValue])
    .nice();

  color = d3.scaleOrdinal()
    .domain(subgroups.map(x => x.name || x.description))
    .range(COLOR);

  /* Add SVG */
  const svg = mainElement
    .append("svg")
    .attr('class', 'chart-plot')
    .attr("width", (width + vhmarginleft + config.chartConfig.margin.right) + "px")
    .attr("height", (height + vwmarginTop + vwmarginBottom) + "px");

  /* Add tooltip */
  const tooltip = mainElement
    .append("div")
    .classed('chart-tooltip', true);

  const container = svg.append('g')
    .attr("transform", `translate(${vhmarginleft}, ${vwmarginTop})`);

  /* Add line into SVG */
  const line = d3.line()
    .x((d: any) => xScale(d.dateTime))
    .y((d: any) => yScale(d.value || 0));

  /* Add line into SVG */
  const area = d3.area()
    .x((d: any) => xScale(d.dateTime))
    .y0(height)
    .y1((d: any) => yScale(d.value || 0));

  if (config.chartConfig?.axis?.yAxis?.showGrid) {
    container.append('g')
      .attr('class', 'y-axis-grid')
      .call(yAxisGrid);
  }

  let lines = container.append('g')
    .attr('class', 'lines');

  let areas = container.append('g')
    .attr('class', 'area');

  let gradient = container.append('g')
    .attr('class', 'gradient');

  container.append("defs").append("clipPath")
    .attr("id", `clip-${guid}`)
    .append("rect")
    .attr("x", 0)
    .attr("width", width)
    .attr("height", height);

  areas.selectAll('.areas-group')
    .data(data).enter()
    .append('g')
    .attr('class', 'areas-group')
    .append('path')
    .attr('class', 'area')
    // .style('fill', (d: any, i: any) => color(d.name || d.description))
    .attr("fill", (d, i) => `url(#gradient-${i}-${guid})`)
    .attr("clip-path", `url(#clip-${guid})`)
    .attr('d', (d: any) => area(d.nativeList))
  // .style('opacity', 0.6)

  gradient.selectAll('.gradient-group')
    .data(data).enter()
    .append('g')
    .attr('class', 'gradient-group')
    .append('linearGradient')
    .attr('class', (d, i) => `linear-gradient-${i}-${guid}`)
    .attr("id", (d, i) => `gradient-${i}-${guid}`)
    .attr('x1', '0%')
    .attr('y1', '100%')
    .attr('x2', '0%')
    .attr('y2', '0%')
    .selectAll("stop")
    .data((d: any, i) => {
      return [{
        offset: "0%",
        color: color(d.name || d.description),
        opacity: 0.05
      },
      {
        offset: "100%",
        color: color(d.name || d.description),
        opacity: 0.5
      }
      ]
    })
    .enter().append("stop")
    .attr("offset", function (d) {
      return d.offset;
    })
    .attr("stop-opacity", function (d) {
      return d.opacity;
    })
    .attr("stop-color", function (d) {
      return d.color;
    });

  lines.selectAll('.line-group')
    .data(data).enter()
    .append('g')
    .attr('class', 'line-group')
    .append('path')
    .attr('class', 'line')
    .style('stroke-width', lineWidth)
    .style('fill', 'none')
    .attr("clip-path", `url(#clip-${guid})`)
    .attr('d', (d: any) => line(d.nativeList))
    .style('stroke', (d: any, i: any) => color(d.name || d.description))
    .style('opacity', lineOpacity)

  container.selectAll('.circle-tooltip')
    .data(data).enter()
    .append("circle")
    .attr('class', (d: any, i: any) => `circle-tooltip circle-tooltip-${i}`)
    .attr("r", tooltipCircleRadius)
    .style('fill', (d: any, i: any) => color(d.name || d.description))
    .style('stroke', (d: any, i: any) => color(d.name || d.description))
    .style('opacity', 0)
    .style('stroke-width', tooltipCircleStrokeWidth)
    .style('stroke-opacity', tooltipCircleStrokeOpacity)

  container
    .append("line")
    .attr("class", "line-tooltip")
    .style("stroke", tooltipLineColor)
    .style("stroke-width", tooltipLineWidth)
    .style("stroke-dasharray", tooltipLineDasharray)
    .style('opacity', 0);

  /* Add Axis into SVG */
  const xAxis = d3.axisBottom(xScale);
  const yAxis = d3.axisLeft(yScale);

  container.append("g")
    .attr("class", "axis-bottom x-axis")
    .attr("transform", `translate(0, ${height})`)
    .call(xAxis);

  container.append("g")
    .attr("class", "axis-left y-axis")
    .call(yAxis
      .ticks(tickSize)
      .tickFormat((d: any) => {
        return convertValues(d);
      }))
    .append('text')
    .attr("y", 15)
    .attr("transform", "rotate(-90)")
    .attr("fill", "#000")

  formatAxisConfig(svg, config.chartConfig);

  if (config.showScrollbar) {
    ploatFiltersvg = mainElement
      .append("svg")
      .attr("class", 'filter-plot')
      .attr("width", width + vhmarginleft + config.chartConfig.margin.right)
      .attr("height", ploatFilterHeight)
      .append('g')
      .attr("transform", `translate(${vhmarginleft},${0})`);

    brush.extent([[0, 0], [width, ploatFilterHeight]])
      .on("brush", (d) => {
        if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom
        // get bounds of selection
        const s = d3.event.selection || _x.range();
        xScale.domain(s.map(_x.invert, _x));
        container.select(".x-axis").call(xAxis);
        const maxValue = formatData(data, xScale.domain());
        yScale.domain([d3.min(maxValue), d3.max(maxValue)]).nice();

        lines.selectAll(".line")
          .attr('d', (d: any) => line(d.nativeList));
        areas.selectAll(".area")
          .attr('d', (d: any) => area(d.nativeList));
        container.select(".y-axis").call(yAxis);

        data.forEach((element, i) => {
          const values = formatDataForDevice(element, xScale.domain());
          const y1 = ((d3.max(values.dataPoints) - d3.min(values.allDataPoints)) * 100) / (d3.max(values.allDataPoints) - d3.min(values.allDataPoints));
          const y2 = ((d3.min(values.dataPoints) - d3.min(values.allDataPoints)) * 100) / (d3.max(values.allDataPoints) - d3.min(values.allDataPoints));
          svg.selectAll(`.gradient .gradient-group linearGradient#gradient-${i}-${guid}`).selectAll('stop')
            .attr('offset', (d: any, i: number) => {
              return i === 0 ? `${y2}%` : `${y1}%`
            })
        });

        svg.select(".zoom").call(zoom.transform, d3.zoomIdentity
          .scale(width / (s[1] - s[0]))
          .translate(-s[0], 0));

        updateAxisConfig(yAxisGrid, yScale, svg, '.y-axis-grid')
        formatAxisConfig(svg, config.chartConfig);
        updateBrushHandle(context);
        handle.attr("x", (d) => {
          const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
          return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
        })
      });

    /* Add line into SVG filter */
    const lineFilter = d3.line()
      .x((d: any) => _x(d.dateTime))
      .y((d: any) => _y(d.value));

    const xAxisFilter = d3.axisTop(_x).tickSize(0).tickPadding(filterScrollTextPadding);

    const linesFilter = ploatFiltersvg.append('g')
      .attr('class', 'lines');

    linesFilter.selectAll('.line-group')
      .data(data).enter()
      .append('g')
      .attr('class', 'line-group')
      .append('path')
      .attr('class', 'line')
      .style('stroke-width', lineWidth)
      .style('fill', 'none')
      .attr('d', (d: any) => lineFilter(d.nativeList))
      .style('stroke', (d: any, i: any) => color(d.name || d.description))
      .style('opacity', lineOpacity)

    linesFilter.append("g")
      .attr("class", "x-axis-filter")
      .attr("transform", `translate(0, ${ploatFilterHeight})`)
      .call(xAxisFilter);

    context = ploatFiltersvg.append("g")
      .attr("class", "context")
      .attr('transform', 'translate(' + 0 + ', ' + 0 + ')');

    handle = chartCustomScrollhandler(context, brush, ploatFilterHeight, ploatFiltersvg);
  }

  zoom = d3.zoom()
    .scaleExtent([1, Infinity])
    .translateExtent([[0, 0], [width, height]])
    .extent([[0, 0], [width, height]])
    .on("zoom", (d) => {
      if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush

      hideTooltip(svg, tooltip);
      const t = d3.event.transform;
      xScale.domain(t.rescaleX(_x).domain());
      let diff = (xScale.domain()[1].getTime() - xScale.domain()[0].getTime()) / 1000;
      diff = diff / 60;
      if (diff > 3) {
        container.select(".x-axis").call(xAxis);
        const maxValue = formatData(data, xScale.domain());
        yScale.domain([d3.min(maxValue), d3.max(maxValue)]).nice();

        mainElement.select(".x_brush").call(brush.move, xScale.range().map(t.invertX, t));
        lines.selectAll(".line")
          .attr('d', (d: any) => line(d.nativeList));
        areas.selectAll(".area")
          .attr('d', (d: any) => area(d.nativeList));

        data.forEach((element, i) => {
          const values = formatDataForDevice(element, xScale.domain());
          const y1 = ((d3.max(values.dataPoints) - d3.min(values.allDataPoints)) * 100) / (d3.max(values.allDataPoints) - d3.min(values.allDataPoints));
          const y2 = ((d3.min(values.dataPoints) - d3.min(values.allDataPoints)) * 100) / (d3.max(values.allDataPoints) - d3.min(values.allDataPoints));
          svg.selectAll(`.gradient .gradient-group linearGradient#gradient-${i}-${guid}`).selectAll('stop')
            .attr('offset', (d: any, i: number) => {
              return i === 0 ? `${y2}%` : `${y1}%`
            })
        });

        container.select(".y-axis").call(yAxis);
        updateAxisConfig(yAxisGrid, yScale, svg, '.y-axis-grid')
        formatAxisConfig(svg, config.chartConfig);
        updateBrushHandle(context);
        handle.attr("x", (d) => {
          const x = parseFloat(mainElement.select(".x_brush").select(`.handle--${d.type}`).style('x'));
          return d.type === 'w' ? (x - (vwhandleWidth / 2)) : (x - vwhandleWidth / 2);
        })
      }
    });

  container.append("rect")
    .attr("class", "zoom")
    .attr("width", width)
    .attr("height", height - config.chartConfig.margin.right)
    .style("fill", 'none')
    .style("cursor", 'move')
    .style("pointer-events", 'all')
    .attr("transform", `translate(${0}, ${0})`)
    .call(zoom)
    .on("mouseover", () => { })
    .on("mouseout", () => {
      hideTooltip(svg, tooltip);
    })
    .on("mousemove", () => {
      let toolTipText = '';
      const x0: any = xScale.invert(d3.event.offsetX - vhmarginleft),
        i = bisectDate(dateList, x0, 1),
        d0 = dateList[i - 1],
        d1 = dateList[i];
      let dateTime = x0 - d0 > d1 - x0 ? d1 : d0;
      for (let index = 0; index < data.length; index++) {
        const d0 = data[index].nativeList.find(x => moment(x.dateTime).format(DATE_TIME_FORMAT_IGNORE_MILLISECONDS) === moment(dateTime).format(DATE_TIME_FORMAT_IGNORE_MILLISECONDS));
        container.select(`.circle-tooltip-${index}`)
          .attr("cx", (_d, _i) => {
            if (d0) {
              return xScale(d0.dateTime);
            }
          })
          .attr("cy", (_d: any, _i) => {
            if (d0) {
              toolTipText += `<p> ${dateTime.toLocaleString(EN_GB_LOCAL_STRING)} </p><p> <span class='legend-box' style='background-color:${color(_d.name || _d.description)}'></span> ${_d.name || _d.description} : ${d0.value.toFixed(3)} </p>`;
              return yScale(d0.value);
            }
          })
          .style("opacity", (_d: any, _i) => {
            if (d0) {
              return 1;
            }
            return 0;
          })
      }

      if (config.alertRange && (dateTime >= convertDate(config.alertRange.startDate) && dateTime <= convertDate(config.alertRange.endDate))) {
        toolTipText += `<p>Alert Start Time : ${moment(config.alertRange.startDate).format("DD/MM/YYYY, HH:mm:ss")} </p>`;
        toolTipText += config.alertRange.isActive ? '' : `<p>Alert End Time : ${moment(config.alertRange.endDate).format("DD/MM/YYYY, HH:mm:ss")} </p>`;
      }

      if (config.operationalRange) {
        let i = bisectDate(dateList, x0, 1),
          d0 = data[0].nativeList[i - 1],
          d1 = data[0].nativeList[i];
        let d = x0 - d0?.dateTime > d1?.dateTime - x0 ? d1 : d0;
        if (d.value >= config.operationalRange.minValue && d.value <= config.operationalRange.maxValue) {
          toolTipText += `<p>Lower Limit : ${config.operationalRange.minValue}</p>`;
          toolTipText += `<p>Upper Limit : ${config.operationalRange.maxValue}</p>`;
        }
      }

      container.selectAll('.line-tooltip')
        .attr("x1", xScale(dateTime))
        .attr("y1", 0)
        .attr("x2", xScale(dateTime))
        .attr("y2", height)
        .style('opacity', 1);

      tooltip
        .style("opacity", "0.89")
        .html(toolTipText)

      tooltip.node().style.right = 'auto';
      tooltip.node().style.top = 'auto';
      tooltip.node().style.left = 'auto';

      let width = svg.node().clientWidth;
      let tipWidth = tooltip.node().offsetWidth;
      let tipHeight = tooltip.node().offsetHeight;
      if (width - xScale(dateTime) < tipWidth) {
        tooltip.node().style.right = (width - xScale(dateTime)) - vhmarginleft + 10 + 'px';
        tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
        tooltip.node().style.left = 'auto';
        tooltip.classed('chart-tooltip-right', true);
        tooltip.classed('chart-tooltip-left', false);
      }
      else {
        tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
        tooltip.node().style.left = xScale(dateTime) + vhmarginleft + 10 + 'px';
        tooltip.node().style.right = 'auto';
        tooltip.classed('chart-tooltip-right', false);
        tooltip.classed('chart-tooltip-left', true);
      }
    });

  ploatFiltersvg?.select('.x_brush').call(brush.move, _x.range());
}
