export const routes = {
    GET_ALL_DEVICES :'/device-onboarding/getAllDevice',
    GET_AVAILABLE_COLUMN_VALUES :'/device-onboarding/getAvailableColumnValues',
    GET_ALL_DEVICE_PROFILE :'/device-onboarding/getAllDeviceProfile',
    GET_AVAILABLE_PROFILE : '/device-onboarding/availableProfiles',
    GET_PROFILE_BY_NAME : '/device-onboarding/getDeviceProfileById',
    GET_PROFILE_BY_PROFILE_NAME : '/device-onboarding/getProfileByProfileName',
    DOWNLOAD_CERTIFICATE_BY_DEVICE_ID : '/device-onboarding/downloadCertificateByDeviceId',
    COPY_DOWNLOAD_CERTIFICATE : '/device-onboarding/copyDownloadCertificate',
    DELETE_DEVICE_REGISTRATION_BY_DEVICE_ID : '/device-onboarding/deleteDeviceRegistrationByDeviceId',
    REMOVE_DEVICE_REGISTRATION_BY_DEVICE_ID : '/device-onboarding/deRegistrationByDeviceId',
    REGISTER_DEVICE : '/device-onboarding/registerDevice',
    GET_REGISTRATION_BY_DEVICEID : '/device-onboarding/getRegistrationByDeviceId',
    UPDATE_DEVICE : '/device-onboarding/updateDevice',
    BULK_REGISTRATIOB_OF_DEVICE : '/device-onboarding/bulkRegistrationOfDevices',
    GATEWAY_BY_PROFILE: '/device-onboarding/getGatewayName'
}