import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(private router: Router, private dialog: MatDialog) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        const ssoUser = localStorage.getItem('sso-user') || 'false';
        if (token) {
            // if the token is  stored in localstorage add it to http header
            let headers = new HttpHeaders();

            if (request.headers.keys().indexOf('entity') !== -1) {
                headers = new HttpHeaders().set('access-token', token).set('sso-user', ssoUser)
                    .set('entity', request.headers.get('entity'))
            }
            else {
                headers = new HttpHeaders().set('access-token', token).set('sso-user', ssoUser)
            }
            // clone http to the custom AuthRequest and send it to the server
            const AuthRequest = request.clone({ headers: headers });
            //return next.handle(AuthRequest);
            return next.handle(AuthRequest).pipe(tap(),
                catchError((err: any) => {
                    if ((err.status === 400 || err.status === 401) && err.url.includes('validate-token')) {
                        return next.handle(err.error);
                    }
                    else if (err.status === 403 ) {
                        this.router.navigate(['/forbidden']);
                    }
                    else if (err.status === 401 ) {
                        this.logout();
                    }
                    else if (err.status === 500) {
                        return next.handle(err.error);
                    }
                    return of(err);
                }));
        } else {
            //return next.handle(request);
            return next.handle(request).pipe(tap(),
                catchError((err: any) => {
                    if (err.status === 401) {
                        //this.logout();
                    }
                    return of(err);
                }));
        }
    }

    public logout() {
        // this.deleteToken();
        localStorage.clear();
        this.dialog.closeAll();
        this.router.navigate(['/user']);
        return;
    }
}
