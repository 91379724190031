import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ElasticSearchService } from 'src/app/reusable/services/elastic-search.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-add-additional-tags',
  templateUrl: './add-additional-tags.component.html',
  styleUrls: ['./add-additional-tags.component.css']
})
export class AddAdditionalTagsComponent implements OnInit {

  displayedColumns = ['select', 'Name', 'Current Value', 'Trend', 'Data'];
  dataSource = new MatTableDataSource<Element>();
  selection = new SelectionModel<any>(true, []);

  constructor(private elasticSearchservice: ElasticSearchService,
    private sharedService: SharedServiceService,
    private dialogRef: MatDialogRef<AddAdditionalTagsComponent>) { }

  ngOnInit(): void {
    this.sharedService.tagList.forEach(element => {
      this.selection.select(element);
    });

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  addTags() {
    if (this.selection.selected.length) {
      this.sharedService.tagList = this.selection.selected
      this.dialogRef.close(true);
    }
  }

}
