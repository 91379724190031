import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';

@Component({
  selector: 'app-filter-types',
  templateUrl: './filter-types.component.html',
  styleUrls: ['./filter-types.component.css']
})
export class FilterTypesComponent implements OnInit, OnChanges {

  @Input() data: {options: string[], index: number};
  @Input() selectedValue: string;
  @Input() countForFilters;
  @Input() filterType: string;
  @Input() title: string;
  @Output() selectOptionEvent = new EventEmitter<any>();

  public filterName: string = '';
  public alertFilters: string[] = [];
  public filterCount = {};

  constructor(private sharedService: SharedServiceService) { }

  ngOnInit(): void {
    this.calculateFiterCount();
    return;
  }

  ngOnChanges() {
    this.filterName = this.selectedValue;
    this.calculateFiterCount();
    return;
  }

  calculateFiterCount() {
    if (!this.countForFilters) return;
    if (this.filterType === 'deviceListing') {
      for (const filterType in this.countForFilters[0]) {
        if (filterType === '_id') {
          continue;
        }
        this.countForFilters[0][filterType] = this.countForFilters[0][filterType].filter(item => item != null );
      }
    
      this.filterCount['Zone'] = this.countForFilters[0]['zone'].length;
      this.filterCount['Gateway'] = this.countForFilters[0]['gateway'].length;
      this.filterCount['Status'] = this.countForFilters[0]['deviceStatus'].length;
      this.filterCount['Device type'] = this.countForFilters[0]['deviceType'].length;
      this.filterCount['Custom tags'] = this.countForFilters[0]['tags'].length;
      this.filterCount['Serial Number'] = this.countForFilters[0]['serialNumber'].length;
      this.filterCount['Manufacturer'] = this.countForFilters[0]['manufacturerName'].length;
      this.filterCount['Location'] = this.countForFilters[0].measurableLocation.length;
    } else {
      this.filterCount = {'Critical': 0, 'High': 0, 'Medium': 0, 'Low': 0, 'Active': 0, 'Resolved': 0, 'Acknowledged': 0, 'Unacknowledged': 0, 'Dismissed': 0};
      for(let i = 0; i < this.countForFilters.length; i++) {
        this.filterCount[this.getAttribute(this.countForFilters[i])] += 1;
      }
    }
  }

  getAttribute(attribute: string) {
    if (attribute === 'acknowledge') return 'Acknowledged';
    if (attribute === null) return 'Unacknowledged';
    if (attribute === 'dismiss') return 'Dismissed';
    return attribute;
  }

valueOfFilter(){

}
  filterSelect(value) {
    // let value = e.target.value;
    this.filterName = value;
    this.selectOptionEvent.emit({optionSelected: value, index: this.data.index, count: this.filterCount[value]});
    return;
  }

  removeFilterName(): void {
    this.filterName = '';
    this.selectOptionEvent.emit({optionSelected: this.filterName, index: this.data.index});
    return;
  }

  selectAlertFilter(value: string) {
    if (this.alertFilters.indexOf(value) > -1) {
      this.alertFilters.splice(this.alertFilters.indexOf(value), 1);
      this.selectOptionEvent.emit({optionSelected: this.alertFilters, filterType: this.title});
      return;
    }
    this.alertFilters.push(value);
    this.selectOptionEvent.emit({optionSelected: this.alertFilters, filterType: this.title});
    return;
  }

  getSelectedFilterClass(filter: string) {
    return this.alertFilters.indexOf(filter) > -1 ? 'selectedFilter' : '';
  }

  getTitle(title: string) {
    return this.sharedService.toStartCase(title);
  }

}
