import { Directive, HostListener } from '@angular/core';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ALPHANUMERIC_REGEX, SPECIAL_CHARACTER_NOT_ALLOWED } from '../reusable/constant';
@Directive({
  selector: '[appSearchInputValidation]'
})
export class SearchInputValidationDirective {

  constructor(    
    private toastr: ToastService  
  ) { }

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    const regex = ALPHANUMERIC_REGEX; // Define the regular expression to allow only alphanumeric characters and spaces

    const inputChar = String.fromCharCode(event.charCode);
    if (regex.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
      this.toastr.warning(SPECIAL_CHARACTER_NOT_ALLOWED)
    }
  }
}
