<div class="main-Div">
    <header class="main-Header">
        <app-breadcrumb [data]="breadcrumb"></app-breadcrumb>
        <section class="pageHeader">
            <div class="pageHeading">
                <span class="main-Title ">{{deviceDetails[0]?.name}}</span>
            </div>
            <div id="tagOptions" *ngIf="!(breakPoints.isMobilePortrait || breakPoints.isMobileLandscape)">
                <div id="showRange" *ngIf="!statusChart && chartSelected === 'linechart' && deviceDetails.length > 1">
                    <span>Split Y-axis</span>
                    <mat-slide-toggle (change)="enableSplitYAxis()" [checked]="splitYAxis"></mat-slide-toggle>
                </div>
                <div id="showRange" *ngIf="!statusChart && chartSelected === 'linechart' && deviceDetails.length === 1 && deviceType != FLOW_METER">
                    <span>Show Range</span>
                    <mat-slide-toggle (change)="enableOprationalRange()" [checked]="oprationalRange"></mat-slide-toggle>
                </div>
               
                <button (click)="downloadData()" class="button-Secondary-N expertBtn"><img
                        src="assets/Neom-Images/download.svg" width="10px"> Export Data</button>
                <button (click)="saveProject()" class="button-Primary-N">Save</button>
            </div>
            <!-- this more option menu only open mobile and tablet screen -->
            <div id="mobileMoreOptions">
                <div [ngStyle]="breakPoints.isMobilePortrait && {'display':'flex'}">
                    <div *ngIf="breakPoints.isMobilePortrait" class="dropDownSelect borderSquare">
                        <img class="timeIcon" src="../../../../assets/images/time.svg">
                        <mat-form-field appearance="none">
                            <mat-select [(ngModel)]="aggregationInterval" (selectionChange)="intervalChange($event)"
                                class="form-select" [disableOptionCentering]="true">
                                <mat-option *ngFor="let interval of aggregationIntervalOptions"
                                    value="{{interval.value.toLowerCase()}}">{{interval.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="breakPoints.isMobilePortrait" class="dropDownSelect borderSquare">
                        <img src="assets/images/min-max.png" class="minIcon">
                        <mat-form-field *ngIf="aggregationInterval!=='native'" appearance="none">
                            <mat-select [(ngModel)]="aggregationType" (selectionChange)="typeChange($event)"
                                class="form-select"[disableOptionCentering]="true">
                                <mat-option *ngFor="let type of aggregationTypeOptions"
                                    [value]="type.value.toLowerCase()">{{type.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

        </section>
    </header>

    <section id="chartSection">
        <section id="chartDataSelector" class="col-12">
            <div class="commonDatepicker borderSquareDatePicker">
                <app-common-datepicker [dateSelected]="[minDate,maxDate]"
                    (dateChange)="selectedDate($event)"></app-common-datepicker>
            </div>

            <div *ngIf="!breakPoints.isMobilePortrait" class="commonDatepicker">
                <mat-form-field appearance="none">
                    <img src="../../../../assets/images/time.svg" class="timeIcon2"> 
                    <mat-select [(ngModel)]="aggregationInterval" (selectionChange)="intervalChange($event)"
                        class="form-select" [disableOptionCentering]="true">
                        <mat-option *ngFor="let interval of aggregationIntervalOptions"
                            value="{{interval.value.toLowerCase()}}">{{interval.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="commonDatepicker" *ngIf="!breakPoints.isMobilePortrait && aggregationInterval!=='native'">
                <mat-form-field appearance="none" *ngIf="aggregationInterval!=='native'">
                    <img src="assets/images/min-max.png" class="timeIcon2">
                    <mat-select [(ngModel)]="aggregationType" (selectionChange)="typeChange($event)"
                        class="form-select" [disableOptionCentering]="true">
                        <mat-option *ngFor="let type of aggregationTypeOptions"
                            [value]="type.value.toLowerCase()">{{type.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <div class="dropDownSelect borderSquare" *ngIf="chartSelected === 'linechart' && aggregationInterval === 'native' && deviceDetails.length === 1 && deviceType != FLOW_METER">
                <div class="chartOptionDropdown">
                    <mat-form-field appearance="none" class="operationalBtn">
                        <mat-select multiple  placeholder="View Alerts" panelClass="pannelClassTRTag" disableOptionCentering="true" [(ngModel)]="selectedOptions">
                            <!-- <div style="border-bottom: 1px solid whitesmoke; margin-block: 10px; "> -->
                                <!-- <span class="dropdownLabes">Alert Type</span> -->
                                <mat-option value="Threshold" (click)="handleAlertClassificationChange(false)">
                                    <mat-checkbox (change)="handleAlertClassificationChange()" [(ngModel)]="alertsThreshold">Threshold</mat-checkbox>
                                </mat-option>
                                <mat-option value="Stale" (click)="handleAlertClassificationChange(false)">
                                    <mat-checkbox (change)="handleAlertClassificationChange()" [(ngModel)]="alertsStale">Stale</mat-checkbox>
                                </mat-option>
                                <mat-option value="Stagnant" (click)="handleAlertClassificationChange(false)"> 
                                    <mat-checkbox (change)="handleAlertClassificationChange()" [(ngModel)]="alertsStagnant">Stagnation</mat-checkbox>
                                </mat-option>
                            <!-- </div> -->
                            <!-- <div *ngIf="aggregationInterval === 'native'">
                                <span class="dropdownLabes">Alert</span>
                                <mat-option value="Created" (click)="handleAlertClassificationChange(false)">
                                    <mat-checkbox (change)="handleAlertClassificationChange()" [(ngModel)]="alertsCreated">Created</mat-checkbox>
                                </mat-option>
                                <mat-option value="Subscribed" (click)="handleAlertClassificationChange(false)">
                                    <mat-checkbox (change)="handleAlertClassificationChange()" [(ngModel)]="alertsSubscribed">Subscribed</mat-checkbox>
                                </mat-option>
                            </div> -->
                        </mat-select>
                      </mat-form-field>
                </div>
                <!-- <mat-form-field appearance="none">
                    <mat-select [(ngModel)]="chartSelected"
                        (selectionChange)="selectChart($event.value)" [disableOptionCentering]="true">
                        <mat-option *ngIf="aggregationInterval!=='native'"
                            [value]="'barchart'">Bar Chart</mat-option>
                        <mat-option [value]="'linechart'">Line Chart</mat-option>
                        <mat-option [value]="'areachart'">Area Chart</mat-option>
                        <mat-option [value]="'tablechart'">Table Chart</mat-option>
                        <mat-option [value]="'kpicard'">KPI Cards</mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
          
        </section>
        <div id="chartAndOption">
            <section id="chart" [ngStyle]="!breakPoints.isDesktop && {'width': '100%'}">
                <div class="chart-container">
                    <div #container class="main-chart"
                        [hidden]="!((chartSelected ==='linechart' || chartSelected ==='barchart' || chartSelected ==='areachart') && !isLoader)">
                        <app-chart-data-not-found-template *ngIf="noDataFound"></app-chart-data-not-found-template>
                    </div>
                    <div [hidden]="!((chartSelected ==='tablechart') && !isLoader)">
                        <app-table-view (pageChange)="pageChange($event)" *ngIf="showTable" [widget]="widget"
                            [isLoader]="isLoader" [data]="responseData"></app-table-view>
                    </div>
                    <div [hidden]="!((chartSelected ==='kpicard') && !isLoader)">
                        <app-kpi-card *ngIf="showKpiCard" [groups]="subgroups" [responseData]="responseData"
                            (getCardType)="getCardInfo($event)"></app-kpi-card>
                    </div>
                    <div [ngStyle]="{ 'display': isLoader === true ? 'block' : 'none' }" class="chart-loader">
                        <div class="spinner">
                            <img id="loading-image" src="assets/images/91.gif" alt="Loading..." height="40"
                                width="40" />
                        </div>
                    </div>
                </div>
            </section>
            <section id="chartSelectors" *ngIf="breakPoints.isDesktop">
                <button class="chartView" *ngIf="aggregationInterval!=='native'" (click)="selectChart('barchart')"
                    [ngClass]="chartSelected === 'barchart' ? 'chartSelected' : ''">
                    <img class="barChart" src="assets/images/bar-graph.svg" width="18px">Bar Chart
                </button>
                <button class="chartView" (click)="selectChart('linechart')"
                    [ngClass]="chartSelected === 'linechart' ? 'chartSelected' : ''">
                    <img class="lineChart" src="assets/images/line-chart.svg" width="18px">Line Chart
                </button>
                <button class="chartView" (click)="selectChart('areachart')"
                    [ngClass]="chartSelected === 'areachart' ? 'chartSelected' : ''">
                    <img class="lineChart areaChart" src="assets/images/area chart icon3.svg" width="18px">Area Chart
                </button>
                <button class="chartView" (click)="selectChart('tablechart')"
                    [ngClass]="chartSelected === 'tablechart' ? 'chartSelected' : ''">
                    <img src="assets/images/table-chart.svg" width="18px">Table View
                </button>
                <button class="chartView" (click)="selectChart('kpicard')"
                    [ngClass]="chartSelected === 'kpicard' ? 'chartSelected' : ''">
                    <img src="assets/images/status-chart.svg" width="18px">KPI Cards
                </button>
                <button (click)="openAdditionalParameter()" class="addParameters button-Primary-N">
                    Add/Remove Parameter
                </button>
            </section>
        </div>
        <section id="chartLegends" *ngIf="chartSelected!=='tablechart' && chartSelected!=='kpicard' && !noDataFound"
            class="row col-12 ml-4 pl-2 chartLegends chartLegendstag">
            <app-chart-legend (legendOutput)="displayChart($event)" [subgroups]="subgroups"></app-chart-legend>
        </section>
    </section>
</div>