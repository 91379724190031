import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// import { Data } from '../entities/data';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    public initData: any = {};

    constructor() { }

    private dataSource = new BehaviorSubject(this.initData);
    public currentSector = new BehaviorSubject('');
    data = this.dataSource.asObservable();

    public updatedDataSelection(data) {
        this.dataSource.next(data);
    }

    public updateSector(sector: string){
        this.currentSector.next(sector);
    }

}