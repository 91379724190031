<div class="row page-width main-Div">
    <div class="col-lg-12">
      <div class="error-wrapper">
        <img src="https://iotsenseuat2.oci.sense.neomos.online/assets/images/logo-neom.da169c7.png" alt="Neom Logo" style="width:100px"> 
         <h2>404</h2>
         <h3>Oops 404, Page not found</h3>
         <p>The page you are looking for does not exists or some other error has occurred.
          Please go back or head over to the <a href="/home">Homepage</a>.</p>
      </div>
    </div>
</div>
  