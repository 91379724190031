import { Injectable } from '@angular/core';
import { UserAuthService } from './user-auth.service';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserManagementService } from '../access-management/services/user-management.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class AppInitializerService {
    constructor(private userAuthService: UserAuthService,
        public router: Router,
        public userManagementService: UserManagementService,
        private keycloakService: KeycloakService,
        private dialog: MatDialog) { }

    initializeApp(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            if ((localStorage.getItem('sso-user') === 'true' && localStorage.getItem('token').length > 0 && !window.location.href.includes('sso'))) {
                this.userManagementService.validateToken().subscribe(async (data: any) => {
                    if (Object.keys(data).indexOf('preferred_username') !== -1 || Object.keys(data).indexOf('email') !== -1) {
                        await this.keycloakService.init({
                            config: environment.keycloak,
                            initOptions: {
                                onLoad: 'login-required',
                                checkLoginIframe: true
                            },
                            enableBearerInterceptor: true,
                            bearerExcludedUrls: ['/assets', '/clients/public']
                        }).then((authenticated) => {
                            if (authenticated) {
                                this.userAuthService.setToken();
                                if (window.location.pathname.endsWith('user') || window.location.href.endsWith('user?returnUrl')) {
                                    this.router.navigate(['/home']);
                                    resolve(true)
                                }
                                else {
                                    history.pushState({}, null, window.location.href.split('#')[0]);
                                    resolve(true);
                                }
                            }
                        });
                    }
                    else {
                        this.logout();
                        this.router.navigate(['/user']);
                        resolve(true)
                    }
                }, (err) => {
                    this.logout();
                    this.router.navigate(['/user']);
                    resolve(true)
                })
            }
            else {
                if (localStorage.getItem('token') && localStorage.getItem('token').length > 0 && (window.location.pathname.endsWith('user') || window.location.href.endsWith('user?returnUrl'))) {
                    this.userManagementService.validateToken().subscribe(async (data: any) => {
                        this.userAuthService.loadUserInfo();
                        if (Object.keys(data).indexOf('preferred_username') !== -1 || Object.keys(data).indexOf('email') !== -1) {
                            this.router.navigate(['/home']);
                            resolve(true)
                        }
                    }, (err) => {
                        this.logout();
                        this.router.navigate(['/user']);
                        resolve(true)
                    })
                }
                else {
                    resolve(true)
                }
            }
        })
    }

    public logout() {
        localStorage.clear();
        this.dialog.closeAll();
        this.router.navigate(['/user']);
        return;
    }
}