import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ToastService } from 'src/app/services/toast/toast.service';


@Component({
  selector: 'app-marketplacedaterangepicker',
  templateUrl: './marketplacedaterangepicker.component.html',
  styleUrls: ['./marketplacedaterangepicker.component.css']
})
export class MarketplacedaterangepickerComponent implements OnInit {

  @Output()
  authorClick: EventEmitter<any> = new EventEmitter<any>(); //creating an output event

  constructor(private toastr: ToastService) { }


  public daterange: any = {};
  public minDate: any = moment("2018-09-01",'YYYY-MM-DD');
  public maxDate: any = moment("2019-02-07",'YYYY-MM-DD');

  public dateDisplay: any;
  public todaysDate: any = moment();

  // see original project for full list of options
  // can also be setup using the config service to apply to multiple pickers
  public options: any = {
    startDate: this.minDate,
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    showCustomRangeLabel:true,
    minDate: this.minDate,
    maxDate: this.maxDate
  };
  //private JSON: any;

  public selectedDate(value: any, datepicker?: any) {
    // datepicker.start = value.start;
    // datepicker.end = value.end;
    // this.dateDisplay = value.start.format('Do MMM YY') + ' - ' + value.end.format('Do MMM YY');
    // this.storeDateInLS(datepicker.start, datepicker.end);
    // this.authorClick.emit('yay'); //emmiting the event.
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.displayDate(value.start, value.end);
    const dateArr = this.storeDateInLS(datepicker.start, datepicker.end);
    this.authorClick.emit("emitted");
    //this.dateChangedSource.next(dateArr);
    // this.dateChangedService.announceDate(dateArr);
  }


  /*
      Storing date in localstorage
  */
  public storeDateInLS(startDate: any, endDate: any){
    if(endDate.diff(endDate, 'days') > 90) {
      this.toastr.warning('Please select less than 90 days');
      return;
    }
    let datearr = [];
    datearr.push(startDate.format('YYYY-MM-DD'));
    datearr.push(endDate.format('YYYY-MM-DD'));
    localStorage.setItem("dates", JSON.stringify(datearr));
    return;
  }

  public checkIfDateIsInLS(){
    if(!localStorage.getItem("dates")){
      return false;
    }
    else{
      return true;
    }
  }

  // public getDateFromLS(){
  //   let start = JSON.parse(localStorage.getItem("dates"))[0];
  //   let end = JSON.parse(localStorage.getItem("dates"))[1];
  //   return [start, end]
  // }

  ngOnInit() {
    console.log("in marketplace daterangepicker")
    //this.startdate = moment("2018-09-01",'YYYY-MM-DD');
    if(this.checkIfDateIsInLS()){
      let date = this.getDateFromLS();
      this.dateDisplay = moment(date[0]).format('Do MMM YY') + ' - ' + moment(date[1]).format('Do MMM YY');
    }
    else{
      this.dateDisplay = this.minDate.format('Do MMM YY') + ' - ' + this.maxDate.format('Do MMM YY');
    }
    const selectedDate = JSON.parse(localStorage.getItem('dates'));

    let startDate;
    let endDate;
    if (selectedDate) {
      console.log("in if")
      startDate = moment(selectedDate[0]);
      endDate = moment(selectedDate[1]);
      this.displayDate(startDate, endDate);
    } else {
      console.log('in else')
      startDate = moment(this.todaysDate).subtract(1, 'days'), moment();
      endDate = moment();
      this.displayDate(startDate, endDate);
      this.storeDateInLS(startDate, endDate);
      console.log(startDate)
    }
    let yearDate= new Date(new Date(new Date().getFullYear(),0,2))
    // @ts-ignore
    let days= Math.ceil((new Date() - yearDate ) / 86400000);
    // @ts-ignore
    let monthdays= Math.ceil((new Date()-new Date(new Date().getFullYear(),new Date().getMonth(),2))  / 86400000)
    this.options = {

      ranges: {
        /*'24 hours': [moment(this.todaysDate).subtract(1, 'days'), moment()],
        '3 days': [moment(this.todaysDate).subtract(3, 'days'), moment()],
        '7 days': [moment(this.todaysDate).subtract(7, 'days'), moment()],
        '30 Days': [moment(this.todaysDate).subtract(30, 'days'), moment()],
        'YTD':[moment(this.todaysDate).subtract(days,'days'), moment()],*/
        'Jan 2021':[moment("2021-01-01", "YYYY-MM-DD"), moment("2021-01-31", "YYYY-MM-DD")],
        'Feb 2021':[moment("2021-02-01", "YYYY-MM-DD"), moment("2021-02-28", "YYYY-MM-DD")],
        'Mar 2021':[moment("2021-03-01", "YYYY-MM-DD"), moment("2021-03-31", "YYYY-MM-DD")],
        'Apr 2021':[moment("2021-04-01", "YYYY-MM-DD"), moment("2021-04-30", "YYYY-MM-DD")],
        'May 2021':[moment("2021-05-01", "YYYY-MM-DD"), moment("2021-05-31", "YYYY-MM-DD")],
        'Jun 2021':[moment("2021-06-01", "YYYY-MM-DD"), moment("2021-06-30", "YYYY-MM-DD")],
        'Jul 2021':[moment("2021-07-01", "YYYY-MM-DD"), moment("2021-07-31", "YYYY-MM-DD")],
        'Aug 2021':[moment("2021-08-01", "YYYY-MM-DD"), moment("2021-08-31", "YYYY-MM-DD")],
        'Sep 2021':[moment("2021-09-01", "YYYY-MM-DD"), moment("2021-09-30", "YYYY-MM-DD")],
        'Oct 2021':[moment("2021-10-01", "YYYY-MM-DD"), moment("2021-10-31", "YYYY-MM-DD")],
        'Nov 2021':[moment("2021-11-01", "YYYY-MM-DD"), moment("2021-11-30", "YYYY-MM-DD")],
        'Dec 2021':[moment("2021-12-01", "YYYY-MM-DD"), moment("2021-12-31", "YYYY-MM-DD")],
        'Month to Date':[moment(this.todaysDate).subtract(monthdays,'days'), moment()],
      },
      startDate: moment(startDate),
      endDate: moment(endDate),
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: false,
      showCustomRangeLabel: false,
      minDate: this.minDate,
      // maxDate: this.maxDate
    };



    //Todo: Add tag if date contains 'st' or 'th'
    /*if(this.dateDisplay.includes('st') || this.dateDisplay.includes('th') ){
      console.log('entered in st');
      $('.applied-date').html.replace(/(st)/g, '<sup>$1</sup>');
      //console.log()
      //this.dateDisplay = this.dateDisplay.replace('st', '<sup>$1</sup>');

    }

    if($(".applied-date:contains('st')").html(function(_, html) {
      //console.log('entered in st');
      return  html.replace(/(st)/g, '<sup>$1</sup>')
    })){
    }
    if($(".applied-date:contains('th')").html(function(_, html) {
      return  html.replace(/(th)/g, '<sup>$1</sup>')
    })){
    }
    if($(".applied-date:contains('nd')").html(function(_, html) {
      return  html.replace(/(nd)/g, '<sup>$1</sup>')
    })){
    }
    if($(".applied-date:contains('rd')").html(function(_, html) {
      return  html.replace(/(rd)/g, '<sup>$1</sup>')
    })){
    }
    console.log(moment("2018-09-01",'YYYY-MM-DD'));*/

    //$('#reportrange span').html(moment(JSON.parse(localStorage.getItem("dates"))[0]).format('Do MMM YY') + ' - ' + moment(JSON.parse(localStorage.getItem("dates"))[1]).format('Do MMM YY'));


  }


  public displayDate(startDate, endDate) {
    let d1;
    d1 = moment(endDate.format('YYYY-MM-DD'));
    d1 = d1.toDate();
    let d2;
    d2 = moment(this.todaysDate.format('YYYY-MM-DD'));
    d2 = d2.toDate();
    let d3;
    d3 = moment(startDate.format('YYYY-MM-DD'));
    d3 = d3.toDate();
    console.log(d3)
    let setDate=new Date(new Date().getFullYear(),0,1);
    console.log(setDate)
    let daysDifference

    if(d1.getTime() === d2.getTime()) {
      /*if(d3.getTime()===setDate.getTime()){
        this.dateDisplay = `Year to Date`;
        return;
      }*/
      if(d3.getTime() === new Date(new Date().getFullYear(),new Date().getMonth(),1).getTime()){
        this.dateDisplay = `Month to Date`;
        return;
      }
      daysDifference = endDate.diff(startDate, 'days');
      this.dateDisplay = `Last ${daysDifference} ${daysDifference > 1 ? 'Days' : 'Day'}`;
      return;
    }
    //this.dateDisplay = startDate.format('Do MMM YY') + ' - ' + endDate.format('Do MMM YY');
    this.dateDisplay = startDate.format('MMM YYYY');
    return;
  }





  public getDateFromLS() {
    if (localStorage.getItem('dates') !== null) {
      const start = JSON.parse(localStorage.getItem('dates'))[0];
      const end = JSON.parse(localStorage.getItem('dates'))[1];
      return [start, end];
    } else {
      // TODO: A little repetitive else block can be improved
      this.options.startDate = this.minDate;
      this.options.endDate = this.maxDate;
      this.dateDisplay = this.minDate.format('Do MMM YY') + ' - ' + this.maxDate.format('Do MMM YY');
      this.storeDateInLS(this.minDate, this.maxDate);
      return [this.minDate.format('YYYY-MM-DD'), this.maxDate.format('YYYY-MM-DD')];
    }
  }

}
