<div class="main-Div">
    <div class="assetPageWrapper">
        <div class="assetBackArrow">
            <div class="backArrowDiv buttonShadow">
                <img class="backArrow" (click)="back()" src="assets/images/backArrow.svg">
            </div>
            <h1 class="align-self-center heading">{{this.searchTerm | attributeRename | titlecase}}</h1>
        </div>
        <div style="" class="d-flex align-items-center assetOptions">
            <div *ngIf="nativeDataDownloadLoader">
                <div matTooltip="Data is getting downloaded" class="spinnerSave" matTooltipPosition="above"
                    matTooltipClass="above">
                    <img id="loading-image" src="assets/images/91.gif" alt="Loading..." height="20"
                        width="20" />
                </div>
            </div>
           
            <div id="tagOptions">
                <div id="showRange" *ngIf="chartSelected ==='linechart'">
                    <span>Split Y-axis</span>
                    <mat-slide-toggle (change)="enableSplitYAxis()" [checked]="splitYAxis"></mat-slide-toggle>
                </div>
                <button (click)="downloadData()" class="button-Secondary-N downloadBtn"><img src="assets/Neom-Images/download.svg"
                        width="10px"> Export Data</button>
                <button (click)="saveProject()" class="button-Primary-N h-40" >Save</button>
            </div>
        </div>
    </div>
    <div class="chart">
        <div class="pageDatepicker">
            <div class="assetDatepickerRow">
                <div class="commonDatepicker borderSquareDatePicker">
                    <app-common-datepicker [dateSelected]="[startDate,endDate]"
                        (dateChange)="datepicker($event)"></app-common-datepicker>
                </div>
                <div class="commonDatepicker">
                    <mat-form-field appearance="none"> 
                        <img src="assets/images/time.svg" class="dropdowmIcons" width="17px">
                        <mat-select [(ngModel)]="aggregationInterval" (selectionChange)="intervalChange($event)"
                            class="form-select" [disableOptionCentering]="true" panelClass="chartNativeClass">
                            <mat-option *ngFor="let interval of aggregationIntervalOptions"
                                value="{{interval.value.toLowerCase()}}">{{interval.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="commonDatepicker" *ngIf="aggregationInterval!='native'">
                    <mat-form-field appearance="none">
                        <img src="assets/images/min-max.png" class="dropdowmIcons" width="16px" >
                        <mat-select [(ngModel)]="aggregationType" (selectionChange)="typeChange($event)"
                            class="form-select" [disableOptionCentering]="true" panelClass="chartNativeClass">
                            <mat-option *ngFor="let type of aggregationTypeOptions"
                                [value]="type.value.toLowerCase()">{{type.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="tittleRow">
                <label id="legendTitle"></label>
            </div>
        </div>

        <div class="chartSection">
            <div class="chart-container">
                <div #container class="main-chart"
                    [hidden]="!((chartSelected ==='linechart' || chartSelected ==='barchart' || chartSelected === 'areachart') && !isLoader)">
                    <app-chart-data-not-found-template *ngIf="noDataFound"></app-chart-data-not-found-template>

                </div>
                <div [hidden]="!(chartSelected ==='tablechart')">
                    <app-table-view (pageChange)="pageChange($event)" *ngIf="showTable" [widget]="widget"
                        [isLoader]="isLoader" [data]="responseData"></app-table-view>
                </div>
                <div [hidden]="!((chartSelected ==='kpicard') && !isLoader)">
                    <app-kpi-card *ngIf="showKpiCard" [groups]="subgroups" [responseData]="responseData"
                        (getCardType)="getCardInfo($event)"></app-kpi-card>
                </div>
                <div [ngStyle]="{ 'display': isLoader === true ? 'block' : 'none' }" class="chart-loader">
                    <div class="spinner">
                        <div class="loader"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="chartBtnContainer">
            <div *ngIf="aggregationInterval!='native'" class="chartOption" (click)="selectChart('barchart')"
                [ngClass]="chartSelected === 'barchart' ? 'chartSelected' : ''">
                <div class="imgSquare">
                    <img class="barChart" src="assets/images/bar-chart.svg">
                </div>
                <div class="option">Bar Chart</div>
            </div>
            <div class="chartOption" (click)="selectChart('linechart')"
                [ngClass]="chartSelected === 'linechart' ? 'chartSelected' : ''">
                <div class="imgSquare">
                    <img class="lineChart" src="assets/images/line-chart.svg">
                </div>
                <div class="option">Line Chart</div>
            </div>
            <div class="chartOption"  (click)="selectChart('areachart')"
                [ngClass]="chartSelected === 'areachart' ? 'chartSelected' : ''">
                <div class="imgSquare">
                    <img class="lineChart" src="assets/images/line-chart.svg">
                </div>
                <div class="option">Area Chart</div>
            </div>
            <div class="chartOption" (click)="selectChart('tablechart')"
                [ngClass]="chartSelected === 'tablechart' ? 'chartSelected' : ''">
                <div class="imgSquare">
                    <img class="tablechart" src="assets/images/table-chart.svg">
                </div>
                <div class="option">Table View</div>
            </div>
            <div class="chartOption" (click)="selectChart('kpicard')"
                [ngClass]="chartSelected === 'kpicard' ? 'chartSelected' : ''">
                <div class="col-2.7 imgSquare">
                    <img class="lineChart" src="assets/images/status-chart.svg">
                </div>
                <div class="col-9 option">KPI Cards</div>
            </div>
            <!-- <div class="row chartOption" (click)="selectChart('statuschart')"
            [ngClass]="chartSelected === 'statuschart' ? 'chartSelected' : ''">
            <div class="col-2.7 imgSquare">
                <img class="statusChart" src="assets/images/status-chart.svg">
            </div>
            <div class="col-9 option">Status Chart</div>
        </div> -->
            <div>
                <button (click)="openAdditionalParameter()" class="addParameters button-Primary-N">
                    Add/Remove Parameter
                </button>
            </div>
        </div>
        <div *ngIf="chartSelected!=='tablechart' && chartSelected!=='kpicard' && !noDataFound" class="row col-12 ml-4 pl-2 chartLegends">
            <app-chart-legend (legendOutput)="displayChart($event)" [subgroups]="subgroups"></app-chart-legend>
        </div>
    </div>
</div>