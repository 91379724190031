<form>
    <mat-dialog-title>
        <div class="deviceformHeading">
            <h4>Bulk Register</h4>
            <img src="assets/images/cross.svg" class="crossImg" mat-dialog-close>
        </div>
    </mat-dialog-title>
    <mat-dialog-content class="mat-typography profileHeight">
        <div class="formSpacing">
            <span class="label-text">Select Profile </span> <span class="asterick">*</span>
            <div class="selector formField">
                <mat-form-field appearance="outline" floatLabel=never>
                    <mat-select [disableOptionCentering]="true" name="profileId" placeholder="Select Profile"
                        [(ngModel)]="profile" (selectionChange)="profileChange()">
                        <mat-option *ngFor="let profile of profiles;let i=index"
                            value="{{profile.id}}">{{profile.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="formSpacing uploadField">
            <div>
                <span class="label-text">Upload </span><span class="asterick">*</span>
            </div>

            <button *ngIf="profile" (click)="downloadSample()" class="button-Secondary-N downlink"
                matTooltip="Sample Download" matTooltipPosition="above" matTooltipClass="above">
                <img src="assets/Neom-Images/download-bulk.svg"
                    class="downloadIcon"> <span class="downloadText">Download Template </span>
            </button>
        </div>
        <ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="inputField" [disabled]="!profile">
            <ngx-dropzone-label>
                <div class="fileUploader">
                    <img src="assets/images/upload-icon.svg" class="uploadIcon" />
                    <p>Drag & Drop or <span class="blueText">Choose file</span> to upload<br><span
                            class="greyText">CSV</span>
                    </p>
                </div>
            </ngx-dropzone-label>
        </ngx-dropzone>

        <div class="fileProgress" *ngIf="file">
            <div class="uploadData">
                <div class="fileName">
                    <img src="assets/images/do-icon.svg" class="doIcon">
                    <span>{{file.name}} {{file.type}}</span>
                    <span class="uploadFile">{{progressValue === 100 ? 'Uploaded' : ''}}</span>
                </div>
               
                <div>
                    <button class="crossButton" (click)="deleteFile()"><img src="assets/Neom-Images/cross-icon.svg"></button>
                </div>
            </div>
            <div>
                <div class="percentage">
                    {{progressValue}}%
                </div>
                <div class="progress">
                    <div [ngStyle]="{'width':progressValue+'%'}" style="background-color: #e4bf32;"></div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <div class="popupBtn">
        <button class="button-Primary-N buttonYellow" [disabled]="loading" (click)="bulkRegistrationOfDevices()">
            <i class="fa" [ngClass]="{'fa-spin fa-spinner mr-2': loading}"></i>
            Register
        </button>
        <button class="button-Tertiary-N buttonWhite cancelBtn" mat-dialog-close>Cancel</button>
    </div>
</form>