import { Component, OnInit } from '@angular/core';
import { waterCommercial, waterIndustrials } from 'src/app/reusable/constant';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAuthService } from '../../sso/user-auth.service';
import { ElasticSearchService } from 'src/app/reusable/services/elastic-search.service';



@Component({
  selector: 'app-sector-kpicards',
  templateUrl: './sector-kpicards.component.html',
  styleUrls: ['./sector-kpicards.component.css','../../../../assets/Reusable-CSS/main.scss']
})
export class SectorKpicardsComponent implements OnInit {

  public defaultDashboard: any;
  dashboard: {
    name: string; icon: string; icon2: string; option: ({ name: string; option: { name: string; description: string; route: string; icon: string; heading: string; }[]; } | {
      name: string; option: {
        name: string; description: string; tooltip: string; isDisable: boolean;
        // route: '/',
        icon: string; heading: string;
      }[];
    })[];
  };
  option: { name: string; option: { name: string; description: string; route: string; icon: string; heading: string; }[]; } | {
    name: string; option: {
      name: string; description: string; tooltip: string; isDisable: boolean;
      // route: '/',
      icon: string; heading: string;
    }[];
  };
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private elasticSearchService: ElasticSearchService,
    public userAuthService: UserAuthService
  ) { }

  ngOnInit(): void {
    this.defaultDashboard = this.userAuthService.defaultDashboard;
    let meterOptionType;
    this.elasticSearchService.meterOptionType.subscribe(v => {
      meterOptionType = v;
    });
    if (this.router.url.includes('/watermeters/customer')) {
      this.dashboard = this.defaultDashboard.filter(v => v.name === 'water')[0];
      this.option = meterOptionType === 'Customer' ?
        this.defaultDashboard[0].option[0] : this.defaultDashboard[0].option[0];
    }
    else if (this.router.url.includes('/watermeters/industrial')) {
      this.dashboard = this.defaultDashboard.filter(v => v.name === 'water')[0];
      this.option = meterOptionType === 'Customer' ?
        this.defaultDashboard[0].option[0] : this.defaultDashboard[0].option[1];

    } else if (this.router.url.includes('/energymeters/customer')) {
      this.dashboard = this.defaultDashboard.filter(v => v.name === 'energy')[0];
      this.option = meterOptionType === 'Customer' ?
        this.defaultDashboard[1].option[0] : this.defaultDashboard[1].option[0];

    }
    else if (this.router.url.includes('/energymeters/industrial')) {
      this.dashboard = this.defaultDashboard.filter(v => v.name === 'energy')[0];
      this.option = meterOptionType === 'Customer' ?
        this.defaultDashboard[1].option[0] : this.defaultDashboard[1].option[1];

    }
  }

  back() {
    this.router.navigate(['']);
  }

}
