import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor(private apiService: ApiService) { }

  public getAllDeviceTypes() {
    return this.apiService.get(`/generic/get-all-device-types`);
  }
}
