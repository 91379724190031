import { Injectable } from '@angular/core';
import { loadModules } from "esri-loader";
import { ElementRef } from '@angular/core';
import { MapInfo } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class ArcgisMapService {
  private map: any;
  private view: any;
  private recenterIcon:any
  mapInfo:MapInfo

  constructor() { }

  async initializeMap(mapViewEl: ElementRef, mapInfo:MapInfo): Promise<void> {
    try {
      const [esriConfig, Map, MapView, Fullscreen, Zoom] = await loadModules([
        "esri/config",
        'esri/Map',
        'esri/views/MapView',
        "esri/widgets/Fullscreen",
        "esri/widgets/Zoom"
      ]);
      esriConfig.apiKey = "AAPK42c8946bf93b4d33b26315762163300af2a7iancfHwc19TRSo35thrPVaa1vCkSW4W718K72WpLRqT5BbYFE2Wvz5E2zHFJ"

      this.map = new Map({
        basemap: 'osm-streets-relief'
      });

      this.mapInfo = mapInfo;

      this.view = new MapView({
        container: mapViewEl.nativeElement,
        map: this.map,
        center: this.mapInfo.center,
        zoom: this.mapInfo.zoom
      });

      // Add the full screen option to map
      const fullscreen = new Fullscreen({
        view: this.view
      });

      // added fullscreen widget
      this.view.ui.add(fullscreen, {
        position:"bottom-right",
        index: 2
      });

      // added recenter icon widget
      const recenterIcon = this.getRecenterIcon();

      this.view.ui.add(recenterIcon, {
        position: "bottom-right",
        index: 0
      });

      this.view.ui.remove('zoom');

      const zoomWidget = new Zoom({
        view: this.view
      });
   
      // Add the zoom widget to the top right corner of the view
      this.view.ui.add(zoomWidget, {
        position: 'bottom-right',
        index: 1
      });

      return this.view.when().then(() => {
        console.log('Map is ready');
      });
      

    } catch (error) {
      console.error('Error loading the ArcGIS modules:', error);
    }
  }

  setMapInfo(mapInfo) {
    this.mapInfo = mapInfo;
    this.reCentre(this.mapInfo);
  }

  async reCentre(mapInfo:MapInfo) {
    const [Point] = await loadModules([
      "esri/geometry/Point"
    ]);
    const centerPoint = new Point({
      longitude: mapInfo.center[0],
      latitude: mapInfo.center[1],
      spatialReference: { wkid: 4326 }  // WGS84 spatial reference
    });
    this.view.goTo({
      center: centerPoint,
      zoom: mapInfo.zoom
    }, {
      duration: 1500,
      easing: 'easy-in-out'
    }
     
  )
    // this.view.center = mapInfo.center;
    // this.view.zoom = mapInfo.zoom;
  }

  getRecenterIcon() {
    this.recenterIcon = document.createElement('div');
    this.recenterIcon.className = 'custom-icon';
    this.recenterIcon.style.backgroundImage = 'url("assets/images/recenter.svg")';
    this.recenterIcon.style.backgroundSize = 'contain';
    this.recenterIcon.style.width = '32px';
    this.recenterIcon.style.height = '32px';
    this.recenterIcon.style.cursor = 'pointer';
    this.recenterIcon.style.backgroundSize = '60%';
    this.recenterIcon.style.backgroundRepeat =  'no-repeat';
    this.recenterIcon.style.backgroundPosition = 'center center';
    this.recenterIcon.style.backgroundColor = 'white';
    this.recenterIcon.addEventListener('click', () => {
      this.reCentre(this.mapInfo);
    })
    return this.recenterIcon;
  }

  getMapInstance(): any {
    return this.map;
  }

  getViewInstance(): any {
    return this.view;
  }

}
