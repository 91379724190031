import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TAG_DEVICES, TELEMETRY } from '../constant';
import { noDataFoundSubTitle, noDataFoundTitle } from 'src/app/modules/watermeters-industrial/pumpstation-dashboard/constant';

@Component({
  selector: 'search-attribute-results',
  templateUrl: './search-attribute-results.component.html',
  styleUrls: [
    './search-attribute-results.component.css',
    '../../../assets/Reusable-CSS/matTooltip.scss'
  ]
})
export class SearchAttributeResultsComponent implements OnInit {

  @Input() attributeList = [];
  @Input() data = [];
  @Input() filteredRecordsCount: number;
  @Input() isLoader = false;
  @Output() updateAttributeList = new EventEmitter<any>();
  @Input() isNotFound = false;

  noDataFoundTitle: string = noDataFoundTitle;
  noDataFoundSubTitle: string = noDataFoundSubTitle;

  constructor() { }

  ngOnInit(): void {
  }

  selectAttribute(checked: boolean, data: any) {
    const { id, attribute, deviceID, deviceType, path,tags, description, manufacturerName, site} = data;
    const name = data?.displayName || data?.name;
    const payload = {
      id,
      name,
      deviceId: `${deviceID}`,
      deviceType,
      dataType: TELEMETRY,
      path,
      deviceID,
      tags,
      manufacturerName,
      site,
      description,
      display : true
    }
    if (checked) {
      this.attributeList.push(payload);
    } else {
      this.attributeList = this.attributeList.filter(x => x.id !== payload.id);
    }
    this.updateAttributeList.emit(this.attributeList);
  }

  getTagName(device:any) {
    const {tags, deviceType, uplinkReferenceKey } = device;
      if(tags.length > 1 && TAG_DEVICES.includes(deviceType)) {
        return `${tags[tags.length - 1]}`
      }
      else {
        return  `SR.No : ${uplinkReferenceKey}`;
      }
    }
}
