import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastComponent } from 'src/app/reusable/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastr: ToastrService) { }

  success(message: string) {
    this.toastr.show(message, null, {
      toastComponent: ToastComponent,
      positionClass: 'toast-top-right',
      closeButton: true,
      toastClass: 'success',
    });
  }

  error(message: string) {
    this.toastr.show(message, null, {
      toastComponent: ToastComponent,
      positionClass: 'toast-top-right',
      closeButton: true,
      toastClass: 'error'
    });;
  }

  warning(message: string) {
    this.toastr.show(message, null, {
      toastComponent: ToastComponent,
      positionClass: 'toast-top-right',
      closeButton: true,
      toastClass: 'warning'
    });;
  }

  info(message: string) {
    this.toastr.show(message, null, {
      toastComponent: ToastComponent,
      positionClass: 'toast-top-right',
      closeButton: true,
      toastClass: 'info'
    });;
  }
}
