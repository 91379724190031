import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ElasticSearchService {

  public meterOptionType = new BehaviorSubject(null);

  constructor(
    private apiService: ApiService
  ) { }

  public searchSuggestion({query}) {
    return this.apiService.get(`/elastic-search/search-suggestion?query=${query}`);
  }

  public attributeTextSearch(postData, pageSize: number, pageNumber: number) {
    return this.apiService.post(`/elastic-search/get-attribute-text-search?pageSize=${pageSize}&pageNumber=${pageNumber}`, postData);
  }

  public getTelemetryParams(deviceType: string) {
    return this.apiService.get(`/elastic-search/device-telemetry-parameters?deviceType=${deviceType}`);
  }

  public getRegistrationParams(deviceType: string) {
    return this.apiService.get(`/elastic-search/device-registration-parameters?deviceType=${deviceType}`);
  }

  public getProfileParams() {
    return this.apiService.get(`/elastic-search/device-profile-parameters`);
  }
}
