import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { whiteListedAttributes, deviceTypeData, locationData } from 'src/app/reusable/dataSource';
import { ElasticSearchService } from 'src/app/reusable/services/elastic-search.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css', '../../../assets/Reusable-CSS/main.scss', '../../../assets/Reusable-CSS/buttons.scss', '../../../assets/Reusable-CSS/mat.scss']
})
export class SearchResultComponent implements OnInit {

  public searchTerm: string;
  public results = [];
  public attributeList = [];
  public panelOpenState: boolean;
  public deviceTypeSelectedList: string[] = [];
  public locationSelectedList: string[] = [];
  public activeFilterCount = 0;
  public whiteListedAttributes = whiteListedAttributes;
  public isLoader = true;
  public filterSearchTerm: string;

  currentPage = 1;
  totalRecordsCount = 0;
  totalPageCount = 0;
  pageSize = 10;
  pageSizeOption = [10, 15, 20, 25];
  filteredRecordsCount;

  subscription: Array<Subscription> = [];;

  constructor(private sharedService: SharedServiceService, private router: Router,
    private toastr: ToastService,
    private location: Location,
    private elasticSearchservice: ElasticSearchService) { }

  ngOnInit() {
    const subscribe = this.sharedService.searchTerm.subscribe((data: string) => {
      this.searchTerm = data;
      // if we refresh the page get the latest search term from local storage
      if (!this.searchTerm) {
        this.searchTerm = localStorage.getItem('searchTerm');
      }
      this.attributeList = [];
      this.searchResult();
    })
    this.subscription.push(subscribe);
  }

  searchResult() {
    this.isLoader = true;
    const payload = {
      query: this.searchTerm,
      filter: {
        deviceType: this.deviceTypeSelectedList?.length ? this.deviceTypeSelectedList : null,
        location: this.locationSelectedList?.length ? this.locationSelectedList : null
      }
    };
    const subscribe = this.elasticSearchservice.attributeTextSearch(payload, this.pageSize, this.currentPage)
      .subscribe((data: any) => {
        data?.currentValues.forEach(element => {
          element.isSeleted = this.attributeList.find(x => x.id === element.id);
        });
        this.isLoader = false;
        if (data) {
          this.results = data?.currentValues.filter((result: any) => {
            // if (this.whiteListedAttributes.includes(result?.path)) {
            return result;
            // }
            return;
          });
          this.totalPageCount = data.totalNumberOfPages;
          this.totalRecordsCount = data.totalNumberOfRecords;
          this.filteredRecordsCount = data.filteredRecords;

        } else {
          this.results = [];
          // this.toastr.error("No results found");
        }
      })
      this.subscription.push(subscribe);
  }

  clearResults() {
    this.sharedService.updateSearchTerm('');
    this.searchTerm = null;
    this.router.navigateByUrl('/home');
  }


  searchAttribute() {
    if (this.attributeList?.length) {
      this.sharedService.attributeList = this.attributeList;
      this.sharedService.attributeList?.forEach(item => {
        const tag = this.sharedService?.statusDateList?.find(x => x.deviceID === item.deviceID);
        if (tag) {
          item.enumeritation = tag.enumeration;
          item.path = 'content.value'
        }
      })
      this.router.navigateByUrl('/asset-result');
    }
  }

  updateAttributeList(event) {
    this.attributeList = event;
  }

  updateSearchResult(event) {
    this.deviceTypeSelectedList = event.deviceTypeSelectedList;
    this.locationSelectedList = event.locationSelectedList;
    this.handlePageChange(1);
  }

  back() {
    this.location.back()
  }

  handlePageChange(pageNo) {
    this.currentPage = pageNo;
    this.searchResult();
  }

  tablePageSizeChange(itemPerPage) {
    this.pageSize = itemPerPage;
    this.handlePageChange(1);
  }

  ngOnDestroy() {
    if (this.subscription.length) {
      this.subscription.forEach(item => item.unsubscribe());
    }
  }
}
