import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { ToastService } from 'src/app/services/toast/toast.service';


@Component({
  selector: 'app-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.css']
})
export class DaterangepickerComponent implements OnInit {

  @Output()
  authorClick: EventEmitter<any> = new EventEmitter<any>(); //creating an output event

  constructor(private toastr: ToastService) { }


  public daterange: any = {};
  public minDate: any = moment("2018-09-01",'YYYY-MM-DD');
  public maxDate: any = moment("2019-02-07",'YYYY-MM-DD');

  public dateDisplay: any;
  public todaysDate: any = moment();

  // see original project for full list of options
  // can also be setup using the config service to apply to multiple pickers
  public options: any = {
    startDate: this.minDate,
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
    showCustomRangeLabel:true,
    minDate: this.minDate,
    maxDate: this.todaysDate
  };
  //private JSON: any;

  public selectedDate(value: any, datepicker?: any) {
    // datepicker.start = value.start;
    // datepicker.end = value.end;
    // this.dateDisplay = value.start.format('Do MMM YY') + ' - ' + value.end.format('Do MMM YY');
    // this.storeDateInLS(datepicker.start, datepicker.end);
    // this.authorClick.emit('yay'); //emmiting the event.
    datepicker.start = value.start;
    datepicker.end = value.end;
    this.displayDate(value.start, value.end);
    const dateArr = this.storeDateInLS(datepicker.start, datepicker.end);
    this.authorClick.emit("emitted");
    //this.dateChangedSource.next(dateArr);
   // this.dateChangedService.announceDate(dateArr);
  }


  /*
      Storing date in localstorage
  */
  public storeDateInLS(startDate: any, endDate: any){
    if(endDate.diff(endDate, 'days') > 90) {
      this.toastr.warning('Please select less than 90 days');
      return;
    }
    let datearr = [];
    datearr.push(startDate.format('YYYY-MM-DD'));
    datearr.push(endDate.format('YYYY-MM-DD'));
    localStorage.setItem("dates", JSON.stringify(datearr));
    return;
  }

  public checkIfDateIsInLS(){
    if(!localStorage.getItem("dates")){
      return false;
    }
    else{
      return true;
    }
  }

      // public getDateFromLS(){
      //   let start = JSON.parse(localStorage.getItem("dates"))[0];
      //   let end = JSON.parse(localStorage.getItem("dates"))[1];
      //   return [start, end]
      // }

  ngOnInit() {
    //this.startdate = moment("2018-09-01",'YYYY-MM-DD');
    if(this.checkIfDateIsInLS()){
      let date = this.getDateFromLS();
      this.dateDisplay = moment(date[0]).format('Do MMM YY') + ' - ' + moment(date[1]).format('Do MMM YY');
    }
    else{
      this.dateDisplay = this.minDate.format('Do MMM YY') + ' - ' + this.maxDate.format('Do MMM YY');
    }
    const selectedDate = JSON.parse(localStorage.getItem('dates'));

    let startDate;
    let endDate;
    if (selectedDate) {
      startDate = moment(selectedDate[0]);
      endDate = moment(selectedDate[1]);
      this.displayDate(startDate, endDate);
    } else {
      startDate = moment(this.todaysDate).subtract(1, 'days'), moment();
      endDate = moment();
      this.displayDate(startDate, endDate);
      this.storeDateInLS(startDate, endDate);
    }
let yearDate= new Date(new Date(new Date().getFullYear(),0,2))
    // @ts-ignore
    let days= Math.ceil((new Date() - yearDate ) / 86400000);
    // @ts-ignore
    let monthdays= Math.ceil((new Date()-new Date(new Date().getFullYear(),new Date().getMonth(),2))  / 86400000)
    this.options = {

      ranges: {
        '24 Hours': [moment(this.todaysDate).subtract(1, 'days'), moment()],
        '3 Days': [moment(this.todaysDate).subtract(3, 'days'), moment()],
        '7 Days': [moment(this.todaysDate).subtract(7, 'days'), moment()],
        '30 Days': [moment(this.todaysDate).subtract(30, 'days'), moment()],
        'YTD':[moment(this.todaysDate).subtract(days,'days'), moment()],
        'MTD':[moment(this.todaysDate).subtract(monthdays,'days'), moment()],

      },
      startDate: moment(startDate),
      endDate: moment(endDate),
      locale: { format: 'YYYY-MM-DD' },
      alwaysShowCalendars: true,
      showCustomRangeLabel: true,
      minDate: this.minDate,
      maxDate: this.todaysDate
    };


  }


  public displayDate(startDate, endDate) {
    let d1;
    d1 = moment(endDate.format('YYYY-MM-DD'));
    d1 = d1.toDate();
    let d2;
    d2 = moment(this.todaysDate.format('YYYY-MM-DD'));
    d2 = d2.toDate();
    let d3;
    d3 = moment(startDate.format('YYYY-MM-DD'));
    d3 = d3.toDate();
    let setDate=new Date(new Date().getFullYear(),0,1);
    const daysDifference = endDate.diff(startDate, 'days'); 

    if (d1.getTime() === d2.getTime() && daysDifference !== 0) {
      if(d3.getTime()===setDate.getTime()){
        this.dateDisplay = `Year to Date`;
        return;
      }
      if(d3.getTime() === new Date(new Date().getFullYear(),new Date().getMonth(),1).getTime()){
        this.dateDisplay = `Month to Date`;
        return;
      }
      // this.dateDisplay = `Last ${daysDifference} ${daysDifference > 1 ? 'Days' : 'Day'}`;
      // return;
      this.dateDisplay = startDate.format('Do MMM YY') + ' - ' + endDate.format('Do MMM YY');

    }
    this.dateDisplay = startDate.format('Do MMM YY') + ' - ' + endDate.format('Do MMM YY');
    return;
  }





  public getDateFromLS() {
    if (localStorage.getItem('dates') !== null) {
      const start = JSON.parse(localStorage.getItem('dates'))[0];
      const end = JSON.parse(localStorage.getItem('dates'))[1];
      return [start, end];
    } else {
      // TODO: A little repetitive else block can be improved
      this.options.startDate = this.minDate;
      this.options.endDate = this.maxDate;
      this.dateDisplay = this.minDate.format('Do MMM YY') + ' - ' + this.maxDate.format('Do MMM YY');
      this.storeDateInLS(this.minDate, this.maxDate);
      return [this.minDate.format('YYYY-MM-DD'), this.maxDate.format('YYYY-MM-DD')];
    }
  }

}
