import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { deviceTypeData, locationData, whiteListedAttributes } from '../dataSource';
import { GenericService } from '../services/generic.service';
import { RegistrationService } from '../services/registration.service';
import { forkJoin, from } from 'rxjs';
import { METERS } from '../constant';

@Component({
  selector: 'search-attribute-filter',
  templateUrl: './search-attribute-filter.component.html',
  styleUrls: [
    './search-attribute-filter.component.css',
    '../../../assets/Reusable-CSS/buttons.scss',
    '../../../assets/Reusable-CSS/mat.scss',
    '../../../assets/Reusable-CSS/main.scss'
  ]
})
export class SearchAttributeFilterComponent implements OnInit {

  public whiteListedAttributes = whiteListedAttributes;
  public deviceTypes: string[] = [];
  public locationData: string[] = [];
  public filteredLocationData: string[] = []; 
  public filterSearchTerm:string = "";
  public deviceTypeSelectedList: string[] = [];
  public locationSelectedList: string[] = [];
  public locationLoader: boolean = false;
  public allSelected: boolean = false;
  @Output() updateSearchResult = new EventEmitter<any>();
  @Input() showIcon : boolean = true;
  @Input() showText : boolean = true

  constructor(
    private genericService: GenericService,
    private registrationService: RegistrationService
  ) { }

  ngOnInit(): void {
    this.getAllDeviceTypes();
  }

  addDeviceTypeFilter(value: string, checked: boolean) {
    if (checked) {
      this.deviceTypeSelectedList.push(value);
    } else {
      this.deviceTypeSelectedList = this.deviceTypeSelectedList.filter((device) => device !== value);
    }
    this.emitSearchResult();
  }

  addLocationFilter(value: string, checked: boolean) {
    if (checked) {
      this.locationSelectedList.push(value);
    } else {
      this.locationSelectedList = this.locationSelectedList.filter((location) => location !== value);
    }
    this.updateSelectAllState();
    this.emitSearchResult();
  }

  onSearch() {
    if (!this.filterSearchTerm?.trim()) {
      this.filteredLocationData = [...this.locationData];
    } else {
      const searchTerm = this.filterSearchTerm.trim().toLowerCase();
      this.filteredLocationData = this.locationData.filter(location => 
        location.toLowerCase().includes(searchTerm)
      );
    }
    this.updateSelectAllState();
  }

  clearFilter() {
    this.filterSearchTerm = '';
    this.locationSelectedList = [];
    this.deviceTypeSelectedList = [];
    this.filteredLocationData = [...this.locationData];
    this.emitSearchResult();
  }

  getAllDeviceTypes() {
    this.genericService.getAllDeviceTypes().subscribe((data: any) => {
      this.deviceTypes = data;
      this.getAllLocations();
    });
  }

  getAllLocations() {
    this.locationLoader = true;

    const observables = this.deviceTypes
      .filter(meter => METERS.includes(meter))  // Filter device types to include only those in METERS
      .map(meter => from(this.getAlertFilterCount(meter)));  // Map each meter to an observable from getAlertFilterCount

    // Use forkJoin to execute all observables in parallel and wait for all to complete
    forkJoin(observables).subscribe({
      next: results => {
        // Iterate through each result
        results.forEach((result: any) => {
          // Append measurable locations from the result to the locationData array
          this.locationData.push(...result.measurableLocation);
        });
        // Remove duplicate locations using a Set and convert back to an array
        this.locationData = Array.from(new Set(this.locationData));
        // Copy the unique locations to filteredLocationData
        this.filteredLocationData = [...this.locationData];
        this.locationLoader = false;
      },
      error: err => {
        console.error('An error occurred:', err);
        this.locationLoader = false;
      }
    });
  }

  getAlertFilterCount(meterType) {
    return this.registrationService.GetFilterTypeAndCount(meterType);
  }

  toggleSelectAll(isChecked: boolean) {
    if (isChecked) {
      // Add all locations from filteredLocationData to locationSelectedList, ensuring no duplicates using a Set
      this.locationSelectedList = Array.from(new Set([...this.locationSelectedList, ...this.filteredLocationData]));
    } else {
      // Remove all locations from filteredLocationData in locationSelectedList
      this.locationSelectedList = this.locationSelectedList.filter(location => !this.filteredLocationData.includes(location));
    }
    this.updateSelectAllState();
    this.emitSearchResult();
  }

  updateSelectAllState() {
    const selectedLocationsSet = new Set(this.locationSelectedList);
    this.allSelected = this.filteredLocationData.every(location => selectedLocationsSet.has(location));
  }

  getAppliedFiltersLength(): string {
    const totalLength = this.locationSelectedList.length + this.deviceTypeSelectedList.length;
    return totalLength > 9 ? "9+" : totalLength.toString();
  }

  emitSearchResult() {
    this.updateSearchResult.emit({
      deviceTypeSelectedList: this.deviceTypeSelectedList,
      locationSelectedList: this.locationSelectedList
    });
  }

}
