<div class="row page-width main-Div">
    <div class="col-lg-12">
      <div class="error-wrapper">
        <img src="assets/images/logo-neom.da169c7.png" alt="Neom Logo" style="width:100px"> 
         <h2>403</h2>
         <h3>Forbidden</h3>
         <p>You don't have permission to access this resource
          Please go back or head over to the <a href="/home">Homepage</a>.</p>
      </div>
    </div>
</div>
  