import {Injectable} from "@angular/core";
import { COLOR } from "../reusable/chart-util/chart-config";

@Injectable({
  providedIn: "root"
})
export class EchartGraphsService {

  constructor() {
  }

  public barGraph(title: string, legend: any, xAxis: any, yAxis: any, series: any) {
    const option = {
      title: {
        text: title,
        subtext: "",
        x: "centre",
        bottom: 0,
        left: 200,
        textStyle: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: 20,
          fontStyle: "normal",
          fontWeight: "400",
          color: "#333"
        }
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      legend: {
        x: "right",
        top: 0,
        data: legend
      },
      grid: {
      /*  top: 60,
        left: 10,
        right: 10,
        bottom: 10,*/
        left: 30,
        containLabel: true
      },

      calculable: true,
      xAxis: this.lightOrDarkAxisLine(xAxis),
      yAxis: this.lightOrDarkAxisLine(yAxis),
      "series": series
    };
    return option;
  }

  public homeBarGraph(yAxis: any, title: string, data: any, color: string, barWidth: any) {
    const option = {
      title: {
        text: title,
        left: "center",
        top: 0,
        textStyle: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: 20,
          fontStyle: "normal",
          fontWeight: "400",
          color: "#333"
        }
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      grid: {
        top: 50,
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      yAxis: {
        type: "category",
        data: yAxis,
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      series: [
        {
          type: "bar",
          "data": data,
          "color": color,
          "barWidth": barWidth
        }
      ]
    };
    return option;
  }

  public lightOrDarkAxisLine(xAxis: any) {

    if (xAxis.light === true) {

      return [
        {
          type: xAxis.type ? xAxis.type : "value",
          axisTick: {show: false},
          data: xAxis.data,
          boundaryGap: [0, 0.01],
          splitLine: {
            lineStyle: {
              color: "#f8f8f8"
            }
          },
          axisLabel: {
            textStyle: {
              fontFamily: "Open Sans, sans-serif",
              fontSize: 12,
              fontStyle: "normal",
              fontWeight: "300",
              color: "#777"
            }
          },
          axisLine: {
            lineStyle: {
              color: "#ececec"
            }
          }
        }
      ];


    } else {

      return [
        {
          type: xAxis.type ? xAxis.type : "value",
          axisTick: {show: false},
          data: xAxis.data,
        }
      ];

    }


  }

  public pieChart(title: string, legend: any, data: any) {
    const option = {
      title: {
        text: title,
        subtext: "",
        x: "center",
        bottom: 20,
        textStyle: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: 20,
          fontStyle: "normal",
          fontWeight: "300",
          color: "#333"
        }
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c}"
      },
      legend: {
        orient: "vertical",
        left: "left",
        top: 50,
        data: legend
      },

      series: [
        {
          name: title,
          type: "pie",
          radius: "48%",
          center: ["50%", "40%"],
          "data": data,
          itemStyle: {
            emphasis: {
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0)"
            }
          }
        }
      ]
    };
    return option;
  }

  public getNoComparisonPieChart(title: string, pieColor: any, pieData: any, legend: any) {
    const option: any = {
      color: pieColor,
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)"
      },
      legend: {
        orient: "horizontal",
        left: "right",
        top: 20,
        data: null,
        formatter: (name) => {
          if (name.includes("Roche")) {
            return name.split("-")[0] + "-" + name.split("-")[1];
          } else {
            return name.split("-")[0];
          }
        }
      },
      series: [
        {
          name: title,
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          data: pieData,
          itemStyle: {
            emphasis: {
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0)"
            }
          },
        }
      ]
    };
    return option;
  }

  public getMessagesPieChart(title: string, pieColor: any, pieData: any, legend: any) {
    const option: any = {
      color: pieColor,
      legend: {
        orient: "horizontal",
        left: "right",
        top: -20,
        color: '#ff0000',
        data: title,
        formatter: (name) => {
          if (name.includes("Roche")) {
            return name.split("-")[0] + "-" + name.split("-")[1];
          } else {
            return name.split("-")[0];
          }
        }
      },
      series: [
        {
          name: title,
          type: "pie",
          radius: "60%",
          center: ["50%", "60%"],
          data: pieData,
          itemStyle: {
            emphasis: {
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowColor: "rgba(255, 255, 255, 1)"
            }
          },
          labelLine: {
            normal: {
              lineStyle: {
                color: 'rgba(255, 255, 255, 0)'
              }
            }
          }
        }
      ]
    };
    return option;
  }
  
  public stackedBarGraph(legend: any, yAxis: any, series: any) {
    const option = {
      title: {
        text: ""
      },
      legend: {
        data: legend,
        textStyle: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: 12,
          fontStyle: "normal",
          fontWeight: "300",
          color: "#333"
        },
        top: 0,
        left: "right"
      },
      grid: {
        top: 50,
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      yAxis: {
        type: "category",
        data: yAxis,
        // data: ['my1', 'my2'],
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c}"
      },
      "series": series
    };
    return option;
  }

  public donutChart(data: any) {
    const option = {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)"
      },
      color: ["#91c7ae", "#d48165", "#619fa8", "#ca8622", "#749f83", "#2f4554", "#ce6958"],
      series: [
        {
          name: "",
          type: "pie",
          radius: ["50%", "70%"],
          center: ["21%", "50%"],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: "center"
            },
            emphasis: {
              show: false,
              textStyle: {
                fontSize: "30",
                fontWeight: "bold"
              }
            }
          },
          labelLine: {
            normal: {
              show: false
            }
            },
          "data": data
        }
      ]
    };
    return option;
  }

  public stackedLineChart(legend: any, xAxis: any, all: any, positive: any, negative: any) {
    const option = {
      /*title: {
          text: 'Trend Analysis'

      },*/
      tooltip: {
        trigger: "axis"
      },
      legend: {
        data: legend,
        orient: "horizontal",
        textStyle: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: 12,
          fontStyle: "normal",
          fontWeight: "300",
          color: "#333"
        },
        backgroundColor: "transparent",
        borderColor: "transparent",
        padding: 5,
        top: 20,
        right: 20,
        itemGap: 10
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "50",
        containLabel: true
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: xAxis,
        axisLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },

        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
        // min:30
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        },
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        }
      },
      dataZoom: [
        {
          show: true,
          start: 0,
          end: 100,
          fillerColor : "rgba(60, 26, 163, 0.6)"
        },
        {
          type: "inside",
          start: 0,
          end: 100
        },
        {
          show: true,
          yAxisIndex: 0,
          filterMode: "empty",
          width: 0,
          height: "0",
          showDataShadow: false,
          left: "0"
        }
      ],

      series: [
        {
          name: "All channels",
          type: "line",
          stack: "1",
          data: all,
          color: COLOR[0],
          symbol: "none"
        },
        {
          name: "Positive",
          type: "line",
          stack: "2",
          data: positive,
          color: "#92c47d",
          symbol: "none"
        },
        {
          name: "Negative",
          type: "line",
          stack: "3",
          data: negative,
          color: " #cd0000",
          symbol: "none"
        },


      ]
    };
    return option;
  }


  public trendsStackedLineChartWithoutXaxis(legend: any, xAxis: any, graphData: any[], title?: any) {
    const option = {
      title: {
        text: title,
        textStyle: {
          // fontFamily: "Open Sans, sans-serif",
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: "400",
          color: '#888',


        }
      },
      tooltip: {
        trigger: "axis",
        formatter: "<br/> Time : {b} <br/> " +
            "Value: {c}"

      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "50",
        containLabel: true
      },
      toolbox: {
        feature: {}
      },
      xAxis: {
        // visible:false,
        type: "category",
        min: 7,
        boundaryGap: false,
        data: xAxis,
        splitLine: {

          lineStyle: {

            color: "#f8f8f8"
          }
        },
        axisLine: {
          visible:false,
          lineStyle: {
            color: "#f6f7fa"
          }
        },

        axisLabel: {
          visible:false,
          display:false,
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#f6f7fa"
          }
        }
      },
      yAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      dataZoom: [
        {
          show: true,
          start: 80,
          end: 100,
          fillerColor : "rgba(60, 26, 163, 0.6)"
        },
        {
          type: "inside",
          start: 0,
          end: 100
        },
        {
          show: true,
          yAxisIndex: 0,
          filterMode: "empty",
          width: 0,
          height: "0",
          showDataShadow: false,
          left: "0"
        }
      ],
      series: [
        {
          name: "Flow",
          type: "line",
          stack: "1",
          data: graphData[0],
          color: COLOR[0],
          symbol: "none",
          smooth: true
        },
        // {
        //   name: "Moisture",
        //   type: "line",
        //   stack: "2",
        //   data: graphData[1],
        //   color: "#68a94d",
        //   symbol: "none"
        // },
        {
          name: "Temperature",
          type: "line",
          stack: "3",
          data: graphData[1],
          color: "#dd5026",
          symbol: "none"
        },
        {
          name: "Volume",
          type: "line",
          stack: "4",
          data: graphData[2],
          color: "#f2bb1f",
          symbol: "none"
        }

      ]
    };
    return option;
  }

  public trendsStackedLineChart(legend: any, xAxis: any, graphData: any[], title?: any) {
    const option = {
      title: {
        text: title,
        textStyle: {
          // fontFamily: "Open Sans, sans-serif",
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: "400",
          color: '#888',


        }
      },
      tooltip: {
        trigger: "axis",
        formatter: "<br/> Time : {b} <br/> " +
            "Value: {c}"

      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "50",
        containLabel: true
      },
      toolbox: {
        feature: {}
      },
      xAxis: {
        type: "category",
        min: 7,
        boundaryGap: false,
        data: xAxis,
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      yAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      dataZoom: [
        {
          show: true,
          start: 80,
          end: 100,
          fillerColor : "rgba(60, 26, 163, 0.6)"
        },
        {
          type: "inside",
          start: 0,
          end: 100
        },
        {
          show: true,
          yAxisIndex: 0,
          filterMode: "empty",
          width: 0,
          height: "0",
          showDataShadow: false,
          left: "0"
        }
      ],
      series: [
        {
          name: "Flow",
          type: "line",
          stack: "1",
          data: graphData[0],
          color: COLOR[0],
          symbol: "none"
        },
        // {
        //   name: "Moisture",
        //   type: "line",
        //   stack: "2",
        //   data: graphData[1],
        //   color: "#68a94d",
        //   symbol: "none"
        // },
        {
          name: "Temperature",
          type: "line",
          stack: "3",
          data: graphData[1],
          color: "#dd5026",
          symbol: "none"
        },
        {
          name: "Volume",
          type: "line",
          stack: "4",
          data: graphData[2],
          color: "#f2bb1f",
          symbol: "none"
        }

      ]
    };
    return option;
  }
  public trendsStackedLineChartMarkAreaDoubleLine(legend: any, xAxis: any, graphData: any[], title?: any,  dateOption?:any[],zoomvariable?:any) {

    const option = {
      title: {
        text: title,
        textStyle: {
          // fontFamily: "Open Sans, sans-serif",
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: "400",
          color: '#888',


        }
      },
      tooltip: {
        trigger: "axis",
        formatter: "<br/> Time : {b} <br/> " +
            "Value: {c}"

      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "50",
        containLabel: true
      },
      toolbox: {
        feature: {}
      },
      xAxis: {
        type: "category",
        min: 7,
        boundaryGap: false,
        data: xAxis,
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      yAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      dataZoom: [
        {
          show: true,
          start: 0,
          end: 100,
          fillerColor : "rgba(60, 26, 163, 0.6)"
        },
        {
          type: "inside",
          start: 0,
          end: 100
        },
        {
          show: true,
          yAxisIndex: 0,
          filterMode: "empty",
          width: 0,
          height: "0",
          showDataShadow: false,
          left: "0"
        }
      ],
      series: [
        {
          name: "Flow",
          type: "line",
          stack: "1",
          data: graphData[0],
          color: COLOR[0],
          symbol: "none",
          markLine: {
            symbol: ['none', 'none'],
            name: 'Report By Device',
            label: {show:false},
            lineStyle: {
              type:'solid',
              color:'#70AD47',

              width:2
            },
            data: [

              {
                xAxis: dateOption[2]},
              {
                xAxis: dateOption[3],
                lineStyle: {
                  type:'solid',
                  color:'#843C0C',
                  width:2
                },

              },


            ]
          },


          markArea: {
            itemStyle: {
              color: '#FDF2EB'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1]
              }]]
          }
        },

        {
          name: "Moisture",
          type: "line",
          stack: "2",
          data: graphData[1],
          color: "#68a94d",
          symbol: "none",
          markLine: {
            symbol: ['none', 'none'],
            name: 'Report By Device',
            label: {show:false},
            lineStyle: {
              type:'solid',
              color:'#70AD47',
              width:2
            },
            data: [
              {xAxis: dateOption[2]},
              {
                xAxis: dateOption[3],
                lineStyle: {
                  type:'solid',
                  color:'#843C0C',
                  width:2
                },
              },


            ]
          },
          markArea: {
            itemStyle: {
              color: '#FDF2EB'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1],
              }]]
          }
        },
        {
          name: "Temperature",
          type: "line",
          stack: "3",
          data: graphData[2],
          color: "#dd5026",
          symbol: "none",

          markLine: {
            symbol: ['none', 'none'],
            name:'Report By Device',
            label: {show:false},
            lineStyle: {
              type:'solid',
              color:'#70AD47',
              width:2
            },
            data: [
              {xAxis: dateOption[2]},
              {
                xAxis: dateOption[3],

                lineStyle: {
                  type:'solid',
                  color:'#843C0C',
                  width:2
                },
              },


            ]
          },
          markArea: {
            itemStyle: {
              color: '#FBE5D6'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1]
              }]]
          }
        },
        // {
        //   name: "Volume",
        //   type: "line",
        //   stack: "4",
        //   data: graphData[2],
        //   color: "#f2bb1f",
        //   symbol: "none"
        // }

      ]
    };
    return option;
  }

  public trendsStackedLineChartMarkArea(legend: any, xAxis: any, graphData: any[], title?: any,  dateOption?:any[],zoomvariable?:any) {
    const option = {
      title: {
        text: title,
        textStyle: {
          // fontFamily: "Open Sans, sans-serif",
          fontSize: 14,
          fontStyle: "normal",
          fontWeight: "400",
          color: '#888',


        }
      },
      tooltip: {
        trigger: "axis",
        formatter: "<br/> Time : {b} <br/> " +
            "Value: {c}"

      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "50",
        containLabel: true
      },
      toolbox: {
        feature: {}
      },
      xAxis: {
        type: "category",
        min: 7,
        boundaryGap: false,
        data: xAxis,
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      yAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      dataZoom: [
        {
          show: true,
          start: 0,
          end: 100,
          fillerColor : "rgba(60, 26, 163, 0.6)"
        },
        {
          type: "inside",
          start: 0,
          end: 100
        },
        {
          show: true,
          yAxisIndex: 0,
          filterMode: "empty",
          width: 0,
          height: "0",
          showDataShadow: false,
          left: "0"
        }
      ],
      series: [
        {
          name: "Flow",
          type: "line",
          stack: "1",
          data: graphData[0],
          color: COLOR[0],
          symbol: "none",
          markLine: {
            symbol: ['none', 'none'],
            name: 'Report By Device',
             label: {show:false},
            lineStyle: {
                type:'solid',
              color:'#00a363',

              width:2
            },
            data: [

              {
                  xAxis: dateOption[2]},
              // {
              //   xAxis: dateOption[3],
              //   lineStyle: {
              //     type:'solid',
              //     color:'#e0403f',
              //     width:4
              //   },
              //
              // },


            ]
          },


          markArea: {
            itemStyle: {
              color: '#FBE5D6'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1]
              }]]
          }
        },

        {
          name: "Moisture",
          type: "line",
          stack: "2",
          data: graphData[1],
          color: "#68a94d",
          symbol: "none",
          markLine: {
            symbol: ['none', 'none'],
            name: 'Report By Device',
              label: {show:false},
            lineStyle: {
                type:'solid',
              color:'#00a363',
              width:2
            },
            data: [
              {xAxis: dateOption[2]},
              // {
              //   xAxis: dateOption[3],
              //   lineStyle: {
              //     type:'solid',
              //     color:'#e0403f',
              //     width:4
              //   },
              // },


            ]
          },
          markArea: {
            itemStyle: {
              color: '#FBE5D6'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1],
              }]]
          }
        },
        {
         name: "Temperature",
          type: "line",
          stack: "3",
          data: graphData[2],
          color: "#dd5026",
          symbol: "none",

          markLine: {
            symbol: ['none', 'none'],
            name:'Report By Device',
             label: {show:false},
            lineStyle: {
                type:'solid',
              color:'#00a363',
              width:2
            },
            data: [
              {xAxis: dateOption[2]},
              // {
              //   xAxis: dateOption[3],
              //
              //   lineStyle: {
              //     type:'solid',
              //     color:'#e0403f',
              //     width:4
              //   },
              // },


            ]
          },
          markArea: {
            itemStyle: {
              color: '#FBE5D6'
            },
            data: [
              [{
                xAxis: dateOption[0]

              }, {
                xAxis: dateOption[1]
              }]]
          }
        },
        // {
        //   name: "Volume",
        //   type: "line",
        //   stack: "4",
        //   data: graphData[2],
        //   color: "#f2bb1f",
        //   symbol: "none"
        // }

      ]
    };
    return option;
  }

  public twitterStackedBarChart() {
    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {           
          type: "shadow"       
        }
      },
      legend: {
        data: ["No. of retweets", "Favourites"],
        textStyle: {
          fontFamily: "Open Sans, sans-serif",
          fontSize: 12,
          fontStyle: "normal",
          fontWeight: "300",
          color: "#333"
        },
        top: 0,
        right: 20
      },
      grid: {
        top: 20,
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true
      },
      xAxis: {
        type: "value",
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      yAxis: {
        type: "category",
        data: ["#nomorefingerpricks (Dexcom)", "#buckoffdiabetes (Roche Accu-Chek)", "#freestylelibre2 (Abbott)"],
        splitLine: {
          lineStyle: {
            color: "#f8f8f8"
          }
        },
        axisLine: {
          lineStyle: {
            color: "#e8e8e8"
          }
        },
        axisLabel: {
          textStyle: {
            fontFamily: "Open Sans, sans-serif",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: "300",
            color: "#777"
          }
        }
      },
      series: [
        {
          name: "No. of retweets",
          type: "bar",
          stack: "总量",
          label: {
            normal: {
              show: true,
              position: "insideRight"
            }
          },
          data: [149, 145, 77],
          color: COLOR[0]
        },
        {
          name: "Favourites",
          type: "bar",
          stack: "总量",
          label: {
            normal: {
              show: true,
              position: "insideRight"
            }
          },
          data: [727, 673, 213],
          color: "#e69138"
        },
      ]
    };
    return option;
  }
}
