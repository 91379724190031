import { Component, OnInit, Input, ViewChild, } from '@angular/core';
import * as moment from 'moment';
import { DEVICE_TYPE_WATER_METER, DATE_TIME_FORMAT } from 'src/app/reusable/constant';
import { setDateRange } from 'src/app/reusable/date-util';
import { TelemetryService } from 'src/app/reusable/services/telemetry.service';
import { LandingPageEsriMapComponent } from 'src/app/reusable/landing-page-map/landing-page-esri-map/landing-page-esri-map.component';
import { MapInfo } from 'src/app/services/arcgis-map/interfaces';

@Component({
  selector: 'app-device-listing-map',
  templateUrl: './device-listing-map.component.html',
  styleUrls: ['./device-listing-map.component.css']
})
export class DeviceListingMapComponent implements OnInit {
  @Input() filteredDevices;
  @Input() devices;
  @Input() centerOfMap;
  @Input() isConsumptionsExist;
  @Input() groupedDevices;
  loading: boolean = false;
  heatMapEnabled: boolean = false;
  displayDevices: boolean = false;
  // date picker variables
  startDate: string;
  endDate: string;
  selectedMap: string = "devices";
  @ViewChild(LandingPageEsriMapComponent) map : LandingPageEsriMapComponent;
  mapInfo:MapInfo;
  mapInitialized = false;
  isConsumptionAddedToDevices:boolean = false; // this helps to add consumption data on realtime on map without load
  isMap: boolean = true;
  isHeatMapRendered = false;
  isConsumptionsExist_ = true;
  constructor(
    private telemetryService: TelemetryService
  ) { }

  ngOnInit(): void {
    this.mapInfo = {
      center: this.centerOfMap,
      zoom: 16
    }
    this.startDate = moment().subtract(29, 'days').startOf('day').format(DATE_TIME_FORMAT);
    this.endDate =  moment().endOf('day').format(DATE_TIME_FORMAT);
    this.isConsumptionsExist_ = this.isConsumptionsExist;
  }

  ngOnChanges() {
    if(this.mapInitialized) {
      this.addDevicesToMap();
    }
  }

  esriMapInitialized(event) {
    this.addDevicesToMap();
    this.mapInitialized = event;
  }

  addDevicesToMap() {
    const deviceType = this.devices[0].deviceType;
    this.map.addDevicesToMap(this.filteredDevices, this.mapInfo, deviceType, this.getLabelInfos(), this.groupedDevices);
  }

  getLabelInfos() {
    return {
      showPointLabel : false,
      showCluster: this.filteredDevices.length > 1 ? true : false,
      clusterText: this.filteredDevices[0].deviceType,
      hideClusterOnZoom: true,
      isItMeter: true
    }

  }

  datepicker(value: any) {
    const dateArr = value;
    const date = setDateRange(dateArr, false);
    this.startDate = moment(new Date(date.startDate)).format(DATE_TIME_FORMAT);
    this.endDate = moment(new Date(date.endDate)).format(DATE_TIME_FORMAT);
    const payload = {
      dateRange: [this.startDate, this.endDate],
      deviceType: this.devices[0].deviceType,
      parameter: this.devices[0].deviceType === DEVICE_TYPE_WATER_METER ? "content.VOLUME" : "content.activeEnergyImportTotal"
    };    
    this.getAllDeviceConsumptions(payload);
  }

   getAllDeviceConsumptions(payload) {
    this.loading = true;
    this.displayDevices = false;
    this.isHeatMapRendered = false;
    this.map.removeAllLayer();
    
    const consumptionUnit = this.devices[0].deviceType === DEVICE_TYPE_WATER_METER ? 'm³': 'kWh';
    this.telemetryService.getAllDevicesConsumptions(payload).subscribe(devicesConsumptions => {
      this.devices.forEach((device) => {
        const d = devicesConsumptions.find(consumptionDetails => consumptionDetails.deviceID === device.deviceID)
        device['consumptionFor30Days'] = d?.total ? Number(d.total).toFixed(4) : 0;
        device['consumptionUnit'] = consumptionUnit;

      })
      this.map.updateData();
      this.map.addHeatMap(this.devices);
      this.isHeatMapRendered = true;
      this.isConsumptionsExist_ = devicesConsumptions.length > 0;
      this.loading = false;
    })
  }

  handleShowDevicesToggle(event) {
    if(event.checked) {
      this.map.addDevicePointLayer();
    }
    else {
      this.map.removeDevicePointLayer();
    }
  }

  selectMapType(value){
    if(value === "consumption") {
      if(!this.isConsumptionAddedToDevices) this.map.updateData();
      this.map.removeDevicePointLayer();
      this.map.addHeatMap(this.devices);
      this.heatMapEnabled = true;
    }
    else {
      this.map.removeHeatMapLayer();
      this.map.addDevicePointLayer();
      this.heatMapEnabled = false;
    }
  }
  

  public displayDate() {
    return  moment().startOf('month').format('Do MMM') + ' - ' + moment().format('Do MMM');
  }

}
