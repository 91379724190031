export class Device {
    "id": string = null;
    "deviceIdentifier": string = ""
    "deviceType": string = ''
    "status": string = ""
    "createdAt": string = ""
    "useGateway": boolean = false
    "location": {
        "latitude": string
        "longitude": string
    } = { "latitude": "", "longitude": "" }
    "manufacturerName": string
    "measurableLocation": string
    "model": string
    "operation": string = "create"
    "createdBy": string
    "profileID": string
    "deviceId": string
    "connection": {
        "authenticationType": string,
        "token": string,
        "username": string,
        "password": string,
        "cnName": string,
        "caCertificate": string,
        "clientCertificate": string,
        "clientKey": string,
        "certificateStatus": string,
        "expiry": string,
        "createdAt": string,
        "lastModifiedAt": string,
        "connectionProperties": {
            "uplinkTopic": string,
            "downlinkTopic": string
        }
    } = {
            "authenticationType": "",
            "token": "",
            "username": "",
            "password": "",
            "cnName": "",
            "caCertificate": "",
            "clientCertificate": "",
            "clientKey": "",
            "certificateStatus": "",
            "expiry": "",
            "createdAt": "",
            "lastModifiedAt": "",
            "connectionProperties": {
                "uplinkTopic": "",
                "downlinkTopic": ""
            }
        }
    "hidden": boolean
    "linkedProfiles": string;
    "serialNumber": string = '' //
    "servicePointID": string = ''
    "parentMeterServicePointID": string = ''
    "zone": string = ''
    "site": string = ''
    "port": string = ''
    "commissioningDate": any = ''
    "gatewayName": string
    "waterDiameter": string
    "ipAddress": string
    "meterPassword": string
    "energyBreakerCapacity": string
    "batch": string
    "manufacturingDate": any
    "firmwareVersion": string
    "buildingNumber": string
    "utilityServiceType": string
    "customerMeterType": string
    "customerType": string
    "customerDescription": string
    "isBillable": boolean
    // constructor(data) {
    //     this.deviceIdentifier = data.deviceIdentifier;
    //     this.deviceType = data.deviceType;
    //     this.status = data.status;
    //     this.createdAt = data.createdAt;
    //     this.useGateway = data.useGateway;
    //     this.location = data.location;
    //     this.manufacturerName = data.manufacturerName
    //     this.measurableLocation = data.measurableLocation
    //     this.model = data.model;
    //     this.operation = data.operation;
    //     this.createdBy = data.createdBy;
    //     this.profileName = data.profileName;
    //     this.deviceId = data.deviceId;
    //     this.connection = data.connection;
    // }
}

export class DeviceResponse {
    connection: string
    deviceId: string
} 

export class ProfileResponse {
    profileIds: Object
    defaultProfile: {
        profileID: string
    }
} 

export class DeviceData {
    profileID: string
    deviceId: string
}

// "uniqueIdentifier": string
// connection -> "dns": string,