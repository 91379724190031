import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { DESKTOP, MOBILE_LANDSCAPE, MOBILE_PORTRAIT, TABLET_LANDSCAPE, TABLET_PORTRAIT, breakPoints } from 'src/app/reusable/constant';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  private breakpointChanges$: Observable<any>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointChanges$ = this.breakpointObserver.observe([
      MOBILE_PORTRAIT,
      MOBILE_LANDSCAPE,
      TABLET_PORTRAIT,
      TABLET_LANDSCAPE,
      DESKTOP
    ]);
   
  }

  get breakpointChanges(): Observable<boolean> {
    return this.breakpointChanges$;
  }

  setScreenUsed(matches) {
    breakPoints.isMobilePortrait = matches['breakpoints'][MOBILE_PORTRAIT];
    breakPoints.isMobileLandscape = matches['breakpoints'][MOBILE_LANDSCAPE];
    breakPoints.isTabletPortrait = matches['breakpoints'][TABLET_PORTRAIT];
    breakPoints.isTabletLandscape = matches['breakpoints'][TABLET_LANDSCAPE];
    breakPoints.isDesktop = matches['breakpoints'][DESKTOP];

  }
}
