<div class="main-Div">
  <div id="alertBreadcrumb">
    <div class="">
      <app-breadcrumb [data]="breadcrumb">
      </app-breadcrumb>
    </div>
  </div>
  <div class="container-fluid pageSpacing">
    <div class="PageTitle">
       <span class="main-Title">Developer Guide</span>
    </div>
    <div class="row developers-pagewrapper">
      <div class="col-3 tab-navbar">
  
        <mat-accordion class="filter-main-header">
  
          <mat-panel-title class="filters-pd">
            <div (click)="navigateTo('about','about','About IoT Sense')" [ngClass]="parentRouteName=='about'?'active':''" class="sidebarHead">
              About IoT Sense</div>
          </mat-panel-title>
  
  
          <mat-expansion-panel class="filter-dropdown">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div (click)="navigateTo('Connectivity','Connectivity','Connectivity Mechanism')"
                  [ngClass]="parentRouteName=='Connectivity'?'active':''">
                  Connectivity Mechanism</div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="mt-3 lh-30" (click)="navigateTo('Connectivity','protocol','Connectivity Mechanism')"
              [ngClass]="routeName=='protocol'?'active':''">
              Protocols
              Supported</div>
            <div class="mt-3 lh-30" (click)="navigateTo('Connectivity','data-supported','Connectivity Mechanism')"
              [ngClass]="routeName=='data-supported'?'active':''">
              Supported data formats</div>
            <div class="mt-3 lh-30 mb-3" (click)="navigateTo('Connectivity','protocol-request','Connectivity Mechanism')"
              [ngClass]="routeName=='protocol-request'?'active':''">How to request for a new protocol support</div>
          </mat-expansion-panel>
  
          <mat-expansion-panel class="filter-dropdown">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div (click)="navigateTo('Gateway','Gateway','Edge Gateway')"
                  [ngClass]="parentRouteName=='Gateway'?'active':''">Edge
                  Gateway
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="mt-3 lh-30" (click)="navigateTo('Gateway','minimal','Edge Gateway')"
              [ngClass]="routeName=='minimal'?'active':''">
              Minimal
              Requirements</div>
            <div class="mt-3 lh-30" (click)="navigateTo('Gateway','requirements','Edge Gateway')"
              [ngClass]="routeName=='requirements'?'active':''">
              Recommended Requirements</div>
            <div class="mt-3 lh-30 mb-3" (click)="navigateTo('Gateway','hardware-supported','Edge Gateway')"
              [ngClass]="routeName=='hardware-supported'?'active':''">Hardware Supported & Tested</div>
          </mat-expansion-panel>
  
          <mat-expansion-panel class="filter-dropdown">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div (click)="navigateTo('Consuming','Consuming','Consuming Data')"
                  [ngClass]="parentRouteName=='Consuming'?'active':''">
                  Consuming
                  Data</div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="mt-3 lh-30" (click)="navigateTo('Consuming','Analytics','Consuming Data')"
              [ngClass]="routeName=='Analytics'?'active':''">via
              Analytics Dashboard</div>
            <div class="mt-3 lh-30" (click)="navigateTo('Consuming','via-api','Consuming Data')"
              [ngClass]="routeName=='via-api'?'active':''">via REST
              APIs
            </div>
            <div class="mt-3 lh-30 mb-3" (click)="navigateTo('Consuming','via-subscribing','Consuming Data')"
              [ngClass]="routeName=='via-subscribing'?'active':''">via Subscribing to Kafka topics for streaming data
            </div>
          </mat-expansion-panel>
  
          <mat-panel-title class="filters-pd">
            <div (click)="navigateTo('sample-data','sample-data','Sample Data')"
              [ngClass]="routeName=='sample-data'?'active':''">Sample
              Data
            </div>
          </mat-panel-title>
  
          <mat-panel-title class="filters-pd">
            <div (click)="navigateTo('onboard-case','onboard-case','How to onboard a new use case')"
              [ngClass]="routeName=='onboard-case'?'active':''"> How to
              onboard a
              new use case</div>
          </mat-panel-title>
        </mat-accordion>
      </div>
  
      <div class="col-9 tab-content">
        <section id="about">
          <div class="connectivity-page mb-4">
            <h2>About IoT Sense</h2>
            <p>IOT SENSE is a standard based horizontal IoT orchestration platform that integrates with
              various sensors, devices and IoT systems, abstracts IoT implementations, provides unified
              data, and a standard way to access, monitor and control heterogeneous IoT deployments.
            </p>
            <br>
            <p>It is a core platform to <b>Unify, Manage</b> and <b>Control organization’s IoT</b> assets and enables
              development of specialized applications and innovative use cases with contextual IoT data.</p>
             <div class="connectivityBanner">
                 <div class="informationText">
                    <img src="assets/images/interoperable.svg"> Interoperable Applications and Information Systems
                 </div>
                 <div class="connectivity"></div>
                 <div class="iconCards">
                     <div class="iconHeading">
                        IOT Sense
                     </div>
                     <div class="cardsAlignment">
                        <div class="cardContent">
                            <img src="assets/images/iot-orchestration.svg">
                            IOT Orchestration
                        </div>
                        <div class="cardContent">
                            <img src="assets/images/abstraction.svg">
                            Abstraction
                        </div>
                        <div class="cardContent">
                            <img src="assets/images/standards.svg">
                            Standards
                        </div>
                        <div class="cardContent">
                            <img src="assets/images/ai.svg">
                            AI Analytics
                        </div>
                        <div class="cardContent">
                            <img src="assets/images/security.svg">
                            Security
                        </div>
                     </div>
                 </div>
  
                 <div class="connectivityDiv">
                  <div class="connectivity"></div>
                  <div class="connectivity"></div>
                  <div class="connectivity"></div>
                 </div>
  
                 <div class="networksCards">
                  <div class="cardsIcon">
                      <div class="networkIcon"><img src="assets/images/device-networks.svg"></div>
                      Device Networks
                  </div>
                  <div class="cardsIcon">
                     <div class="networkIcon"><img src="assets/images/iot-devices.svg"></div>
                      IOT Devices
                  </div>
                  <div class="cardsIcon">
                     <div class="networkIcon"><img src="assets/images/iot-platform.svg"></div>
                      IOT Platforms
                  </div>
               </div>
  
             </div> 
            <!-- <div class="connectivityBanner">
              <img src="assets/images/about-banner.png">
            </div> -->
  
          </div>
        </section>
  
        <section id="Connectivity">
          <div class="connectivity-page sectionSpace">
            <h2>Connectivity Mechanism<br>
              Supported Protocols and Message Formats</h2>
            <p>Integrating devices onto IOT SENSE is a straightforward process. IOT SENSE offers a wide
              range of connectivity mechanisms to choose from.</p>
            <div class="connectivityBanner">
  
               <div class="devicesDiv">
                        <div class="devicesCards">
                        <div class="iconHeading">
                          Non IP Enabled Devices
                        </div>
                        <div class="devicesContent">
                          <div class="bulbImage">
                            <img src="assets/images/bulb.svg">
                          </div>
                          <div class="connectivityHr"></div>
                            <div class="gatewayCard">
                                <img src="assets/images/external-gateway.svg">
                                External gateway
                            </div>
                        </div>
                        </div>
                      
                        <div class="connectivityLeft">
                          <div class="connectivityBottom"></div>
                        </div>
                        
                        <div class="connectivityRight">
                          <div class="connectivityBottom right"></div>
                        </div>
  
                        <div class="devicesCards">
                          <div class="iconHeading">
                            From external servers such as<br> OPCUA, SFTP etc.
                         </div>
                         <div class="devicesContent">
                          <div class="gatewayCard">
                            <img src="assets/images/external-server.svg">
                            External Server
                          </div>
                          <div class="connectivityHr"></div>
                          <div class="bulbImage">
                           <img src="assets/images/fan.svg">
                           </div>
                         </div>
                         </div>
                         
               </div>
  
               <div class="cloudSection">
                <img src="assets/images/cloud.svg">
                IOT Sense Cloud
               </div>
  
               <div class="devicesDiv">
                <div class="devicesCards">
                  <div class="iconHeading">
                    Vendor Proprietary Head-end<br>
                    System/ Other Vertical IoT Platforms
                  </div>
                  <div class="devicesContent">
                    <div class="bulbImage">
                      <img src="assets/images/automation.svg">
                    </div>
                    <div class="connectivityHr"></div>
                      <div class="gatewayCard">
                          <img src="assets/images/head-end-system.svg">
                          Head-end System
                      </div>
                  </div>
                  </div>
  
                  <div class="apiText">
                    <span> REST API</span>
                   </div>
                    <div class="apiconnectivityLeft">
                      <div class="apiconnectivitytop"></div>
                    </div>
  
                  <div class="connectivityVertical"></div>
  
                  <div class="apiconnectivityLeft nodealignment">
                    <div class="apiconnectivitytop nodealignmentTop"></div>
                  </div>
  
                  <!-- <div class="apiconnectivityRight">
                    <div class="apiconnectivitytop"></div>
                  </div> -->
                 
                  <div class="devicesCards">
                    <div class="iconHeading">
                      Edge Processing<br>
                      Field Deployment
                   </div>
                   <div class="devicesContent">
                    <div class="gatewayCard">
                      <img src="assets/images/iot-edge.svg">
                      IOT Edge Gateway
                    </div>
                    <div class="connectivityHr"></div>
                    <div class="bulbImage scadaIcon">
                     <img src="assets/images/cpu.svg">
                     Scada System
                     </div>
                   </div>
                   </div>
  
  
               </div>
  
            
               <div class="networksCards networkSpacing">
                <div class="cardsIcon">
                  <div class="networkIcon"><img src="assets/images/ip-enabled.svg"></div>
                  IP Enabled Devices
                </div>
               </div>
  
            </div>
  
            <!-- <div class="connectivityBanner">
              <img src="assets/images/connectivity-banner.png">
            </div> -->
            <div class="protocol-list" id="protocol">
              <h3>Protocols Supported</h3>
              <p>IOT SENSE supports several protocols, including:</p>
              <ul class="protocol-list listSpacing">
                <li>MQTTS</li>
                <li>OPCUA</li>
                <li>SFTP</li>
                <li>COAP</li>
                <li>REST</li>
              </ul>
            </div>
            <p class="paragraph-spacing">We’re constantly evolving, and always looking for new ways to support our users.
              In our roadmap,
              we plan to support additional protocols such as <b>Zigbee</b> and <b>LoRaWAN</b> etc. Some of these on a
              need basis can also be potentially prioritized.</p>
  
            <div class="protocol-list" id="data-supported">
              <h3>Supported data formats</h3>
              <p>IOT SENSE supports following data formats.</p>
              <ul class="protocol-list listSpacing">
                <li>OMS</li>
                <li>JSON</li>
                <li>XML</li>
                <li>DLMS</li>
                <li>REST</li>
              </ul>
            </div>
            <br>
            <p>Basis use case requirements, custom transformers can be developed for proprietary
              data formats.</p>
            <p class="text_link">Our detailed specification document is available <a target="_blank"
                href="https://neomsa.sharepoint.com/:b:/s/NEOSIoTSense/EaZQGVkce5lBiIWfZgbQdIgBj0JEp2jERjlH6kv8JeXd7Q?e=UdU9Kb">here.</a>
            </p>
  
            <div class="protocol-list" id="protocol-request">
              <h3 class="heading-mg">How to request for a new protocol support</h3>
            </div>
            <p style="margin-top: 0;">IOT SENSE platform can develop &amp; integrate custom protocols &amp; manufacturer
              specific message formats onto the platform.</p>
            <br>
            <p>Custom protocol and message format requirements should comply with below
              requirements</p>
            <br>
            <ul class="protocol-list listSpacing">
              <li>IoT devices are able to connect to the platform with custom protocol in secured manner
              </li>
              <li>Device manufacturer is able to provide Java library to read proprietary message format
              </li>
              <li>IoT device is either on the same network as IOT SENSE platform or support VPN
              </li>
            </ul>
            <br>
            <p>To start the discussion on your use case, send an email to <b><a href="mailto:iotsensesupport@neom.com">iotsensesupport@neom.com</a></b>.
              with description of your requirements and we will get in touch with you to take it
              ahead.</p>
          </div>
        </section>
  
  
        <section id="Gateway">
          <div class="connectivity-page sectionSpace">
            <h2>Edge Gateway</h2>
            <p>The IOT SENSE edge module consists of a software that can be run on various kinds of edge
              devices like gateways and PLC. This module may be deployed on constraints devices, field
              servers or cloud servers: there by acting as field, fog, or cloud gateway. This module provides
              the backhaul connectivity to the cloud platform by connecting to field devices in their native
              protocols, converting the data to the one M2M format and sending it to the cloud. This module
              also provides capabilities of edge processing and analytics.</p>
            <br>
            <p>Below are the system requirements for deployment on Edge devices:</p>
            <div class="protocol-list" id="minimal">
              <h3 style="margin-bottom: 5px;">Minimal Requirements</h3>
              <div class="textRightalign headingSpace">
                <h3>Hardware</h3>
                <ul class="protocol-list listSpacing">
                  <li><b>512 MB</b> of Free RAM</li>
                  <li><b>512 MB</b> of usable storage</li>
                  <li>32 Bit Single-Core Processor (ARM/Intel/AMD)</li>
                </ul>
              </div>
            </div>
  
            <div class="protocol-list">
              <div class="textRightalign headingSpace">
                <h3>Software</h3>
                <ul class="protocol-list listSpacing">
                  <li>JAVA 8</li>
                  <li>Linux Kernel and Shell Support</li>
                </ul>
              </div>
            </div>
  
            <div class="protocol-list">
              <div class="textRightalign headingSpace">
                <h3>Limitations/Ideal Usage</h3>
                <ul class="protocol-list listSpacing">
                  <li>Ideal for embedded or low-end devices where data points arrive every 5-10 minutes.</li>
                  <li>Since docker engine cannot be installed due to space constraints, only local SDK update/
                    upgrade is possible. No OTA Updates (Over the Air) can be facilitated in such a setup.</li>
                  <li>The device should be on the local network as VPN tunnelling is not possible because of the
                    single core processor.</li>
                </ul>
              </div>
            </div>
  
            <div class="protocol-list" id="requirements">
              <h3 style="margin-bottom: 5px;">Recommended Requirements</h3>
              <div class="textRightalign headingSpace">
                <h3>Hardware</h3>
                <ul class="protocol-list listSpacing">
                  <li><b>1 GB</b> of Free RAM</li>
                  <li><b>2 GB</b> usable storage</li>
                  <li>64 Bit Dual-Core Processor (ARM/Intel/AMD)</li>
                </ul>
              </div>
            </div>
  
            <div class="protocol-list">
              <div class="textRightalign headingSpace">
                <h3> Software</h3>
                <ul class="protocol-list listSpacing">
                  <li>JAVA 8</li>
                  <li>Linux Kernel and Shell Support</li>
                  <li>Docker Engine v19.0</li>
                  <li>OpenVPN</li>
                </ul>
              </div>
            </div>
  
            <div class="protocol-list">
              <div class="textRightalign headingSpace">
                <h3> Usage</h3>
                <ul class="protocol-list listSpacing">
                  <li>Ideal for medium to high end devices where data points arrive every second or more</li>
                  <li><b>OTA</b> (Over the Air) Updates for SDK is possible through docker.</li>
                  <li>The device can be anywhere on the field as OpenVPN establishes VPN Tunnel to IOT SENSE cloud
                    over the Web.</li>
                </ul>
              </div>
            </div>
  
            <div class="protocol-list" id="hardware-supported">
              <h3> Hardware Supported &amp; Tested</h3>
              <p>Below are few examples of the hardware devices that are supported and have been tested
                with the IOT SENSE Edge Gateway using the OPC UA communication protocol:</p>
            </div>
            <div class="protocol-list">
              <div class="textRightalign headingSpace">
                <h3> AXC F 2152 PLC Device by Phoenix Contact</h3>
                <ul class="protocol-list listSpacing">
                  <li>512 MB Flash Storage</li>
                  <li>512 GB DDR3 SDRAM</li>
                  <li>Arm® Cortex® -A9 2x 800 MHz</li>
                  <li>YOCTO Linux</li>
                </ul>
              </div>
            </div>
  
            <div class="protocol-list">
              <div class="textRightalign headingSpace">
                <h3 style="padding-bottom: 2px;"> AXC F 3152 PLC Device by Phoenix Contact</h3>
                <ul class="protocol-list listSpacing">
                  <li>8 GB Flash Storage</li>
                  <li>2 GB DDR3 SDRAM</li>
                  <li>Intel® Atom™ x5-E3930 2x 1.3 GHz</li>
                  <li>YOCTO Linux</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
  
  
  
        <section id="Consuming">
          <div class="connectivity-page sectionSpace">
            <h2>Consuming Data</h2>
            <p>Sensor data can be consumed from IOT SENSE in multiple ways</p>
  
            <div class="protocol-list" id="Analytics">
              <h3>via Analytics Dashboard</h3>
              <p>The IOT Sense platform allows the user to search and discover device related attributes.
                Using the strong visualization capabilities of the platform, the user can create
                visualizations and custom dashboards from the real-time data that the platform is receiving
                from the network of connected devices. In addition to custom dashboard, the platform also hosts device
                specific dashboards displaying all the data pertaining to an individual device. The strong dashboarding
                capabilities allows the user to uncover analytical insights crucial to various operations pertaining to
                the IoT devices.
              </p>
              <p> IOT Sense includes an alert management module which allows user to manage and monitor all the device
                related alerts and also create custom threshold based alerts which the users can subscribe to.
              </p>
              <p>IOT Sense supports a user access model which allows the platform admins to setup user roles, user role
                permissions and data permissions which enables access control and data control acorss the platform.</p>
            </div>
  
            <div class="protocol-list" id="via-api">
              <h3>via REST APIs</h3>
              <p>The sensor data can be easily and securely consumed by downstream systems using
                REST APIs exposed by IOT SENSE.</p>
            </div>
  
            <div class="protocol-list">
              <div class="textRightalign headingSpace">
                <h3 style="padding-bottom: 8px;">API Documentation</h3>
                <ul class="protocol-list listSpacing">
                  <li class="text_link">Our API documentation can be found <a target="_blank"
                      href="https://neomsa.sharepoint.com/:b:/s/NEOSIoTSense/Ed2rzcrnq_ZFvtdRvRajKy0BF4A-8w6q4LuiDCwqdpIcOg?e=Cz12gb">here.</a>
                  </li>
                  <li class="text_link">Or you can access Swagger documentation <a target="_blank"
                      href="https://neomsense.oci.sense.neomos.online/api-docs/index.html#/">here.</a></li>
                </ul>
              </div>
            </div>
  
            <div class="protocol-list">
              <div class="textRightalign headingSpace">
                <h3>Authentication Mechanism</h3>
                <ul class="protocol-list listSpacing">
                  <li>API authentication is managed using OAuth Tokens. The steps to generate OAuth token are
                    available in the API documentation.</li>
                </ul>
              </div>
            </div>
  
            <div class="protocol-list" id="via-subscribing">
              <h3> via Subscribing to Kafka topics for streaming data</h3>
              <ul class="protocol-list listSpacing">
                <li>IOT SENSE publishes the sensor data to the Kafka topics which can be subscribed by the
                  downstream systems to gain access to real time streaming data.</li>
                <li>Typically, READ access to the topics is provided, however, for relevant use cases WRITE access
                  can also be enabled.</li>
                <li>In order to connect to the Kafka server, the client and server certificates will be provided.</li>
                <li class="text_link">The detailed steps required are available in the documentation <a target="_blank"
                    href="https://neomsa.sharepoint.com/:b:/s/NEOSIoTSense/Ed2rzcrnq_ZFvtdRvRajKy0BF4A-8w6q4LuiDCwqdpIcOg?e=Cz12gb">here.</a>
                </li>
              </ul>
            </div>
            <br>
            <p>To access IOT SENSE data, simply send an email to the IOT SENSE team at
              <b><a href="mailto:iotsensesupport@neom.com">iotsensesupport@neom.com</a></b> with a description of your use
              case. We will be in touch to facilitate
              your data access request and authorization from data owners.
            </p>
            <br>
            <p>Alternatively, we can provide you access to dummy endpoints and data to get yourself familiarise
              with consumption mechanism and support your integration effort.</p>
          </div>
        </section>
  
  
        <section id="sample-data">
          <div class="connectivity-page sectionSpace">
            <h2>Sample Data</h2>
            <p class="text_link"><a target="_blank"
                href="https://neomsa.sharepoint.com/:x:/s/NEOSIoTSense/EblgxQTQmwFDp-cm8kbB8Y0BfMVwSRHtWC7r0eg_kDJ5Eg?e=YxXrTL">Click
                here</a> to explore sample data from Water Meters.<br>
              <a target="_blank"
                href="https://neomsa.sharepoint.com/:x:/s/NEOSIoTSense/EduQeCCjwatJhE3po6ASP-4B_WBEhkax3TxpFBirgErG-w?e=wkpnve">Click
                here</a> to explore sample data from Energy Meters.<br>
              <a target="_blank"
                href="https://neomsa.sharepoint.com/:x:/s/NEOSIoTSense/EblgxQTQmwFDp-cm8kbB8Y0BfMVwSRHtWC7r0eg_kDJ5Eg?e=YxXrTL">Click
                here</a> to explore sample data from Desalination Plant.
            </p>
            <!-- <div class="mt-4 mb-4">
              <p>Here;s how to get started:</p>
            </div>
  
            <p class="text_link">Check out our list of required technical specifications and documents we need for your
              device.
              You can find it <a target="_blank"
                href="https://neomsa.sharepoint.com/:f:/s/NEOSIoTSense/Ehc2au9r4JpCgEpJWJKkN3IBaHNl3ZtpXnFYZrLVMGVXmw?e=Cs3hn5">here.</a>
            </p>
            <br>
  
            <p>Send us an email us at <b><a href="mailto:iotsensesupport@neom.com">iotsensesupport@neom.com</a></b> with a
              description of your use case and
              device documentation (if available). Our team will review your information and help you get
              set up on the platform.</p>
            <br>
            <p>If you have any questions or concerns, reach out to us at.....<br>
              <b><a href="mailto:iotsensesupport@neom.com">iotsensesupport@neom.com</a></b>
            </p> -->
          </div>
        </section>
  
        <section id="onboard-case">
          <div class="connectivity-page sectionSpace">
            <h2>How to onboard a new use case</h2>
            <p class="text_link"> Check out our list of required technical specifications and documents we need for your
              device.
              You can find it <a target="_blank"
              href="https://neomsa.sharepoint.com/:f:/s/NEOSIoTSense/Ehc2au9r4JpCgEpJWJKkN3IBaHNl3ZtpXnFYZrLVMGVXmw?e=Cs3hn5">here.</a></p>
            <br>
            <p>Send us an email us at <b><a href="mailto:iotsensesupport@neom.com">iotsensesupport@neom.com</a></b> with a
              description of your use case and
              device documentation (if available). Our team will review your information and help you get
              set up on the platform.</p>
            <br>
            <!-- <p>Send us an email us at <b><a href="mailto:iotsensesupport@neom.com">iotsensesupport@neom.com</a></b> with a
              description of your use case and
              device documentation (if available). Our team will review your information and help you get
              set up on the platform.</p>
            <br> -->
            <p>If you have any questions or concerns, reach out to us at 
              <b><a href="mailto:iotsensesupport@neom.com">iotsensesupport@neom.com</a>.</b>
            </p>
          </div>
        </section>
  
      </div>
  
    </div>
  </div>
</div>