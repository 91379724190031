<div class="popup-MainDiv customSearch">
    <header class="popup-Header">
        <span class="popup-Title">{{data?.headerTitle}}</span>
        <img mat-dialog-close class="popup-CloseImg">
    </header>
    <section id="searchDiv">
        <div id="searchBar">
            <div id="searchBox">
                <search-attribute (displayResult)="searchResult($event)" redirectPage="Additional Parameter">
                </search-attribute>
            </div>
            <div class="filter">
                <search-attribute-filter [showText]="true" [showIcon]="true" (updateSearchResult)="updateSearchResult($event)"></search-attribute-filter>
            </div>
        </div>
        <div id="serachTab">
            <search-attribute-results [attributeList]="attributeList" [isNotFound]="isNotFound"(updateAttributeList)="updateAttributeList($event)" [isLoader]="isLoader" [data]="results" [filteredRecordsCount]="filteredRecordsCount">
            </search-attribute-results>
        </div>

        <div class="nextPagination">
            <footer id="searchAction">
                <button class="button-Primary-N" [disabled]="attributeList.length ===  0 ? true : null" (click)="addParameter()">{{data?.actionButton}}</button>
                <button mat-dialog-close class="button-Tertiary-N">Cancel</button>
            </footer>
            <div class="pagination" *ngIf="results?.length > 0">
                <app-pagination [data]="results" [currentPage]="currentPage" [itemPerPage]="pageSize"
                    [totalPageCount]="totalPageCount" [pageSizeOption]="pageSizeOption"
                    [totalRecords]="filteredRecordsCount" [asyncPagination]="true" (pageChange)="handlePageChange($event)"
                    (itemPerPageChange)="tablePageSizeChange($event)">
                </app-pagination>
            </div>
        </div>
    </section>
</div>