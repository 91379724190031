import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {

  constructor(private apiService: ApiService, private http: HttpClient) { }

  public saveWidget(postData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post('/widgetDashboard/saveWidget', postData)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }

  public getAllWidgetCustomDashboardId(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post('/widgetDashboard/getDashboardWidgets', { dashboardId: id })
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }

  public getWidgetById(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.get(`/widgetDashboard/widget/${id}`)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }

  public deleteWidget(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.delete(`/widgetDashboard/deleteDashboardWidgets/${id}`)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }

  public updateWidget(postData): Promise<any> {
    return new Promise((resolve, reject) => {
      const _postData = (({ _id, name, type, requestPayload, requestMethod, adaptive, requestUrl, xaxis, yaxis, x, y, h, w, pageSize, isOperationalRange, cardType, splitYAxis }) => ({ _id, name, type, requestPayload, requestMethod, adaptive, requestUrl, xaxis, yaxis, x, y, h, w, pageSize, isOperationalRange, cardType, splitYAxis }))(postData);
      this.apiService.put('/widgetDashboard/updateWidget', _postData)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }

  public linkDashboardWith(dashboardData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.put('/widgetDashboard/linkDashboardWith', dashboardData)
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error);
        });
    });
  }

  public getAllLinkedDashboards(dashboardName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post('/widgetDashboard/getAllLinkedDashboards', { dashboardName: dashboardName })
        .subscribe((response) => {
          resolve(response);
        }), (error) => {
          reject(error);
        }
    })
  }
  public getAllLinkedHMIDashboards(dashboardName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post('/widgetDashboard/getAllLinkedHMIDashboards', { dashboardName: dashboardName })
        .subscribe((response) => {
          resolve(response);
        }), (error) => {
          reject(error);
        }
    })
  }

  public deLinkDashboard(dashboardData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post('/widgetDashboard/deLinkDashboard', dashboardData)
        .subscribe((response) => {
          resolve(response);
        }), (error) => {
          reject(error);
        }

    })
  }
}
