import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})

export class LoaderComponent implements OnInit {
  @Input() showFullPage: boolean = true;

  constructor() { }

  ngOnInit() {
    console.log("in app loader")

  }

}
