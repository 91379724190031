import * as d3 from "d3";
import { convertDate, createSubGroups, formatAxisConfig, setChartConfig } from "./chart-helper";
import { v4 as uuidv4 } from 'uuid';
import * as moment from "moment";
import { COLOR, VPmargin, alertColors, alertEventColor, alertEventOpacity, alertEventWidth, alertLineColor, tooltipCircleRadius, tooltipLineColor, tooltipLineDasharray, tooltipLineWidth, viewportHeight } from "./chart-config";

export const lineGraphForNativeStatusData = (element, data, config) => {

  const mainElement = d3.select(element);
  let width = mainElement.node()["clientWidth"];
  let height = mainElement.node()["clientHeight"];
  const guid = uuidv4()
  let subgroups, color;
  config.chartConfig = setChartConfig(config.chartConfig);
  let vhmarginleft =  ( parseFloat(VPmargin.yAxisMarginLeftStatus) / 100) * viewportHeight;
  if (typeof (config.attributes) === "string") {
    subgroups = config.attributes;
  }
  else {
    subgroups = createSubGroups(config.attributes);
  }
  width = width - vhmarginleft - config.chartConfig.margin.right;
  height = height - config.chartConfig.margin.top - config.chartConfig.margin.bottom;

  const dateList = [];
  const dataPoints = [];
  const bisectDate = d3.bisector(function (d: any) { return d.dateTime; }).left;

  d3.selectAll(`${element} svg`).remove();
  d3.selectAll(`${element} .chart-tooltip`).remove();

  /* Format Data */
  data?.forEach(function (d) {
    d.nativeList.forEach(function (d) {
      d.dateTime = convertDate(d.dateTime);
      d.value = d.value;
      dateList.push(d.dateTime);
      dataPoints.push(d.value);
    });
  });

  const _x = d3.scaleTime()
    .domain(d3.extent(dateList))
    .range([0, width]);

  // const _y = d3.scaleBand()
  //   .domain(config.enumeritation)
  //   .paddingInner(0)
  //   .paddingOuter(0)
  //   .range([ploatFilterHeight, 0])

  color = d3.scaleOrdinal()
    .domain(subgroups.map(x => x.name || x.description))
    .range(COLOR);

  /* Add tooltip */
  const tooltip = mainElement
    .append("div")
    .classed('chart-tooltip', true);

  /* Add SVG */
  const svg = mainElement
    .append("svg")
    .attr('class', 'chart-plot')
    .attr("width", (width + vhmarginleft + config.chartConfig.margin.right) + "px")
    .attr("height", (height + config.chartConfig.margin.top + config.chartConfig.margin.bottom) + "px");

  /* Add Axis into SVG */
  const xScale = d3.scaleTime()
    .domain(d3.extent(dateList))
    .range([0, width]);

  const xAxis = d3.axisBottom(xScale);

  svg.append("defs").append("clipPath")
    .attr("id", `clip-${guid}`)
    .append("rect")
    .attr("x", 0)
    .attr("width", width)
    .attr("height", height);

  svg.append("g")
    .attr("class", "axis-bottom x-axis")
    .attr("transform", `translate(${vhmarginleft}, ${height - config.chartConfig.margin.top})`)
    .attr("clip-path", `url(#clip-${guid})`)
    .call(xAxis);
  /* Add line into SVG */
  config.attributes.forEach((element, index) => {

    let _height = (height / data.length);

    const container = svg.append('g')
      .attr("transform", (d) => {
        return `translate(${vhmarginleft}, ${config.chartConfig.margin.top + ((index * _height))}  )`
      });

    /* Scale */
    const yScale = d3.scaleBand()
      .domain(Object.values(element.enumeritation))
      .paddingInner(0)
      .paddingOuter(0)
      .range([_height - config.chartConfig.margin.bottom, config.chartConfig.margin.top])

    d3.line()
      .x((d: any) => xScale(d.dateTime))
      .y((d: any) => yScale(d.value) || 0)
      .curve(d3.curveStepAfter);

    const area = d3.area()
      .x(function (d: any) { return xScale(d.dateTime) })
      .y0(function (d: any) {
        return ((yScale(d.value)) + (yScale.bandwidth())) || 0
      })
      .y1(function (d: any) {
        return yScale(d.value) || 0
      })
      .curve(d3.curveStepAfter)

    container.append("path")
      .datum(data[index])
      .attr("class", "path")
      .style('fill', (d: any, i: any) => {
        return element?.axisConfig?.color || color(d.name || d.description)
      })
      .attr("clip-path", `url(#clip-${guid})`)
      .attr("stroke", "0")
      .attr('d', (d: any) => area(d.nativeList))

    /* Add Axis into SVG */
    const yAxis = d3.axisLeft(yScale);

    container.append("g")
      .attr("class", "axis-left y-axis")
      .call(yAxis)
      .append('text')
      .attr("y", 15)
      .attr("transform", "rotate(-90)")
      .attr("fill", "#000")

    if (index + 1 === config.attributes.length) {

      const alertRangeElement = container.append("g").attr("id", "alertRange").attr("clip-path", `url(#clip-${guid})`);
      config.alertRange?.forEach((item) => {
        alertRangeElement.append("rect").attr("id", `react-alertRange${item.id}`);
        alertRangeElement.append("g").attr("id", "thresholdLine-alertRange")
          .selectAll('.thresholdLine')
          .data(item.thresholdValues || [])
          .enter()
          .append('line')
          .attr('class', 'thresholdLine');
        alertRangeElement.append("g").attr("id", "line-alertRange")
          .selectAll('.alertline')
          .data(item.alertDate || [])
          .enter()
          .append('line')
          .attr('class', 'alertline');
      })

      const renderAllAlertDataPoints = (config) => {
        config?.alertRange?.forEach(item => {
          alertRange(item);
        })
      };

      const alertRange = (item) => {
        if (item) {
          const pointMin = xScale(convertDate(item.startDate));
          const pointMax = xScale(convertDate(item.endDate));
          const subscribed = item?.subscibedUser;
          const color = subscribed ? alertColors['Subscribed'] : (item?.alertClassification ? alertColors[item.alertClassification] : alertEventColor);
          alertRangeElement.select(`rect#react-alertRange${item.id}`)
            .attr("fill", color)
            .style("fill-opacity", alertEventOpacity)
            .style("stroke-width", alertEventWidth)
            .style("stroke", color)
            .attr("x", pointMin)
            .attr("y", config.chartConfig.margin.top)
            .attr("width", pointMax - pointMin)
            .attr("height", height + config.chartConfig.margin.top - config.chartConfig.margin.bottom)
  
          // The Threshold Value line is not needed for Status Chart Values
          /* if (item.thresholdValues && item.thresholdValues.length > 0) {
            alertRangeElement.selectAll(".thresholdLine")
              .attr("x1", 0)
              .attr("x2", width)
              .attr("y1", (d: any) => yScale(d.value))
              .attr("y2", (d: any) => yScale(d.value))
              .style("stroke-width", 2)
              .style("stroke", (d: any) => {
                return d.color || alertLineColor;
              });
          } */
  
          alertRangeElement.selectAll(".alertline")
            .attr("x1", (d: any) => {
              return xScale(convertDate(d.alertDate))
            })
            .attr("x2", (d: any) => {
              return xScale(convertDate(d.alertDate))
            })
            .attr("y1", 0)
            .attr("y2", height)
            .style("stroke-width", 2)
            .style("stroke", (d: any) => {
              return d.color || alertLineColor;
            });
        }
      }
      renderAllAlertDataPoints(config);

      const zoom = d3.zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([[0, 0], [width, height]])
        .extent([[0, 0], [width, height]])
        .on("zoom", (d) => {
          if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush
          mainElement.select(".zoom").style("cursor", 'move');

          const t = d3.event.transform;
          xScale.domain(t.rescaleX(_x).domain());

          svg.select(".x-axis")
            .call(d3.axisBottom(xScale)
              .tickSizeOuter(0));

          svg.selectAll(".path")
            .attr('d', (d: any) => area(d.nativeList));
          renderAllAlertDataPoints(config);
          formatAxisConfig(svg, config.chartConfig);
        });

      svg.selectAll('.circle-tooltip')
        .attr("transform", `translate(${vhmarginleft}, ${0})`)
        .data(data).enter()
        .append("circle")
        .attr("class", "circle-tooltip")
        .attr("r", tooltipCircleRadius)
        .style('fill', (d: any, i: any) => element?.axisConfig?.color || color(d.name || d.description))
        .style('opacity', 0)

      svg
        .append("line")
        .attr("class", "line-tooltip")
        .attr("transform", `translate(${vhmarginleft}, ${0})`)

        .style("stroke", tooltipLineColor)
        .style("stroke-width", tooltipLineWidth)
        .style("stroke-dasharray", tooltipLineDasharray)
        .style('opacity', 0);

      svg.append("rect")
        .attr("class", "zoom")
        .attr("width", width)
        .attr("height", height)
        .style("fill", 'none')
        .style("cursor", 'move')
        .style("pointer-events", 'all')
        .attr("transform", `translate(${vhmarginleft}, ${0})`)
        .call(zoom)
        .on("mouseout", () => {
          svg.selectAll('.circle-tooltip')
            .style('opacity', 0)

          tooltip
            .style("display", 'none')

          svg.selectAll('.line-tooltip')
            .style('opacity', 0)

          tooltip
            .style("display", 'none')
        })
        .on("mousemove touchmove", () => {
          let toolTipText = '';
          const x0: any = xScale.invert(d3.event.offsetX - vhmarginleft),
            i = bisectDate(data[0].nativeList, x0, 1),
            d0 = data[0].nativeList[i - 1],
            d1 = data[0].nativeList[i];
          let d = x0 - d0?.dateTime > d1?.dateTime - x0 ? d1 : d0;
          mainElement.select(".zoom").style("cursor", 'pointer');
          svg.selectAll('.circle-tooltip')
            .attr("cx", (_d, _i) => {
              const d0 = data[_i].nativeList[i - 1];
              if (d0) {
                const d1 = data[_i].nativeList[i],
                  di = x0 - d0.dateTime > d1.dateTime - x0 ? d1 : d0;
                d = di;
                return xScale(d.dateTime);
              }
            })
            .attr("cy", (_d: any, _i) => {
              const d0 = data[_i].nativeList[i - 1]
              if (d0) {
                const d1 = data[_i].nativeList[i],
                  di = x0 - d0.dateTime > d1.dateTime - x0 ? d1 : d0;
                d = di;
                toolTipText += `<p><span class='legend-box' style='background-color:${element?.axisConfig?.color || color(_d.name)}'></span> ${d.dateTime.toLocaleString('en-GB')} ${_d.name || _d.description} : ${d.value}</p>`;
                return yScale(d.value);
              }
            })

          svg.selectAll('.line-tooltip')
            .attr("x1", xScale(d.dateTime))
            .attr("y1", 0)
            .attr("x2", xScale(d.dateTime))
            .attr("y2", height)
            .style('opacity', 1);

            config.alertRange?.forEach((item) => {
              if (item && (d.dateTime >= convertDate(item.startDate) && d.dateTime <= convertDate(item.endDate))) {
                toolTipText += `<p>${item.subscibedUser ? 'Subscribed' : 'Created'} Alert Start Time : ${moment(item.startDate).format("DD/MM/YYYY, HH:mm:ss")} </p>`;
                toolTipText += item.isActive ? '' : `<p>${item.subscibedUser ? 'Subscribed' : 'Created'} Alert End Time : ${moment(item.endDate).format("DD/MM/YYYY, HH:mm:ss")} </p>`;
                toolTipText += `<p>${item.subscibedUser ? 'Subscribed' : 'Created'} Alert Classification : ${item.alertClassification || 'N/A'} </p>`;
              }
            })

          tooltip
          .style("opacity", "0.89")
            .html(toolTipText)

          tooltip.node().style.right = 'auto';
          tooltip.node().style.top = 'auto';
          tooltip.node().style.left = 'auto';

          let width = svg.node().clientWidth;

          let tipWidth = tooltip.node().offsetWidth;
          let tipHeight = tooltip.node().offsetHeight;

          if (width - xScale(d.dateTime) < tipWidth) {
            tooltip.node().style.right = width - xScale(d.dateTime) - vhmarginleft + 10 + 'px';
            tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
            tooltip.node().style.left = 'auto';
            tooltip.classed('chart-tooltip-right', true);
            tooltip.classed('chart-tooltip-left', false);
          }
          else {
            tooltip.node().style.top = (height / 2) - (tipHeight / 2) + 'px';
            tooltip.node().style.left = d3.event.layerX + 10 + 'px';
            tooltip.node().style.left = xScale(d.dateTime) + vhmarginleft + 10 + 'px';
            tooltip.node().style.right = 'auto';
            tooltip.classed('chart-tooltip-right', false);
            tooltip.classed('chart-tooltip-left', true);
          }
        });
    }
    formatAxisConfig(svg, config.chartConfig, true);
  });
}