import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  constructor() { }
  private meterChangedSource = new Subject<string>();
  private sectorChangedSource = new Subject<string>();
  public meterChanged$ = this.meterChangedSource.asObservable();
  public sectorChanged$ = this.sectorChangedSource.asObservable();
  announceMeter(meter) {
    this.meterChangedSource.next(meter);
  }

  onSectorChange() {
    this.sectorChangedSource.next();
  }

}
