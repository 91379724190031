export let routes = {
    GET_CONSUMPTION_PER_DEVICE : '/threshold-alert-management/get-consumption-per-device',
    GET_CONSUMPTION_PER_DEVICE_ID : '/threshold-alert-management/get-consumption-by-device-id',
    PERCENTAGE_DATA :'/threshold-alert-management/percentageData',
    GET_ALERTS_NOTIFICATIONS: '/alerts-management/fetchNotifications',
    MARK_NOTIFICATION_STATUS: '/alerts-management/markNotificationStatus',
    GET_ALERT_BY_RULE_ID: '/threshold-alert-management/alert-by-ruleid',
    
}

