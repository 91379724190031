import { Component, OnInit } from '@angular/core';
import { breakPoints } from '../constant';
import { DeviceModalComponent } from 'src/app/modules/admin/device-modal/device-modal.component';
import { BulkRegistrationComponent } from 'src/app/modules/admin/bulk-registration/bulk-registration.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-register-device-modal',
  templateUrl: './register-device-modal.component.html',
  styleUrls: ['./register-device-modal.component.css']
})
export class RegisterDeviceModalComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  openDeviceDialog(deviceId, allowEdit, profileID = '') {
    let defaultHeight = '80vh';
    let defaultWidth = '47.584vw';
    let defaultMaxWidth = '47.584vw';

    if (breakPoints.isMobilePortrait || breakPoints.isMobileLandscape) {
      defaultWidth = '100vw';
      defaultMaxWidth = '100vw';
      defaultHeight = '100vh';
    }
    else if (breakPoints.isTabletPortrait || breakPoints.isTabletLandscape) {
      defaultWidth = '85vw';
      defaultMaxWidth = '85vw';
      defaultHeight = '65vh';
    }

    const dialogRef = this.dialog.open(DeviceModalComponent, {
      width: '47.584vw',
      data: {
        deviceId: deviceId,
        profileID,
        allowEdit: allowEdit,
      },
      maxWidth: defaultWidth,
      ...(!breakPoints.isDesktop ? { height: defaultHeight } : {}) // if it is not desktop then only give height

    });
  }

  openBulkRegistrationDialog() {
    let defaultHeight = '80vh';
    let defaultWidth = '39.531vw';
    let defaultMaxWidth = '39.531vw';

    if (breakPoints.isMobilePortrait || breakPoints.isMobileLandscape) {
      defaultWidth = '100vw';
      defaultMaxWidth = '100vw';
      defaultHeight = '100vh';
    }
    else if (breakPoints.isTabletPortrait || breakPoints.isTabletLandscape) {
      defaultWidth = '85vw';
      defaultMaxWidth = '85vw';
      defaultHeight = '65vh';
    }
    const bulkRegisterRef = this.dialog.open(BulkRegistrationComponent, {
      width: defaultWidth,
      maxWidth: defaultWidth,
      ...(!breakPoints.isDesktop ? { height: defaultHeight } : {}) // if it is not desktop then only give height

    })
  }

}
