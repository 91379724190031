import * as moment from "moment";
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_DAY_END, DATE_TIME_FORMAT_DAY_START, DATE_TIME_FORMAT_IGNORE_MILLISECONDS } from "./constant";

export const initializeDateRange = (days = 1, isUseDateFromLS = true) => {
  let startDate, endDate, dateRangeString;
  if (isUseDateFromLS && localStorage.getItem('dates')) {
    dateRangeString = getDateRange(JSON.parse(localStorage.getItem('dates')));
    startDate = dateRangeString[0];
    endDate = dateRangeString[1];
  }
  else {
    endDate = moment().format(DATE_TIME_FORMAT);
    startDate = moment().subtract(24, 'h').format(DATE_TIME_FORMAT);
    dateRangeString = [startDate, endDate, '24 hours'];
  }
  localStorage.setItem('dates', JSON.stringify(dateRangeString));
  return {
    startDate, endDate
  }
}

export const setDateRange = (dateArr, isSaveInLS = true) => {
  const dateRangeString = getDateRange(dateArr);
  if (isSaveInLS) {
    localStorage.setItem('dates', JSON.stringify(dateRangeString));
  }
  return {
    startDate : dateRangeString[0], endDate : dateRangeString[1]
  }
}

function getDateRange(dateArr) {
  let startDate, endDate;
  if (dateArr[2] === "24 Hours") {
    endDate = moment().format(DATE_TIME_FORMAT);
    startDate = moment().subtract(24, 'h').format(DATE_TIME_FORMAT);
  }
  else if(dateArr[2] === "Today") {
    endDate = moment().format(DATE_TIME_FORMAT);
    startDate = moment().subtract(moment().hour(), 'h').format(DATE_TIME_FORMAT_DAY_START);
  }
  else if(dateArr[2] == "3 Days" || dateArr[2] == "7 Days" || dateArr[2] == "30 Days") {
    const subtractDay = dateArr[2].split(" ")[0] - 1;
    endDate = moment().format(DATE_TIME_FORMAT_DAY_END);
    startDate = moment().subtract(subtractDay, 'days').format(DATE_TIME_FORMAT_DAY_START);
  }
  else if(dateArr[2] == "MTD") {
    startDate = moment().startOf('month').format(DATE_TIME_FORMAT_DAY_START);
    endDate = moment().format(DATE_TIME_FORMAT_DAY_END);
  }
  else if(dateArr[2] == "YTD") {
    startDate = moment().startOf('year').format(DATE_TIME_FORMAT_DAY_START);
    endDate = moment().format(DATE_TIME_FORMAT_DAY_END);
  }
  else {
    startDate = moment(new Date(dateArr[0])).format(DATE_TIME_FORMAT_IGNORE_MILLISECONDS);
    endDate =  moment(dateArr[1]).format(DATE_TIME_FORMAT_IGNORE_MILLISECONDS);
  }

  return [startDate, endDate, dateArr[2]];
}