import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: "root",
})

export class DataService {
    private hitAPiForPinnedDashboard = new Subject<boolean>();

    constructor() {}

// Observable streams
  getPinnedDashboard$ = this.hitAPiForPinnedDashboard.asObservable();

  getPinnedDashboard(data:boolean) {
    this.hitAPiForPinnedDashboard.next(data);
  }
}