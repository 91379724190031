import { ALBADA_DEVICE_TYPE, DEVICE_TYPE_COOLING_METER, DEVICE_TYPE_ENERGY_METER, DEVICE_TYPE_WATER_METER, DUBA_DEVICE_TYPE, HPR_STAION_DEVICE_TYPE, HYDRUS_METER, PUMP_STATION_DEVICE_TYPE, deviceTypeTitle } from "../constant"

export class ALERTS_NOTIFICATION {
    thresholdAlerts = {
        alerts: [],
        newAlertsCount: 0
    }
    smartAlerts = {
        alerts: [],
        newAlertsCount: 0
    }
    deviceAlerts = {
        alerts: [],
        newAlertsCount: 0
    }

}

export const HEADER_AND_ICONS = {
    [HYDRUS_METER] : {
      logo: "../../../assets/images/WATER.png",
      deviceType : deviceTypeTitle[HYDRUS_METER]
    },
    [DEVICE_TYPE_ENERGY_METER]: {
      logo: "../../../assets/images/neom-economic-sector-icons-rgb-energy@3x.png",
      deviceType: deviceTypeTitle[DEVICE_TYPE_ENERGY_METER]
    },
    [DUBA_DEVICE_TYPE]: {
      logo: "../../../assets/Neom-Images/desalination-plant.svg",
      deviceType: deviceTypeTitle[DUBA_DEVICE_TYPE]
    },
    [PUMP_STATION_DEVICE_TYPE]: {
      logo: "../../../assets/Neom-Images/pump-station.svg",
      deviceType: deviceTypeTitle[PUMP_STATION_DEVICE_TYPE]
    },
    [ALBADA_DEVICE_TYPE] : {
      logo: "../../../assets/Neom-Images/water-treatment-plant.svg",
      deviceType: deviceTypeTitle[ALBADA_DEVICE_TYPE]
    },
    [HPR_STAION_DEVICE_TYPE] : {
      logo: "../../../assets/Neom-Images/water-treatment-plant.svg",
      deviceType: deviceTypeTitle[HPR_STAION_DEVICE_TYPE]
    },
    [DEVICE_TYPE_COOLING_METER] : {
        logo: "../../../assets/images/WATER.png",
        deviceType: deviceTypeTitle[DEVICE_TYPE_COOLING_METER]
    },
    [DEVICE_TYPE_WATER_METER] : {
      logo: "../../../assets/images/WATER.png",
      deviceType : deviceTypeTitle[HYDRUS_METER]
    },
    
  }