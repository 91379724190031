import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-filter-for-column',
  templateUrl: './table-filter-for-column.component.html',
  styleUrls: ['./table-filter-for-column.component.css']
})
export class TableFilterForColumnComponent implements OnInit {
  public sortOrder = 'Ascending'
  @Input() filterData: Array<any> = [];
  @Input() title: string = '';
  @Output()
  applyFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  filterClick() {
    this.applyFilter.emit({
      filterData: this.filterData,
      title: this.title
    });
  }

}
