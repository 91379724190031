<div class="table-container table-outer-spacing" [ngStyle]="{ 'display': isLoader === true ? 'none' : 'block' }">
    <table>
        <thead>
            <tr class="deviceHeader">
                <th class="tableCell" *ngFor="let col of columnNames; let index = index" title="{{col}}">
                    <span [innerHTML]="col"></span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr style="cursor: pointer;" *ngFor="let item of filteredData;let index = index">
                <td class="tableCell" *ngFor="let col of columnNames; let index = index">
                    <span class="mutedValue">{{item[col]}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<app-table-pagination [current]="current" [itemPerPage]="perPage" [data]="tableData" (pageSizeChange)="pageSizeChange($event)" (goTo)="onGoTo($event)" (next)="onNext($event)"
    (previous)="onPrevious($event)"></app-table-pagination>