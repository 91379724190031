import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { thresholdInputValidatorOptions } from '../reusable/common.interfaces';

@Directive({
  selector: '[appThresholdInputValidation]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ThresholdInputValidationDirective, multi: true }
  ]
})
export class ThresholdInputValidationDirective implements Validator {

  @Input('appThresholdInputValidation') options: thresholdInputValidatorOptions;

  constructor(private customValidator: CustomvalidationService) { }

  validate(control: AbstractControl): { [key: string]: any } | null {
    // Default values if not provided
    const defaultOptions: thresholdInputValidatorOptions = {
      type: 'NUMBER',
      maxValue: 0,
    };
    
    // Merge default options with provided options
    this.options = { ...defaultOptions, ...this.options };
    return this.customValidator.thresholdInputValidator(this.options)(control);
  }

}
