<!-- <div class="locationSelector" *ngIf="availableOptions.length > 0"> -->
<div [matTooltip]="isSiteSelected() ? '' : 'Select Site'" class="sites-container">
  <button mat-button (click)="toggleTreeVisibility()">
    <img class="locationIcon" width="18" src="assets/images/Location-new.svg" /> {{ getSelectedItemsText() }}
    <span class="selectedDevices" *ngIf="getBadgeCount() > 0">+{{ getBadgeCount() }}</span>
    <img class="arrowIcon" width="25" src="assets/images/arrow-dropdown.svg" />
  </button>
  <div *ngIf="treeVisible">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="0">
        <button class="button__arrow" mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
          (change)="todoLeafItemSelectionToggle(node)">{{ node.item }}</mat-checkbox>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="0">
        <button class="button__arrow" mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
          (change)="todoItemSelectionToggle(node)">{{ node.item }}</mat-checkbox>
      </mat-tree-node>
    </mat-tree>
  </div>
</div>

<!-- <ng-select
        [items]="availableOptions"
        [multiple]="true"
        bindLabel="zone"
        groupBy="site"
        [selectableGroup]="true"
        [selectableGroupAsModel]="false"
        [closeOnSelect]="false"
        bindValue="id"
        [(ngModel)]="selectedSites"
        (change)="handleSiteChange()"
    >
        <ng-template
          ng-optgroup-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
            {{ item.site }}
        </ng-template>
        <ng-template
            ng-option-tmp
            let-item="item"
            let-item$="item$"
            let-index="index"
        >
            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
            {{ item.zone }}
        </ng-template>
    </ng-select> -->

<!-- </div> -->