import { ACTIVE, ALERT_CLASSFICATION_STAGNANT, ALERT_CLASSFICATION_STALE, ALERT_CLASSFICATION_THRESHOLD, RESOLVED} from "src/app/reusable/constant"

export const COLUMN_NAMES = {
    alertName : [],
    status : [],
    alertClassification: [],
    eventStart:[],
    eventEnd:[],
    createdOn:[],
    activeFor: [],
}


export class FILTER_COLUMNS {
    status = [
        {
          selected: false,
          name: 'Active',
          status: ACTIVE,
          key : "status"
        },
        {
          selected: false,
          name: 'Resolved',
          status: RESOLVED,
          key : "status"
        }
    ]
    alertClassification = [
        {
          selected: false,
          name: 'Numeric Threshold',
          alertClassification: ALERT_CLASSFICATION_THRESHOLD,
          key : "alertClassification"
        },
        {
          selected: false,
          name: 'Stale Data',
          alertClassification: ALERT_CLASSFICATION_STALE,
          key : "alertClassification"
        },
        {
          selected: false,
          name: 'Stagnant Reading',
          alertClassification: ALERT_CLASSFICATION_STAGNANT,
          key : "alertClassification"
        }
    ]
}

export const SORT_FILTER  = {
    eventStart: {
        inc: true,
        dec: false,
        hover: false,
        key : "eventStart"
    },
    eventEnd: {
        inc: true,
        dec: false,
        hover: false,
        key : "eventEnd"
    },
    createdOn: {
        inc: true,
        dec: false,
        hover: false,
        key : "createdOn"
    },
    activeFor: {
        inc: true,
        dec: false,
        hover: false,
        key : "activeFor"
    },
}