import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { convertDate, getFormatedDate } from '../chart-util/chart-helper';
import * as _ from 'lodash';
@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.css']
})
export class TableViewComponent implements OnInit {

  @Input()
  isLoader = true;

  @Input()
  data = [];

  @Input()
  element = '.chart-container';

  @Input()
  widget: any;

  filteredData = [];
  tableData = [];
  columnNames = [];
  public current: number = 1;
  public perPage = 10;
  constructor() { }

  ngOnInit(): void {
    this.renderTable();
  }

  ngOnChanges() {
    this.renderTable();
  }

  renderTable(htmlElement = this.element) {
    if (this.data.length > 0 && (this.widget.widgetType === "tablechart" || this.widget.type === "tablechart")) {
      d3.select(`${htmlElement} .table-container`).style('height', (0) + 'px')
      d3.select(htmlElement).style("display", 'block');
      const height = parseFloat(d3.select(htmlElement).style('height'));

      d3.select(`${htmlElement} .table-container`).style('height', (height - 70) + 'px');
      const groups = [];
      const _data = [];
      let index = 1;
      if (this.widget.requestPayload.aggregationInterval === "native") {
        if (this.data?.length === 1) {
          this.data?.forEach(element => {
            let newNativeList = _.cloneDeep(element?.nativeList)
            newNativeList?.sort(function (a: any, b: any) {
              return convertDate(b.dateTime) - convertDate(a.dateTime);
            });
            newNativeList?.forEach(item => {
              let attributeObj = {};
              const datetime = convertDate(item.dateTime);
              attributeObj['Sr. No'] = index;
              attributeObj['Date'] = (getFormatedDate(datetime, this.widget.requestPayload.aggregationInterval));
              attributeObj[`${element.name || element.description}`] = Number(item.value).toFixed(2) === "NaN" ? item.value : Number(item.value).toFixed(2);
              index = index + 1;
              _data.push(attributeObj);
            });
          });
        }
        else {
          if (this.widget.oneDeviceData) {
            const { nativeList } = this.data?.find(data => data?.nativeList?.length);
            let position = nativeList?.length;
            for (const obj in nativeList) {
              groups.push({ key: obj, date: new Date(nativeList[obj].dateTime || nativeList[obj]?.dateRange[0]) });
            }
            groups.sort(function (a: any, b: any) {
              return convertDate(b.date) - convertDate(a.date);
            });

            for (const interval of groups) {
              let attributeObj = {};
              const datetime = convertDate(interval.date);
              attributeObj['Sr. No.'] = index;
              attributeObj['Date'] = getFormatedDate(datetime, this.widget.requestPayload.aggregationInterval);
              for (const attribute of this.widget.subgroups) {
                const value = this.data.find((x) => x.name === attribute.name || x.description === attribute.name)?.nativeList[position - 1]?.value;
                let displayName = attribute.deviceType === "Water Meter" ? "WM" : ''
                displayName = attribute.deviceType === "Energy Meter" ? "EM" : displayName;
                displayName = attribute.deviceType === "DubaPlantScada" ? "DP" : displayName;
                displayName = attribute.deviceType === "Flow Meter" ? "FM" : displayName;
                attributeObj[`${attribute?.displayName || attribute?.name || attribute.description}  ${attribute.unit || ''}`] = value ? (Number(value).toFixed(2) === 'NaN' ? value : Number(value).toFixed(2)) : 'null';
              }
              index = index + 1;
              position = position - 1;
              _data.push(attributeObj);
            }
          }
          else {
            this.data.forEach(element => {
              let newNativeList = _.cloneDeep(element.nativeList)
              newNativeList.sort(function (a: any, b: any) {
                return convertDate(b.dateTime) - convertDate(a.dateTime);
              });
              newNativeList.forEach(item => {
                let attributeObj = {};
                const datetime = convertDate(item.dateTime);
                attributeObj['Sr. No'] = index;
                attributeObj['Date'] = (getFormatedDate(datetime, this.widget.requestPayload.aggregationInterval));
                attributeObj['Attribute'] = `${element.name || element.description}`;
                attributeObj['Value'] = Number(item.value).toFixed(2) === "NaN" ? item.value : Number(item.value).toFixed(2);
                index = index + 1;
                _data.push(attributeObj);
              });
            });
          }
        }
      }
      else {
        const aggregatedValue = this.data[0].aggregation;
        for (const obj in aggregatedValue) {
          groups.push({ key: obj, date: new Date(aggregatedValue[obj].dateTime || aggregatedValue[obj]?.dateRange[0]) });
        }

        groups.sort(function (a: any, b: any) {
          return convertDate(b.date) - convertDate(a.date);
        });

        for (const interval of groups) {
          let attributeObj = {};
          const datetime = convertDate(interval.date);
          attributeObj['Sr. No.'] = index;
          index = index + 1;
          attributeObj['Date'] = getFormatedDate(datetime, this.widget.requestPayload.aggregationInterval);
          for (const attribute of this.widget.subgroups) {
            const value = this.data.find((x) => x.name === attribute.name || x.description === attribute.name)?.aggregation[`${interval.key}`]?.value;
            let displayName = attribute.deviceType === "Water Meter" ? "WM" : ''
            displayName = attribute.deviceType === "Energy Meter" ? "EM" : displayName;
            displayName = attribute.deviceType === "DubaPlantScada" ? "DP" : displayName;
            displayName = attribute.deviceType === "Flow Meter" ? "FM" : displayName;
            if (this.widget.oneDeviceData) {
              attributeObj[`${attribute?.displayName || attribute?.name || attribute.description} ${this.widget.requestPayload.aggregationType} ${attribute.unit || ''}`] = value ? parseFloat(value).toFixed(2) : value === 0 ? value : ''
            }
            else {
              attributeObj[`${attribute?.displayLegend}  ${this.widget.requestPayload.aggregationType}  ${attribute.unit || ''}`] = value ? parseFloat(value).toFixed(2) : value === 0 ? value : ''
            }
          }
          _data.push(attributeObj);
        }
      }
      this.columnNames = _data[0] ? Object?.keys(_data[0]) : [];
      this.tableData = _data;
      this.filteredData = this.paginate(this.current, this.perPage)
    }
  }

  public onGoTo(page: any): void {
    this.current = page
    this.filteredData = this.paginate(this.current, this.perPage)
  }

  public onNext(page: number): void {
    this.current = page + 1
    this.filteredData = this.paginate(this.current, this.perPage)
  }

  public onPrevious(page: number): void {
    this.current = page - 1
    this.filteredData = this.paginate(this.current, this.perPage)
  }

  public pageSizeChange(perPage: any): void {
    this.perPage = perPage;
    this.current = 1;
    this.filteredData = this.paginate(this.current, this.perPage)
  }

  public paginate(current: number, perPage: number): string[] {
    return [...this.tableData.slice((current - 1) * perPage).slice(0, perPage)]
  }
}
