<!--New Home Page Start Here-->
<div class="main-Div" *ngIf="accessingFrom !== 'dashboard'">
  <div style="position: relative;">
    <app-navigation class=""></app-navigation>
    <div class="watermeterDatepicker">
      <div class="locationSelector">
        <app-sites [sites]="sites" (siteChanges)="siteChanges($event)">
        </app-sites>
      </div>
      <div class="dateFunction">
        <app-common-datepicker (dateChange)="datepicker($event)"
          [dateSelected]="[startDate,endDate]"></app-common-datepicker>
      </div>
    </div>
  </div>
  <div class="homeContainer">
    <!--------------------------Cards section----------------------->
    <div class="dashboardPage">
      <div class="statusInfo">
        <ng-container *ngIf="!deviceCountLoader">
          <div class="cardMargin">
            <img src="assets/images/Devices-new.svg" class=" deviceImg">
            <p class="status-heading">Total Devices</p>
            <p class="status-data text-big"> {{ deviceListing?.length || 0 }} </p>
            <ng-container *ngIf="deviceListing.length > 0">
              <div class="onlineOffline">
                <div class="onlineDevice" [ngStyle]="getStyles(activeDevices,deviceListing?.length)"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="deviceListing?.length === 0">
              <div class="onlineOffline" [ngStyle]="{'width':'100%'}"></div>
            </ng-container>
            <div class="status-card-footer activeDeviceText">
              <div class="grey-text">
                <span class="totalNumber">{{activeDevices}}</span> Live
              </div>
              <div class="grey-text">
                <span class="totalNumber">{{((deviceListing?.length - activeDevices) || 0)}}</span> Offline
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="deviceCountLoader">
          <div class="loader">
          </div>
        </ng-container>
      </div>
      <div class="statusInfo">
        <div class="loader" *ngIf="averageConsumptionPerHouseholdStatLoader">

        </div>
        <div class="cardMargin" *ngIf="!averageConsumptionPerHouseholdStatLoader">
          <span *ngIf="meterType==='water'"> <img src="assets/images/Water-consumption-new.svg" class=" mtdIcon"></span>
          <span *ngIf="meterType==='energy' || meterType==='cooling'"><img
              src="assets/images/Energy-consumption-new.svg" class=" energyIcon"></span>
          <p class="status-heading">Total Consumption</p>
          <p class="status-data text-big">{{averageConsumptionPerHousehold?.totalConsumption?.toFixed(2)}}
            <span *ngIf="meterType=='water'">m <sup>3</sup></span><span
              *ngIf="meterType=='energy' || meterType=='cooling'">kWh</span>
          </p>
          <div class="status-card-footer cardFooter">
            <div>
              <div class="bgDecColor chartBtn">
                <div class="decColor">
                  <span> 0% </span>
                  <img src='../../../assets/images/downGrowthArrow.svg' class="changePercent">
                </div>
              </div>
            </div>
            <span class="status-card-footer-col grey-text sinceCardtext">Since Last Period</span>
          </div>
        </div>
      </div>
      <div class="statusInfo">
        <div class="loader" *ngIf="averageConsumptionPerHouseholdStatLoader">
        </div>
        <div class="cardMargin">
          <div *ngIf="!averageConsumptionPerHouseholdStatLoader">
            <span *ngIf="meterType==='water'"> <img src="assets/images/Water-consumption-new.svg"
                class=" mtdIcon"></span>
            <span *ngIf="meterType==='energy' || meterType==='cooling'"><img
                src="assets/images/Energy-consumption-new.svg" class=" changePercent"></span>
            <p class="status-heading">Avg Consumption Per Day</p>
            <p class="status-data text-big">{{averageConsumptionPerHousehold?.avgConsumptionPerDay?.toFixed(2)}}
              <span *ngIf="meterType=='water'">m <sup>3</sup></span><span
                *ngIf="meterType=='energy' || meterType=='cooling'">kWh</span>
            </p>
            <div class="status-card-footer cardFooter">
              <div>
                <div class="chartBtn bgIncColor">
                  <div class="incColor">
                    <span> 0% </span>
                    <img src='../../../assets/images/upGrowthArrow.svg' class="changePercent">
                  </div>
                </div>
              </div>
              <span class="status-card-footer-col grey-text sinceCardtext">Since Last Period</span>
            </div>
          </div>
        </div>
      </div>
      <div class="statusInfo" title="Alerts Info">
        <!-- show when alertLoader is false -->
        <ng-container *ngIf="!alertLoader">
          <div class="alertProgress">
            <div class="cardMargin">
              <div class="alertbtn">
                <img src="assets/images/Alerts-new.svg" class=" alertsIcon">
                <a [routerLink]="['/alert-management']" [queryParams]="{ deviceType: meterType }" target="_blank"><span
                    class="alertbtnBg"><img class="shareIcon" src="../../../assets/images/share-icon.svg"> </span></a>
              </div>
              <p class="status-heading">Total Alerts</p>
              <p class="status-data text-big">{{totalAlert}}</p>
            </div>
            <div class="meterCount">
              <ng-container *ngIf="totalAlert > 0">
                <div class="onlineOffline">
                  <div class="onlineDevice" [ngStyle]="getStyles(totalAlert-activeAlerts,totalAlert)"></div>
                </div>
              </ng-container>
              <ng-container *ngIf="totalAlert === 0">
                <div class="onlineOffline" [ngStyle]="{'width':100+'%'}"></div>
              </ng-container>
              <div class="status-card-footer">
                <div class="grey-text">
                  <span class="totalNumber">{{totalAlert-activeAlerts || 0}}</span> Resolved
                </div>
                <div class="grey-text">
                  <span class="totalNumber">{{activeAlerts || 0}}</span> Active
                </div>
              </div>
            </div>
          </div>

        </ng-container>
        <!-- show when alert loader is true -->
        <ng-container *ngIf="alertLoader">
          <div class="loader">
          </div>
        </ng-container>
      </div>
      <div class="statusInfo">
        <div class="loader" *ngIf="percentageLoader">
        </div>
        <div class="cardMargin" *ngIf="!percentageLoader">
          <span *ngIf="meterType==='water' || meterType==='cooling'"> <img src="assets/images/Connection-health-new.svg"
              class=" healthIcon"></span>
          <span *ngIf="meterType==='energy'"><img src="assets/images/Connection-health-new.svg"
              class=" healthIcon"></span>
          <p class="status-heading2">Connection Health Score</p>
          <p class="status-data2 text-big">{{percentageValue?.toFixed(2)}} %
          </p>
          <div class="status-card-footer cardFooter subText">
            <div class="status-card-footer-col grey-text sinceCardtext frequencyText"
              *ngIf="meterType==='water' || meterType==='cooling'">Observed at the expected frequency of 5 mins</div>
            <div class="status-card-footer-col grey-text sinceCardtext frequencyText" *ngIf="meterType==='energy'">
              Observed at the expected frequency of 15 mins</div>
          </div>
        </div>
      </div>
    </div>
    <!--------------------------End Cards section----------------------->

    <!--------------------------Tab Table section----------------------->
    <div class="tableTab">
      <div class="tabTable">
        <div class="energyZonetable">
          <div class="main-table bg-white rounded">
            <table *ngIf="zoneList?.length;else noZones" class="table zonetableSpace">
              <thead>
                <tr>
                  <th class="zoneRow">Zone</th>
                  <th class="zoneRow">Site</th>
                  <th class="zoneRow">Gateway</th>
                  <th class="zoneRow">Device</th>
                  <th class="stateRow">State</th>
                  <th class="stateRow">Alert</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let zone of zoneList">
                  <td>{{zone?.name}}</td>
                  <td>{{zone?.site}}</td>
                  <td>1 Gateway</td>
                  <td>{{zone?.device?.length}} Devices</td>
                  <td>
                    <p *ngIf="zone?.allLive"> All Devices Live</p>
                    <p *ngIf="zone?.offline?.length">
                      {{zone?.offline?.length}} Devices Offline
                    </p>
                  </td>
                  <td>{{zonewiseAlert[zone?.name]||0}} Active Alerts</td>
                </tr>
              </tbody>
            </table>
          </div>
          <ng-template #noZones>
            <div class="noZone">
              <p>No zones available</p>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="tableMapInfo">
        <div class="mapInfo">
          <app-zone-map *ngIf="mapData?.data && deviceListing;else noMap" [mapData]="mapData" [devices]="deviceListing"></app-zone-map>
        </div>
      </div>
      <ng-template #noMap>
        <div class="noZone bg-white rounded">
          <p>No Map available</p>
        </div>
      </ng-template>
    </div>
    <!--------------------------End Tab Table section----------------------->

    <!--------------------------Average Consumption section----------------------->

    <div class="bg-white rounded countDiv averageGraph">
      <div class="">
        <div class="textBlack"
          [ngClass]="{'tabMobileWidth': (breakPoints.isMobilePortrait || breakPoints.isMobileLandscape),'tabTabletWidth':(breakPoints.isTabletPortrait || breakPoints.isTabletLandscape) ,'tabDesktopWidth':breakPoints.isDesktop}">
          <mat-tab-group (selectedTabChange)="consumptionTypeChange($event.tab.textLabel)">
            <mat-tab label="Total Consumption">
              <ng-template mat-tab-label>
                <label>Total Consumption <span *ngIf="meterType=='water'"> (m<sup>3</sup>)</span><span
                    *ngIf="meterType=='energy'">(kWh)</span></label>
              </ng-template>
            </mat-tab>
            <mat-tab label="Average Consumption">
              <ng-template mat-tab-label>
                <label>Avg Consumption Per Device <span *ngIf="meterType=='water'"> (m<sup>3</sup>)</span><span
                    *ngIf="meterType=='energy'">(kWh)</span></label>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
        <div>
          <!-- show for mobile portrait and landscape mode -->
          <div *ngIf="breakPoints.isMobilePortrait || breakPoints.isMobileLandscape">
            <div class="hourActive">
              <a [ngClass]="averageConsumptionPerHouseholdAggregate==='hourly'? 'active' :'unactive' "
                (click)="getConsumptionPerDevice('hourly')">Hourly</a>
              <a [ngClass]="averageConsumptionPerHouseholdAggregate==='daily'? 'active' :'unactive' "
                (click)="getConsumptionPerDevice('daily')">Daily</a>
              <a [ngClass]="averageConsumptionPerHouseholdAggregate==='weekly'? 'active' :'unactive' "
                (click)="getConsumptionPerDevice('weekly')">Weekly</a>
              <a [ngClass]="averageConsumptionPerHouseholdAggregate==='monthly'? 'active' :'unactive' "
                (click)="getConsumptionPerDevice('monthly')">Monthly</a>
            </div>
          </div>
          <!-- ------------------------------------------ -->
          <div class="chart-container deviceDashboardChartMargin">
            <div class="main-chart whiteMinHeight average-consumption deviceDashboardChartMargin"
              [hidden]="averageConsumptionPerHouseholdLoader">
              <div class="pad-10" *ngIf="averageConsumptionPerHouseholdNoDataFound">
                <app-chart-data-not-found-template></app-chart-data-not-found-template>
              </div>
            </div>
            <div [hidden]="!averageConsumptionPerHouseholdLoader" class="loaderImg chartLoader">
              <div class="loader"></div>
            </div>
          </div>
        </div>


        <!-- hide for mobile portrait and landscape mode -->
        <div *ngIf="!(breakPoints.isMobilePortrait || breakPoints.isMobileLandscape)" class="expand ontime">
          <div class="hourActive">
            <a [ngClass]="averageConsumptionPerHouseholdAggregate==='hourly'? 'active' :'unactive' "
              (click)="getConsumptionPerDevice('hourly')">Hourly</a>
            <a [ngClass]="averageConsumptionPerHouseholdAggregate==='daily'? 'active' :'unactive' "
              (click)="getConsumptionPerDevice('daily')">Daily</a>
            <a [ngClass]="averageConsumptionPerHouseholdAggregate==='weekly'? 'active' :'unactive' "
              (click)="getConsumptionPerDevice('weekly')">Weekly</a>
            <a [ngClass]="averageConsumptionPerHouseholdAggregate==='monthly'? 'active' :'unactive' "
              (click)="getConsumptionPerDevice('monthly')">Monthly</a>
          </div>
        </div>
      </div>
    </div>
    <!--------------------------End Average Consumption section----------------------->

    <!-------------------------- Consumption By Device section----------------------->

    <div class="bg-white rounded countDiv averageGraph">
      <div class="">
        <div class="bb-pt">
          <div class="monthlyHeader" [ngClass]="{'mobileNgSelect':breakPoints.isMobilePortrait}">
            <div class="textBlack"> Consumption <span *ngIf="meterType=='water'"> (m<sup>3</sup>)</span><span
                *ngIf="meterType=='energy'">(kWh)</span> by device </div>
            <div>
              <ng-select #selectDropdown class="customDropdown" [disableOptionCentering]="true" #select
                [dropdownPosition]="'right'" [items]="filterDeviceListing" bindLabel="uplinkReferenceKey"
                bindValue="uplinkReferenceKey" [(ngModel)]="selectedDevice" (change)="deviceSelectionChange($event)"
                placeholder="{{selectedDevice?.length ? (selectedDevice?.length / filterDeviceListing?.length) : 'No Devices'}}"
                [multiple]="true" [searchable]="false" [closeOnSelect]="false" [clearable]="false"
                [clearOnBackspace]="false">
                <ng-template ng-header-tmp>
                  <div class="d-flex">
                    <img src="assets/images/searchNew_1.svg" class="w-20 searchIcon" width="20px">
                    <input type="text" placeholder="Search Devices" (input)="select.filter($event.target.value)"
                      class="searchInputMenu" />
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <!-- Attributes ({{selectedDevice?.length }}/{{ filterDeviceListing?.length}}) -->
                  <div class="ng-value valueText" *ngFor="let item of items | slice: 0:1">
                    {{ item?.uplinkReferenceKey }}
                    <span class="selectedDevices">{{selectedDevice.length > 1 ? '+' + (selectedDevice.length-1) :
                      ''}}</span>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <mat-checkbox id="item-{{index}}" [ngModel]="item$.selected"
                    (click)="false">{{item?.uplinkReferenceKey}}
                  </mat-checkbox>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div *ngIf="breakPoints.isMobilePortrait" class="hourActive">
              <a [ngClass]="averageConsumptionPerDeviceAggregate==='hourly'? 'active' :'unactive' "
                (click)="getConsumptionByDeviceId('hourly')">Hourly</a>
              <a [ngClass]="averageConsumptionPerDeviceAggregate==='daily'? 'active' :'unactive' "
                (click)="getConsumptionByDeviceId('daily')">Daily</a>
              <a [ngClass]="averageConsumptionPerDeviceAggregate==='weekly'? 'active' :'unactive' "
                (click)="getConsumptionByDeviceId('weekly')">Weekly</a>
              <a [ngClass]="averageConsumptionPerDeviceAggregate==='monthly'? 'active' :'unactive' "
                (click)="getConsumptionByDeviceId('monthly')">Monthly</a>
            </div>
          </div>
          <div class="chart-container deviceDashboardChartMargin">
            <div class="main-chart whiteMinHeight monthly-consumption deviceDashboardChartMargin"
              [hidden]="averageConsumptionPerDeviceLoader">
              <div class="pad-10" *ngIf="averageConsumptionPerDeviceNoDataFound">
                <app-chart-data-not-found-template></app-chart-data-not-found-template>
              </div>
            </div>
            <div [hidden]="!averageConsumptionPerDeviceLoader" class="loaderImg">
              <div class="loader"></div>
            </div>
          </div>
          <div class="chartLegends">
            <app-chart-legend (legendOutput)="displayChart($event)" [subgroups]="deviceSubgroups"></app-chart-legend>
          </div>
        </div>
        <div class="expand ontime">
          <div *ngIf="!breakPoints.isMobilePortrait" class="hourActive">
            <a [ngClass]="averageConsumptionPerDeviceAggregate==='hourly'? 'active' :'unactive' "
              (click)="getConsumptionByDeviceId('hourly')">Hourly</a>
            <a [ngClass]="averageConsumptionPerDeviceAggregate==='daily'? 'active' :'unactive' "
              (click)="getConsumptionByDeviceId('daily')">Daily</a>
            <a [ngClass]="averageConsumptionPerDeviceAggregate==='weekly'? 'active' :'unactive' "
              (click)="getConsumptionByDeviceId('weekly')">Weekly</a>
            <a [ngClass]="averageConsumptionPerDeviceAggregate==='monthly'? 'active' :'unactive' "
              (click)="getConsumptionByDeviceId('monthly')">Monthly</a>
          </div>
        </div>
      </div>
    </div>
    <!--------------------------End  Consumption By Device section----------------------->

    <!-- <div *ngIf="meterType=='water'" class="bg-white rounded countDiv averageGraph">
      <div class="">
        <div class="forecastingHeader">
          <div class="textBlack"> Consumption (m3) Forecast for 7 Days
          </div>
          <div>
            <button (click)="downloadData()" class="button-Secondary-N downloadButton"><img
                src="assets/Neom-Images/download.svg" width="10px"> Export Data</button>
          </div>
        </div>
        <div>
          <div class="chart-container deviceDashboardChartMargin">
            <div class="main-chart whiteMinHeight average-consumption-forecasting deviceDashboardChartMargin"
              [hidden]="averageConsumptionPerHouseholdLoader">
              <div class="pad-10" *ngIf="foreCastingNoDataFound">
                <app-chart-data-not-found-template></app-chart-data-not-found-template>
              </div>
            </div>
            <div [hidden]="!averageConsumptionPerHouseholdLoader" class="whiteMinHeight spinner">
               <div class="loader"></div>
            </div>
          </div>
          <div class="chartLegends">
            <app-chart-legend (legendOutput)="forecastingChart($event)" noClick="true"
              [subgroups]="forecastinGroups"></app-chart-legend>
          </div>
        </div>
      </div>

    </div> -->

    <!--End New Home Page Start Here-->
    <div *ngIf="accessingFrom == 'dashboard'">
      <div class="hideBborder">
        <div class="textBlack"
          [ngClass]="{'tabMobileWidth': (breakPoints.isMobilePortrait || breakPoints.isMobileLandscape),'tabTabletWidth':(breakPoints.isTabletPortrait || breakPoints.isTabletLandscape) ,'tabDesktopWidth':breakPoints.isDesktop}">
          <mat-tab-group (selectedTabChange)="consumptionTypeChange($event.tab.textLabel)">
            <mat-tab label="Total Consumption">
              <ng-template mat-tab-label>
                <label>Total Consumption <span *ngIf="meterType=='water'"> (m<sup>3</sup>)</span><span
                    *ngIf="meterType=='energy'">(kWh)</span></label>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </div>
        <!-- For new dashboard -->
        <div>
          <div class="switchesEnergy homeGraph">
            <div class="switchTab" [ngClass]="{'active': showContent === 'energy'}" (click)="handleToggle('energy')">
              <div class="dots green"></div> Energy
            </div>
            <div class="switchTab" [ngClass]="{'active': showContent === 'water'}" (click)="handleToggle('water')">
              <div class="dots blue"></div> Water
            </div>
          </div>
        </div>
        <!-- ------------------------------------------ -->
        <div class="chart-container">
          <div class="main-chart average-consumption minHeight300" [hidden]="averageConsumptionPerHouseholdLoader">
            <div class="pad-10" *ngIf="averageConsumptionPerHouseholdNoDataFound">
              <app-chart-data-not-found-template></app-chart-data-not-found-template>
            </div>
          </div>
          <div [hidden]="!averageConsumptionPerHouseholdLoader" class="minHeight300 spinner">
            <!-- <app-loader [showFullPage]="false"></app-loader> -->
            <div class="loader"></div>
          </div>
        </div>
        <!-- <div class="showHideMap" (click)="changeMap()">
          {{!isMap ? 'Show Map':'Show Chart'}}
          <span *ngIf="isMap" class="material-symbols-outlined">show_chart</span>
          <span *ngIf="!isMap" class="material-symbols-outlined">map</span>
        </div> -->
      </div>
    </div>
  </div>
</div>
