import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ngHoverClass]'
})
export class HoverClassDirective {
  @Input(`ngHoverClass`)
  set classesToAply(v: string | string[]) {
    this.classes = Array.isArray(v) ? v : v.split(' ');
  }
  private classes: string[] = [];

  constructor(private renderer: Renderer2, private element: ElementRef) { }

  @HostListener('mouseover')
  onHover() {
    this.classes.forEach(c => this.renderer.addClass(this.element.nativeElement, c));
  }

  @HostListener('mouseleave')
  onLeave() {
    this.classes.forEach(c => this.renderer.removeClass(this.element.nativeElement, c));
  }
}