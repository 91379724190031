import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'fromNowTime'
})
export class FromNowTimePipe implements PipeTransform {

  transform(date: any) {
    const formattedDate = new Date(date);
    const fromNowTime = moment(formattedDate).fromNow();
    return `${fromNowTime}`;
  }

}
