import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomDashboardService } from 'src/app/services/CustomDashboard/custom-dashboard.service';
import { getLoginUser } from '../user-util';

@Component({
  selector: 'app-custom-dashboard-list',
  templateUrl: './custom-dashboard-list.component.html',
  styleUrls: [
    './custom-dashboard-list.component.css',
    '../../../assets/Reusable-CSS/matTooltip.scss'
  ]
})
export class CustomDashboardListComponent implements OnInit {

  public isLoader = false;
  public noFound = false;
  public customDashboard = [];
  public filteredCustomDashboard = [];
  noFoundText = ''
  bottomText = ''

  constructor(public customDashboardService: CustomDashboardService,
    public router: Router,
    public route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getMyDashboard();
  }

  getMyDashboard() {
    this.isLoader = true;
    this.customDashboardService.getAllCustomDashboard(getLoginUser()).then((data: any) => {
      this.isLoader = false;
      this.customDashboard = data.dashboards;
      this.noFound = this.customDashboard.length === 0 ? true : false;

      this.filterCustomDashboards(0);
    })
  }

  redirectDashboard(dashboard) {
    
    if(dashboard.drawingData){
      this.router.navigate([`/connectioncanvas/` + dashboard._id]);
    }else{
      this.router.navigate([`/custom-dashboard/` + dashboard._id]);
    }
  }

  filterCustomDashboards(toggle, event?){
    try {
      if(toggle === 0){
        this.filteredCustomDashboard = this.customDashboard.filter(x=> (!x.drawingData));
        this.noFoundText = "You haven't created any custom dashboards yet"
        this.bottomText = "Execute search to get started"
      }else if(toggle === 1){
        this.filteredCustomDashboard = this.customDashboard.filter(x=> (x.drawingData));
        this.noFoundText = "You haven't created any HMI Projects yet"
        this.bottomText = ""
      }

      this.noFound = this.filteredCustomDashboard.length === 0 ? true : false;

      let switch1 = document.getElementById('switch1');
      let switch2 = document.getElementById('switch2');

      switch1.classList.remove('selectedSwitch');
      switch2.classList.remove('selectedSwitch');

      if(event){
        event.currentTarget.classList.add("selectedSwitch");
      }else{
        switch1.classList.add('selectedSwitch');
      }

    } catch (error) {
      throw new Error("Custom Dashboard List : filterCustomDashboards() : " + error);
    }
  }

}
