
<div *ngIf="!filterName && filterType === 'deviceListing'" class="filter-col col-xs-12">
    <p class="mb-1 grey-text">{{title}}</p>
    <div class="input-dropdown ">
        <div *ngFor="let option of data.options" class="filter-category" [ngClass]="{'no-click': !filterCount[option]}" (click)="filterSelect(option)">
            {{option}} <span class="filter-type-count">{{filterCount[option]}}</span>
        </div>
    </div>
</div>



<div *ngIf="filterType !== 'deviceListing'" class="filter-col col-xs-12">
    <p class="mb-1 grey-text">{{getTitle(title)}}</p>
    <div class="input-dropdown">
        <div *ngFor="let option of data.options" [ngClass]="getSelectedFilterClass(option)" class="filter-category" (click)="selectAlertFilter(option)">
            {{option}} <span class="filter-type-count">{{filterCount[option]}}</span>
        </div>
    </div>
</div>
