import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TableAppliedFiltersComponent } from 'src/app/reusable/custom-table/table-applied-filters/table-applied-filters.component';
import { TableSearchComponent } from 'src/app/reusable/custom-table/table-search/table-search.component';
import { PaginationComponent } from 'src/app/reusable/custom-table/pagination/pagination.component';
import { Router } from '@angular/router';


@Component({
  selector: 'app-meter-logs',
  templateUrl: './meter-logs.component.html',
  styleUrls: ['./meter-logs.component.css', '../../../assets/Reusable-CSS/table.scss', '../../../assets/Reusable-CSS/main.scss', '../../../assets/Reusable-CSS/matTooltip.scss']
})

export class MeterLogsComponent implements OnInit {

  @Input() filterColumns = {};
  @Input() sortFilter = {};
  @Input() columnNames = {};
  @Input() loading = false;
  @Input() data = [];

  @ViewChild('tablePagination', { static: false }) tablePagination: PaginationComponent;

  constructor() { }
  ngOnInit() {
  }

  public appliedFilters = [];
  public paginatedData: any = []; // this will store the paginated data based on item per page, like 10 if itemPerPage = 10, this variable used on the ui
  public filterAlerts = [];
  public currentPage: number = 1;
  public itemPerPage: number = 10;
  public pageSizeOption = [10, 15, 20, 25]

  handlePaginationDone(data) {
    this.filterAlerts = data?.filteredData;
    this.paginatedData = data?.paginatedData;
  }

  handleAppliedFilter(item) {
    if (item.selected) {
      this.appliedFilters.push(item);
    }
    else {
      const idx = this.appliedFilters.findIndex(filter => filter.name === item.name);
      this.appliedFilters.splice(idx, 1);
    }
    this.tablePagination.handleAppliedFilters();
  }

  sortTable(data) {
    this.tablePagination.handleSort(data);
  }

}
