export class mqttProfile {
    authenticationType: string = ''//
    connectorProperties: {
        applicationUri?:string,
        certificatePath?:string,
        host?:string,
        username?:string,
        password?:string,
        messageSecurityMode?:string,
        port?:number,
        pollInterval?:number,
        privateKeyPath?:string,
        securityPolicy?:string,
        useSubscription?:boolean,
        useCommonDeviceTopic?: boolean,
        uniqueTopicIdentifier?: string

    }= {
        applicationUri:"",
        certificatePath:'',
        host:'',
        username:'',
        password:'',
        messageSecurityMode:'',
        port:0,
        pollInterval:1,
        privateKeyPath:'',
        securityPolicy:'',
        useSubscription:true,
        useCommonDeviceTopic: false,
        uniqueTopicIdentifier: ''
    }
    datasource:{} = {}
    connectorProtocol: string = ''
    description: string = ''
    deviceManufacturer: string = ''
    deviceModel: string = ''
    deviceType: string = ''
    gatewayManufacturer: string = ''
    gatewayModel: string = ''
    id: string = null
    installationType: string
    isDefault: true
    operation: string = ''
    operationBy: string = ''
    profileName: string = ''
    lineOfBusiness: string = ''
    sector: string = ''
    tags:string[]
    profileID: string
    status:string
    // measurableLocation:string=''
    // facilityType:string
    deviceMeasurement: {}={}
    location: {
        latitude: string,
        longitude: string
    }={
        latitude: '',
        longitude:''
    }
    metadata: {
        facilityType?:string,
        measurableLocation?:string,
        certName?:string,
        KeyName?:string,
        testConnection?:boolean,
        generate?:boolean,
        upload?:boolean,
        isIpReachable?:boolean
    } ={
        // testConnection:false,
        // generate:false,
        // upload:false
    }
    onboarding: true
    registrationMetadata: string[]=[]
    site:string[]=[]
    transformer: {
        downlink: {
            basePath: string,
            metadataAttributes: [
                {
                    destinationType: string,
                    name: string,
                    path: string,
                    sourceType: string
                }
            ],
            telemetryAttributes: [
                {
                    destinationType: string,
                    name: string,
                    path: string,
                    sourceType: string
                }
            ]
        },
        uplink: {
            basePath: string,
            metadataAttributes: [
                {
                    destinationType: string,
                    name: string,
                    path: string,
                    sourceType: string
                }
            ],
            telemetryAttributes: [
                {
                    destinationType: string,
                    name: string,
                    path: string,
                    sourceType: string
                }
            ]
        }
    } = {
            downlink: {
                basePath: null,
                metadataAttributes: null,
                telemetryAttributes: null
            },
            uplink: {
                basePath: '',
                metadataAttributes: [
                    {
                        destinationType: '',
                        name: '',
                        path: '',
                        sourceType: ''
                    }
                ],
                telemetryAttributes: [
                    {
                        destinationType: '',
                        name: '',
                        path: '',
                        sourceType: ''
                    }
                ]
            }
        }
}

export const defaultTopicIdentifierUplink = 'uplink/';
export const defaultTopicIdentifierDownlink = 'downlink/';
export const InstallationType = [{ name: 'Direct', value: 'Direct' }, { name: 'Via Gateway', value: 'viaGateway' }]
export const fetchingMechanism = ['Polling', 'Subscription']
export const messageSecurity = ['None', 'Sign','SignAndEncrypt']
export const securityPolicy = ['Basic128Rsa15','Basic256','Basic256Sha256','Aes128_Sha256_RsaOaep','Aes256_Sha256_RsaPss','None']
export const destinationType = ['int', 'long', 'string', 'boolean', 'millis','epochSeconds','double','epochMillis']
export const sourceType = ['int', 'long', 'string', 'boolean', 'millis', 'isoDate','epochSeconds','double','epochMillis']
export const DeviceType = ['Water Meter', 'Energy Meter', 'AQI Sensors', 'CCTV Cameras', 'Location Tracker']
export const ConnecterType = ['MQTT(S)', 'REST', 'OPCUA', 'SFTP', 'COAP','Modbus']
export const Sectors = ['Water','Energy'];
export const LineOfBusiness = ['Industrial','Customer']
export const AccesToken = 'Access Token';
export const Certificate = 'Certificate Based';
export const UsernamePassword = 'Username and Password';
export const AuthentationType = [{ name: AccesToken, value: 'Token' }, { name: Certificate, value: 'Certificate' }, { name: UsernamePassword, value: 'UserPass' }]
export const Status = {Draft:'draft',Create:'create',Created:'Created',Active:'Active',connectionSuccess:'Connection Successful',Delete:'delete',Registered:'Registered'}
export const DataTypes = [{name:'Enum',value:'Enum'},{name:'Double',value:'Double'},{name:'Float',value:'Float'},{name:'Int',value:'Int'},{name:'Boolean',value:'Boolean'},{name:'String',value:'String'},{name:'DateTime',value:'DateTime'}] 
export const StatusMessages ={duplicate:'DUPLICATE_KEY_EXCEPTION'}
export const ToastMessages ={duplicate:`Profile already exists with same name`,somethingWrong:'something went wrong',noFurtherNode:'This node does not have further child',SelectAtleastOne:'Select atleast one node',FillAllInfo:'Please fill all the required information to register profile(s)'}
