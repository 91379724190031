import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ApiService } from 'src/app/services/api.service';
import { waterCommercial, waterIndustrials } from 'src/app/reusable/constant';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  user: any
  public defaultDashboard = [
    {
      name: 'water',
      icon: '../../../assets/images/WATER.png',
      icon2: '../../../assets/Neom-Images/gps-icon.svg',
      option: [
        {
          name: 'Customer',
          option: [
            {
              name: 'Multiple Sites',
              description: 'Water Meters',
              route: '/watermeters/dashboard',
              icon: '../../../assets/images/WATER.png',
              heading: 'Residential'
            },
            {
              name: 'Royal Palace',
              description: 'Flow Meters',
              route: waterCommercial['Flow Meter'].route,
              icon: '../../../assets/images/WATER.png',
              heading: 'Commercial'
            },
            {
              name: 'Sample Device',
              description: 'Cooling Meters',
              route: '/coolingmeters/dashboard',
              icon: '../../../assets/images/WATER.png',
              heading: 'Residential'
            }
          ]
        },
        {
          name: 'Industrial',
          option: [
            {
              name: 'Duba',
              description: 'Desalination Plant',
              route: waterIndustrials['DubaPlantScada'].route,
              icon: '../../../assets/Neom-Images/desalination-plant.svg',
              heading: 'Drinking Water'
            },
            {
              name: 'Sharma',
              description: 'Pump Station & TFS',
              route: waterIndustrials['Pump Station'].route,
              icon: '../../../assets/Neom-Images/pump-station.svg',
              heading: 'Drinking Water'
            },
            {
              name: 'Sharma',
              description: 'High Point Reservoir',
              route: waterIndustrials['Dosing Plant'].route,
              icon: '../../../assets/Neom-Images/water-treatment-plant.svg',
              heading: 'Drinking Water'
            },
            {
              name: 'Al Bada',
              description: 'Water Recycling Plant',
              route: waterIndustrials['Water Recycling Plant'].route,
              icon: '../../../assets/Neom-Images/water-treatment-plant.svg',
              heading: 'Recycled Water'
            },
            {
              name: 'Sharma',
              description: 'Logistics Park',
              route: waterIndustrials['Logistics Park'].route,
              // icon: '../../../assets/Neom-Images/water-treatment-plant.svg',
              heading: 'Drinking Water'
            },
            {
              name: 'Sharma',
              description: 'NEOM Bay Airport',
              route: waterIndustrials['NEOM Bay Airport'].route,
              // icon: '../../../assets/Neom-Images/water-treatment-plant.svg',
              heading: 'Drinking Water'
            },
            {
              name: 'Sharma',
              description: 'Experience Center',
              route: waterIndustrials['Experience Center'].route,
              // icon: '../../../assets/Neom-Images/water-treatment-plant.svg',
              heading: 'Drinking Water'
            }
          ]
        }
      ]
    },
    {
      name: 'energy',
      icon: '../../../assets/images//neom-economic-sector-icons-rgb-energy@3x.png',
      icon2: '../../../assets/Neom-Images/gps-icon.svg',
      option: [
        {
          name: 'Customer',
          option: [
            {
              name: 'NEOM Community 1',
              description: 'Energy Meters',
              route: '/energymeters/dashboard',
              icon: '../../../assets/images//neom-economic-sector-icons-rgb-energy@3x.png',
              heading: 'Residential'
            }
          ]
        },
        {
          name: 'Industrial',
          option: [
            {
              name: 'Industrial',
              description: 'Solar Plant',
              tooltip: 'Coming soon',
              isDisable: true,
              // route: '/',
              icon: '../../../assets/images//neom-economic-sector-icons-rgb-energy@3x.png',
              heading: 'Energy Dashboard'
            }
          ]
        }
      ]
    }
  ]

  constructor(private keycloakService: KeycloakService) { }

  getLoggedUser() {
    if (localStorage.getItem('sso-user') === 'true') {
      //return this.keycloakService.getKeycloakInstance().profile.email || this.keycloakService.getKeycloakInstance().profile.username
      return localStorage.getItem('useremail');
    }
    else {
      return localStorage.getItem('useremail');
    }
  }

  logout() {
    this.keycloakService.isLoggedIn().then(() => {
      const userDetails = this.keycloakService.getKeycloakInstance()
      window.location.href = `${environment.keycloak.url}realms/${environment.keycloak.realm}/protocol/openid-connect/logout?post_logout_redirect_uri=${window.location.origin}/user&client_id=${environment.keycloak.clientId}&id_token_hint=${userDetails.idToken}`;

    })
  }

  logoutForNotInvite() {
    this.keycloakService.isLoggedIn().then(() => {
      const userDetails = this.keycloakService.getKeycloakInstance()
      window.location.href = `${environment.keycloak.url}realms/${environment.keycloak.realm}/protocol/openid-connect/logout?post_logout_redirect_uri=${window.location.origin}/user?unauthorized=true&client_id=${environment.keycloak.clientId}&id_token_hint=${userDetails.idToken}`;
    })
  }

  setToken() {
    localStorage.setItem('token', this.keycloakService.getKeycloakInstance().token);
    localStorage.setItem('useremail', this.keycloakService.getKeycloakInstance().profile.email);
    localStorage.setItem('sso-user', 'true');
    this.keycloakService.getKeycloakInstance().loadUserInfo().success((data: any) => {
      this.user = data
    })
  }

  loadUserInfo() {
    this.user = this.parseJwt(localStorage.getItem('token'));
  }

  getUserId() {
    if (localStorage.getItem('sso-user') === 'true') {
      return this.keycloakService.getKeycloakInstance().profile.id;
    }
    else {
      return this.user.sub;
    }
  }

  getRoles() {
    this.keycloakService.getUserRoles();
  }

  userHasAccess(role: string) {
    this.keycloakService.getUserRoles();
  }

  userHasGroup(role: string) {
    if (this.user) {
      return this.user.groups.indexOf(role) !== -1 ? true : false;
    }
    return false
  }

  parseJwt(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  extractNameFromEmail(email: string): string {
    if (email) {
      const userName = email.split('@')[0];
      if (userName.includes('.') || /\d/.test(userName)) {
        const parts = userName.split(/[\.\d]+/).filter(Boolean);
        const formattedName = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
        return formattedName;
      } else {
        return userName.charAt(0).toUpperCase() + userName.slice(1);
      }
    }
  }

  init() {
    this.keycloakService.init({
      config: environment.keycloak,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: true
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: ['/assets', '/clients/public']
    }).then((authenticated) => {
      if (authenticated) {
        this.setToken();
      }
    });
  }
}
