import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { IEntityHeader, lineOfBusiness } from './entity.interface';


@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private apiService: ApiService) { }

  public getDevices(deviceType: string, filter) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.customer,
      // site: site,
      sector: deviceType.split(' ')[0],
    }
    return this.apiService.post(`/registration/devices?deviceType=${deviceType}`,filter, entity);
  }

  public getDeviceById(deviceID: any, entity: IEntityHeader) {
    return this.apiService.get(`/registration/device-by-id?deviceID=${deviceID}`, entity);
  }

  public realTimeData(postData: any) {
    const entity: IEntityHeader = {
      deviceType: postData.deviceType,
      lineOfBusiness: lineOfBusiness.industrial,
      sector: 'Water',
    }
    return this.apiService.get(`/registration/real-time-data?deviceType=${postData.deviceType}&pageNumber=${postData.pageNumber}&pageSize=${postData.pageSize}&filter=${postData.filter}&measurableLocation=${postData.measurableLocation}`, entity);
  }

  public getParameterValue(filters: any) {
    const entity: IEntityHeader = {
      deviceType: filters.deviceType,
      lineOfBusiness: lineOfBusiness.industrial,
      sector: 'Water',
    }
    return this.apiService.post(`/registration/get-parameter-value`, filters, entity);
  }

  public getDeviceList(deviceType: any,measurableLocation) {
    const entity: IEntityHeader = {
      deviceType: deviceType,
      lineOfBusiness: lineOfBusiness.industrial,
      sector: 'Water',
    }
    return this.apiService.get(`/registration/list-devices?deviceType=${deviceType}&measurableLocation=${measurableLocation}`, entity);
  }

  public saveDeviceRange(payload) {
    const entity: IEntityHeader = {
      deviceType: payload[0].deviceType,
      lineOfBusiness: lineOfBusiness.industrial,
      sector: 'Water',
    }
    return this.apiService.put(`/registration/device`, payload, entity);
  }

  public fetchRegistrationMetadata(deviceID, entity: IEntityHeader) {
    return this.apiService.get(`/registration/registration-metadata?deviceID=${deviceID}`, entity);
  }

  public getTagDataForStatusValues() {
    return this.apiService.get(`/registration/device-with-enum`);
  }

  public GetFilterTypeAndCount(meterType: string) {
    return this.apiService.get(`/registration/filter-type-and-count?meterType=${meterType}`); 
  }

  public getDeviceInformation(payload) {
    return this.apiService.post(`/registration/get-device-information`, payload); 

  }

  getDeviceByDescription(payload: any) {
    return this.apiService.post('/registration/get-device-by-description', payload);
  }

  getStatusCountByDeviceType(payload) {
    return this.apiService.post('/registration/get-status-count-by-device-type', payload);
  }

  public getSiteZonesByDeviceType(deviceType: string) {
    return this.apiService.get(`/registration/get-sites-zones-by-device-type?deviceType=${deviceType}`);
  }

  getDeviceByMetricIds(payload: any) {
    return this.apiService.post('/registration/get-device-by-metricIDs', payload);
  }

  getDevicesLocationDetails(payload, username) {
    return this.apiService.post(`/registration/get-devices-location-details?username=${username}`, payload);
  }

  getSectorDivisions() {
    return this.apiService.get('/registration/get-sector-divisions');
  }

  getFacilitiesParametersData(payload) {
    return this.apiService.post('/registration/get-facilities-parameters-data', payload);
  }
}

