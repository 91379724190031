<div class="not-found-template">
    <div *ngIf="showIcon" class="d-flex justify-content-center">
        <img src="{{iconUrl}}" alt="" class="notfoundIcon">
    </div>
    <div class="title d-flex justify-content-center">
        <p class="font-weight-bold">{{title}}</p>
    </div>
    <div class="sub-title d-flex justify-content-center">
        <p>{{subTitle}}</p>
    </div>
</div>

