import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { SqlInjectionValidatorOptions } from '../reusable/common.interfaces';

@Directive({
  selector: '[appSqlInjectionValidation]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: SqlInjectionValidationDirective, multi: true }
  ]
})
export class SqlInjectionValidationDirective implements Validator {

  @Input('appSqlInjectionValidation') options: SqlInjectionValidatorOptions;

  constructor(private customValidator: CustomvalidationService) { }

  validate(control: AbstractControl): { [key: string]: any } | null {
    // Default values if not provided
    const defaultOptions: SqlInjectionValidatorOptions = {
      maxLength: 50
    };
    
    // Merge default options with provided options
    this.options = { ...defaultOptions, ...this.options };
    return this.customValidator.sqlInjectionValidator(this.options)(control);
  }
}
