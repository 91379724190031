import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appInputTrim]'
})
export class InputTrimDirective {

  constructor(private control: NgControl) { }

  @HostListener('blur')
  onBlur() {
    const value: string = this.control.value;
    if (typeof value === 'string') {
      this.control.control.setValue(value.trim());
    }
  }
}
