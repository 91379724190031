import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
@Component({
  selector: 'app-table-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css', '../../../assets/Reusable-CSS/form.scss']
})
export class TablePaginationComponent implements OnInit {


  @Input() current: number = 0;
  @Input() totalCount: number = 0;
  @Input() itemPerPage: number = 10;
  @Input() data: [];
  @Input() serverLoad = false;

  public total = 0;
  @Output() goTo: EventEmitter<number> = new EventEmitter<number>();
  @Output() next: EventEmitter<number> = new EventEmitter<number>();
  @Output() previous: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter<number>();

  public pages: number[] = [];
  public pageSizeOption = [5, 10, 20, 30, 100];

  ngOnInit(): void {
    if (this.serverLoad) {
      this.total = Math.ceil(this.totalCount / this.itemPerPage);
    }
    else {
      this.totalCount = this.data.length;
      this.total = Math.ceil(this.data.length / this.itemPerPage);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.current && changes.current.currentValue) ||
      (changes.total && changes.total.currentValue)
    ) {
      this.pages = this.getPages(this.current, this.total);
    }
  }

  public onGoTo(page: number): void {
    this.goTo.emit(page);
  }

  public onNext(): void {
    this.next.emit(this.current);
  }

  public onPrevious(): void {
    this.previous.next(this.current);
  }

  private getPages(current: number, total: number): any[] {
    if (total <= 7) {
      return [...Array(total).keys()].map((x) => ++x);
    }

    if (current > 5) {
      if (current >= total - 4) {
        return [1, '...', total - 4, total - 3, total - 2, total - 1, total];
      } else {
        return [1, '...', current - 1, current, current + 1, '...', total];
      }
    }
    return [1, 2, 3, 4, 5, '...', total];
  }

  tablePageSizeChange(event) {
    if (this.serverLoad) {
      this.total = Math.ceil(this.totalCount / this.itemPerPage);
    }
    else {
      this.totalCount = this.data.length;
      this.total = Math.ceil(this.data.length / this.itemPerPage);
    }
    this.pageSizeChange.emit(Number(this.itemPerPage));
  }
}
