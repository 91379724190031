<div id="alertTable">
    <table>
        <thead>
            <tr>
                <th class="tableHeaderCell" id="alertNameHeader">
                    <app-table-select-filter [data]="filterColumns['eventName']"
                        [name]="'Event Name'" (handleAppliedFilter)="handleAppliedFilter($event)"
                        (applySorting)="sortTable($event)">
                    </app-table-select-filter>
                </th>
                <th class="tableHeaderCell secondRow">
                    Timestamp
                </th>
                <th class="tableHeaderCell">
                    Event Details
                </th>
                <th class="lastColumn tableHeaderCell"> </th>
            </tr>
        </thead>
        <tbody>
            
            <!-- if it is loading show loader -->
            <ng-container *ngIf="loading ">
                <tr class="loadingGif">
                    <td colspan="8" class="loading">
                        <div class="loader"></div>
                    </td>
                </tr>

            </ng-container>
            <!-- if it is not loading and no data found show no data found -->
            <ng-container *ngIf="!loading && !paginatedData?.length">
                <tr id="noResult">
                    <td colspan="8" class="noResult">
                        No logs avaliable
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="!loading && paginatedData?.length">
                <tr *ngFor="let log of paginatedData" style="cursor: pointer;">
                    <td class="secondRow">
                        {{log.eventName}}
                    </td>
                    <td>
                        {{log.timestamp | date: 'dd-MMM-y, hh:mm:ss'}}
                    </td>
                    <td>{{log.events[0]['unit']}} = {{log.events[0]['value']}}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>
<app-pagination #tablePagination [currentPage]="currentPage" [data]="data" [itemPerPage]="itemPerPage"
    (paginationDone)="handlePaginationDone($event)" [COLUMN_NAMES]="columnNames" [appliedFilters]="appliedFilters"
    [pageSizeOption]="pageSizeOption">
</app-pagination>