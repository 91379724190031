import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomDashboardService {

  constructor(private apiService: ApiService, private http: HttpClient) { }

  public getAllCustomDashboard(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.get('/widgetDashboard/getAllDashboard/' + id)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }

  public saveCustomDashboard(postData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.post('/widgetDashboard/saveDashboard', postData)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }


  public updateCustomDashboard(postData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.put('/widgetDashboard/updateDashboard', postData)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }

  public deleteCustomDashboard(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.delete(`/widgetDashboard/deleteDashboard/${id}`)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }

  public shareCustomDashboard(postData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.put('/widgetDashboard/shareDashboard', postData)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }

  public saveCustomDashboardPosition(postData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.put('/widgetDashboard/saveDashboardPosition', postData)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }
  
  // public savePinnedDashboard(postData): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.apiService.post('/widgetDashboard/savePinnedDashboard', postData)
  //       .subscribe((response) => {
  //         resolve(response);
  //       }, (error: any) => {
  //         reject(error);
  //       });
  //   });
  // }

  public getPinnedDashboard(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiService.get('/widgetDashboard/getPinnedDashboard/' + id)
        .subscribe((response) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }
}