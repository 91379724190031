import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private apiService: ApiService) { }

  public createGroup(data: any) {
    return this.apiService.post(`/user-management/groups/`, data);
  }

  public updateGroup(data: any) {
    return this.apiService.put(`/user-management/groups`, data);
  }

  public getGroups(first: number, max: number, payload) {
    return this.apiService.post(`/user-management/groups-with-pagination?first=${first}&max=${max}`, payload);
  }

  public getGroupById(id: string) {
    return this.apiService.get(`/user-management/groups/${id}`);
  }

  public getAllGroups() {
    return this.apiService.get(`/user-management/groups`);
  }

  public deleteGroup(id: string) {
    return this.apiService.delete(`/user-management/groups/${id}`);
  }

  public updateGroupUsers(data) {
    return this.apiService.put(`/user-management/update-group-users`, data);
  }

  public deleteGroupUsers(data) {
    return this.apiService.put(`/user-management/delete-group-users`, data);
  }
  
  public getUserRoles(email) {
    return this.apiService.get(`/user-management/role-by-email?email=${email}`);
  }

  public getDeviceProfiles() {
    return this.apiService.get(`/user-management/device-profiles`);
  }

  // node api
  public getAllUsers(first: number, max: number, filters,invitedBy:string) {
    return this.apiService.get(`/user-management/users?first=${first}&max=${max}&organization=${filters['organization']}&sector=${filters['sector']}&invitedBy=${invitedBy}`);
  }

  public updateUser(id: string, data: any) {
    return this.apiService.put(`/user-management/users/${id}`, data);
  }

  public deleteUser(id: string) {
    return this.apiService.delete(`/user-management/users/${id}`);
  }

  public getUsers(search: string) {
    return this.apiService.get(`/user-management/get-users?search=${search}`);
  }

  public getAllSiteAdminUsers(id: string) {
    return this.apiService.get(`/user-management/site-admin/${id}`);
  }

  public getAccessforInviteUser() {
    return this.apiService.get(`/user-management/get-access-for-invite-user`);
  }

  public giveAccessToInviteUser(data: Array<string>, id: string) {
    return this.apiService.post(`/user-management/give-access-to-invite-user/${id}`, data);
  }

  public addUsersToGroup(data: any) {
    return this.apiService.put(`/user-management/assign-access-to-user`, data);
  }

  public removeUsersFromGroup(data: any) {
    return this.apiService.put(`/user-management/revoke-access-to-user`, data);
  }

  public validateToken() {
    return this.apiService.post(`/user-management/validate-token`, null);
  }

  public getRoles() {
    return this.apiService.get(`/user-management/roles`);
  }

  public inviteUser(data: object) {
    return this.apiService.post(`/user-management/invite-user`, data);
  }

  public getInvitedUsers() {
    return this.apiService.get(`/user-management/invited-users`);
  }

  public revokeUserInvitation(id:string) {
    return this.apiService.delete(`/user-management/revoke-user-invitation/${id}`);
  }
}
