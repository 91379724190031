<section class="child-data-section">
    <div id="cardDraw">
        <div class="backArrow">
            <img (click)="back()" src="assets/images/backArrow.svg">
        </div>
        <div class="dashboardName">
            {{option.name}} dashboards
        </div>
    </div>
    <div class="subHeader">
        {{option.option.length}} result found
    </div>
    <div class="dashboardResult">
        <div *ngFor="let _option of option.option" ngbTooltip={{_option.tooltip}} class="dashboardCard" [routerLink]="_option.isDisable === true ? null : [_option.route]">
            <div class="cardHeader">
                <!-- <span *ngIf="">{{dashboard.name}} Dashboard</span> -->
                <span>{{_option.heading}}</span>

            </div>
            <div id="childCardDetails">
                <div>
                    <div class="cardType">{{_option.description}}</div>
                    <div class="category"><img src="{{dashboard.icon2}}" alt=""> {{_option.name}}</div>
                </div>
                <div class="cardImg">
                    <img *ngIf="_option?.icon" src="{{_option.icon}}" alt="">
                </div>
            </div>
        </div>
    </div>
</section>