import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { deviceTypeDashboardRoute } from '../../route-constants';
import { getTemplatesBySystemType } from './landing-page-esri-templates';

@Injectable({
  providedIn: 'root'
})
export class LandingPageEsriMapService {

  constructor() { }

  createFields(systemType) {
    if(systemType === 'Devices') {
      return  [
        { name: "oid",alias: "Object ID",type: "oid"},
        { name: 'deviceID ', alias: 'Device ID', type: 'string' },
        { name: 'uplinkReferenceKey', alias: 'Uplink Reference Key', type: 'string' },
        { name: 'deviceType', alias: 'Device Type', type: 'string' },
        { name: 'status', alias: 'Status', type: 'string' },
        { name: 'measurableLocation', alias: 'Location', type: 'string' },
        { name: 'percentageData', alias: 'Percentage Data', type: 'string' },
        { name: 'consumptionFor30Days', alias: 'Consumption', type: 'double' },
        { name: 'deviceManufacturer', alias: 'Device Manufacturer', type: 'string' },
        { name: 'dmsLongitude', alias: 'DMS Longitude', type: 'string' },
        { name: 'dmsLatitude', alias: 'DMS Latitude', type: 'string' },
        { name: 'domain', alias: 'Domain', type: 'string' },
        { name: 'description', alias: 'Description', type: 'string' },
        {name : 'activeAlerts', alias: 'Active Alerts', type: 'integer'},
        {name : 'pointColor', alias: 'Point Color', type: 'string'},
        { name: 'flow', alias: 'Flow', type: 'string' },
        { name: 'volume', alias: 'Volume', type: 'string' },
        { name: 'lastDataReceivedOn', alias: 'Last Data Recieved On', type: 'string' },
        { name: 'consumptionUnit', alias: 'Consumption Unit', type: 'string' }
      ]
    }
    else if(systemType === 'SCADA Systems') {
      return [
        { name: "oid",alias: "Object ID",type: "oid"},
        { name: 'deviceType', alias: 'Device Type', type: 'string' },
        { name: 'deviceID', alias: 'Device ID', type: 'string' },
        { name: 'uplinkReferenceKey', alias: 'Uplink Reference Key', type: 'string' },
        { name: 'status', alias: 'Status', type: 'string' },
        { name: 'dmsLongitude', alias: 'DMS Longitude', type: 'string' },
        { name: 'dmsLatitude', alias: 'DMS Latitude', type: 'string' },
        { name: 'lastDataReceivedOn', alias: 'Last Data Recieved On', type: 'string' },
        { name: 'conductivity', alias: 'Conductivity', type: 'string' },
        { name: 'turbidity', alias: 'Turbidity', type: 'string' },
        { name: 'domain', alias: 'Domain', type: 'string' },
        { name: 'description', alias: 'Description', type: 'string' },
        {name : 'pH', alias: 'pH', type: 'string'},
        {name : 'activeAlerts', alias: 'Active Alerts', type: 'integer'},
        {name : 'pointColor', alias: 'Point Color', type: 'string'}
      ]
    }
    else if(systemType === "Transmission Lines"){
      return [
        { name: "oid",alias: "Object ID",type: "oid"},
        { name: 'deviceType', alias: 'Device Type', type: 'string' },
        { name: 'deviceID', alias: 'Device ID', type: 'string' },
        { name: 'uplinkReferenceKey', alias: 'Uplink Reference Key', type: 'string' },
        { name: 'status', alias: 'Status', type: 'string' },
        { name: 'dmsLongitude', alias: 'DMS Longitude', type: 'string' },
        { name: 'dmsLatitude', alias: 'DMS Latitude', type: 'string' },
        { name: 'lastDataReceivedOn', alias: 'Last Data Recieved On', type: 'string' },
        { name: 'downstreamPressure', alias: 'Downstream Pressure', type: 'string' },
        { name: 'upstreamPressure', alias: 'Upstream Pressure', type: 'string' },
        { name: 'flowRate', alias: 'Flow Rate', type: 'string' },
        { name: 'domain', alias: 'Domain', type: 'string' },
        { name: 'description', alias: 'Description', type: 'string' },
        {name : 'activeAlerts', alias: 'Active Alerts', type: 'integer'},
        {name : 'pointColor', alias: 'Point Color', type: 'string'}
      ]
    }
  }

  createAttributes(device, systemType, isItMeter, groupedDevices) {
    const key = `${device.location?.longitude},${device.location?.latitude}`;
    const isMultipleDeviceOnAPoint = groupedDevices[key].length > 1 ? 'multiple' : 'single';
    if(systemType === 'Devices') {
      return {
        oid: device.oid,
        deviceID: device.deviceID || '',
        uplinkReferenceKey: device.uplinkReferenceKey,
        deviceManufacturer: device.deviceManufacturer,
        status: device.status,
        measurableLocation: device.measurableLocation,
        deviceType: device.deviceType,
        percentageData: device.percentageData?.toFixed(2).toString() || '',
        consumptionFor30Days: device.consumptionFor30Days || 0,
        consumptionUnit: device.consumptionUnit || '',
        dmsLongitude: this.decimalToDMS(device.location.longitude),
        dmsLatitude: this.decimalToDMS(device.location.latitude),
        domain: `${window.location.origin}${deviceTypeDashboardRoute[device.deviceType]}/${device.deviceID ? device.deviceID: device.description}`,
        description: device.description || '',
        pointColor: `${isMultipleDeviceOnAPoint}-${device.activeAlerts > 0 ? 'red' : (device.status === 'Live' ? 'green' : 'gray')}`,
        volume: device.Volume || '',
        flow: device.Flow || '',
        lastDataReceivedOn: moment(device.lastDataReceivedOn).format('Do MMMM YYYY, h:mm:ss A') || '',
        activeAlerts: device.activeAlerts || 0
      }
    }
    else if(systemType === 'SCADA Systems'){
      const key = `${device.location?.longitude},${device.location?.latitude}`;
      const isMultipleDeviceOnAPoint = groupedDevices[key].length > 1 ? 'multiple' : 'single';
      return {
        oid: device.oid,
        deviceType: device.deviceType,
        status: device.status,
        lastDataReceivedOn: moment(device.lastDataReceivedOn).format('Do MMMM YYYY, h:mm:ss A'),
        dmsLongitude: this.decimalToDMS(device.location.longitude),
        dmsLatitude: this.decimalToDMS(device.location.latitude),
        conductivity: device.Conductivity || '',
        turbidity: device.Turbidity || '',
        description: device.description || '',
        pH : device.pH || '',
        domain: `${window.location.origin}${deviceTypeDashboardRoute[device.deviceType]}`,
        pointColor: `${isMultipleDeviceOnAPoint}-${device.activeAlerts > 0 ? 'red' : (device.status === 'Live' ? 'green' : 'gray')}`,
        activeAlerts: device.activeAlerts || 0
      }
    }
    else if(systemType === "Transmission Lines") {
      const key = `${device.location?.longitude},${device.location?.latitude}`;
      const isMultipleDeviceOnAPoint = groupedDevices[key].length > 1 ? 'multiple' : 'single';
      return {
        oid: device.oid,
        deviceType: device.deviceType,
        status: device.status,
        lastDataReceivedOn: moment(device.lastDataReceivedOn).format('Do MMMM YYYY, h:mm:ss A'),
        dmsLongitude: this.decimalToDMS(device.location.longitude),
        dmsLatitude: this.decimalToDMS(device.location.latitude),
        downstreamPressure : device['Downstream Pressure'] || '',
        upstreamPressure: device['Upstream Pressure'] || '',
        flowRate: device['Flow Rate'] || '',
        description: device.description || '',
        domain: `${window.location.origin}${deviceTypeDashboardRoute[device.description]}`,
        pointColor: `${isMultipleDeviceOnAPoint}-${device.activeAlerts > 0 ? 'red' : (device.status === 'Live' ? 'green' : 'gray')}`,
        activeAlerts: device.activeAlerts || 0
      }
    }
  }

  decimalToDMS(decimal) {
    const degrees = Math.floor(decimal);
    const decimalMinutes = (decimal - degrees) * 60;
    const minutes = Math.floor(decimalMinutes);
    const seconds = Math.round((decimalMinutes - minutes) * 60);

    return `${degrees}° ${minutes}\´${seconds}`
  }

  getLabelClass(LabelClass, TextSymbol) {
    return new LabelClass({
      labelExpressionInfo: {
        expression: "$feature.description"
      },
      symbol: new TextSymbol({
        color: "black",
        haloColor: "white",
        haloSize: "1px",
        font: {
          size: 12
        },
       
      }),
      labelPlacement: "center-right",
      deconflictionStrategy: "none",
      allowOverlapping: true
    })
  }
  
  getRenderer(UniqueValueRenderer,  meterOrFacility, SimpleMarkerSymbol) {
    const shapeAndSize = this.getShapeAndSize(meterOrFacility);    
    return  new UniqueValueRenderer({
      field: "pointColor",
      uniqueValueInfos: [
        {
          value: "single-green",
          symbol: new SimpleMarkerSymbol({
            color:'#4dc254', //Green color
            size: shapeAndSize.size,
            style: shapeAndSize.style,
          }),
          label: "Live"
        },
        {
          value: "single-gray",
          symbol: new SimpleMarkerSymbol({
            color: '#AAAAAA', //Gray Color
            haloColor: "white",
            size: shapeAndSize.size,
            style: shapeAndSize.style,
          }),
          label: "Offline"
        },
        {
          value: "single-red",
          symbol: new SimpleMarkerSymbol({
            color: '#d74454', //Red Color
            haloColor: "white",
            size: shapeAndSize.size,
            style: shapeAndSize.style,
          }),
          label: "Active Alert"
        },
        {
          value: "multiple-green",
          symbol: {
            type: "picture-marker", // Use a picture-marker for the icon
            url: this.getConcentricCircleIcon('#4dc254'), // green color 
            width: "20px",
            height: "20px"
          },
          label: "Multiple Green"
        },
        {
          value: "multiple-gray",
          symbol: {
            type: "picture-marker", // Use a picture-marker for the icon
            url: this.getConcentricCircleIcon('#AAAAAA'), 
            width: "20px",
            height: "20px"
          },
          label: "Multiple Gray"
        },
        {
          value: "multiple-red",
          symbol: {
            type: "picture-marker", // Use a picture-marker for the icon
            url: this.getConcentricCircleIcon('#d74454'), 
            width: "20px",
            height: "20px"
          },
          label: "Multiple Alert"
        }
      ]
    });
  }

  getShapeAndSize(meterOrFacility) {
    switch(meterOrFacility) {
      case 'SCADA Systems':
        return {
          size: '12px',
          style: 'circle'
        }
      case 'Transmission Lines':
        return {
          size: '12px',
          style: 'circle'
        }
      default :
        return {
          size: '12px',
          style : 'circle'
        }

    }
  }

  createTemplate(data, systemType) {
    const meterOrFacility = data.deviceType === 'Flow Meter' ? 'Flow Meter' : systemType;
    return getTemplatesBySystemType(meterOrFacility, data)   
  }
  //Define a function to generate the custom content for the cluster popup
  generateClusterContent(graphic) {
    const clusterData = graphic.attributes.cluster_data;
    let content = "<b>Clustered Devices:</b><br><ul>";
    clusterData.forEach(device => {
        content += `<li>Device ID: ${device.deviceID}, Status: ${device.status}</li>`;
    });
    content += "</ul>";
    return content;
  }

  getFeatureReductionTemplateForRadius(clusterRadius) {
    const clusterSymbol = {
      type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
      style: "circle", // You can change this to 'circle', 'diamond', 'cross', 'x', etc.
      color: "black",
      size: "30px", // Size of the cluster marker
    };
    return {
      type: 'cluster',
      clusterRadius: clusterRadius, // Increase the radius to form larger initial clusters
      // maxClusterRadius: 10, // Maximum cluster radius to enforce single large cluster
      clusterMinSize: "50px",
      clusterMaxSize: "60px",
      labelingInfo: [
        {
          deconflictionStrategy: "none",
          labelExpressionInfo: {
            expression: "Text($feature.cluster_count, '#,###')"
          },
          symbol: {
            type: "text",
            color: "white",
            font: {
              family: "Noto Sans",
              size: "14px",
              weight: "bold"
            },

          },
          labelPlacement: "center-center"
        }
      ],
      symbol: clusterSymbol, // Apply the custom cluster symbol
      // popupTemplate: {
      //   content: this.generateClusterContent,
      //   fieldInfos: [{
      //     fieldName: "cluster_count",
      //     format: {
      //       places: 0,
      //       digitSeparator: true
      //     }
      //   }]
      // }
    }
  }

  getConcentricCircleIcon(fillColor) {
    const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 40 40">
      <!-- Outer Circle -->
      <circle cx="20" cy="20" r="17" fill="transparent" stroke="black" stroke-width="1.5"/>
  
      <!-- Inner Circle -->
      <circle cx="20" cy="20" r="10" fill="${fillColor}" stroke="black" stroke-width="1.5"/>
    </svg>
  `;
  return `data:image/svg+xml;base64,${btoa(svgContent)}`;

  }
   
  getColorStopsForHeatMap() {
    const colors = ["rgba(115, 115, 0, 0)", "rgba(6, 189, 18, 0.51)", "rgba(0, 248, 33, 0.44)", "rgba(104, 255, 16, 0.44)", "rgba(134, 255, 16, 0.44)", "rgba(164, 255, 16, 0.44)", "rgba(198, 255, 16, 0.44)", "rgba(233, 255, 16, 0.44)", "rgba(255, 247, 16, 0.44)", "rgba(255, 187, 16, 0.44)", "rgba(255, 147, 16, 0.44)", "rgba(255, 97, 16, 0.44)", "rgba(255, 52, 21, 0.44)"];
    return  [
      { color: colors[0], ratio: 0 },
      { color: colors[1], ratio: 0.083 },
      { color: colors[2], ratio: 0.166 },
      { color: colors[3], ratio: 0.249 },
      { color: colors[4], ratio: 0.332 },
      { color: colors[5], ratio: 0.415 },
      { color: colors[6], ratio: 0.498 },
      { color: colors[7], ratio: 0.581 },
      { color: colors[8], ratio: 0.664 },
      { color: colors[9], ratio: 0.747 },
      { color: colors[10], ratio: 0.831 },
      { color: colors[11], ratio: 0.913 },
      { color: colors[12], ratio: 1 }
    ]
  }

}
