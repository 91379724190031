import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';
import { ToastService } from 'src/app/services/toast/toast.service';
import { v4 as uuidv4 } from 'uuid';
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_DAY_END, DATE_TIME_FORMAT_DAY_START } from '../constant';
@Component({
  selector: 'app-common-datepicker',
  templateUrl: './common-datepicker.component.html',
  styleUrls: ['./common-datepicker.component.css']
})
export class CommonDatepickerComponent implements OnInit, OnChanges {

  @Output()
  dateChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private toastr: ToastService) { }

  public daterange: any = {};
  @Input()
  public minDate: any;
  @Input()
  public maxDate: any;
  @Input()
  public dateSelected?: any;

  @Input()
  public disabled?: boolean = false;

  public dateDisplay: any;
  public uuidv4 = 'commondatepicker'+uuidv4();
  
  // attributes for calendar
  public options: any = { };

  ngOnInit() {
  }

  ngOnChanges() {
    this.initChanges();
  }

  ngAfterViewInit() {
    this.initChanges();
  }


  initChanges() {
    const selectedDate = this.dateSelected;
    let startDate = moment(selectedDate[0]);
    let endDate = moment(selectedDate[1]);
    this.options = {
      timePicker : true,
      timePicker24Hour: true,
      // timePickerSeconds : true,
      ranges: {
        'Today': [moment(new Date()).subtract(moment().hour(), 'h').format(DATE_TIME_FORMAT_DAY_START), moment().format(DATE_TIME_FORMAT)],
        '24 Hours': [moment().subtract(24, 'h').format(DATE_TIME_FORMAT), moment().format(DATE_TIME_FORMAT)],
        '3 Days': [moment().subtract(2, 'days').format(DATE_TIME_FORMAT_DAY_START), moment().format(DATE_TIME_FORMAT_DAY_END)],
        '7 Days': [moment().subtract(6, 'days').format(DATE_TIME_FORMAT_DAY_START), moment().format(DATE_TIME_FORMAT_DAY_END)],
        '30 Days': [moment().subtract(29, 'days').format(DATE_TIME_FORMAT_DAY_START), moment().format(DATE_TIME_FORMAT_DAY_END)],
        'YTD': [moment().startOf('year').format(DATE_TIME_FORMAT_DAY_START), moment().format(DATE_TIME_FORMAT_DAY_END)],
        'MTD': [moment().startOf('month').format(DATE_TIME_FORMAT_DAY_START), moment().format(DATE_TIME_FORMAT_DAY_END)],
      },
      locale: { format: 'YYYY-MM-DD HH:mm' },
      alwaysShowCalendars: true,
      showCustomRangeLabel: true,
      minDate: this.minDate,
      maxDate: moment().format(DATE_TIME_FORMAT_DAY_END),
      startDate: startDate.format('YYYY-MM-DD HH:mm:ss'),
      endDate: endDate.format('YYYY-MM-DD HH:mm:ss'),
      parentEl: 'div.'+this.uuidv4
    };
    this.displayDate(startDate, endDate); 
  }


  public selectedDate(value: any, datepicker?: any) {
    this.dateSelected = [];
    if(value.label == 'Custom Range') {
      this.dateSelected.push(value.start);
      this.dateSelected.push(value.end);
    }
    else {
      this.dateSelected.push(value.start.format('YYYY-MM-DD'))
      this.dateSelected.push(value.end.format('YYYY-MM-DD'))
    }
    this.dateSelected.push(value.label);
    this.dateChange.emit(this.dateSelected);
  }


  public displayDate(startDate, endDate) {
    this.dateDisplay = startDate.format('Do MMM YY') + ' - ' + endDate.format('Do MMM YY');
  }

}